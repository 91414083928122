/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import Button from '../../Common/Button'
import InputGroup from '../../Common/InputGroup'
import { Breadcrumb, Card, Dropdown, Table } from 'react-bootstrap'
import AnchorLink from '../../Common/AnchorLink'
import App_url from '../../Common/Constants'
import ContentLayout from '../../layout/ContentLayout'
import { useDispatch, useSelector } from 'react-redux'
import wsSendRequest from '../../../socketIO/wsSendRequest'
import { WebSocketContext } from '../../../WSContext'
import Icon from '../../Common/Icon'
import { toast } from 'react-toastify'
import Utils from '../../Common/Utils'
import { useLocation, useNavigate } from 'react-router-dom'
import { setWebsocketReqRes } from '../../../store/modules/users_data/action'
import UtilsGetCalculations from '../UtilsGetCalculations'
import { useTranslation } from '../../../translate'
import UtilsGetList from '../../Common/UtilsGetList'
import { setStoreGlobalLoader } from '../../../store/modules/setting_reducers/action'

const MaintenanceCleaning = () => {
    const { t, changeLanguage } = useTranslation();

    const navigate = useNavigate()
    const { ws, send } = useContext(WebSocketContext);
    const dispatch = useDispatch();
    const { lang, globalLoader } = useSelector(App_url.settingReducers)
    const { device_id, websocketReqRes } = useSelector(App_url.userDataReducers);
    const { customerDetails } = useSelector(App_url.customerReducers);
    const { currentCategory, roomList } = useSelector(App_url.calculationReducers);
    // console.log("floorlist",roomList?.floor_list)
    const location = useLocation();
    const { service } = location.state || {};
    // console.log("service", service);

    const [formData, setFormData] = useState({
        surface_area: "",
        full_cleaning_performance_value: "",
        calculation_id: "",
        weekday_hourly_rate: "",
        full_cleaning_monthly_cost: 0,
        full_cleaning_yearly_cost: 0,
        full_cleaning_yearly_weekday_frequency: 0,
        full_cleaning_yearly_sunday_frequency: 0,
        full_cleaning_monthly_sunday_cost: 0,
        full_cleaning_monthly_weekday_cost: 0,
        sunday_hourly_rate: "",
        full_cleaning_monthly_sunday_hours: 0,
        full_cleaning_monthly_weekday_hours: 0,
        full_cleaning_monthly_hours: 0,
        full_cleaning_yearly_hours: 0,
        room_type: "",
        floor_name: "",
        room_name: "",
        room_number: "",
        full_cleaning: false,
        visual_cleaning: false,
        visual_cleaning_weekday_frequency: 0,
        visual_cleaning_sunday_frequency: 0,
        visual_cleaning_performance_value: "",
        visual_cleaning_monthly_hour: 0,
        visual_cleaning_yearly_hour: 0,
        visual_cleaning_monthly_cost: 0,
        visual_cleaning_yearly_cost: 0,
        visual_cleaning_sunday_hour: 0,
        visual_cleaning_weekday_hour: 0,
        visual_cleaning_sunday_cost: 0,
        visual_cleaning_weekday_cost: 0,

        visualSundayFrequency: "",
        visualWeekdayFrequency: "",
        weekdayFrequency: "",
        sundayFrequency: '',

        customer_id: "",
        category_id: "",
        category_name: "",
        calculation_id: "",

    })
    const [error, setError] = useState({
        surface_area: "",
        full_cleaning_performance_value: "",
        visual_cleaning_performance_value: "",
        full_cleaning_yearly_weekday_frequency: "",
        full_cleaning_yearly_sunday_frequency: "",
        weekday_hourly_rate: "",
        sunday_hourly_rate: "",
        visual_cleaning_weekday_frequency: "",
        visual_cleaning_sunday_frequency: "",
        room_type: "",
        floor_name: "",
        room_name: "",
        room_number: "",
    })

    useEffect(() => {
        if (service) {
            console.log("service====", service);
            setFormData((prevData) => ({
                ...prevData,
                ...service,

                customer_id: service?.customer_id,
                category_id: service?.category_id,
                category_name: service?.category_name,
                calculation_id: service?.calculation_id,

                full_cleaning_performance_value: service?.value,
                visual_cleaning_performance_value: service?.visual_cleaning_value,
                weekday_hourly_rate: service?.hourly_rate,
                sunday_hourly_rate: service?.sunday_hourly_rate,

                full_cleaning_monthly_weekday_hours: service?.weekday_hour,
                full_cleaning_monthly_sunday_hours: service?.sunday_hour,
                full_cleaning_monthly_hours: service?.monthly_hour,
                full_cleaning_yearly_hours: service?.yearly_hour,

                full_cleaning_monthly_weekday_cost: service?.weekday_cost,
                full_cleaning_monthly_sunday_cost: service?.sunday_cost,
                full_cleaning_monthly_cost: service?.monthly_cost,
                full_cleaning_yearly_cost: service?.yearly_cost,


                full_cleaning_yearly_weekday_frequency: service?.weekday_frequency,
                full_cleaning_yearly_sunday_frequency: service?.sunday_frequency,
                sunday_hourly_rate: service?.sunday_hourly_rate,


                weekdayFrequency: selectWeekDayList?.find(item => item?.freq === service?.weekday_frequency)?.label,
                sundayFrequency: selectSundayList?.find(item => item?.freq === service?.sunday_frequency)?.label,
                visualWeekdayFrequency: selectWeekDayList?.find(item => item?.freq === service?.visual_cleaning_weekday_frequency)?.label,
                visualSundayFrequency: selectSundayList?.find(item => item?.freq === service?.visual_cleaning_sunday_frequency)?.label,

                full_cleaning: service?.weekday_frequency ? true : false,
                visual_cleaning: service?.visual_cleaning_weekday_frequency ? true : false,
            }))
        }
    }, [service])

    const callFloorListApi = (page) => {
        const param = {
            transmit: "broadcast",
            url: "room_list",
            request: {
                limit: "20",
                page_no: page,
                order_by: "-updated_at"
            }
        };
        send(param, device_id);
    };
    useEffect(() => {
        if (ws) {
            callFloorListApi(1);
        }
    }, [ws]);



    const validation = () => {
        let val = true;
        let error = {};

        if (!formData?.floor_name) {
            error.floor_name = t("Please select floor type");
            val = false;
        }
        if (!formData?.room_type) {
            error.room_type = t("Please select room type");
            val = false;
        }
        if (!formData?.room_name) {
            error.room_name = t("Please enter room name");
            val = false;
        }
        if (!formData?.room_number) {
            error.room_number = t("Please enter room number");
            val = false;
        }

        if (!formData?.surface_area) {
            error.surface_area = t("Please enter surface area");
            val = false;
        } else if (formData?.surface_area <= 0) {
            error.surface_area = t(" must be a positive number");
            val = false;
        }

        if (!formData?.weekday_hourly_rate) {
            error.weekday_hourly_rate = t("Please enter hourly rate");
            val = false;
        }

        if (!formData?.sunday_hourly_rate) {
            error.sunday_hourly_rate = t("Please enter sunday hourly rate");
            val = false;
        }

        error.full_cleaning_yearly_weekday_frequency = "";
        error.full_cleaning_yearly_sunday_frequency = "";
        error.visual_cleaning_weekday_frequency = "";
        error.visual_cleaning_sunday_frequency = "";    

        // Check if at least one frequency is selected
        if (!formData?.full_cleaning_yearly_weekday_frequency &&
            !formData?.full_cleaning_yearly_sunday_frequency &&
            !formData?.visual_cleaning_weekday_frequency &&
            !formData?.visual_cleaning_sunday_frequency) {
            error.full_cleaning_yearly_weekday_frequency = t("Please select at least one frequency"); // Combined error message
            val = false;
        }
        // Clear previous errors
        error.full_cleaning_performance_value = "";
        error.visual_cleaning_performance_value = "";

        // Check if any full cleaning frequency is selected (weekday or sunday)
        if (formData?.full_cleaning_yearly_weekday_frequency || formData?.full_cleaning_yearly_sunday_frequency) {
            // Validate full cleaning value if a full cleaning frequency is selected
            if (!formData?.full_cleaning_performance_value || formData.full_cleaning_performance_value <= 0) {
                error.full_cleaning_performance_value = t("enter a positive value for full cleaning");
                val = false;
            }
        }

        // Check if any visual cleaning frequency is selected (visual weekday or visual sunday)
        if (formData?.visual_cleaning_weekday_frequency || formData?.visual_cleaning_sunday_frequency) {
            // Validate visual cleaning value if a visual cleaning frequency is selected
            if (!formData?.visual_cleaning_performance_value || formData.visual_cleaning_performance_value <= 0) {
                error.visual_cleaning_performance_value = t("enter a positive value for visual cleaning");
                val = false;
            }
        }

        setError((prevData) => ({
            ...prevData,
            ...error
        }));

        return val;
    }
    const onChange = (e, index) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,  // Update the current field value
            // Set full_cleaning to true if the entered value is greater than 0
            ...(name === 'full_cleaning_performance_value' && { full_cleaning: Number(value) > 0 }),
            // Set visual_cleaning to true if the entered visual_cleaning_value is greater than 0
            ...(name === 'visual_cleaning_performance_value' && { visual_cleaning: Number(value) > 0 })
        }));

        setError((prevData) => ({
            ...prevData,
            [e.target.name]: "",
        }))
    };
    // console.log(formData?.weekday_hourly_rate, formData?.sunday_hourly_rate)


    const handleCalculationClick = () => {
        const isValid = validation();
        if (!isValid) {
            console.log("Validation failed.");
            return;
        }
        if (lang === "de") {
            console.log("formData", formData)

            UtilsGetCalculations?.calculateMaintenanceCleaningGermen({ formData, setFormData });
        }
        else {
            UtilsGetCalculations?.calculateMaintenanceCleaning({ formData, setFormData });
        }
    }
    const selectWeekDayList = [
        { label: t('No Weekday'), value: t('No Weekday'), freq: 0 },

        { label: t("Once a week"), value: t("Once a week"), freq: 52 },
        { label: t("Twice a week"), value: t("Twice a week"), freq: 104 },
        { label: t("Three times a week"), value: t("Three times a week"), freq: 156 },
        { label: t("Four times a week"), value: t("Four times a week"), freq: 208 },
        { label: t("Five times a week"), value: t("Five times a week"), freq: 260 },
        { label: t("Six times a week"), value: t("Six times a week"), freq: 312 },
        { label: t("Four times a month"), value: t("Four times a month"), freq: 48 },
        { label: t("Three times a month"), value: t("Three times a month"), freq: 36 },
        { label: t("Twice a month"), value: t("Twice a month"), freq: 24 },
        { label: t("Once a month"), value: t("Once a month"), freq: 12 },
    ];

    const selectSundayList = [
        { label: t('No Sunday'), value: t('No Sunday'), freq: 0 },
        { label: t("Once a week"), value: t("Once a week"), freq: 52 },
        { label: t("Four times a month"), value: t("Four times a month"), freq: 48 },
        { label: t("Three times a month"), value: t("Three times a month"), freq: 36 },
        { label: t("Twice a month"), value: t("Twice a month"), freq: 24 },
        { label: t("Once a month"), value: t("Once a month"), freq: 12 },
    ];
    const selectWeekdayLabel = selectWeekDayList.find(option => option.value === formData?.weekdayFrequency)?.label || t('Frequency per year');
    const selectSundayLabel = selectSundayList.find(option => option.value === formData?.sundayFrequency)?.label || t('Frequency per year');
    const selectVisualWeekdayLabel = selectWeekDayList.find(option => option.value === formData?.visualWeekdayFrequency)?.label || t('Frequency per year');
    const selectVisualSundayLabel = selectSundayList.find(option => option.value === formData?.visualSundayFrequency)?.label || t('Frequency per year');

    const handleSelectWeekDay = (eventKey) => {
        const selectedOption = selectWeekDayList.find(option => option.value === eventKey);

        if (selectedOption) {
            console.log("Selected sunday Frequency:", selectedOption.freq);
            setFormData(prevFormData => ({

                ...prevFormData,
                weekdayFrequency: selectedOption.value,
                full_cleaning_yearly_weekday_frequency: selectedOption.freq,
            }));
        }

    };

    const handleSelectSunday = (eventKey) => {
        const selectedOption = selectSundayList.find(option => option.value === eventKey);
        if (selectedOption) {
            console.log("Selected sunday Frequency:", selectedOption.freq);
            setFormData(prevFormData => ({

                ...prevFormData,
                sundayFrequency: selectedOption.value,
                full_cleaning_yearly_sunday_frequency: selectedOption.freq,
            }));
        }

    };
    const handleSelectVisualWeekDay = (eventKey) => {
        const selectedOption = selectWeekDayList.find(option => option.value === eventKey);
        if (selectedOption) {
            console.log("Selected weekday Frequency:", selectedOption.freq);
            setFormData(prevFormData => ({

                ...prevFormData,
                visualWeekdayFrequency: selectedOption.value,
                visual_cleaning_weekday_frequency: selectedOption.freq,
            }));
        }

    };
    const handleSelectVisualSunday = (eventKey) => {
        const selectedOption = selectSundayList.find(option => option.value === eventKey);
        if (selectedOption) {
            console.log("Selected sunday Frequency:", selectedOption.freq);
            setFormData(prevFormData => ({

                ...prevFormData,
                visualSundayFrequency: selectedOption.value,
                visual_cleaning_sunday_frequency: selectedOption.freq,
            }));
        }

    };


    const floorOptions = roomList?.floor_list?.map((item) => {

        return { label: item?.name, value: item?.name }
    })
    const selectFloorTypeLabel = floorOptions.find(option => option.value === formData?.floor_name)?.label || t('Select Floor type');
    const handleSelectFloorType = (eventKey) => {
        setFormData(prev => ({
            ...prev,
            floor_name: eventKey,
        }));
    }
    const roomsOptions = roomList?.rooms?.map((item) => {

        return { label: item?.name, value: item?.name }
    })
    const selectRoomTypeLabel = roomsOptions.find(option => option.value === formData?.room_type)?.label || t('Select Room type');
    const handleSelectRoomType = (eventKey) => {
        setFormData(prev => ({
            ...prev,
            room_type: eventKey,
        }));
    }
    const callMaintenanceApi = () => {
        dispatch(setStoreGlobalLoader(service ? "update_maintenance_calculation":"add_maintenance_calculation"))
        const param = {
            transmit: "broadcast",
            url: service ? "update_maintenance_calculation" : "add_maintenance_calculation",
            request: {
                surface_area: formData?.surface_area,
                full_cleaning_performance_value: formData?.full_cleaning_performance_value || 0,
                weekday_hourly_rate: formData?.weekday_hourly_rate,
                full_cleaning_monthly_cost: formData?.full_cleaning_monthly_cost,
                full_cleaning_yearly_cost: formData?.full_cleaning_yearly_cost,
                full_cleaning_yearly_weekday_frequency: formData?.full_cleaning_yearly_weekday_frequency,
                full_cleaning_yearly_sunday_frequency: formData?.full_cleaning_yearly_sunday_frequency,
                full_cleaning_monthly_sunday_cost: formData?.full_cleaning_monthly_sunday_cost,
                full_cleaning_monthly_weekday_cost: formData?.full_cleaning_monthly_weekday_cost,
                sunday_hourly_rate: formData?.sunday_hourly_rate,
                full_cleaning_monthly_sunday_hours: formData?.full_cleaning_monthly_sunday_hours,
                full_cleaning_monthly_weekday_hours: formData?.full_cleaning_monthly_weekday_hours,
                full_cleaning_monthly_hours: formData?.full_cleaning_monthly_hours,
                full_cleaning_yearly_hours: formData?.full_cleaning_yearly_hours,
                room_type: formData?.room_type,
                floor_name: formData?.floor_name,
                room_name: formData?.room_name,
                room_number: formData?.room_number,
                full_cleaning: formData?.full_cleaning,
                visual_cleaning: formData?.visual_cleaning,
                visual_cleaning_yearly_weekday_frequency: formData?.visual_cleaning_weekday_frequency,
                visual_cleaning_yearly_sunday_frequency: formData?.visual_cleaning_sunday_frequency,
                visual_cleaning_performance_value: formData?.visual_cleaning_performance_value || 0,
                visual_cleaning_monthly_hours: formData?.visual_cleaning_monthly_hour,
                visual_cleaning_yearly_hours: formData?.visual_cleaning_yearly_hour,
                visual_cleaning_monthly_cost: formData?.visual_cleaning_monthly_cost,
                visual_cleaning_yearly_cost: formData?.visual_cleaning_yearly_cost,
                visual_cleaning_monthly_sunday_hours: formData?.visual_cleaning_sunday_hour,
                visual_cleaning_monthly_weekday_hours: formData?.visual_cleaning_weekday_hour,
                visual_cleaning_monthly_sunday_cost: formData?.visual_cleaning_sunday_cost,
                visual_cleaning_monthly_weekday_cost: formData?.visual_cleaning_weekday_cost,
                ...(!service && { customer_id: customerDetails?.[0]?.id || "" }),
                ...(!service && { category_id: currentCategory?.id }),
                ...(!service && { category_name: currentCategory?.name }),
                ...(!service && { calculation_id: currentCategory?.calculationId ? currentCategory?.calculationId : "" }),
                ...(service && { customer_id: formData?.customer_id }),
                ...(service && { category_id: formData?.category_id }),
                ...(service && { category_name: formData?.category_name }),
                ...(service && { calculation_id: formData?.calculation_id }),
                // ...(service && { service_id: service?.id }),
                service_id: service?.id || "",
            }
        };
        send(param);

    }

    useEffect(() => {
        if (websocketReqRes?.url === "add_maintenance_calculation" || websocketReqRes?.url === "update_maintenance_calculation") {
            dispatch(setStoreGlobalLoader());
            if (websocketReqRes?.status === 200) {
                toast.success(websocketReqRes?.response?.msg)
                dispatch(setWebsocketReqRes())
                UtilsGetList.callGetCalculationListApi({ ws, device_id, calculationId: websocketReqRes?.response?.data?.calculation_id, lang: lang })
                navigate(`${App_url.link.calculationOverview}/${websocketReqRes?.response?.data?.calculation_id}`)
                // navigate(App_url.link.savedCalculation);
            } else {
                toast.info(websocketReqRes?.response?.msg || "error");
                dispatch(setWebsocketReqRes());
            }
        }
    }, [websocketReqRes?.url])

    const onSubmit = () => {
        // Check validation after finalData is set
        if (validation()) {
            callMaintenanceApi();
        } else {
            console.log("Validation failed.");
        }
    };

    return (
        <ContentLayout title={'Add New Calculation'} >
            <div className='category mx-1'>
                <Breadcrumb className="breadcrumb-main">
                    <Breadcrumb.Item>
                        <AnchorLink to={App_url.link.savedCalculation}>{t("Saved Calculation")} </AnchorLink>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item >
                        <AnchorLink to={`${App_url.link.selectCategory}/${customerDetails?.[0]?.id}`}>{t("Select Category")}</AnchorLink>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active> {t("Maintenance Cleaning")}</Breadcrumb.Item>
                </Breadcrumb>
                <div className='row mt-2'>
                    <div className='col-8'>
                        <Card className='card '>
                            <div className='card-body-header'>
                                <h6 className='card-body-header-title p-3'>{t("Maintenance Cleaning")}</h6>
                            </div>
                            <div className='heading mt-0'>
                                <h6>{t("Customer")} : {customerDetails?.[0]?.customer_name}</h6>
                            </div>
                            <Card.Body>
                                <div className='preview mt-0'>
                                    <h6 className=''>{t("Service Details")}</h6>
                                </div>
                                <div className=' row mt-3'>
                                    <div className='col'>
                                        <label htmlFor=" ">{t("Select Floor")}</label>
                                        <Dropdown className={`status-dropdown ${error?.floor_name ? "error" : ""}`} >
                                            <Dropdown.Toggle variant="secondary" className='status-dropdown-toggle col-12 text-start' id="dropdown-basic">
                                                {selectFloorTypeLabel}
                                                <Icon image attrIcon={App_url.img.DropDownIcon} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className='dropdown-menu'>
                                                {floorOptions && floorOptions?.map(option => (
                                                    <Dropdown.Item key={option.value}
                                                        onClick={() => handleSelectFloorType(option.value)}
                                                        eventKey={option.value} style={{ paddingRight: '8px' }}>
                                                        <div className='check_menu'>
                                                            <span>
                                                                {option.label}
                                                            </span>
                                                            {formData?.floor_name === option.value && (
                                                                <span className='title'>
                                                                    <Icon image attrIcon={App_url.img.Radiobutton2} />
                                                                </span>
                                                            )}
                                                        </div>
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                            {error?.floor_name && <div className='mt-1 ps-1 text-danger fs-13' >{error?.floor_name}</div>}
                                        </Dropdown>
                                    </div>
                                    <div className='col'>
                                        <label htmlFor=" ">{t("Select Room type")}</label>{/*  */}
                                        <Dropdown className={`status-dropdown ${error?.room_type ? "error" : ""}`} >
                                            <Dropdown.Toggle variant="secondary" className={`status-dropdown-toggle col-12 text-start `} id="dropdown-basic">
                                                {selectRoomTypeLabel}
                                                <Icon image attrIcon={App_url.img.DropDownIcon} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className='dropdown-menu'>
                                                {roomsOptions && roomsOptions?.map(option => (
                                                    <Dropdown.Item key={option.value}
                                                        eventKey={option.value}
                                                        onClick={() => handleSelectRoomType(option.value)}
                                                        style={{ paddingRight: '8px' }}>
                                                        <div className='check_menu'>
                                                            <span>
                                                                {option.label}
                                                            </span>
                                                            {formData?.room_type === option.value && (
                                                                <span className='title'>
                                                                    <Icon image attrIcon={App_url.img.Radiobutton2} />
                                                                </span>
                                                            )}
                                                        </div>
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                            {error?.room_type && <div className='mt-1 ps-1 text-danger fs-13' >{error?.room_type}</div>}
                                        </Dropdown>

                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-6'>
                                        <label>{t("Room Name")}</label>
                                        <InputGroup
                                            placeholder={t("Enter room name")}
                                            name={"room_name"}
                                            value={formData?.room_name}
                                            error={error?.room_name}
                                            onChange={onChange}
                                            type="text"
                                        />

                                    </div>
                                    <div className='col-6'>
                                        <label>{t("Room Number")}</label>
                                        <InputGroup
                                            placeholder={t("Enter room number")}
                                            name={"room_number"}
                                            value={formData?.room_number}
                                            error={error?.room_number}
                                            onChange={onChange}
                                            type="text"
                                        />

                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-6 '>

                                        <label>{t("Surface area (in m2)")}</label>
                                        <InputGroup
                                            placeholder={t("Enter Value")}
                                            name={"surface_area"}
                                            value={formData?.surface_area}
                                            error={error?.surface_area}
                                            onChange={onChange}
                                            type="text"
                                            rightLabel={' m2'}
                                        />

                                    </div>
                                </div>
                                <div className='preview'>
                                    <h6 className=''>{t("Full Cleaning")}</h6>
                                </div>
                                <div className=' row mt-3'>
                                    <div className='col'>
                                        <label htmlFor=" ">{t("Monday - Saturday")}</label>{/*  */}
                                        <Dropdown className={`status-dropdown ${error?.full_cleaning_yearly_weekday_frequency ? "error" : ""} `}>
                                            <Dropdown.Toggle variant="secondary" className='status-dropdown-toggle col-12 text-start' id="dropdown-weekday">
                                                {selectWeekdayLabel}
                                                <Icon image attrIcon={App_url.img.DropDownIcon} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className='dropdown-menu'>
                                                {selectWeekDayList && selectWeekDayList.map(option => (
                                                    <Dropdown.Item
                                                        key={option.value}
                                                        eventKey={option.value}
                                                        onClick={() => handleSelectWeekDay(option.value)} // Handle click to update state
                                                        style={{ paddingRight: '8px' }}
                                                    >
                                                        <div className='check_menu'>
                                                            <span>{option.label}</span>
                                                            {formData?.full_cleaning_yearly_weekday_frequency === option.value && (
                                                                <span className='title'>
                                                                    <Icon image attrIcon={App_url.img.Radiobutton2} />
                                                                </span>
                                                            )}
                                                        </div>
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                            {error?.full_cleaning_yearly_weekday_frequency && <div className='mt-1 ps-1 text-danger fs-13' >{error?.full_cleaning_yearly_weekday_frequency}</div>}
                                        </Dropdown>
                                    </div>

                                    <div className='col'>
                                        <label htmlFor="">{t("Sunday")} </label>{/*  */}
                                        <Dropdown className={`status-dropdown ${error?.full_cleaning_yearly_sunday_frequency ? "error" : ""}`}>
                                            <Dropdown.Toggle variant="secondary" className='status-dropdown-toggle col-12 text-start' id="dropdown-sunday">
                                                {selectSundayLabel}
                                                <Icon image attrIcon={App_url.img.DropDownIcon} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className='dropdown-menu'>
                                                {selectSundayList && selectSundayList.map(option => (
                                                    <Dropdown.Item
                                                        key={option.value}
                                                        eventKey={option.value}
                                                        onClick={() => handleSelectSunday(option.value)} // Handle click to update state
                                                        style={{ paddingRight: '8px' }}
                                                    >
                                                        <div className='check_menu'>
                                                            <span>{option.label}</span>
                                                            {formData?.full_cleaning_yearly_sunday_frequency === option.value && (
                                                                <span className='title'>
                                                                    <Icon image attrIcon={App_url.img.Radiobutton2} />
                                                                </span>
                                                            )}
                                                        </div>
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                            {error?.full_cleaning_yearly_sunday_frequency && <div className='mt-1 ps-1 text-danger fs-13' >{error?.full_cleaning_yearly_sunday_frequency}</div>}
                                        </Dropdown>


                                    </div>

                                </div>
                                <div className='preview'>
                                    <h6 className=''>{t("Visual Cleaning")}</h6>
                                </div>
                                <div className=' row mt-3'>
                                    <div className='col'>
                                        <label htmlFor=" ">{t("Monday - Saturday")}</label>{/*  */}
                                        <Dropdown className={`status-dropdown  ${error?.visual_cleaning_sunday_frequency ? "error" : ""}`} onSelect={handleSelectVisualWeekDay}>
                                            <Dropdown.Toggle variant="secondary" className='status-dropdown-toggle col-12 text-start' id="dropdown-weekday">
                                                {selectVisualWeekdayLabel}
                                                <Icon image attrIcon={App_url.img.DropDownIcon} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className='dropdown-menu'>
                                                {selectWeekDayList && selectWeekDayList.map(option => (
                                                    <Dropdown.Item
                                                        key={option.value}
                                                        eventKey={option.value}
                                                        // onClick={() => handleSelectVisualWeekDay(option.value)} // Handle click to update state
                                                        style={{ paddingRight: '8px' }}
                                                    >
                                                        <div className='check_menu'>
                                                            <span>{option.label}</span>
                                                            {formData?.visual_cleaning_weekday_frequency === option.value && (
                                                                <span className='title'>
                                                                    <Icon image attrIcon={App_url.img.Radiobutton2} />
                                                                </span>
                                                            )}
                                                        </div>
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                            {error?.visual_cleaning_weekday_frequency && <div className='mt-1 ps-1 text-danger fs-13' >{error?.visual_cleaning_weekday_frequency}</div>}
                                        </Dropdown>
                                    </div>

                                    <div className='col'>
                                        <label htmlFor="">{t("Sunday")} </label>{/* */}
                                        <Dropdown className={`status-dropdown  ${error?.visual_cleaning_sunday_frequency ? "error" : ""}`} onSelect={handleSelectVisualSunday}>
                                            <Dropdown.Toggle variant="secondary" className='status-dropdown-toggle col-12 text-start' id="dropdown-sunday">
                                                {selectVisualSundayLabel}
                                                <Icon image attrIcon={App_url.img.DropDownIcon} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className='dropdown-menu'>
                                                {selectSundayList && selectSundayList.map(option => (
                                                    <Dropdown.Item
                                                        key={option.value}
                                                        eventKey={option.value}
                                                        // onClick={() => handleSelectVisualSunday(option.value)} // Handle click to update state
                                                        style={{ paddingRight: '8px' }}
                                                    >
                                                        <div className='check_menu'>
                                                            <span>{option.label}</span>
                                                            {formData?.visual_cleaning_sunday_frequency === option.value && (
                                                                <span className='title'>
                                                                    <Icon image attrIcon={App_url.img.Radiobutton2} />
                                                                </span>
                                                            )}
                                                        </div>
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                            {error?.visual_cleaning_sunday_frequency && <div className='mt-1 ps-1 text-danger fs-13' >{error?.visual_cleaning_sunday_frequency}</div>}
                                        </Dropdown>
                                    </div>

                                </div>
                                <div className='preview'>
                                    <h6 className=''>{t("Performance Value(in m2 /Hr )")}</h6>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-6'>
                                        <label>{t("Full cleaning")}</label>
                                        <InputGroup
                                            placeholder={t("Enter Value")}
                                            name={"full_cleaning_performance_value"}
                                            value={formData?.full_cleaning_performance_value}
                                            // value={formData?.full_cleaning ? "true" : "false"}
                                            error={error?.full_cleaning_performance_value}
                                            onChange={onChange}
                                            type="text"
                                            rightLabel={' m2/hr'}
                                        />

                                    </div>
                                    <div className='col-6'>
                                        <label>{t("Visual cleaning")}</label>
                                        <InputGroup
                                            placeholder={t("Enter Value")}
                                            name={"visual_cleaning_performance_value"}
                                            // value={formData?.visual_cleaning ? "true" : "false"}
                                            value={formData?.visual_cleaning_performance_value}
                                            error={error?.visual_cleaning_performance_value}
                                            onChange={onChange}
                                            type="text"
                                            rightLabel={' m2/hr'}
                                        />

                                    </div>
                                </div>
                                <div className='preview'>
                                    <h6 className=''>{t("Hourly Rate")}</h6>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-6'>
                                        <label>{t("Monday - Saturday")}</label>
                                        <InputGroup
                                            placeholder={t("Enter Value")}
                                            name={"weekday_hourly_rate"}
                                            value={formData?.weekday_hourly_rate}
                                            error={error?.weekday_hourly_rate}
                                            onChange={onChange}
                                            type="text"
                                            rightLabel={' €'}
                                        />

                                    </div>
                                    <div className='col-6'>
                                        <label>{t("Sunday")}</label>
                                        <InputGroup
                                            placeholder={t("Enter Value")}
                                            name={"sunday_hourly_rate"}
                                            value={formData?.sunday_hourly_rate}
                                            error={error?.sunday_hourly_rate}
                                            onChange={onChange}
                                            type="text"
                                            rightLabel={'€'}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <Button size={'sm'} variant={"primary"} onClick={handleCalculationClick} className={'w-25  mt-3'}>{t("Calculate")}</Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className=' col-4 ps-0'>
                        <Card className='card '>
                            <Card.Body>
                                <div className='preview'>
                                    <h6 className=''>{t("Full Cleaning costing")}</h6>
                                </div>
                                <div className='custom-table-preview'>
                                    <Card >
                                        <Table className='mb-0'>
                                            <thead className=''>
                                                <tr>
                                                    <th>{t("Particular")}</th>
                                                    <th>{t("Hours")}</th>
                                                    <th>{t("Cost")}</th>
                                                </tr>
                                            </thead>
                                            <tbody className='table-with-radius'>
                                                <tr>
                                                    <td>{t("Sunday")}</td>
                                                    <td>{Utils.trimDecimal(formData?.full_cleaning_monthly_sunday_hours || 0, 2)}</td>
                                                    <td>€ {Utils.trimDecimal(formData?.full_cleaning_monthly_sunday_cost || 0, 2)}</td>
                                                </tr>
                                                <tr>
                                                    <td>{t("Weekday")}</td>
                                                    <td>{Utils.trimDecimal(formData?.full_cleaning_monthly_weekday_hours || 0, 2)}</td>
                                                    <td>€ {Utils.trimDecimal(formData?.full_cleaning_monthly_weekday_cost || 0, 2)}</td>
                                                </tr>
                                                <tr>
                                                    <td>{t("Monthly")}</td>
                                                    <td>{Utils.trimDecimal(formData?.full_cleaning_monthly_hours || 0, 2)}</td>
                                                    <td>€ {Utils.trimDecimal(formData?.full_cleaning_monthly_cost || 0, 2)}</td>
                                                </tr>
                                                <tr>
                                                    <td>{t("Yearly")}</td>
                                                    <td>{Utils.trimDecimal(formData?.full_cleaning_yearly_hours || 0, 2)}</td>
                                                    <td>€ {Utils.trimDecimal(formData?.full_cleaning_yearly_cost || 0, 2)}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Card>
                                </div>
                                <div className='preview'>
                                    <h6 className='addCost'>{t("Visual Cleaning costing")}</h6>
                                </div>
                                <div className='custom-table-preview'>
                                    <Card>
                                        <Table className='mb-0'>
                                            <thead className=''>
                                                <tr>
                                                    <th>{t("Particular")}</th>
                                                    <th>{t("Frequency")}</th>
                                                    <th >{t("Cost")}</th>
                                                </tr>
                                            </thead>
                                            <tbody className='table-with-radius'>
                                                <tr>
                                                    <td>{t("Sunday")}</td>
                                                    <td>{Utils.trimDecimal(formData?.visual_cleaning_sunday_hour || 0, 2)}</td>
                                                    <td>€ {Utils.trimDecimal(formData?.visual_cleaning_sunday_cost || 0, 2)}</td>
                                                </tr>
                                                <tr>
                                                    <td>{t("Weekday")}</td>
                                                    <td>{Utils.trimDecimal(formData?.visual_cleaning_weekday_hour || 0, 2)}</td>
                                                    <td>€ {Utils.trimDecimal(formData?.visual_cleaning_weekday_cost || 0, 2)}</td>
                                                </tr>
                                                <tr>
                                                    <td>{t("Monthly")}</td>
                                                    <td>{Utils.trimDecimal(formData?.visual_cleaning_monthly_hour || 0, 2)}</td>
                                                    <td>€ {Utils.trimDecimal(formData?.visual_cleaning_monthly_cost || 0, 2)}</td>
                                                </tr>
                                                <tr>
                                                    <td>{t("Yearly")}</td>
                                                    <td>{Utils.trimDecimal(formData?.visual_cleaning_yearly_hour || 0, 2)}</td>
                                                    <td>€ {Utils.trimDecimal(formData?.visual_cleaning_yearly_cost || 0, 2)}</td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                    </Card>
                                </div>
                                <div className=''>{console.log("formData=====", formData)}
                                    <Button disable={ globalLoader || (formData?.visual_cleaning_yearly_cost === 0 && formData?.full_cleaning_yearly_cost === 0)} size={'md'} variant={"primary"} className={'w-100  mt-5'} onClick={onSubmit} >{service ? `${globalLoader ? t("Please wait..."):t("Update this Service")}` : `${globalLoader ? t("Please wait..."):t("Submit this Service")}`}</Button>

                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        </ContentLayout >
    )
}

export default MaintenanceCleaning