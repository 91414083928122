/* eslint-disable */
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Breadcrumb, Card, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import { toast } from 'react-toastify';
import ContentLayout from '../layout/ContentLayout'
import { PostRequestAPI } from '../../Api/api/PostRequest';
import PrimeReactPage from '../Common/quill/PrimeReactPage';
import Utils from '../Common/Utils';
import Icon from '../Common/Icon';
import App_url from '../Common/Constants';
import Button from '../Common/Button';
import { setStoreAddCustomerModal, setStoreTemplateModal } from '../../store/modules/modals/action';
import { redirect, useLocation } from 'react-router-dom';
import { useTranslation } from '../../translate';
import AnchorLink from '../Common/AnchorLink';
import DropDownMenu from '../Common/DropDownMenu';
import { WebSocketContext } from '../../WSContext';

const CreatePromotion = () => {
    const { t, } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const {promotion} = location?.state || {};
    console.log("promotion", promotion)
    const { access_token } = useSelector(App_url.userDataReducers);
    const { ws, send } = useContext(WebSocketContext)
    const [loader, setLoader] = useState(false);
    const [loader2, setLoader2] = useState(false);
    const [selectedInvitees, setSelectedInvitees] = useState([])
    const [selectedData, setSelectedData] = useState(null);
    const [UploadFile, setUploadFile] = useState([]);
    const [documentImageIds, setDocumentImageIds] = useState([]);
    const refs = useRef({
        refUpload: null,
        refBold: null,
        refItalic: null,
        refUnderline: null,
        refOrderedList: null,
        refUnOrderedList: null,
        refImage: null,
        refLink: null
    });

    const [formData, setFormData] = useState({
        topic: "",
        description: "",
        document: [],
        inline_ids: [],
        cc: [],
        bcc: []
    })
    const [error, setError] = useState({
        topic: "",
        description: "",
    })

    const handleSetCustomerValues = (customerToRemove) => {
        setSelectedInvitees(prevInvitees =>
            prevInvitees.filter(item => item.id !== customerToRemove.id)
        );
    };

    useEffect(() => {
        if (selectedData) {
            console.log("documentImage", selectedData)
            setUploadFile(selectedData);

            const imageIds = selectedData?.map(doc => doc?.document_image)
            setDocumentImageIds(imageIds)
        }
    }, [selectedData]);
    
    useEffect(() => {
        if (promotion) {
            const inlineIds = promotion?.attachment?.map(item => item?.content_id)
            setFormData((prevData) => ({
                ...prevData,
                topic: promotion?.topic,
                description: promotion?.description,
                document: promotion?.document || [],
                inline_ids: inlineIds || [],
            }))
        }
    }, [promotion]);

    console.log("formData", formData)

    const validation = () => {
        let val = true;

        if (formData?.topic.trim() === "") {
            error.topic = t("Please provide topic");
            val = false;
            // toast.error(t("Please provide topic"));
        }
        // if (formData?.description.trim() === "") {
        //     error.description = t("Please provide description");
        //     val = false;
        //     // toast.error(t("Please provide  description"));
        // }

        setError((data) => ({
            ...data,
            ...error,
        }))
        return val;
    }

    // Convert base64 string to a Blob
    function base64ToBlob(base64, mimeType) {
        // console.log("filerArray", base64);
        const byteCharacters = atob(base64.split(',')[1]);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], { type: mimeType });
    }


    // Convert base64 string to a File
    function base64ToFile(base64, filename) {
        const mimeType = base64.split(',')[0].match(/:(.*?);/)[1];
        const blob = base64ToBlob(base64, mimeType);
        return new File([blob], filename, { type: mimeType });
    }


    const replaceImageSrcs = (htmlString, newSrcs) => {
        let index = 0;

        // Regex to match base64 image data URLs
        return htmlString.replaceAll(/data:image\/[^;]+;base64,[^"]*/g, (match) => {
            const newSrc = newSrcs[index];
            index++;
            return newSrc ? `cid:${newSrc}` : match;
            // return `cid:${newSrc}`;

        });
    };

    const convertSrcQuotes = (htmlString) => {
        return htmlString.replaceAll(/src="([^"]*)"/g, (match, p1) => {
            return `src='${p1}' /`;
        });
    };


    const onSubmit = async () => {
        if (validation()) {
            setLoader2(true);
            let InlineIds = ""

            // console.log("html===========", formData?.description)
            const base64ImagesArray = [];
            console.log("base64ImagesArray", base64ImagesArray)
            const base64Regex = /<img\s+src="(data:image\/[^;]+;base64,[^"]+)"/g;
            let match;
            while ((match = base64Regex.exec(formData?.description)) !== null) {
                base64ImagesArray.push(match[1]);
            }


            if (base64ImagesArray?.length > 0) {
                const filesArray = base64ImagesArray.map((item, index) => {
                    const base64 = item;
                    const extension = base64.split(';')[0].split('/')[1];
                    return base64ToFile(base64, `image${index + 1}.${extension}`);
                });

                console.log('filesArray', filesArray)


                try {
                    console.log("coming inside try...")
                    const uploadPromises = filesArray.map(async (file) => {
                        const formData = new FormData();
                        formData.append("file", file, file.name);
                        return PostRequestAPI(App_url.api.FILE_UPLOAD, formData, access_token, true);
                    });

                    const responses = await Promise.all(uploadPromises);
                    const successfulUploads = responses.filter(item => item?.status === 200);
                    InlineIds = successfulUploads.map(item => item.data.id);
                    // setInlineIds(inlineIds);

                } catch (error) {
                    console.error("Error during file upload:", error);
                }
            }

            const emails = selectedInvitees?.map(item => item?.email);
            const customers = selectedInvitees?.map(item => item?.id);
            // const names = selectedInvitees?.map(item => ({name: item?.customer_name,lastName: item?.last_name}));
            // console.log(names)

            // console.log(emails)
            const uploadIds = UploadFile ? UploadFile.map(item => item.id) : [];
            const replacedMsg = replaceImageSrcs(formData?.description, InlineIds);
            // console.log("Replaced message:", replacedMsg);

            const payload = {
                topic: formData?.topic,
                description: replacedMsg,
                to: [],
                cc: [],
                customer: customers,
                bcc: emails,
                inline_ids: InlineIds || [],
                document: documentImageIds?.length > 0 ? documentImageIds : uploadIds,
                content_type: "Html"
            };
            // 
            console.log('payload', payload)
            const response = await PostRequestAPI(App_url.api.CREATE_PROMOTION, payload, access_token)
            console.log("response", response);
            if (response?.status === 201 || response?.status === 200) {
                toast.success(response?.data?.msg || response?.data?.message);
                redirect(App_url.link.promotion)

            } else {
                toast.error(response?.data?.error || response.data?.detail || "error...");
            }
        } else {
            console.log("Validation error");
        }
    };
    // console.log(formData)
    const onChange = (e) => {
        setFormData((data) => ({
            ...data,
            [e.target.name]: e.target.value
        }))
        setError((prevData) => ({
            ...prevData,
            topic: ""
        }))
    }

    const handleQuillData = (messageData) => {
        setFormData((prevData) => ({
            ...prevData,
            description: messageData
        }));
    };
    const handleFileSelect = (event, item) => {
        const files = event.target.files;
        handleUploadedFiles(files);
    };

    const handleAttach = () => {
        const param = {
            transmit: "broadcast",
            url: "document_list",
            request: {
                document_type: "",
                file_type: "",
                search: "",
                page_no: 1,
                limit: -1,
                order_by: "-updated_at",
            }
        }

        send(param);
        dispatch(setStoreTemplateModal({
            show: "TEMPLATE_MODAL",
            callBackModal: (docs) => handleSelectedDocuments(docs)
        }));
    }

    const handleSelectedDocuments = (docs) =>{
        dispatch(setStoreTemplateModal())
        setSelectedData(docs);
    }
    const handleUploadedFiles = async (files) => {
        const validFileTypes = ['csv', 'xls', 'xlsx', 'pdf', 'pptx', 'png', 'jpg', 'jpeg'];

        Array.from(files).forEach(async (file) => {
            if (file) {
                const fileName = file.name;
                const fileType = fileName.split('.').pop().toLowerCase();

                if (validFileTypes.includes(fileType)) {
                    setLoader(true);
                    // Create a new FormData object
                    const formData = new FormData();
                    formData.append('file', file); // Append the file to the form data
                    // console.log("formDatasssssss", [...formData]);

                    try {
                        // Send the form data using your API function
                        const response = await PostRequestAPI(App_url.api.FILE_UPLOAD, formData, access_token, true);
                        setLoader(false)
                        console.log("responseFileUpload", response);
                        if (response?.status === 200) {
                            setUploadFile(prevFiles => [...prevFiles, response?.data]);
                            // setUploadFileIds(preFileIds => [...preFileIds, response?.data?])
                        }
                    } catch (error) {
                        console.error("File upload failed", error);
                    }
                } else {
                    toast.error("Invalid file format");
                }
            }
        });
    };
    const handleDeleteAttachment = (file) => {
        const filterUploadFile = UploadFile.filter((item) => item?.id != file?.id);
        console.log("fileUPladed", filterUploadFile);
        setUploadFile(filterUploadFile)

        if (documentImageIds?.length > 0) {
            const updatedDocumentImageIds = documentImageIds.filter((id) => id !== file.document_image);
            setDocumentImageIds(updatedDocumentImageIds);
        }
    };
    const callIcons = (attachment) => {
        const extension = attachment?.split('.').pop();
        // console.log("attachment", extension);
        if (extension === "pdf") {
            return (
                <Icon attrIcon={App_url.img.PdfIcon2} image className='sm me-1 ms-1 d-flex-center' />
            )
        } else if (extension === "jpg" || extension === "jpeg" || extension === "png") {
            return (
                <Icon attrIcon={App_url.img.DocIcon} image className='sm me-1 ms-1 d-flex-center' />
            )
        } else if (extension === "xlsx" || extension === "csv") {
            return (
                <Icon attrIcon={App_url.img.ExcelIcon} image className='sm me-1 ms-1 d-flex-center' />
            )
        } else {
            return (
                <Icon attrIcon={App_url.img.TEXTIcon} image className='sm me-1 ms-1 d-flex-center' />
            )
        }
    }

    const handleAddCustomerModal = () => {
        dispatch(setStoreAddCustomerModal({
            show: "ADD_CUSTOMER_MODAL",
            callBackModal: (customers) => handleSelectedInvitees(customers),
        }))
    }

    const handleSelectedInvitees = (customers) => {
        console.log("selectedInvitees", customers)
        setSelectedInvitees(customers);
        // setSelectedInvitees([]); 
    }

    // useEffect(() => {
    //     if (ws) {
    //         handleCreatePromotionAPI();
    //     }
    // }, [ws]);

    const tabItems = [
        { Icon: <Icon image className='me-2 sm' attrIcon={App_url.img.FileManagerIcon} />, title: "File Manager" },
        { Icon: <Icon image className='me-2 sm' attrIcon={App_url.img.TemplateIcon} />, title: "Templates" },
    ]?.filter(item => item);

    const onSelect = (e) => {
        if (e.title === "File Manager") {
            refs?.current?.refUpload?.click()
        }
        if (e.title === "Templates") {
            handleAttach()
        }
    }

    return (
        <div>
            <ContentLayout title={'Create Promotion'}>
                <div className='promotion'>
                    <Breadcrumb className="breadcrumb-main">
                        <Breadcrumb.Item>
                            <AnchorLink to={App_url.link.promotion}>{t("Promotions List")} </AnchorLink>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>{t("Create promotion")}</Breadcrumb.Item>
                    </Breadcrumb>
                    <Card>
                        <Card.Body>
                            <div className='card-body-header '>
                                <h6 className='fs-20'>{t("Create promotion")}</h6>
                                <Button className={""} variant={"primary"} onClick={onSubmit}>{t("Submit")}</Button>
                            </div>
                            <div className='promotion-body'>
                                <div className='to-name'>
                                    <div className='title-search'>
                                        <h6 className='title'>{t("Add Customer")}</h6>
                                        <div className='search-user'>
                                            {selectedInvitees?.length > 0 &&
                                                <>
                                                    {selectedInvitees?.map((item) => (
                                                        <div className='customer-name'>
                                                            <h6>{item?.customer_name} {item?.last_name}</h6>
                                                            <Icon onClick={() => handleSetCustomerValues(item)} className={" cross-btn sm"} attrIcon={App_url.img.CrossWhiteIcon} />
                                                        </div>
                                                    ))}
                                                </>
                                            }
                                        </div>
                                    </div>
                                    <div className='ms-2'>
                                        <span className='plusbtn' onClick={handleAddCustomerModal}><Icon className='plusticon ' attrIcon={App_url.img.PlusIcon} /></span>
                                    </div>
                                </div>

                                <div>
                                    <textarea type="text" name='topic' onChange={onChange} value={formData?.topic} className='mail-input-box' rows={1} placeholder={t('Topic')} />
                                    {error?.topic && <h6 className='fs-14 compose-email-subject-error fw-4' style={{ color: "red" }}>{error?.topic}</h6>}
                                </div>
                                <div className=' d-flex justify-content-between '>
                                    <div className='prime-header-icon-box'>
                                        <input ref={el => refs.current.refUpload = el} type='file' onChange={handleFileSelect} id='input_file' hidden />
                                        <DropDownMenu
                                            listItem={tabItems}
                                            variant={""}
                                            title={<Icon className='icons' attrIcon={App_url.img.AttachmentIcon} />}
                                            borderBottom
                                            onSelect={onSelect}
                                            className={"btn-md btn-auto promotions-file-manger-dropdown"}
                                        />
                                        {/* <Button onClick={() => refs?.current?.refUpload?.click()} onClick={handleAttach} variant={"secondary"} className={"prime-header-icon"} size={"sm"} ><Icon className='icons' attrIcon={App_url.img.AttachmentIcon} /></Button> */}
                                        <Button onClick={() => refs?.current?.refLink?.click()} variant={"secondary"} className={"prime-header-icon"} size={"sm"} ><Icon className='promotions-icons' attrIcon={App_url.img.LinkIcon2} /></Button>
                                        <Button onClick={() => refs?.current?.refImage?.click()} variant={"secondary"} className={"prime-header-icon"} size={"sm"} ><Icon className='promotions-icons' attrIcon={App_url.img.GalleryIcon} /></Button>
                                        <Button onClick={() => refs?.current?.refBold?.click()} variant={"secondary"} className={"prime-header-icon bold-to-unordered"} size={"sm"} ><Icon className='promotions-icons bold-icon' attrIcon={App_url.img.BoldIcon} /></Button>
                                        <Button onClick={() => refs?.current?.refItalic?.click()} variant={"secondary"} className={"prime-header-icon bold-to-unordered"} size={"sm"} ><Icon className='promotions-icons i-icon' attrIcon={App_url.img.ItalicIcon} /></Button>
                                        <Button onClick={() => refs?.current?.refUnderline?.click()} variant={"secondary"} className={"prime-header-icon bold-to-unordered"} size={"sm"} ><Icon className='promotions-icons u-icon' attrIcon={App_url.img.UnderlineIcon} /></Button>
                                        <Button onClick={() => refs?.current?.refOrderedList?.click()} variant={"secondary"} className={"prime-header-icon bold-to-unordered"} size={"sm"} ><Icon className='promotions-icons' attrIcon={App_url.img.OrderedListIcon} /></Button>
                                        <Button onClick={() => refs?.current?.refUnOrderedList?.click()} variant={"secondary"} className={"prime-header-icon bold-to-unordered"} size={"sm"} ><Icon className='promotions-icons' attrIcon={App_url.img.UnorderedListIcon} /></Button>
                                    </div>
                                </div>

                                <div className='prime-react-container'>
                                    {/* {error?.description && <h6 className='fs-14 compose-email-subject-error fw-4' style={{ color: "red" }}>{error?.description}</h6>} */}
                                    <PrimeReactPage handleQuillData={handleQuillData} refs={refs}  />
                                </div>

                                {loader && <h6 className='ms-2'>{t("Please wait while the file is uploading")}...</h6>}
                                {UploadFile?.length > 0 && (
                                    <React.Fragment>
                                        <h6 className='attachments-text'>{t("Attachments")}</h6>
                                        <div className='email-file-preview-box'>
                                            {UploadFile.map((file, index) => (
                                                <div key={index} className='email-file-preview mb-2'>
                                                    <div className='email-file-name'>
                                                        {callIcons(file?.name || file?.document_type)}
                                                        <span className='email-attachment-name-ellipsis'>{file?.name || file?.document_name || ""}</span>
                                                    </div>
                                                    <div
                                                        className='email-delete-icon'
                                                        onClick={() => handleDeleteAttachment(file)}
                                                    >
                                                        <Icon
                                                            image
                                                            attrIcon={App_url.img.DeleteIcon}
                                                            className='sm ms-4 me-1 d-flex-center'
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </ContentLayout>
        </div>
    )
}

export default CreatePromotion