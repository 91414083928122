// GetRequestAPI

export const GetRequestCallAPI = async (apiurl, access_token, data, lang,  pdf) => {
    const headers = {
      'Accept':"application/json",
      // ...(lang && { lang: lang })
    }
  
    if(access_token){
      headers.Authorization = `Bearer ${access_token}`
    }
    if(pdf){
      headers.Accept = "application/pdf"
    }
    const header = {
      headers:headers
    }
    if(data){
      header.params = data
    }
    
      const getResponse = window.axios.get(`${process.env.REACT_APP_API_DOMAIN_URL}/${apiurl}`,header).then(function (result) {
        return result;
      }).catch((e)=>e.response)
      return getResponse;
  }