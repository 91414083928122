/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import App_url from './Constants';

export default function Images(props) {
  const { access_token, } = useSelector(App_url.userDataReducers)
  const [imageSrc, setImageSrc] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);


  useEffect(() => {
    const img = new Image();
    if (!props?.auth || window.location.hostname !== "localhost") {
      img.src = props.src;

      img.onload = () => {
        setImageSrc(img.src);
      };
    } else if (access_token && window.location.hostname == "localhost") {
      fetchData()
    }
    return () => {
      img.src = '';
    };
  }, [props.src]);

  const fetchData = async () => {
    try {
      const response = await fetchImageWithAuthorization(props.src);
      // console.log('content-type', response?.headers.get('content-type'))
      setFileType(response?.headers.get('content-type'));
      if (response?.status == 200) {
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        setImageSrc(url);
        setFileUrl(url);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle the error as needed, such as displaying an error message to the user
    }
  };
  // console.log("fileUrl",fileUrl)
  const fetchImageWithAuthorization = async (url) => {
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${access_token}`);
    const requestOptions = {
      method: 'GET',
      headers: headers,
      redirect: 'follow'
    };
    return fetch(url, requestOptions);
  };



  // console.log("fileType",props)
  const renderFile = () => {
    // console.log("fileUrl",fileUrl)
    if (!fileType) {
      // console.log("fileType", props);
      return <picture className={`picture-opacity-1 ${props?.imageClassName}`}>
        {imageSrc && <source type={props.type} srcSet={imageSrc} />}
        {imageSrc && (
          <img
            src={imageSrc}
            alt={props.alt}
            loading="eager"
            className={props.className}
            width={props?.width}
            height={props?.height}
          />
        )}
      </picture>;
    }else if(fileType === 'application/pdf' && props?.pdfView){
      return (
        <React.Fragment>
           <iframe src={`${fileUrl}#toolbar=0`} width="100%" height="500px" title="PDF Document"></iframe>
        </React.Fragment>
      )
    } else if (fileType.startsWith('image/') && props?.calculationCategory) {
      // console.log("fileType-===", fileType.startsWith('image/'));
      return <img src={fileUrl} width="100%" height="100%" />;
    } else if (fileType.startsWith('image/')) {
      // console.log("fileType-===", fileType.startsWith('image/'));
      return <img src={fileUrl} width="100%" height="600px" alt="Document" />;
    } else if (fileType === 'application/pdf') {
      return <iframe src={fileUrl} width="100%" height="600px" title="PDF Document"></iframe>;
    }/* application/json */
    else if (fileType === 'text/html') {
      return <iframe src={fileUrl} width="100%" height="600px" title="PDF Document"></iframe>;
    } else {
      return (
        <div className='document-modal-doc-file-download-btn-box'>
          <a className='document-modal-doc-file-download-btn' href={fileUrl} download={fileUrl}>Download File To See</a>
        </div>
      );
    }
  };

  return (
    <React.Fragment>
      {renderFile()}
    </React.Fragment>
  );
}

Images.propTypes = {
  type: PropTypes.any,
  src: PropTypes.any,
  alt: PropTypes.any,
  className: PropTypes.any,
  imageClassName: PropTypes.any,
  width: PropTypes.any,
  height: PropTypes.any,
  auth: PropTypes.bool,
};

Images.defaultProps = {
  type: 'image/webp',
  src: '',
  alt: '',
  className: '',
  imageClassName: '',
  width: 0,
  height: 0,
  auth: false,
};
