/* eslint-disable */
import React, { createContext, useContext, useState } from 'react';
import en from './en.json'; // English language file
import de from './de.json'; // French language file
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from '../store/modules/language/action';
import App_url from '../components/Common/Constants';
import { setStoreApplicationLanguage } from '../store/modules/setting_reducers/action';

// Step 1: Create the context
const TranslationContext = createContext();

  // Step 2: Create a provider component
  export const TranslationProvider = ({ children }) => {
    const dispatch = useDispatch();
    const {lang} = useSelector(App_url.settingReducers);
    const translations = lang === 'en' ? en : de; // Select translations based on the language

    // console.log("Selected Language:", lang);
    // console.log("Today's Translation:", translations['Today']); 

  const languages = { en, de };

  // Function to change the language
  const changeLanguage = (langu) => {
    console.log("langu",langu)
    dispatch(setStoreApplicationLanguage(langu));
  };
  
  const translate = (key) => {
    // const langData = languages[lang]; 
    // console.log(langData[key])
    if (translations && translations[key]) {
      return translations[key];
    }
    return key; 
  };

  return (
    <TranslationContext.Provider value={{ t: translate, changeLanguage, lang }}>
      {children}
    </TranslationContext.Provider>
  );
};
// Step 3: Create a custom hook to use the translation context
export const useTranslation = () => useContext(TranslationContext);