/* eslint-disable */
import React, { createContext, useEffect, useState } from 'react';
import MainRouter from './router';
import { useDispatch, useSelector } from 'react-redux';
import { WebSocketProvider } from './WSContext';
import Utils from './components/Common/Utils';
import App_url from './components/Common/Constants';
import { setStoreDeviceID } from './store/modules/users_data/action';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NewAppointment from './components/Sales/Prospects/Appointment/NewAppointment';
import InviteeModal from './components/Sales/Prospects/Appointment/InviteeModal';
import InviteeInfoModal from './components/Sales/Prospects/Appointment/InviteeInfoModal';
import ViewAppointmentModal from './components/Sales/Prospects/Appointment/ViewAppointmentModal';
import CallScheduledSuccessfulModal from './components/Sales/Prospects/ScheduleCall/CallScheduledSuccessfulModal';
import ViewScheduledCallModal from './components/Sales/Prospects/Common/ViewScheduledCallModal';
import RingCallModal from './components/Sales/Prospects/Common/RingCallModal';
import ScheduleCallModal from './components/Sales/Prospects/ScheduleCall/ScheduleCallModal';
import AddRemarkModal from './components/Sales/Prospects/AddRemarkModal';
import ViewRemarkModal from './components/Sales/Prospects/ViewRemarkModal';
import DiscardChangesModal from './components/Common/DiscardChangesModal';
import ViewEmail from './components/Sales/Prospects/EmailHistory/ViewEmail';
import ShareDocumentModal from './components/Sales/Prospects/ShareDocumentModal';
import ComposeEmail from './components/Sales/Prospects/EmailHistory/ComposeEmail';
import SearchCompanyModal from './components/Sales/Prospects/SearchCompanyModal';
import EditAppointment from './components/Sales/Prospects/Appointment/EditAppointment';
import AdditionalCostModal from './components/Calculation/AdditionalCostModal';
import ViewExternalOfferDetailModal from './components/ExternalOffer/ViewExternalOfferDetailModal';
import AddCustomerModal from './components/Promotion/AddCustomerModal';
import TemplatesModal from './components/Promotion/TemplatesModal';
import { useTranslation } from './translate';
import ViewDocument from './components/Modals/ViewDocument';
import OfferModal from './components/Modals/OfferModal';
import UserMessageModal from './components/Common/UserMessageModal';
import ConfirmModal from './components/Common/ConfirmModal';
import RenameFolder from './components/Documentation/RenameFolder';
import MessageModal from './components/Common/MessageModal';
import SessionExpiredModal from './components/Common/SessionExpiredModal';


function App() {
  const { access_token, device_id } = useSelector(App_url.userDataReducers);
  const [ws, setWebSocket] = useState(null)
  const dispatch = useDispatch();

  const setAllState = () => {
    if (!device_id) {
      const device = Utils.uuidv4();
      dispatch(setStoreDeviceID(device));
    }
  }

  useEffect(() => {
    setAllState();
  })
  const { t } = useTranslation();
  // console.log("translation", t("Hello World"))

  return (
    <WebSocketProvider ws={ws} setWebSocket={setWebSocket} access_token={access_token} device_id={device_id}>
      <main className="App">
        <MainRouter />


        <NewAppointment />
        <InviteeModal />
        <InviteeInfoModal />
        <ViewAppointmentModal />
        <ScheduleCallModal />
        <CallScheduledSuccessfulModal />
        <ViewScheduledCallModal />
        <RingCallModal />
        <AddRemarkModal />
        <ViewRemarkModal />
        <DiscardChangesModal />
        <EditAppointment />
        <ViewEmail />
        {/* <ViewDocumentModal /> */}
        <ViewDocument />
        <ShareDocumentModal />
        <SearchCompanyModal />
        <AdditionalCostModal />
        <ViewExternalOfferDetailModal />
        {/* <OfferModal /> */}
        <OfferModal />
        <AddCustomerModal />
        <TemplatesModal />
        <ComposeEmail />
        <ToastContainer />
        <UserMessageModal />
        <RenameFolder />
        <MessageModal />
        <SessionExpiredModal />
        <ConfirmModal />
      </main>
    </WebSocketProvider>
  );
}

export default App;
