/* eslint-disable no-unused-vars */


import React, { useState } from 'react'
import CustomTableTwo from '../../Common/CustomTableTwo';
import Button from '../../Common/Button';
import { useDispatch } from 'react-redux';
import { setStoreViewScheduledCallModal } from '../../../store/modules/modals/action';
import { useTranslation } from '../../../translate';

export default function CallHistory() {
  const { t } = useTranslation();

    const dispatch = useDispatch();


    const CallHistory = [
        { person_name: "Martin Vetrovs", mobile_no: "+49 15 1234 5678", dateTime: "28 Feb 2024, 10:10 AM", duration: "10 min", action: "Call Now" },
        { person_name: "John Smith", mobile_no: "+44 20 7946 0958", dateTime: "27 Feb 2024, 02:30 PM", duration: "15 min", action: "Call Now" },
        { person_name: "Alice Johnson", mobile_no: "+1 202 555 0114", dateTime: "26 Feb 2024, 11:00 AM", duration: "8 min", action: "Call Now" },
        { person_name: "Robert Brown", mobile_no: "+91 98765 43210", dateTime: "25 Feb 2024, 09:45 AM", duration: "12 min", action: "Call Now" },
        { person_name: "Emma Davis", mobile_no: "+61 2 1234 5678", dateTime: "24 Feb 2024, 03:15 PM", duration: "20 min", action: "Call Now" },
        { person_name: "Michael Wilson", mobile_no: "+81 3 1234 5678", dateTime: "23 Feb 2024, 08:30 AM", duration: "5 min", action: "Call Now" },
        { person_name: "Sophia Martinez", mobile_no: "+33 1 2345 6789", dateTime: "22 Feb 2024, 01:00 PM", duration: "7 min", action: "Call Now" },
        { person_name: "Daniel Garcia", mobile_no: "+55 11 2345 6789", dateTime: "21 Feb 2024, 04:45 PM", duration: "10 min", action: "Call Now" },
        { person_name: "Olivia Taylor", mobile_no: "+27 21 123 4567", dateTime: "20 Feb 2024, 12:30 PM", duration: "6 min", action: "Call Now" },
        { person_name: "James Anderson", mobile_no: "+61 7 1234 5678", dateTime: "19 Feb 2024, 11:15 AM", duration: "9 min", action: "Call Now" },
        { person_name: "Mia Thomas", mobile_no: "+82 2 1234 5678", dateTime: "18 Feb 2024, 10:00 AM", duration: "4 min", action: "Call Now" },
    ];

    const callBack = (e, state) => {
        if (state === "AddButton") {
            console.log("add...")
        }
    }

    const handleView = (state) =>{
        if(state === "callView"){
            dispatch(setStoreViewScheduledCallModal({
                show:"VIEW_SCHEDULED_CALL_MODAL",
                data:{item:"schedule_call"},
            }))
        }
    }

    const RenderBody = (item) => {
        // console.log("item", item);
        return (
            <React.Fragment>
                <td>{item?.person_name}</td>
                <td className='text-primary'>{item?.mobile_no}</td>
                <td>{item?.dateTime}</td>
                <td>{item?.duration}</td>
                <td><Button className={""} onClick={()=>handleView("callView")} variant={"primary"} size={"sm"}>Call Now</Button></td>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div className="col-lg-12 mt-0">
                <CustomTableTwo
                    title={{ text: t("Call History"), size: "fs-20", textTwo: `Total Calls ${CallHistory?.length}` }}
                    columnNames={[t("Person Name"), t("Mobile No."), t("Date & Time"), t("Duration"), t("Action")]}
                    recordData={CallHistory}
                    renderBody={RenderBody}
                    callBack={callBack}
                    search_placeholder={t("Search By Name,City,Industry & Postcode")}
                    typePlaceHolder={t("Select Status")}
                    scrollbar={CallHistory?.length > 10 && true}
                    style={{ height: "350px" }}
                />
            </div>
        </React.Fragment>
    )
}
