/* eslint-disable no-unused-vars */
import React, { useContext, useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Icon from '../../Common/Icon';
import App_url from '../../Common/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { setConfirmModalPopup, setStoreDiscardChangesModal, setStoreViewRemarkModal } from '../../../store/modules/modals/action';
import wsSendRequest from '../../../socketIO/wsSendRequest';
import { WebSocketContext } from '../../../WSContext';
import { useNavigate } from 'react-router-dom';
import Utils from '../../Common/Utils';
import { useTranslation } from '../../../translate';


export default function ViewRemarkModal(props) {
  const { t, changeLanguage } = useTranslation();

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { ModalPopup } = useSelector(App_url.modalReducers);
    const { ws, send } = useContext(WebSocketContext)

// console.log("ModalPopup?.data",ModalPopup?.data)
    const handleClose = async (state) => {
        if (ModalPopup?.callBackModal) {
            await ModalPopup?.callBackModal(state, ModalPopup?.data?.item);
        }
        CloseData()
    };
    const CloseData = () => {
        dispatch(setStoreViewRemarkModal())
    }

    if (ModalPopup?.show !== "VIEW_REMARK_MODAL") {
        return null
    }
    const handleDiscardModal = (item) => {
        console.log("item",item)
        dispatch(setStoreDiscardChangesModal({
            show: "DISCARD_CHANGES_MODAL",
            data: { item: item }
        }))
    }

    return (
        <div>
            <Modal show={true} onHide={CloseData} variant="secondary" centered className='view-remark'>
                <Modal.Body className='custom-modal-body'>
                    <div className=' header'>
                        <h6 className='title'>{t("Spa Paragon")}</h6>
                        <div className='header-icons'>
                            <Icon  onClick={()=>handleClose("editRemark")} className='' attrIcon={App_url.img.EditIcon} />
                            <Icon  onClick={()=>handleClose("deleteRemark")} className={'delete-icon'} onClickIcon attrIcon={App_url.img.DeleteIcon} />
                            <Icon onClick={CloseData} className={' ms-1'} image attrIcon={App_url.img.RedCrossIcon} />
                        </div>
                    </div>
                    <div className='remark'>  
                        <span >{Utils.formatISOToFullDayMonthTime(ModalPopup?.data?.item?.created_at,t)}</span>
                    </div>
                    <div className='remark-box'>
                        <h6 className='title'>{t("Remark")}</h6>
                        <h6 className=' mt-2'>
                            {ModalPopup?.data?.item?.message}
                        </h6>
                    </div>

                </Modal.Body>
            </Modal>
        </div>
    )
}



ViewRemarkModal.propTypes = {
    handleClose: PropTypes?.func,
    show: PropTypes?.bool,
}
ViewRemarkModal.defaultProps = {
    handleClose: () => { },
    show: false,
}