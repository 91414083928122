/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import ContentLayout from '../layout/ContentLayout'
import { Card } from 'react-bootstrap'
import CustomTableTwo from '../Common/CustomTableTwo'
import Icon from '../Common/Icon'
import App_url from '../Common/Constants'
import wsSendRequest from '../../socketIO/wsSendRequest'
import { WebSocketContext } from '../../WSContext'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from '../../translate'
import Utils from '../Common/Utils'
import { setViewDocumentModal } from '../../store/modules/modals/action'

export default function DocumentationPage() {
  const { t, changeLanguage } = useTranslation();
  const dispatch = useDispatch();
  const {ws,send} = useContext(WebSocketContext);
  const { device_id, websocketReqRes } = useSelector(App_url.userDataReducers);
  const { documentationList } = useSelector(App_url.documentationReducers);
 
  const [loader, setLoader] = useState(false);
  // console.log("documentationList",documentationList?.data);

  const [filter, setFilter] = useState({
    search:"",
    file_type:"",
  })
 
  const callDocumentListAPI = (page) => {
    const param = {
        transmit: "broadcast",
        url: "document_list",
        request: {
            document_type: "",
            page_no: page,
            limit: "10",
            search: filter?.search,
            file_type: filter?.file_type,
            order_by:"folder"
        }
    }
    send( param);
}

const fileTypesOptions = Array.from(new Set(documentationList?.data?.map(item => item.document_type)))
.map(document_type => {
    return { label: document_type, value: document_type };
});

useEffect(() => {
    if (ws) {
      callDocumentListAPI(1)
    }
}, [ws, filter]);
const callBack =()=>{
}

  const handleView = (item) => {
    console.log("item", item)
    dispatch(setViewDocumentModal({
      show: "VIEW_DOCUMENT_MODAL",
      data: { item: item },
    }))
  }



  const RenderBody = (item,) => {
    console.log("item===", item);
    return (
      <React.Fragment className='dashTitle'>
        <td><Icon className={"me-2 document-type"} attrIcon={Utils.getIcon(item?.document_type)} image />{Utils.formatOneLineEllipse(item?.document_name)}</td>
        <td>{item?.document_type}</td>
        <td>{item?.document_size? Utils.getFileSize(item?.document_size):"-"}</td>
        <td>{Utils.formatDateTime2(item?.updated_at,t)}</td>
        <td className='d-flex gap-2'>
          {item?.document_type !== "folder" ?
            (<span className='view-icon-box' onClick={() => handleView(item)}><Icon className={"bg-primary"} attrIcon={App_url.img.EyeIcon} /></span>)
            :
            (<span className='view-icon-box2'  ><Icon className={"bg-grey"} attrIcon={App_url.img.EyeIcon} /></span>)
          }

        </td>
      </React.Fragment>
    )
  }
  return (
    <ContentLayout title={"Documentation Page"}>

          <CustomTableTwo
            title={{ text: t("Documentation List"), size: "fs-20", textTwo: "" }}
            // AddButton={{ title: t("+ Add Document"), variant: "outline-primary", size: "md" }}
            // BrowseButton={{ title: `${loader ? t("Please wait...") : t("+ Add Document")}`, variant: "outline-primary", size: "md" }}
            columnNames={[t("Name"),t("Type"), t("File Size"), t( "Last Modified"), t("Action")]}
            recordData={documentationList?.data}
            renderBody={RenderBody}
            callBack={callBack}
            setFilter={setFilter}
            filter={filter}
            pagination={documentationList?.pagination}
            onPagination={(page) => callDocumentListAPI(page)}
            search_placeholder={t("Search By Name,City,Industry & Postcode")}
            typePlaceHolder={t("Select File Type")}
            typeOption={fileTypesOptions || []}
          />
     
    </ContentLayout>
  )
}
