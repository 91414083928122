
import React from 'react'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'

export default function PhoneInputPage(props) {
  return (
        <PhoneInput
            country={props?.country}
            enableSearch={props?.enableSearch}
            value={props?.value}
            className={props?.className}
            showDropdown={false}
            disableDropdown={false}
            onChange={props?.onChange}
            inputStyle={{
                width: "100%"
            }}
            containerClass={props?.error && 'is-invalid'}
        />
  )
}