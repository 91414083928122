/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import ContentLayout from '../../layout/ContentLayout'
import { Breadcrumb, Card, Dropdown, Table } from 'react-bootstrap'
import AnchorLink from '../../Common/AnchorLink'
import App_url from '../../Common/Constants'
import Button from '../../Common/Button'
import InputGroup from '../../Common/InputGroup'
import Icon from '../../Common/Icon'
import { useDispatch, useSelector } from 'react-redux'
import { WebSocketContext } from '../../../WSContext'
import { toast } from 'react-toastify'
import Utils from '../../Common/Utils'
import UtilsGetCalculations from '../UtilsGetCalculations'
import { setWebsocketReqRes } from '../../../store/modules/users_data/action'
import { useLocation, useNavigate } from 'react-router-dom'
import PreviewDataTable from './PreviewDataTable'
import { useTranslation } from '../../../translate'
import UtilsGetList from '../../Common/UtilsGetList'
import { setStoreGlobalLoader } from '../../../store/modules/setting_reducers/action'

const IntervalCleaning = () => {
    const { t, changeLanguage } = useTranslation();

    const { ws, send } = useContext(WebSocketContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { lang, globalLoader } = useSelector(App_url.settingReducers)
    const { device_id, websocketReqRes } = useSelector(App_url.userDataReducers);
    const { currentCategory, roomList, serviceSummary } = useSelector(App_url.calculationReducers);
    // console.log("category_name", currentCategory);
    const { customerDetails } = useSelector(App_url.customerReducers);
    const location = useLocation();
    const { service } = location.state || {};
    // console.log("service===", service);
    // const [tableData, setTableData] = useState([{
    //     room_type: '',
    //     weekly_sunday_count: 0,
    //     type_of_cleaning: " ",
    //     company_surcharge: 0,
    //     hourly_rate: 0,
    //     yearly_weekday_frequency: 0,
    //     yearly_sunday_frequency: 0,
    //     surface_area: 0,
    //     monthly_weekday_cost: 0,
    //     monthly_hour: 0,
    //     yearly_hour: 0,
    //     employee_per_hour_cost: 0,
    //     sunday_hourly_rate: 0,
    //     monthly_sunday_cost: 0,
    // }])
    const [formData, setFormData] = useState({
        employee_per_hour_cost: "",
        company_surcharge: "",
        hourly_rate: 0,
        room_type: "",
        type_of_cleaning: "",
        surface_area: "",
        performance_value: "",
        weekly_weekday_count: null,
        weekly_sunday_count: null,

        total_surface_area: 0,
        total_monthly_weekday_hours: 0,
        total_monthly_weekday_cost: 0,
        total_monthly_sunday_hours: 0,
        total_monthly_sunday_cost: 0,
        total_monthly_hours: 0,
        total_monthly_cost: 0,
        total_yearly_hours: 0,
        total_yearly_cost: 0,
        interval_array: [],

        edit_room_type: "",
        edit_type_of_cleaning: "",
        currentUid: "",

        customer_id: "",
        category_id: "",
        category_name: "",
        calculation_id: "",

    })
    const [error, setError] = useState({
        employee_per_hour_cost: '',
        surface_area: '',
        company_surcharge: '',
        performance_value: '',
        room_type: '',
        type_of_cleaning: '',
        weekly_weekday_count: '',
        weekly_sunday_count: ''
    })


    // console.log("formData", formData)
    function convertCommaToDot(number) {
        return number?.toString()?.replace(',', '.');
    }
    const onChange = (e) => {
        let { employee_per_hour_cost, company_surcharge } = formData;
        employee_per_hour_cost = convertCommaToDot(formData?.employee_per_hour_cost);
        company_surcharge = convertCommaToDot(formData?.company_surcharge)

        if (e.target.name === "company_surcharge" && employee_per_hour_cost > 0 && e.target.value > 0) {
            const costForPercent = parseFloat(employee_per_hour_cost) * (parseFloat(e.target.value) * 0.01);
            const hourly_rate = parseFloat(employee_per_hour_cost) + parseFloat(costForPercent);
            console.log("formData", hourly_rate);
            setFormData((data) => ({
                ...data,
                hourly_rate: hourly_rate,
            }))
        }
        if (e.target.name === "employee_per_hour_cost" && company_surcharge > 0 && e.target.value > 0) {
            const costForPercent = parseFloat(e.target.value) * (parseFloat(company_surcharge) * 0.01);
            const hourly_rate = parseFloat(e.target.value) + parseFloat(costForPercent);
            setFormData((data) => ({
                ...data,
                hourly_rate: hourly_rate,
            }))
        }
        setFormData((data) => ({
            ...data,
            [e.target.name]: e.target.value,
        }));

        setError((prevData) => ({
            ...prevData,
            [e.target.name]: "",
        }))
    };

    useEffect(() => {
        if (service) {
            setFormData((prevData) => ({
                ...prevData,
                ...serviceSummary?.summary,
                customer_id: serviceSummary?.service?.[0]?.customer_id ? serviceSummary?.service?.[0]?.customer_id : "",
                category_id: serviceSummary?.service?.[0]?.category_id,
                category_name: serviceSummary?.service?.[0]?.category_name,
                calculation_id: serviceSummary?.service?.[0]?.calculation_id,

                total_surface_area: serviceSummary?.summary?.[0]?.surface_area,

                total_monthly_weekday_hours: serviceSummary?.summary?.[0]?.weekday_hour,
                total_monthly_sunday_hours: serviceSummary?.summary?.[0]?.sunday_hour,
                total_monthly_hours: serviceSummary?.summary?.[0]?.monthly_hour,
                total_yearly_hours: serviceSummary?.summary?.[0]?.yearly_hour,

                total_monthly_weekday_cost: serviceSummary?.summary?.[0]?.weekday_cost,
                total_monthly_sunday_cost: serviceSummary?.summary?.[0]?.sunday_cost,
                total_monthly_cost: serviceSummary?.summary?.[0]?.monthly_cost,
                total_yearly_cost: serviceSummary?.summary?.[0]?.yearly_cost,

                interval_array: handleEditFormIntervalArrayObjects(serviceSummary?.service)
            }))
        }
    }, [service?.id, serviceSummary])

    // console.log("serviceSummary", serviceSummary)



    // Options of room type cleaning type
    const roomTypeOptions = roomList?.rooms?.map((item) => {
        return { label: item?.name, value: item?.id }
    })
    const cleaningTypeOptions = roomList?.cleaning_types?.map((item) => {
        return { label: item?.cleaning_name, value: item?.id }
    })

    // select room type cleaning type
    const handleSelectType = (eventKey, type) => {
        if (type === "roomType") {
            const selectedRoomType = roomTypeOptions?.find(option => option.value === eventKey);
            setFormData((prevData) => ({
                ...prevData,
                room_type: selectedRoomType?.label,
            }));
            setError((data) => ({
                ...data,
                room_type: "",
            }));
        }
        if (type == "cleaningType") {
            const selectedCleaningType = cleaningTypeOptions?.find(option => option.value === eventKey);
            setFormData(prev => ({
                ...prev,
                type_of_cleaning: selectedCleaningType?.label,
            }));
            setError((data) => ({
                ...data,
                type_of_cleaning: "",
            }));
        }
    }

    // options of weekday and sunday frequency
    const weekDayOptionList = [
        { label: 0, value: 0 },
        { label: 1, value: 1 },
        { label: 2, value: 2 },
        { label: 3, value: 3 },
        { label: 4, value: 4 },
        { label: 5, value: 5 },
        { label: 6, value: 6 },
    ]
    const sundayOptionList = [
        { label: 0, value: 0 },
        { label: 1, value: 1 },
    ]
    // Handle the selection of a weekday option
    const handleSelectWeekDayFrequency = (eventKey, type) => {
        if (type === "weeklyWeekday") {
            const selectedWeeklyWeekday = weekDayOptionList?.find(option => option.value === parseInt(eventKey));
            console.log("week", eventKey, type, selectedWeeklyWeekday, typeof (eventKey))
            setFormData((prevData) => ({
                ...prevData,
                weekly_weekday_count: selectedWeeklyWeekday?.label
            }));
            setError((data) => ({
                ...data,
                weekly_weekday_count: "",
            }));
        }
        if (type === "weeklySunday") {
            const selectedWeeklySunday = sundayOptionList?.find(option => option.value === parseInt(eventKey));
            setFormData((prevData) => ({
                ...prevData,
                weekly_sunday_count: selectedWeeklySunday?.label
            }));
            setError((data) => ({
                ...data,
                weekly_sunday_count: "",
            }));
        }

    };


    const callRoomListApi = (page) => {
        const param = {
            transmit: "broadcast",
            url: "room_list",
            request: {
                limit: "-1",
                page_no: page,
                order_by: "-updated_at"
            }
        };
        send(param);
    };


    useEffect(() => {
        if (ws) {
            callRoomListApi(1);
        }
    }, [ws]);


    const handleCalculateClick = () => {
        // Perform validation first
        const isValid = validation();  // Assuming validation returns true/false
        if (!isValid) {
            console.log("Validation failed.");
            return;
        }
        if (lang === "de") {
            UtilsGetCalculations.calculateIntervalCleaningGermen({ formData, setFormData });
        }
        else {
            UtilsGetCalculations.calculateIntervalCleaning({ formData, setFormData });
        }
    }
    console.log("formData", formData)
    const validation = () => {
        let val = true;
        let error = {};
        if (!formData?.surface_area) {
            error.surface_area = t("Please enter surface area");
            val = false;
        }
        if (!formData?.company_surcharge) {
            error.company_surcharge = t("Please enter company surcharges");
            val = false;
        }
        if (!formData?.employee_per_hour_cost) {
            error.employee_per_hour_cost = t("Enter employee per hour cost");
            val = false;
        }
        if (!formData?.performance_value) {
            error.performance_value = t("Please enter performance value");
            val = false;
        }
        if (!formData?.room_type) {
            error.room_type = t("Please select room type");
            val = false;
        }
        if (!formData?.type_of_cleaning) {
            error.type_of_cleaning = t("Please select type of cleaning");
            val = false;
        }
        if (formData?.weekly_weekday_count === null) {
            error.weekly_weekday_count = t("Please select weekday frequency");
            val = false;
        }
        if (formData?.weekly_sunday_count === null) {
            error.weekly_sunday_count = t("Please select sunday frequency");
            val = false;
        }
        setError((prevData) => ({
            ...prevData,
            ...error
        }));

        return val;
    };

    const handleEditFormIntervalArrayObjects = (intervalArray, type) => {
        console.log("intervalArray", intervalArray)
        const filterArray = intervalArray?.map((item) => {
            let filteredItem = {
                room_type: item?.room_type,
                type_of_cleaning: item?.type_of_cleaning,
                surface_area: item?.surface_area,
                performance_value: item?.value,
                employee_per_hour_cost: item?.employee_per_hour_cost,
                company_surcharge: item?.company_surcharge,
                hourly_rate: item?.hourly_rate,
                sunday_hourly_rate: item?.sunday_hourly_rate,
                weekly_weekday_count: item?.weekday_count,
                weekly_sunday_count: item?.sunday_count,
                yearly_weekday_frequency: item?.weekday_frequency,
                yearly_sunday_frequency: item?.sunday_frequency,
                monthly_weekday_hour: item?.weekday_hour,
                monthly_weekday_cost: item?.weekday_cost,
                monthly_sunday_hour: item?.sunday_hour,
                monthly_sunday_cost: item?.sunday_cost,
                monthly_hours: item?.monthly_hour,
                monthly_cost: item?.monthly_cost,
                yearly_hours: item?.yearly_hour,
                yearly_cost: item?.yearly_cost,
                id: item?.id
            };
            return filteredItem
        })


        return filterArray
    }

    const handleIntervalArrayObjects = (intervalArray) => {
        const filterArray = intervalArray?.map((item) => {
            if (item?.uid) {
                delete item?.id
            }
            return item
        })
        return filterArray
    }

    const callAddIntervalCleaningApi = () => {
        dispatch(setStoreGlobalLoader(service ? "update_interval_calculation" : "add_interval_calculation"));
        const filteredIntervalArray = handleIntervalArrayObjects(formData?.interval_array);
        // console.log("filteredIntervalArray",filteredIntervalArray)
        const param = {
            transmit: "single",
            url: service ? "update_interval_calculation" : "add_interval_calculation",
            request: {
                interval_array: filteredIntervalArray,
                total_surface_area: formData?.total_surface_area,
                total_monthly_sunday_hours: formData?.total_monthly_sunday_hours,
                total_monthly_sunday_cost: formData?.total_monthly_sunday_cost,
                total_monthly_weekday_hours: formData?.total_monthly_weekday_hours,
                total_monthly_weekday_cost: formData?.total_monthly_weekday_cost,
                total_monthly_hours: formData?.total_monthly_hours,
                total_monthly_cost: formData?.total_monthly_cost,
                total_yearly_hours: formData?.total_yearly_hours,
                total_yearly_cost: formData?.total_yearly_cost,
                ...(!service && { customer_id: customerDetails?.[0]?.id || "" }),
                ...(!service && { category_id: currentCategory?.id }),
                ...(!service && { category_name: currentCategory?.name }),
                ...(!service && { calculation_id: currentCategory?.calculationId ? currentCategory?.calculationId : "" }),
                ...(service && { customer_id: formData?.customer_id }),
                ...(service && { category_id: formData?.category_id }),
                ...(service && { category_name: formData?.category_name }),
                ...(service && { calculation_id: formData?.calculation_id }),
                ...(service && { service_summary_id: service?.id }),
            },
        };
        send(param);
    };


    useEffect(() => {
        if (websocketReqRes?.url === "add_interval_calculation" || websocketReqRes?.url === "update_interval_calculation") {
            dispatch(setStoreGlobalLoader());
            if (websocketReqRes?.status === 200) {
                toast.success(websocketReqRes?.response?.msg)
                dispatch(setWebsocketReqRes())
                UtilsGetList.callGetCalculationListApi({ ws, device_id, calculationId: websocketReqRes?.response?.data?.calculation_id, lang: lang })
                navigate(`${App_url.link.calculationOverview}/${websocketReqRes?.response?.data?.calculation_id}`)
                // navigate(App_url.link.savedCalculation);
            } else {
                toast.info(websocketReqRes?.response?.msg || "error");
                dispatch(setWebsocketReqRes());
            }
        }
    }, [websocketReqRes?.url])

    const onSubmit = () => {
        if (formData?.interval_array?.length > 0) {
            callAddIntervalCleaningApi();
        }
    }


    return (
        <ContentLayout title={'Interval Cleaning'}>
            <div className='interval'>
                <Breadcrumb className="breadcrumb-main">
                    <Breadcrumb.Item>
                        <AnchorLink to={App_url.link.savedCalculation}>{t("Saved Calculation")} </AnchorLink>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item >
                        <AnchorLink to={`${App_url.link.selectCategory}/${customerDetails?.[0]?.id}`}>{t("Select Category")}</AnchorLink>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>{t("Interval Cleaning")}</Breadcrumb.Item>
                </Breadcrumb>
                <div className='first row interval-cleaning m-0'>
                    <div className='col-8 p-0'>
                        <Card>
                            <div className='card-body-header'>
                                <h6 className='card-body-header-title p-3'>{t("Interval Cleaning")}</h6>
                            </div>
                            <div className='heading mt-0'>
                                <h6>{t("Customer")} : <span className='text-capitalize'>{customerDetails?.[0]?.customer_name ? customerDetails?.[0]?.customer_name : customerDetails?.[0]?.company_name}</span></h6>
                            </div>
                            <Card.Body>
                                <div className='rate'>
                                    <div className='row align-items-baseline'>
                                        <div className='preview p-0'>
                                            <h6>{t("Hourly Rate (in € )")}</h6>
                                        </div>
                                        <div className='col-5 p-0'>
                                            <div className='emp-per-hr-cost'>
                                                <label>{t("Employer Per Hour Cost")}</label>
                                                <InputGroup
                                                    name={"employee_per_hour_cost"}
                                                    formClassName={`${error?.employee_per_hour_cost ? "error" : ""} emp-per-hr-cost-input-box `}
                                                    value={formData?.employee_per_hour_cost}
                                                    // error={error?.employee_per_hour_cost}
                                                    onChange={onChange}
                                                    type="text"
                                                    rightLabel={'€'}
                                                // number
                                                // allowDot
                                                />
                                            </div>
                                            {error?.employee_per_hour_cost && <div className='mt-1 text-danger fs-13' >{error?.employee_per_hour_cost}</div>}
                                        </div>
                                        <div className='col-5 p-0 cmp-surcharge-col'>
                                            <div className='cmp-surcharge'>
                                                <label>{t("Company surcharges in %")}</label>
                                                <InputGroup
                                                    name={"company_surcharge"}
                                                    formClassName={`${error?.company_surcharge ? "error" : ""} cmp-surcharge-input-box`}
                                                    value={formData?.company_surcharge}
                                                    // error={error?.company_surcharge}
                                                    onChange={onChange}
                                                    type="text"
                                                    rightLabel={'%'}
                                                // number
                                                // allowDot
                                                />
                                            </div>
                                            {error?.company_surcharge && <div className='mt-1 text-danger fs-13' >{error?.company_surcharge}</div>}
                                        </div>
                                        <div className='col-2  p-0'>
                                            <h6 className='text-rate'>  € {Utils.trimDecimal(formData?.hourly_rate, 2)}</h6>
                                        </div>
                                    </div>

                                </div>
                                <div className=' row mt-3'>
                                    <div className='col'>
                                        <label htmlFor=" ">{t("Select Room type")}</label>
                                        <Dropdown className={`status-dropdown ${error?.room_type ? "error" : ""}`} onSelect={(e) => handleSelectType(e, "roomType")}>
                                            <Dropdown.Toggle variant="secondary" className={`status-dropdown-toggle col-12 text-start `} id="dropdown-basic">
                                                {formData?.room_type ? formData?.room_type : t("Select room type")}
                                                <Icon image attrIcon={App_url.img.DropDownIcon} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className='dropdown-menu'>
                                                {roomTypeOptions && roomTypeOptions?.map(option => (
                                                    <Dropdown.Item key={option.value} eventKey={option.value}
                                                        style={{ paddingRight: '8px' }}>
                                                        <div className='check_menu'>
                                                            <span>
                                                                {option.label}
                                                            </span>
                                                            {formData?.room_type === option.value && (
                                                                <span className='title'>
                                                                    <Icon image attrIcon={App_url.img.Radiobutton2} />
                                                                </span>
                                                            )}
                                                        </div>
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                            {error?.room_type && <div className='mt-1 ps-1 text-danger fs-13' >{error?.room_type}</div>}
                                        </Dropdown>

                                    </div>
                                    <div className='col'>
                                        <label htmlFor=" ">{t("Type Of Cleaning")}</label>
                                        <Dropdown className={`status-dropdown ${error?.type_of_cleaning ? "error" : ""}`} onSelect={(e) => handleSelectType(e, "cleaningType")} >
                                            <Dropdown.Toggle variant="secondary" className='status-dropdown-toggle col-12 text-start' id="dropdown-basic">
                                                {formData?.type_of_cleaning ? formData?.type_of_cleaning : t("Select cleaning type")}
                                                <Icon image attrIcon={App_url.img.DropDownIcon} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className='dropdown-menu'>
                                                {cleaningTypeOptions && cleaningTypeOptions?.map(option => (
                                                    <Dropdown.Item key={option.value}
                                                        eventKey={option.value} style={{ paddingRight: '8px' }}>
                                                        <div className='check_menu'>
                                                            <span>
                                                                {option.label}
                                                            </span>
                                                            {formData?.type_of_cleaning === option.value && (
                                                                <span className='title'>
                                                                    <Icon image attrIcon={App_url.img.Radiobutton2} />
                                                                </span>
                                                            )}
                                                        </div>
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                            {error?.type_of_cleaning && <div className='mt-1 ps-1 text-danger fs-13' >{error?.type_of_cleaning}</div>}
                                        </Dropdown>
                                    </div>

                                </div>
                                <div className='row mt-2'>
                                    <div className='col'>
                                        <InputGroup
                                            label={t("Surface area (in m2)")}
                                            placeholder={t("Enter Value")}
                                            name={"surface_area"}
                                            // value={inputData?.surface_area}
                                            value={formData?.surface_area}
                                            error={error?.surface_area}
                                            onChange={(e) => onChange(e, 0)}
                                            type="text"
                                            rightLabel={' m2'}
                                        // number
                                        // allowDot
                                        />
                                    </div>
                                    <div className='col'>
                                        <InputGroup
                                            label={t("Performance Value")}
                                            placeholder={t("Enter Value")}
                                            name={"performance_value"}
                                            // value={inputData?.performance_value}
                                            value={formData?.performance_value}
                                            error={error?.performance_value}
                                            onChange={(e) => onChange(e, 0)}
                                            type="text"
                                            rightLabel={' m2/Hr'}
                                        // number
                                        // allowDot
                                        />
                                    </div>
                                </div>
                                <div className=' row mt-3'>
                                    <div className='col'>
                                        <label htmlFor=" ">{t("Weekday Frequency")}</label>
                                        <Dropdown className={`status-dropdown ${error?.weekly_weekday_count ? "error" : ""}`} onSelect={(e) => handleSelectWeekDayFrequency(e, "weeklyWeekday")}>
                                            <Dropdown.Toggle variant="secondary" className='status-dropdown-toggle col-12 text-start' id="dropdown-weekday">
                                                {formData?.weekly_weekday_count !== null ? formData?.weekly_weekday_count : t("Select weekly weekday")}
                                                <Icon image attrIcon={App_url.img.DropDownIcon} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className='dropdown-menu'>
                                                {weekDayOptionList && weekDayOptionList.map(option => (
                                                    <Dropdown.Item
                                                        key={option.value}
                                                        eventKey={option.value}
                                                        style={{ paddingRight: '8px' }}
                                                    >
                                                        <div className='check_menu'>
                                                            <span>{option.label}</span>
                                                            {formData?.weekly_weekday_count === option.value && (
                                                                <span className='title'>
                                                                    <Icon image attrIcon={App_url.img.Radiobutton2} />
                                                                </span>
                                                            )}
                                                        </div>
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                            {error?.weekly_weekday_count && <div className='mt-1 ps-1 text-danger fs-13' >{error?.weekly_weekday_count}</div>}
                                        </Dropdown>
                                    </div>

                                    <div className='col'>
                                        <label htmlFor="">{t("Sunday Frequency")}</label>
                                        <Dropdown className={`status-dropdown ${error?.weekly_sunday_count ? "error" : ""}`} onSelect={(e) => handleSelectWeekDayFrequency(e, "weeklySunday")}>
                                            <Dropdown.Toggle variant="secondary" className='status-dropdown-toggle col-12 text-start' id="dropdown-sunday">
                                                {formData?.weekly_sunday_count !== null ? formData?.weekly_sunday_count : t("Select weekly sunday")}
                                                <Icon image attrIcon={App_url.img.DropDownIcon} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className='dropdown-menu'>
                                                {sundayOptionList && sundayOptionList.map(option => (
                                                    <Dropdown.Item
                                                        key={option.value}
                                                        eventKey={option.value}
                                                        style={{ paddingRight: '8px' }}
                                                    >
                                                        <div className='check_menu'>
                                                            <span>{option.label}</span>
                                                            {formData?.weekly_sunday_count === option.value && (
                                                                <span className='title'>
                                                                    <Icon image attrIcon={App_url.img.Radiobutton2} />
                                                                </span>
                                                            )}
                                                        </div>
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                            {error?.weekly_sunday_count && <div className='mt-1 ps-1 text-danger fs-13' >{error?.weekly_sunday_count}</div>}
                                        </Dropdown>


                                    </div>

                                </div>
                                <div>
                                    <Button size={'sm'} variant={"primary"} onClick={handleCalculateClick} className={'  mt-3'}>{t("Calculate")}</Button>
                                </div>
                            </Card.Body>
                        </Card>

                    </div>
                    <div className='col-4 p-0 ps-3'>
                        <Card className='card interval-cleaning-preview'>
                            <Card.Body>
                                <div className='preview'>
                                    <h6 className=''>{t("Preview Summary")}</h6>
                                </div>
                                <Card>
                                    <div className='p-2'>

                                        <h6>{t("Surface Area")}: {formData?.total_surface_area} m</h6>
                                    </div>
                                    <Table>
                                        <thead className=''>
                                            <tr className='preview-thead'>
                                                <th>{t("Particular")}</th>
                                                <th>{t("Hours")}</th>
                                                <th>{t("Cost")}</th>
                                            </tr>
                                        </thead>
                                        <tbody className='table-with-radius preview-tbody'>
                                            <tr>
                                                <td>{t("Sunday")}</td>
                                                <td>{Utils.trimDecimal(formData?.total_monthly_sunday_hours || 0, 2, lang)}</td>
                                                <td>€ {Utils.trimDecimal(formData?.total_monthly_sunday_cost || 0, 2, lang)}</td>
                                            </tr>
                                            <tr>
                                                <td>{t("Weekday")}</td>

                                                <td>{Utils.trimDecimal(formData?.total_monthly_weekday_hours || 0, 2, lang)}</td>
                                                <td>€ {Utils.trimDecimal(formData?.total_monthly_weekday_cost || 0, 2, lang)}</td>
                                            </tr>
                                            <tr>
                                                <td>{t("Monthly")}</td>

                                                <td>{Utils.trimDecimal(formData?.total_monthly_hours || 0, 2, lang)}</td>
                                                <td>€ {Utils.trimDecimal(formData?.total_monthly_cost || 0, 2, lang)}</td>

                                            </tr>
                                            <tr>
                                                <td>{t("Yearly")}</td>
                                                <td>{Utils.trimDecimal(formData?.total_yearly_hours || 0, 2, lang)}</td>
                                                <td>€ {Utils.trimDecimal(formData?.total_yearly_cost || 0, 2, lang)}</td>

                                            </tr>
                                        </tbody>
                                    </Table>
                                </Card>

                                <div className=''>
                                    <Button disable={globalLoader || formData?.interval_array?.length == 0} onClick={onSubmit} size={'md'} variant={"primary"} className={'w-100  mt-5'}>{service ? `${globalLoader ? t("Please wait..."):t("Update this Service")}` : `${globalLoader ? t("Please wait..."):t("Submit this Service")}`}</Button>

                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
                <div className='interval-prev row m-0'>
                    <div className="col-12 p-0">
                        <Card className='card mt-3'>
                            <Card.Body>
                                <div className='preview'>
                                    <h6>{t("Preview Summary")}</h6>
                                </div>
                                <div>
                                    <PreviewDataTable formData={formData} setFormData={setFormData} />
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        </ContentLayout>
    )
}

export default IntervalCleaning