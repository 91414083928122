/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import ContentLayout from '../layout/ContentLayout';
import CustomTableTwo from '../Common/CustomTableTwo';
import UtilsGetList from '../Common/UtilsGetList';
import { WebSocketContext } from '../../WSContext';
import { useDispatch, useSelector } from 'react-redux';
import App_url from '../Common/Constants';
import { Breadcrumb } from 'react-bootstrap';
import AnchorLink from '../Common/AnchorLink';
import { useTranslation } from '../../translate';
import { setStoreCurrentUser } from '../../store/modules/users_data/action';
import { setStoreCustomersDetails } from '../../store/modules/customers/action';

export default function SelectServiceCustomerList() {
  const { t, changeLanguage } = useTranslation();

  const { ws, send } = useContext(WebSocketContext);
  const dispatch = useDispatch();
  const { device_id, websocketReqRes } = useSelector(App_url.userDataReducers);
  const { customersList, citiesIndustriesPincodes } = useSelector(App_url.customerReducers);
  const { lang } = useSelector(App_url.settingReducers);
  const navigate = useNavigate();
  console.log("customersList===", customersList)

  const location = useLocation();
  const serviceData = location?.state;
  // console.log("serviceData===", serviceData)

  const [filter, setFilter] = useState({
    search: "",
    city: "",
    postcode: "",
    industry_search: "",
  })

  const citiesOptions = citiesIndustriesPincodes?.cities?.map((item) => {
    return { label: item?.city_name, value: item?.city_name }
  })

  const postCodeOptions = citiesIndustriesPincodes?.pincodes?.map((item) => {
    return { label: item?.pincode, value: item?.pincode }
  })

  const handleView = (customerId) => {
    UtilsGetList.callCustomerDetailsApi({ ws, customer_id: customerId, lang: lang })
    if (serviceData) {
      navigate(`${App_url.link.addServiceList}/${customerId}`, { state: serviceData })
    } else {
      navigate(`${App_url.link.addServiceList}/${customerId}`)
    }
  }
  const callBack = (e, state) => {
    if (state === "AddButton2") {
      dispatch(setStoreCustomersDetails());
      navigate(`${App_url.link.addServiceListWithoutCustomer}`)
    }
  }

  useEffect(() => {
    if (ws) {
      UtilsGetList.callCustomersListApi({ ws, filter, device_id, lang, page: 1 })
      UtilsGetList.getPinCodeCityList({ ws, device_id });
    }
  }, [ws, filter])

  const RenderBody = (item) => {
    // console.log("item", item);
    return (
      <React.Fragment>
        <td className='new-calc-td' onClick={() => handleView(item?.id)} title={item.industry_type}>{item?.industry_type ? item?.industry_type : "-"}</td>
        <td className='new-calc-td' onClick={() => handleView(item?.id)} title={item.company_name}>{item?.company_name ? item?.company_name :"-"}</td>
        <td className='new-calc-td' onClick={() => handleView(item?.id)} title={item.customer_name}>{item?.customer_name ? item?.customer_name :"-"}</td>
        <td className='new-calc-td' onClick={() => handleView(item?.id)} title={item.email}>{item?.email ? item?.email :"-"}</td>
        <td className='new-calc-td' onClick={() => handleView(item?.id)} title={item.company_mobile}>{item?.company_mobile ? item?.company_mobile :"-"}</td>
        <td className='new-calc-td' onClick={() => handleView(item?.id)} title={item.company_address}>{item?.company_address ? item?.company_address :"-"}</td>
        <td className='new-calc-td' onClick={() => handleView(item?.id)} title={item.city}>{item?.city ? item?.city :"-"}</td>
      </React.Fragment>
    )
  }

  return (
    <ContentLayout title={t('Calculation')}>
      <Breadcrumb className="breadcrumb-main">
        <Breadcrumb.Item>
          <AnchorLink to={App_url.link.services}>{t("List of Services ")} </AnchorLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{t("Select Customer")}</Breadcrumb.Item>
      </Breadcrumb>
      <CustomTableTwo
        title={{ text: t("Select Customer"), size: "fs-18" }}
        AddButton2={{ title: t("Proceed Without Customer"), variant: "grey", size: "md" }}
        cityPlaceHolder={t("Select City")}
        postcodePlaceHolder={t("Select Postcode")}
        typePlaceHolder2={t("Select City")}
        columnNames={[t("Industry"), t("Company Name"), t("Person Name"), t("Email"), t("Phone No."), t("Street No"), t("City")]}
        recordData={customersList?.data}
        filter={filter}
        setFilter={setFilter}
        search_placeholder={t("Search by Company Name")}
        callBack={callBack}
        renderBody={RenderBody}
        pagination={customersList?.pagination}
        onPagination={(page) => UtilsGetList.callCustomersListApi({ ws, device_id, lang, page: page })}
        sr_no={"new-calc-td"}
        cityOption={citiesOptions}
        postCodeOption={postCodeOptions}

      />

    </ContentLayout>
  )
}
