/* eslint-disable */
import React, { useState } from 'react'
import { Card, Table } from 'react-bootstrap'
import InputGroup from '../../Common/InputGroup'
import { useTranslation } from '../../../translate';


const   BuildingCalculationTable = ({childFormData, setChildFormData, handleDataChange}) => {
  const { t, changeLanguage } = useTranslation();

  
  const [childErrors, setChildErrors] = useState({
    Office: {  weekly_weekday_count: "", weekly_sunday_count: "", performance_value: ""},
    Conference: {  weekly_weekday_count: "", weekly_sunday_count: "", performance_value: ""},
    Hallways: {  weekly_weekday_count: "", weekly_sunday_count: "", performance_value: ""},
    "Sanitary Facilities": {  weekly_weekday_count: "", weekly_sunday_count: "", performance_value: ""},
    "Tea Kitchen"	: {  weekly_weekday_count: "", weekly_sunday_count: "", performance_value: ""},
    Entrance: {  weekly_weekday_count: "", weekly_sunday_count: "", performance_value: ""},
    Elevator: {  weekly_weekday_count: "", weekly_sunday_count: "", performance_value: ""},
    Store: {  weekly_weekday_count: "", weekly_sunday_count: "", performance_value: ""},
    Stair: {  weekly_weekday_count: "", weekly_sunday_count: "", performance_value: ""},
  });

      const options = [
        { label: 0, value: 0 },
        { label: 1, value: 1 },
        { label: 2, value: 2 },
        { label: 3, value: 3 },
        { label: 4, value: 4 },
        { label: 5, value: 5 },
    ]
    const options2 = [
        { label: 0, value: 0 },
        { label: 1, value: 1 },
    ]

      const handleInputChange = (areaName, field, value) => {
        setChildFormData(prevData => {
          const updatedData = {
            ...prevData,
            [areaName]: {
              ...prevData[areaName],
              area_name:areaName,
              [field]: value,
              // isChecked: false,
            },
          };

          // Notify the parent component about the change
          // handleDataChange(areaName, updatedData[areaName]);
          
          return updatedData;
        });

        setChildErrors(prevData => {
          const updatedData = {
            ...prevData,
            [areaName]: {
              ...prevData[areaName],
              [field]: "",
            },
          };
          return updatedData;
        });

      };

      const handleCheck = (e, areaName) => {
        if (e.target.checked) {
          setChildFormData(prevData => {
              const updatedData = {
                  ...prevData,
                  [areaName]: {
                      ...prevData[areaName],
                      isChecked: true,
                  },
              };
              console.log("updatedData", updatedData, Object.keys(updatedData));
      
              // Calculate the not selected area percentage
              let notSelectedTotalPercentage = 0;
              Object.keys(updatedData).forEach((area) => {
                  if (!updatedData[area].isChecked && updatedData[area].area_percentage) {
                      notSelectedTotalPercentage += parseFloat(updatedData[area].area_percentage);
                  }
              });
              console.log("Not selected area percentage", notSelectedTotalPercentage);
      
              handleDataChange(areaName, updatedData[areaName], notSelectedTotalPercentage);
              return updatedData;
          });
      } else {
          setChildFormData(prevData => {
              const updatedData = {
                  ...prevData,
                  [areaName]: {
                      ...prevData[areaName],
                      isChecked: false,
                  },
              };
              console.log("updatedData", updatedData, Object.keys(updatedData));
      
              // Recalculate the not selected area percentage after unchecking
              let notSelectedTotalPercentage = 0;
              Object.keys(updatedData).forEach((area) => {
                  if (!updatedData[area].isChecked && updatedData[area].area_percentage) {
                      notSelectedTotalPercentage += parseFloat(updatedData[area].area_percentage);
                  }
              });
              console.log("Not selected area percentage", notSelectedTotalPercentage);
      
              handleDataChange(areaName, updatedData[areaName], notSelectedTotalPercentage);
              return updatedData;
          });
      }
      
      
    };


      const validation = (areaName) =>{
        const areaData = childFormData[areaName];
        const newErrors = {};

        if (!areaData?.weekly_weekday_count || areaData?.weekly_weekday_count === 0) {
            newErrors.weekly_weekday_count = t('Required');
          }
          if (!areaData?.weekly_sunday_count || areaData?.weekly_sunday_count === 0) {
            newErrors.weekly_sunday_count = t('Required');
          }
          if (!areaData?.performance_value) {
            newErrors.performance_value = t('Required');
          }
      
          setChildErrors(prevErrors => ({
            ...prevErrors,
            [areaName]: newErrors,
          }));
      
          return Object.keys(newErrors).length === 0;
      }

      // console.log("error", childErrors)





    return (
        <div>
            <Card className='card '>
                <Table className='building-table' striped >
                    <thead className='text-center'>
                        <tr >
                            <th>{t("Area Name")}</th>
                            <th>{t("Weekday")}</th>
                            <th>{t("Sunday")}</th>
                            <th>{t("Performance m2/hr")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(childFormData)?.map((area)=>(
                            <tr key={t(area)}>
                            <td className='d-flex  align-items-center'>
                                {/* <div> */}
                                <input
                                    type="checkbox"
                                    className="custom-checkbox"
                                    onChange={(e) => handleCheck(e, area)}
                                    checked={
                                      // childFormData[area].weekly_weekday_count &&
                                      // childFormData[area].weekly_sunday_count &&
                                      // childFormData[area].performance_value &&
                                      childFormData[area]?.isChecked
                                    }
                                />
                                <span className="label-text text-nowrap">{t(area)}</span>
                                {/* </div> */}
                            </td>
                            <td className='pb-0'>
                                <InputGroup
                                    placeholder={t("Select Value")}
                                    name={"weekly_weekday_count"}
                                    value={childFormData[area]?.weekly_weekday_count} 
                                    error={childErrors[area]?.weekly_weekday_count}
                                    onChange={(e) => handleInputChange(area, 'weekly_weekday_count', e.target.value)} 
                                    type="select"
                                    selected
                                    number
                                    option={options}
                                    className={'m-0'}
                                    allowDot
                                />
                            </td>
                            <td className='pb-0'>
                                <InputGroup
                                    placeholder={t("Select Value")}
                                    name={"weekly_sunday_count"}
                                    value={childFormData[area]?.weekly_sunday_count} 
                                    error={childErrors[area]?.weekly_sunday_count}
                                    onChange={(e) => handleInputChange(area, 'weekly_sunday_count', e.target.value)} 
                                    type="select"
                                    selected
                                    number
                                    option={options2}
                                    className={'m-0'}
                                    allowDot
                                />
                            </td>
                            <td className='pb-0'>
                                <InputGroup
                                    placeholder={t("Enter Value")}
                                    name={"performance_value"}
                                    value={childFormData[area]?.performance_value} 
                                    error={childErrors[area]?.performance_value}
                                    onChange={(e) => handleInputChange(area, 'performance_value', e.target.value)} 
                                    type="text"
                                    className={'m-0'}
                                    number
                                    allowDot
                                />
                            </td>
                        </tr>
                        ))}
                    </tbody>

                </Table>

            </Card>
        </div>
    )
}

export default BuildingCalculationTable