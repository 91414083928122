/* eslint-disable */
import React from 'react'
import { Modal } from 'react-bootstrap'
import Button from './Button'
import { useDispatch, useSelector } from 'react-redux'
import App_url from './Constants'
import { setStoreValidationMessageModal } from '../../store/modules/modals/action'
import { useTranslation } from '../../translate'

const MessageModal = () => {
    const { t, } = useTranslation();

    const dispatch = useDispatch();
    const { ModalPopupThree } = useSelector(App_url.modalReducers);


    const handleClose = async () => {
        if (ModalPopupThree?.callBackModal) {
            await ModalPopupThree?.callBackModal(ModalPopupThree?.data?.item);
        }
        CloseData()
    };
    const CloseData = () => {
        dispatch(setStoreValidationMessageModal())
    }
    if (ModalPopupThree?.show !== "VALIDATION_MESSAGE_MODAL") {
        return null
    }

    return (
        <div>
            <Modal show={true} centered  >
                <Modal.Body className='message-modal'>
                    <div className='main-content mt-1'>
                        <h6 className='title'>{ModalPopupThree?.title}</h6>
                        <h6 className='subtitle' dangerouslySetInnerHTML={{ __html:ModalPopupThree?.description }}></h6>
                    </div>
                    <div className='discard-btn-container'>
                        <Button variant="outline-primary" className='no-btn' onClick={CloseData}>{t("Back")} </Button>
                        <Button onClick={handleClose} variant="primary" className='yes-btn'>{t("Update")}</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default MessageModal