/* eslint-disable */
import { ActionTypes } from "./action";

const initialData = {

    documentationList: {
        data: [],
        pagination: []
    },
    documentInsideFolderList: {
        data: [],
        pagination: [],
    }

}
export const documentationReducers = (state = initialData, action) => {
    switch (action.type) {

        case ActionTypes.SET_STORE_DOCUMENTATION_LIST: {
            console.log("action?.payload", action?.payload);
            return {
                ...state,
                documentationList: {
                    data: action?.payload?.data,
                    pagination: action?.payload?.pagination,
                }
            }
        }
        case ActionTypes.SET_STORE_DOCUMENT_INSIDE_FOLDER_LIST: {
            console.log("action?.payload", action?.payload);
            return {
                ...state,
                documentInsideFolderList: {
                    data: action?.payload?.data,
                    pagination: action?.payload?.pagination,
                }
            }
        }



        default:
            return state;
    }

}