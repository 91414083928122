import { ActionTypes } from "./action";

const initialData = {
    servicesList: {
        data: [],
        pagination: {}
    },
    getServiceList: [],

}
export const servicesReducers = (state = initialData, action) => {
    switch (action.type) {
        case ActionTypes.SET_STORE_SELECTED_SERVICES_LIST: {
            // console.log("action?.payload", action?.payload);
            return {
                ...state,
                servicesList: {
                    data: action?.payload?.data,
                    pagination: action?.payload?.pagination,
                }
            }
        }
        case ActionTypes.SET_STORE_GET_SERVICES_LIST: {
            // console.log("action?.payload", action?.payload);
            return {
                ...state,
                getServiceList: action?.payload
            }
        }


        default:
            return state;
    }

}