/* eslint-disable */
import React from 'react'
import { Modal } from 'react-bootstrap';
import { setStoreSessionExpiredModal, } from '../../store/modules/modals/action';
import { useTranslation } from '../../translate';
import { useDispatch, useSelector } from 'react-redux';
import App_url from './Constants';
import Button from './Button';

const SessionExpiredModal = () => {
    const { t, } = useTranslation();

    const dispatch = useDispatch();
    const { ModalPopupThree } = useSelector(App_url.modalReducers);


    const handleClose = async () => {
        if (ModalPopupThree?.callBackModal) {
            await ModalPopupThree?.callBackModal(ModalPopupThree?.data?.item);
        }
        CloseData();
    };
    const CloseData = () => {
        dispatch(setStoreSessionExpiredModal())
    }
    if (ModalPopupThree?.show !== "SESSION_EXPIRE_MODAL") {
        return null
    }
    return (
        <div>
            <Modal show={true} centered className='discard-modal' >
                <Modal.Body className=' '>
                    <div className='main-content mt-1'>
                        <h6 className='title'>{ModalPopupThree?.data?.description}</h6>
                    </div>
                    <div className='discard-btn-container'>
                        <Button onClick={handleClose} variant="primary" className='yes-btn'>{t("Okay..!")}</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default SessionExpiredModal