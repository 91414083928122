/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import CustomTableTwo from '../Common/CustomTableTwo'
import Icon from '../Common/Icon'
import App_url from '../Common/Constants'
import ContentLayout from '../layout/ContentLayout'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import wsSendRequest from '../../socketIO/wsSendRequest'
import { WebSocketContext } from '../../WSContext'
import { useDispatch, useSelector } from 'react-redux'
import { setStoreDiscardChangesModal, setStoreOfferModal } from '../../store/modules/modals/action'
import Utils from '../Common/Utils'
import { setStoreCustomersDetails } from '../../store/modules/customers/action'
import { useTranslation } from '../../translate'
import UtilsGetList from '../Common/UtilsGetList'

const SavedOfferList = () => {
    const { t, changeLanguage } = useTranslation();

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { ws, send } = useContext(WebSocketContext);
    const { device_id, websocketReqRes } = useSelector(App_url.userDataReducers);
    const { lang } = useSelector(App_url.settingReducers);
    const { customerDetails } = useSelector(App_url.customerReducers);
    const { quickOfferList } = useSelector(App_url.externalOfferReducers);
    // console.log("customerDetails", customerDetails);
    const location = useLocation();

    const [filter, setFilter] = useState({
        search: "",
    })

    const callBack = (e, state) => {
        if (state === "AddButton") {
            navigate(App_url.link.addCreateOffer)
        }
    }

    const handleDeleteOfferModal = (offerId) => {
        dispatch(setStoreDiscardChangesModal({
            show: "DISCARD_CHANGES_MODAL",
            data: { description: t("Are you sure you want to delete this Quick offer ?") },
            callBackModal: () => handleDeleteOffer(offerId)

        }))
    }

    const handleEditOffer = (offerId, offerItem) => {
        console.log("offerItem", offerItem)
        UtilsGetList.callCustomerDetailsApi({ws: ws, customer_id:offerItem?.customer_id, lang:lang});
        const param = {
            transmit: "broadcast",
            url: "get_offer",
            request: {
                offer_id: offerId,
            }
        };
        send(param);
        navigate(`${App_url.link.addCreateOffer}/${offerId}`, { state: offerItem })
    }

    const callQuickOfferListApi = (page) => {
        const param = {
            transmit: "broadcast",
            url: "quick_offer_list",
            request: {
                page_no: page,
                limit: "20",
                search: filter?.search,
                order_by: '-updated_at',
            }
        };
        // console.log("param",param)
        send(param);
    };

    useEffect(() => {
        if (ws) {
            callQuickOfferListApi(1)
            // if (filter?.search === "") {
            //     dispatch(setStoreCustomersDetails());
            // }
        }
    }, [ws, filter]);

    const handleCategoryArray = (category) => {
        const categoryString = category?.join(', ')
        return categoryString;
    }

    const handleDeleteOffer = (offerId) => {
        const param = {
            transmit: "broadcast",
            url: "delete_offer",
            request: { offer_id: offerId },
            toast: true,
        };
        send(param, device_id)
    }
    useEffect(() => {
        if (websocketReqRes?.url === "delete_offer" && websocketReqRes?.status === 200) {
            callQuickOfferListApi(1)
        }
    }, [websocketReqRes?.url]);
    
    // useEffect(()=>{
    //     console.log("location?.state?.fromPage", location?.state)
    //     if(location?.state?.fromPage === "customerEdit"){
    //         dispatch(setStoreOfferModal({
    //             show: "OFFER_MODAL",
    //             data: { ...location?.state, fromPage:null }
    //         }));

    //     }
    // },[location?.state?.fromPage === "customerEdit"])

    const RenderBody = (item,) => {
        return (
            <React.Fragment>
                <td onClick={() => handleEditOffer(item?.id, item)} className='new-calc-td' >{item?.category ? handleCategoryArray(item?.category) : "-"}</td>
                <td onClick={() => handleEditOffer(item?.id, item)} className='new-calc-td' >{item?.customer_name ? item?.customer_name : "-"}</td>
                <td onClick={() => handleEditOffer(item?.id, item)} className='new-calc-td' >{item?.updated_at ? Utils.formatDateTimeCreatedAt(item?.updated_at) : "-"}</td>
                <td className='d-flex gap-2 new-calc-td'>
                    {/* <span className='view-icon-box'><Icon className={"bg-primary"} attrIcon={App_url.img.EyeIcon} /></span> */}
                    {/* <span className='view-icon-box'><Icon className={"bg-primary"} onClick={() => handleEditOffer(item?.id, item)} attrIcon={App_url.img.EditIcon} /></span> */}
                    <span className='view-icon-box'><Icon className={"bg-primary"} onClick={() => handleEditOffer(item?.id, item)} attrIcon={App_url.img.EyeIcon} /></span>
                    <span className='view-icon-box'><Icon onClick={() => handleDeleteOfferModal(item?.id)} className={"bg-primary"} attrIcon={App_url.img.DeleteIcon} /></span>
                </td>
            </React.Fragment>
        )
    }

    return (
        <ContentLayout>
            <div>
                <CustomTableTwo
                    title={{ text: t("Saved Offer"), textTwo: `${t("Total Saved Offer")} ${quickOfferList?.data?.length || 0}` }}
                    AddButton={{ title: t("Quick Offer"), variant: "outline-primary", size: "sm" }}
                    columnNames={[t('Service Category'), t("Company Name"), t("Last Edit"), t("View")]}
                    recordData={quickOfferList?.data}
                    renderBody={RenderBody}
                    callBack={callBack}
                    search_placeholder={t("Search By Name,City,Industry & Postcode")}
                    typePlaceHolder={t("Select Status")}
                    pagination={quickOfferList?.pagination}
                    onPagination={(page) => callQuickOfferListApi(page)}
                    style={{ height: "182px" }}
                />
            </div>
        </ContentLayout>
    )
}

export default SavedOfferList