/* eslint-disable */
import React, { useState } from 'react'
import { Card, Modal } from 'react-bootstrap'
import Button from '../Common/Button'
import Icon from '../Common/Icon'
import App_url from '../Common/Constants'
import InputGroup from '../Common/InputGroup'
import { toast } from 'react-toastify'
import { useTranslation } from '../../translate'

const CreateFolderModal = ({ isModalOpen, handleClose, handleCreateFolder }) => {
    const [folderName, setFolderName] = useState("");
    const { t, changeLanguage } = useTranslation();
    const handleClickOk = () =>{
        if(folderName){
            handleCreateFolder(folderName);
            setFolderName("");
            handleClose();
        }else{
            toast.error("Pleaser enter the folder name")
        }
    }
    return (
        <div>
            <Modal show={isModalOpen} onHide={handleClose} size='sm' centered className='createFolder'>

                <Modal.Body>
                    <div className=' heading '>
                        <h6 className='heading-1'>{t("Create Folder")}</h6>
                        <h6>{t("Please enter a new name for the folder")}</h6>
                    </div>
                    <div className='mt-3'>
                        <InputGroup
                            label={""}
                            placeholder={t("Enter new name")}
                            formClassName=""
                            name="folder_name"
                            type={'text'}
                            value={folderName}
                            onChange={(e)=> setFolderName(e.target.value)}
                        />
                    </div>
                    <div className='text-center mt-4'>
                        <Button onClick={handleClose} className={"col-5 me-3"} variant={"outline-primary"}>{t("Cancel")}</Button>
                        <Button className={"col-5"} onClick={handleClickOk} variant={"primary"}>{t("Okay")}</Button>
                    </div>
                </Modal.Body>

            </Modal>
        </div>
    )
}

export default CreateFolderModal