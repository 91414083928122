
/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import ContentLayout from '../layout/ContentLayout'
import CustomTableTwo from '../Common/CustomTableTwo'
import Icon from '../Common/Icon'
import App_url from '../Common/Constants'
import wsSendRequest from '../../socketIO/wsSendRequest'
import { useDispatch, useSelector } from 'react-redux'
import { WebSocketContext } from '../../WSContext'
import { setStoreComposeMailModal } from '../../store/modules/modals/action'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from '../../translate'
import Utils from '../Common/Utils'

const PromotionList = () => {
    const { t, changeLanguage } = useTranslation();
    const { id: promotionId } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { ws, send } = useContext(WebSocketContext);
    const { device_id } = useSelector(App_url.userDataReducers);
    const { customerDetails } = useSelector(App_url.customerReducers);
    const { promotionList } = useSelector(App_url.promotionReducers);
    const [filter, setFilter] = useState({
        search: "",
        date: ''
    })

    const callPromotionListApi = (page) => {
        const param = {
            transmit: "broadcast",
            url: "promotion_list",
            request: {
                limit: "10",
                page_no: page,
                search: filter?.search,
                // customer_id: customerDetails?.[0]?.id,
                from_date: "",
            }
        };
        // console.log("param",param)
        send(param);
    };
    useEffect(() => {
        if (ws) {
            callPromotionListApi(1);
        }
    }, [ws, filter]);
    const callBack = (e, state, buttonName) => {
        if (state === "AddButton") {
            navigate(App_url.link.createPromotion);
        }
    }
    const handleViewPromotion = (promotionItem) => {
        // console.log(promotionId)
        navigate(`${App_url.link.viewPromotion}/${promotionItem?.id}`, {state:promotionItem?.promotion_id})
    }
    const maxLength = 205 || "";

    const EllipsisText = (text, maxLength) => {
      if (text?.length > maxLength) {
        return text?.substring(0, maxLength) + '...';
      }
      return text;
    };

    // console.log(promotionList?.data?.description)
    const handleRemoveImg = (descriptionString) => {
        let cleanedString = descriptionString?.replace(/<img[^>]*>/g, '');

        // Remove all <p> tags and their content, as well as <br> tags
        cleanedString = cleanedString?.replace(/<\/?p>|<br>/g, '');

        // Remove any extra spaces (leading, trailing, or multiple spaces)
        cleanedString = cleanedString?.replace(/\s+/g, ' ').trim();

        // console.log("cleanedString", cleanedString);
        return cleanedString;
    }
    const RenderBody = (item) => {
        // console.log("item", item);
        return (
            <React.Fragment className='dashTitle'>
                <td className='new-calc-td'>{item?.topic}</td>
                <td className='new-calc-td ' dangerouslySetInnerHTML={{ __html: handleRemoveImg(item?.description), }} ></td>
                <td className='new-calc-td'>{Utils.formatDateTime2(item?.created_at,t)}</td>
                <td className='new-calc-td'>
                    <span className='view-icon-box' onClick={() => handleViewPromotion(item)}><Icon className={"bg-primary"} attrIcon={App_url.img.EyeIcon} /></span>
                </td>
            </React.Fragment>
        )
    }
    return (
        <ContentLayout title={t("Promotion Page")}>
            <CustomTableTwo
                title={{ text: t("Promotions List"), size: "fs-20", textTwo: "" }}
                AddButton={{ title: t("+ Create Promotion"), variant: "outline-primary", size: "md" }}
                columnNames={[t("Topic"), t("Description"), t("Last Modified"), t("Action")]}
                recordData={promotionList?.data || []}
                renderBody={RenderBody}
                callBack={callBack}
                filter={filter}
                setFilter={setFilter}
                search_placeholder={t("Search by customer or by topic")}
                typePlaceHolder={t("Select Date")}
                pagination={promotionList?.pagination}
                onPagination={(page) => callPromotionListApi(page)}
                sr_no={"new-calc-td"}

            />
        </ContentLayout>

    )
}

export default PromotionList