/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import { Card, Modal, ModalHeader } from 'react-bootstrap'
import Button from '../Common/Button'
import Icon from '../Common/Icon'
import App_url from '../Common/Constants'
import InputGroup from '../Common/InputGroup'
import { toast } from 'react-toastify'
import { useTranslation } from '../../translate'
import { useSelector } from 'react-redux'
import { WebSocketContext } from '../../WSContext'

export default function MoveFolderModal({ isModalOpen, handleClose, handleMoveDocToFolder }){
    const { t } = useTranslation();
    const { documentationList} = useSelector(App_url.documentationReducers);

    const folderList = documentationList?.data?.filter((item)=> item.document_type === "folder");

console.log("folderList", folderList);

    const handleClickOk = () =>{
        // if(folderName){
        //     handleCreateFolder(folderName);
        //     setFolderName("");
        //     handleClose();
        // }else{
        //     toast.error("Pleaser enter the folder name")
        // }
    }

    return (
        <div>
            <Modal show={isModalOpen} onHide={handleClose} size='sm' centered className='move-folder'>
                <ModalHeader closeButton>
                <div className=' heading '>
                        <h6 className='heading-1'>{t("Move Document to Folder")}</h6>
                        <h6>{t("Please select the folder")}</h6>
                    </div>
                </ModalHeader>
                <Modal.Body className='move-folder-body'>
                    <div className='mt-3'>
                        {folderList?.map((item) => (
                          <>
                            <div onClick={()=> handleMoveDocToFolder(item)} key={item.id} className="folder-item cursor-pointer">
                            <Icon className={"me-2 document-type"} attrIcon={App_url.img.FolderIcon} image />{item.document_name}
                            </div>
                            <hr />
                          </>
                        ))}
                    </div>
                    
                </Modal.Body>

            </Modal>
        </div>
    )
}

