import { ActionTypes } from "./action";
const initialData = {
    lang: "en",
    globalLoader:null,
}

export const settingReducers = (state = initialData, action) => {
    switch (action.type) {
        case ActionTypes.SET_APPLICATION_LANGUAGE: {
            return {
                ...state,
                lang: action?.payload,
            }
        }
        case ActionTypes.SET_STORE_GLOBAL_LOADER:{
            return{
                ...state,
                globalLoader:action?.payload ? action?.payload : initialData?.globalLoader
            }
        }
        
        default:
            return state;
    }

}
