// PutRequestAPI

export const PutRequestCallAPI = async (apiurl, data, access_token, pdf) => {
    const headers = {
        'Accept': "application/json",
        'Content-Type': "application/json",  
    }

    if (access_token) {
        headers.Authorization = `Bearer ${access_token}`;
    }
    if (pdf) {
        headers.Accept = "application/pdf";
    }

    const header = {
        headers: headers
    }
    const putResponse = window.axios.put(`${process.env.REACT_APP_API_DOMAIN_URL}/${apiurl}`, data, header).then(function (result) {
        return result;
    }).catch((e) => e.response);

    return putResponse;
}
