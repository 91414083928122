/* eslint-disable */
import React, { useMemo, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import Icon from './Icon'
import App_url from './Constants'
import AnchorLink from './AnchorLink'
import PropTypes from "prop-types";
import InputGroup from './InputGroup'
import { uuidv4 } from './Utils'
import { useNavigate } from 'react-router'

export default function DropDownMenu(props) {
  const router = useNavigate();
  const [search, setSearch] = useState("")
  const [show, setShow] = useState(false);
  let ids = useMemo(() => uuidv4()+`_select`, []); // removed name dependency from here
  const BtnClick = (e) =>{
    if(props?.listItem?.length>0){
      setShow(!show)      
    }else{
      setShow(false)
    }
  }
  const onClick = (e, item) =>{
    if(!item?.to){
      props?.onSelect(item)
      setShow(false)
    }else{
      router(item?.to);
    }
  }
  const onChange = (e) =>{
    setSearch(e.target.value)
  }
  const filteredItems = ()=> {
    if(props?.listItem?.length<=0){
      return [];
    }
    return props?.listItem.filter(item =>{
      if(String(item?.title) || String(item?.value)){
        return  String(item?.title)?.toLowerCase()?.includes(search?.toLowerCase())||
        String(item?.value)?.toLowerCase()?.includes(search?.toLowerCase())
      }else{
        return true
      }
    });
  }
  const onToggle = (item, {source, originalEvent}) =>{
    console.log("source", source)
    if(source == "rootClose"){
      setShow(false);
    }
  }
  return (
    <Dropdown show={show} onToggle={onToggle} className={`${props?.dropDownClassName} common-dropdown`} >
        <Dropdown.Toggle onClick={BtnClick} className={`w-100 ${props?.className}`} variant={props?.variant} id="dropdown-basic">
          {props?.leftIcon}
          {props?.title} 
          <Icon attrIcon={App_url.img.ArrowDown} className='sm ms-1' />
        </Dropdown.Toggle>
        
        {props?.listItem?.length>0 ? (
          <Dropdown.Menu show={show} className={`b-0 type-${props?.type} ${props?.borderBottom?"border-bottom":""}`} >
            {props?.type === "input" && <Dropdown.Item className='px-0 search'>{console.log("ids", ids)}
              <InputGroup placeholder='Search' size={"sm"} id={ids} onChange={onChange} value={search} className='border-1' />
            </Dropdown.Item>}
            {filteredItems()?.map((item, index)=>(
              <React.Fragment key={index}>
                    <Dropdown.Item onClick={(e)=>onClick(e, item)} className={`${props?.value != "" && props?.value === item?.value?"selected":""}`}>
                        {props?.type === "input" && (
                          <span className={`circle`}>
                            {props?.value != "" && props?.value === item?.value &&(
                              <Icon className='xsm white' attrIcon={App_url.image.Check}/>
                            )}
                          </span>
                        )}
                        {item?.Icon}
                        {item?.title}
                    </Dropdown.Item>
                </React.Fragment>
              ))}
          </Dropdown.Menu>
        ):
        
        <React.Fragment/>}
    </Dropdown>
  )
}
DropDownMenu.propTypes = {
  className: PropTypes.any,
  listItem: PropTypes.array,
  dropDownClassName: PropTypes.any,
  variant: PropTypes.any,
  value: PropTypes.any,
  onSelect: PropTypes.func,
  type: PropTypes.any,
  borderBottom: PropTypes.bool,
}
DropDownMenu.defaultProps = {
  className: "",
  listItem: [],
  dropDownClassName: "",
  variant: "",
  value: "",
  onSelect: ()=>{},
  type: "dropdown",
  borderBottom: false,
}