/* eslint-disable */
import { toast } from "react-toastify";
import Utils, { uuidv4 } from "../Common/Utils";
import { useEffect } from "react";


// converting comma to . calculation
function convertDotToComma(number) {

    // Convert number to a string and replace '.' with ','
    return number?.toString()?.replace(',', '.');
}
// Glass Calculations
const calculatePerformance = ({ formData, setFormData }) => {
    const { value, surface_area } = formData;

    const per_exceution_hour = parseFloat(surface_area / value);
    const per_exceution = parseFloat(per_exceution_hour * formData?.hourly_rate);

    const yearly_hour = parseFloat(per_exceution_hour * (formData?.frequency || 0));
    const yearly = parseFloat(yearly_hour * formData?.hourly_rate);

    const monthly_cost = parseFloat(yearly / 12);
    const monthly_hour = parseFloat(yearly_hour / 12);

    setFormData(prevFormData => ({
        ...prevFormData,
        per_exceution_hour: per_exceution_hour,
        per_exceution: per_exceution,
        yearly: yearly,
        monthly_cost: monthly_cost,
        monthly_hour: monthly_hour,
        yearly_hour: yearly_hour
    }));
};
const calculatePerformanceGermen = ({ formData, setFormData }) => {
    let { value, surface_area } = formData;
    value = convertDotToComma(formData?.value);
    // console.log(value)
    surface_area = convertDotToComma(formData?.surface_area)

    const per_exceution_hour = parseFloat(surface_area / value);
    const per_exceution = parseFloat(per_exceution_hour * formData?.hourly_rate);

    const yearly_hour = parseFloat(per_exceution_hour * (formData?.frequency || 0));
    const yearly = parseFloat(yearly_hour * formData?.hourly_rate);

    const monthly_cost = parseFloat(yearly / 12);
    const monthly_hour = parseFloat(yearly_hour / 12);

    setFormData(prevFormData => ({
        ...prevFormData,
        value: value, surface_area: surface_area,
        per_exceution_hour: per_exceution_hour,
        per_exceution: per_exceution,
        yearly: yearly,
        monthly_cost: monthly_cost,
        monthly_hour: monthly_hour,
        yearly_hour: yearly_hour
    }));
};

const calculateStandard = ({ formData, setFormData }) => {
    const { value, surface_area } = formData;

    const standRate = parseFloat(value * surface_area);
    const yearlyStandardRate = parseFloat(standRate * formData?.frequency);
    const monthly_cost = parseFloat(yearlyStandardRate / 12);
    setFormData(prevFormData => ({
        ...prevFormData,
        standRate: standRate,
        yearly: yearlyStandardRate,
        monthly_cost: monthly_cost,
        per_exceution: standRate,
    }));
};

const calculateStandardGermen = ({ formData, setFormData }) => {
    let { value, surface_area } = formData;
    value = convertDotToComma(formData?.value);
    // console.log(value)
    surface_area = convertDotToComma(formData?.surface_area)
    const standRate = parseFloat(value * surface_area);
    const yearlyStandardRate = parseFloat(standRate * formData?.frequency);
    const monthly_cost = parseFloat(yearlyStandardRate / 12);
    setFormData(prevFormData => ({
        ...prevFormData,
        value: value,
        surface_area: surface_area,
        standRate: standRate,
        yearly: yearlyStandardRate,
        monthly_cost: monthly_cost,
        per_exceution: standRate,
    }));
};

// Full Cleaning
const calculateFullCleaning = ({ formData, setFormData }) => {
    const { employee_per_hour_cost, company_surcharge, surface_area, value, weekday_frequency, sunday_frequency } = formData
    const costForPercent = employee_per_hour_cost * (company_surcharge * 0.01);
    const hourly_rate = parseFloat(employee_per_hour_cost) + parseFloat(costForPercent);
    const sunday_hourly_rate = hourly_rate * 1.5;
    const hoursReq = surface_area / value;

    const yearlyWeekdayHours = hoursReq * weekday_frequency;
    const yearlyWeekdayCost = yearlyWeekdayHours * hourly_rate;
    const weekday_hour = yearlyWeekdayHours / 12;
    const weekday_cost = weekday_hour * hourly_rate

    const yearlySundayHours = hoursReq * sunday_frequency;
    const yearlySundayCost = yearlySundayHours * sunday_hourly_rate;

    const sunday_hour = yearlySundayHours / 12;
    const sunday_cost = sunday_hour * sunday_hourly_rate

    const yearly = parseFloat(yearlyWeekdayCost) + parseFloat(yearlySundayCost);
    const monthly_cost = yearly / 12;

    const yearly_hour = parseFloat(yearlyWeekdayHours) + parseFloat(yearlySundayHours)
    const monthly_hour = yearly_hour / 12

    setFormData(prevFormData => ({
        ...prevFormData,
        hourly_rate: hourly_rate,
        yearlySundayCost: yearlySundayCost,
        yearlySundayHours: yearlySundayHours,
        yearlyWeekdayCost: yearlyWeekdayCost,
        yearlyWeekdayHours: yearlyWeekdayHours,
        yearly: yearly,
        yearly_hour: yearly_hour,
        monthly_cost: monthly_cost,
        monthly_hour: monthly_hour,
        weekday_cost: weekday_cost,
        sunday_cost: sunday_cost,
        weekday_hour: weekday_hour,
        sunday_hour: sunday_hour,
        sunday_hourly_rate: sunday_hourly_rate,
    }));

}

const calculateFullCleaningGermen = ({ formData, setFormData }) => {
    let { employee_per_hour_cost, company_surcharge, surface_area, value, weekday_frequency, sunday_frequency } = formData;

    employee_per_hour_cost = convertDotToComma(formData?.employee_per_hour_cost);
    console.log("emp", employee_per_hour_cost)

    company_surcharge = convertDotToComma(formData?.company_surcharge);
    surface_area = convertDotToComma(formData?.surface_area);
    value = convertDotToComma(formData?.value)
    const costForPercent = employee_per_hour_cost * (company_surcharge * 0.01);
    const hourly_rate = parseFloat(employee_per_hour_cost) + parseFloat(costForPercent);
    const sunday_hourly_rate = hourly_rate * 1.5;
    const hoursReq = surface_area / value;

    const yearlyWeekdayHours = hoursReq * weekday_frequency;
    const yearlyWeekdayCost = yearlyWeekdayHours * hourly_rate;
    const weekday_hour = yearlyWeekdayHours / 12;
    const weekday_cost = weekday_hour * hourly_rate

    const yearlySundayHours = hoursReq * sunday_frequency;
    const yearlySundayCost = yearlySundayHours * sunday_hourly_rate;

    const sunday_hour = yearlySundayHours / 12;
    const sunday_cost = sunday_hour * sunday_hourly_rate

    const yearly = parseFloat(yearlyWeekdayCost) + parseFloat(yearlySundayCost);
    const monthly_cost = yearly / 12;

    const yearly_hour = parseFloat(yearlyWeekdayHours) + parseFloat(yearlySundayHours)
    const monthly_hour = yearly_hour / 12

    setFormData(prevFormData => ({
        ...prevFormData,
        employee_per_hour_cost: employee_per_hour_cost,
        company_surcharge: company_surcharge,
        surface_area: surface_area,
        value: value,
        hourly_rate: hourly_rate,
        yearlySundayCost: yearlySundayCost,
        yearlySundayHours: yearlySundayHours,
        yearlyWeekdayCost: yearlyWeekdayCost,
        yearlyWeekdayHours: yearlyWeekdayHours,
        yearly: yearly,
        yearly_hour: yearly_hour,
        monthly_cost: monthly_cost,
        monthly_hour: monthly_hour,
        weekday_cost: weekday_cost,
        sunday_cost: sunday_cost,
        weekday_hour: weekday_hour,
        sunday_hour: sunday_hour,
        sunday_hourly_rate: sunday_hourly_rate,
    }));

}

// Interval Cleaning
const calculateIntervalCleaning = ({ formData, setFormData }) => {
    let {
        employee_per_hour_cost,
        surface_area,
        company_surcharge,
        performance_value,
        room_type,
        type_of_cleaning,
        weekly_weekday_count,
        weekly_sunday_count,
        currentUid,
        total_surface_area,
        total_monthly_sunday_hours,
        total_monthly_sunday_cost,
        total_monthly_weekday_hours,
        total_monthly_weekday_cost,
        total_monthly_hours,
        total_monthly_cost,
        total_yearly_hours,
        total_yearly_cost,
        interval_array,
        edit_room_type,
        edit_type_of_cleaning,
    } = formData;

    const isRoomTypeExist = interval_array?.find((item) => item.room_type === room_type && item?.type_of_cleaning === type_of_cleaning && (item?.id) !== currentUid)

    if (isRoomTypeExist) {
        toast.warn(`Room type ${room_type} with  ${type_of_cleaning} already exist`);
        return
    }

    // Check if surface area matches only if the room type exists
    const isSurfaceAreaMismatch = interval_array?.find((item) => item.room_type === room_type && item.surface_area !== parseFloat(surface_area) && item.id !== currentUid);

    if (isSurfaceAreaMismatch) {
        toast.warn(`Surface area does not match for room type ${room_type}`);
        return;
    }


    const costForPercent = parseFloat(employee_per_hour_cost) * (parseFloat(company_surcharge) * 0.01);
    const hourly_rate = parseFloat(employee_per_hour_cost) + parseFloat(costForPercent);
    const sunday_hourly_rate = hourly_rate * 1.5;
    const hoursReq = parseFloat(surface_area) / parseFloat(performance_value);

    const yearly_weekday_hour = (weekly_weekday_count * 52) * hoursReq;
    const yearly_sunday_hour = (weekly_sunday_count * 52) * hoursReq;
    const monthly_weekday_hour = yearly_weekday_hour / 12;
    const monthly_sunday_hour = yearly_sunday_hour / 12;
    const yearly_hours = yearly_weekday_hour + yearly_sunday_hour;
    const monthly_hours = yearly_hours / 12;


    const yearly_weekday_cost = yearly_weekday_hour * hourly_rate;
    const yearly_sunday_cost = yearly_sunday_hour * sunday_hourly_rate;
    const monthly_weekday_cost = monthly_weekday_hour * hourly_rate;
    const monthly_sunday_cost = monthly_sunday_hour * sunday_hourly_rate;
    const yearly_cost = yearly_weekday_cost + yearly_sunday_cost;
    const monthly_cost = yearly_cost / 12;



    let newUpdatedObject = {
        room_type,
        type_of_cleaning,
        surface_area: parseFloat(surface_area),
        performance_value: parseFloat(performance_value),
        employee_per_hour_cost: parseFloat(employee_per_hour_cost),
        company_surcharge: parseFloat(company_surcharge),
        hourly_rate: parseFloat(hourly_rate),
        sunday_hourly_rate: parseFloat(sunday_hourly_rate),
        weekly_weekday_count: parseInt(weekly_weekday_count),
        weekly_sunday_count: parseInt(weekly_sunday_count),
        yearly_weekday_frequency: weekly_weekday_count * 52,
        yearly_sunday_frequency: weekly_sunday_count * 52,
        monthly_weekday_hour: monthly_weekday_hour,
        monthly_weekday_cost: monthly_weekday_cost,
        monthly_sunday_hour: monthly_sunday_hour,
        monthly_sunday_cost: monthly_sunday_cost,
        monthly_hours: monthly_hours,
        monthly_cost: monthly_cost,
        yearly_hours: yearly_hours,
        yearly_cost: yearly_cost,
        id: currentUid || uuidv4(), // Use currentUid if editing, else generate new
    };


    total_monthly_weekday_hours = parseFloat(total_monthly_weekday_hours) || 0;
    total_monthly_weekday_cost = parseFloat(total_monthly_weekday_cost) || 0;
    total_monthly_sunday_hours = parseFloat(total_monthly_sunday_hours) || 0;
    total_monthly_sunday_cost = parseFloat(total_monthly_sunday_cost) || 0;
    total_monthly_hours = parseFloat(total_monthly_hours) || 0;
    total_monthly_cost = parseFloat(total_monthly_cost) || 0;
    total_yearly_hours = parseFloat(total_yearly_hours) || 0;
    total_yearly_cost = parseFloat(total_yearly_cost) || 0;
    total_surface_area = parseFloat(total_surface_area) || 0;

    // console.log("newUpdate", newUpdatedObject);

    // Update or add object based on `currentUid`
    let updatedArray = [...interval_array];
    if (currentUid) {
        // Find the existing object and subtract its values from totals
        const existingObject = interval_array.find((item) => item.id === currentUid);
        console.log("existingObject", existingObject)
        if (existingObject) {
            total_monthly_weekday_hours = total_monthly_weekday_hours - parseFloat(existingObject.monthly_weekday_hour) + parseFloat(monthly_weekday_hour);
            total_monthly_weekday_cost = total_monthly_weekday_cost - parseFloat(existingObject.monthly_weekday_cost) + parseFloat(monthly_weekday_cost);
            total_monthly_sunday_hours = total_monthly_sunday_hours - parseFloat(existingObject.monthly_sunday_hour) + parseFloat(monthly_sunday_hour);
            total_monthly_sunday_cost = total_monthly_sunday_cost - parseFloat(existingObject.monthly_sunday_cost) + parseFloat(monthly_sunday_cost);
            total_monthly_hours = total_monthly_hours - parseFloat(existingObject.monthly_hours) + parseFloat(monthly_hours);
            total_monthly_cost = total_monthly_cost - parseFloat(existingObject.monthly_cost) + parseFloat(monthly_cost);
            total_yearly_hours = total_yearly_hours - parseFloat(existingObject.yearly_hours) + parseFloat(yearly_hours);
            total_yearly_cost = total_yearly_cost - parseFloat(existingObject.yearly_cost) + parseFloat(yearly_cost);

            const existingRoomType = interval_array?.find(item => item.room_type === room_type);
            if (existingRoomType) {
                // If the room type exists, calculate the surface area difference and update
                const surfaceAreaDifference = parseFloat(surface_area) - parseFloat(existingRoomType.surface_area);
                total_surface_area = parseFloat(total_surface_area) + surfaceAreaDifference;
            } else {
                // If the room type is new, add the surface_area to total_surface_area
                total_surface_area = parseFloat(total_surface_area) + parseFloat(surface_area);
            }
            updatedArray = interval_array.map((item) =>
                item.id === currentUid ? { ...item, ...newUpdatedObject } : item
            );
        }
    } else {
        // Append new object to the array
        updatedArray.push(newUpdatedObject);
        // Update totals by adding the new values
        total_monthly_weekday_hours += parseFloat(monthly_weekday_hour);
        total_monthly_weekday_cost += parseFloat(monthly_weekday_cost);
        total_monthly_sunday_hours += parseFloat(monthly_sunday_hour);
        total_monthly_sunday_cost += parseFloat(monthly_sunday_cost);
        total_monthly_hours += parseFloat(monthly_hours);
        total_monthly_cost += parseFloat(monthly_cost);
        total_yearly_hours += yearly_hours;
        total_yearly_cost += yearly_cost;

        const existingRoomType = interval_array?.find(item => item.room_type === room_type);
        if (existingRoomType) {
            // If the room type exists, calculate the surface area difference and update
            const surfaceAreaDifference = parseFloat(surface_area) - parseFloat(existingRoomType.surface_area);
            total_surface_area = parseFloat(total_surface_area) + surfaceAreaDifference;
        } else {
            // If the room type is new, add the surface_area to total_surface_area
            total_surface_area = parseFloat(total_surface_area) + parseFloat(surface_area);
        }

    }

    console.log("updatedArray", updatedArray)


    // Set the new form data
    setFormData((prevData) => ({
        ...prevData,
        total_surface_area: total_surface_area,
        total_monthly_weekday_hours: total_monthly_weekday_hours,
        total_monthly_weekday_cost: total_monthly_weekday_cost,
        total_monthly_sunday_hours: total_monthly_sunday_hours,
        total_monthly_sunday_cost: total_monthly_sunday_cost,
        total_monthly_hours: total_monthly_hours,
        total_monthly_cost: total_monthly_cost,
        total_yearly_hours: total_yearly_hours,
        total_yearly_cost: total_yearly_cost,
        interval_array: updatedArray, // Update or append to interval_array
        edit_room_type: "",
        edit_type_of_cleaning: "",
        currentUid: "",
    }));
};
const calculateIntervalCleaningGermen = ({ formData, setFormData }) => {
    let {
        employee_per_hour_cost,
        surface_area,
        company_surcharge,
        performance_value,
        room_type,
        type_of_cleaning,
        weekly_weekday_count,
        weekly_sunday_count,
        currentUid,
        total_surface_area,
        total_monthly_sunday_hours,
        total_monthly_sunday_cost,
        total_monthly_weekday_hours,
        total_monthly_weekday_cost,
        total_monthly_hours,
        total_monthly_cost,
        total_yearly_hours,
        total_yearly_cost,
        interval_array,
        edit_room_type,
        edit_type_of_cleaning,
    } = formData;

    employee_per_hour_cost = convertDotToComma(formData?.employee_per_hour_cost);
    console.log(employee_per_hour_cost)
    company_surcharge = convertDotToComma(formData?.company_surcharge);
    surface_area = convertDotToComma(formData?.surface_area)
    performance_value = convertDotToComma(formData?.performance_value)
    const isRoomTypeExist = interval_array?.find((item) => item.room_type === room_type && item?.type_of_cleaning === type_of_cleaning && (item?.id) !== currentUid)

    if (isRoomTypeExist) {
        toast.warn(`Room type ${room_type} with  ${type_of_cleaning} already exist`);
        return
    }

    // Check if surface area matches only if the room type exists
    const isSurfaceAreaMismatch = interval_array?.find((item) => item.room_type === room_type && item.surface_area !== parseFloat(surface_area) && item.id !== currentUid);

    if (isSurfaceAreaMismatch) {
        toast.warn(`Surface area does not match for room type ${room_type}`);
        return;
    }


    const costForPercent = parseFloat(employee_per_hour_cost) * (parseFloat(company_surcharge) * 0.01);
    const hourly_rate = parseFloat(employee_per_hour_cost) + parseFloat(costForPercent);
    const sunday_hourly_rate = hourly_rate * 1.5;
    const hoursReq = parseFloat(surface_area) / parseFloat(performance_value);

    const yearly_weekday_hour = (weekly_weekday_count * 52) * hoursReq;
    const yearly_sunday_hour = (weekly_sunday_count * 52) * hoursReq;
    const monthly_weekday_hour = yearly_weekday_hour / 12;
    const monthly_sunday_hour = yearly_sunday_hour / 12;
    const yearly_hours = yearly_weekday_hour + yearly_sunday_hour;
    const monthly_hours = yearly_hours / 12;


    const yearly_weekday_cost = yearly_weekday_hour * hourly_rate;
    const yearly_sunday_cost = yearly_sunday_hour * sunday_hourly_rate;
    const monthly_weekday_cost = monthly_weekday_hour * hourly_rate;
    const monthly_sunday_cost = monthly_sunday_hour * sunday_hourly_rate;
    const yearly_cost = yearly_weekday_cost + yearly_sunday_cost;
    const monthly_cost = yearly_cost / 12;



    let newUpdatedObject = {
        room_type,
        type_of_cleaning,
        surface_area: parseFloat(surface_area),
        performance_value: parseFloat(performance_value),
        employee_per_hour_cost: parseFloat(employee_per_hour_cost),
        company_surcharge: parseFloat(company_surcharge),
        hourly_rate: parseFloat(hourly_rate),
        sunday_hourly_rate: parseFloat(sunday_hourly_rate),
        weekly_weekday_count: parseInt(weekly_weekday_count),
        weekly_sunday_count: parseInt(weekly_sunday_count),
        yearly_weekday_frequency: weekly_weekday_count * 52,
        yearly_sunday_frequency: weekly_sunday_count * 52,
        monthly_weekday_hour: monthly_weekday_hour,
        monthly_weekday_cost: monthly_weekday_cost,
        monthly_sunday_hour: monthly_sunday_hour,
        monthly_sunday_cost: monthly_sunday_cost,
        monthly_hours: monthly_hours,
        monthly_cost: monthly_cost,
        yearly_hours: yearly_hours,
        yearly_cost: yearly_cost,
        id: currentUid || uuidv4(), // Use currentUid if editing, else generate new
    };


    total_monthly_weekday_hours = parseFloat(total_monthly_weekday_hours) || 0;
    total_monthly_weekday_cost = parseFloat(total_monthly_weekday_cost) || 0;
    total_monthly_sunday_hours = parseFloat(total_monthly_sunday_hours) || 0;
    total_monthly_sunday_cost = parseFloat(total_monthly_sunday_cost) || 0;
    total_monthly_hours = parseFloat(total_monthly_hours) || 0;
    total_monthly_cost = parseFloat(total_monthly_cost) || 0;
    total_yearly_hours = parseFloat(total_yearly_hours) || 0;
    total_yearly_cost = parseFloat(total_yearly_cost) || 0;
    total_surface_area = parseFloat(total_surface_area) || 0;

    // console.log("newUpdate", newUpdatedObject);

    // Update or add object based on `currentUid`
    let updatedArray = [...interval_array];
    if (currentUid) {
        // Find the existing object and subtract its values from totals
        const existingObject = interval_array.find((item) => item.id === currentUid);
        console.log("existingObject", existingObject)
        if (existingObject) {
            total_monthly_weekday_hours = total_monthly_weekday_hours - parseFloat(existingObject.monthly_weekday_hour) + parseFloat(monthly_weekday_hour);
            total_monthly_weekday_cost = total_monthly_weekday_cost - parseFloat(existingObject.monthly_weekday_cost) + parseFloat(monthly_weekday_cost);
            total_monthly_sunday_hours = total_monthly_sunday_hours - parseFloat(existingObject.monthly_sunday_hour) + parseFloat(monthly_sunday_hour);
            total_monthly_sunday_cost = total_monthly_sunday_cost - parseFloat(existingObject.monthly_sunday_cost) + parseFloat(monthly_sunday_cost);
            total_monthly_hours = total_monthly_hours - parseFloat(existingObject.monthly_hours) + parseFloat(monthly_hours);
            total_monthly_cost = total_monthly_cost - parseFloat(existingObject.monthly_cost) + parseFloat(monthly_cost);
            total_yearly_hours = total_yearly_hours - parseFloat(existingObject.yearly_hours) + parseFloat(yearly_hours);
            total_yearly_cost = total_yearly_cost - parseFloat(existingObject.yearly_cost) + parseFloat(yearly_cost);

            const existingRoomType = interval_array?.find(item => item.room_type === room_type);
            if (existingRoomType) {
                // If the room type exists, calculate the surface area difference and update
                const surfaceAreaDifference = parseFloat(surface_area) - parseFloat(existingRoomType.surface_area);
                total_surface_area = parseFloat(total_surface_area) + surfaceAreaDifference;
            } else {
                // If the room type is new, add the surface_area to total_surface_area
                total_surface_area = parseFloat(total_surface_area) + parseFloat(surface_area);
            }
            updatedArray = interval_array.map((item) =>
                item.id === currentUid ? { ...item, ...newUpdatedObject } : item
            );
        }
    } else {
        // Append new object to the array
        updatedArray.push(newUpdatedObject);
        // Update totals by adding the new values
        total_monthly_weekday_hours += parseFloat(monthly_weekday_hour);
        total_monthly_weekday_cost += parseFloat(monthly_weekday_cost);
        total_monthly_sunday_hours += parseFloat(monthly_sunday_hour);
        total_monthly_sunday_cost += parseFloat(monthly_sunday_cost);
        total_monthly_hours += parseFloat(monthly_hours);
        total_monthly_cost += parseFloat(monthly_cost);
        total_yearly_hours += yearly_hours;
        total_yearly_cost += yearly_cost;

        const existingRoomType = interval_array?.find(item => item.room_type === room_type);
        if (existingRoomType) {
            // If the room type exists, calculate the surface area difference and update
            const surfaceAreaDifference = parseFloat(surface_area) - parseFloat(existingRoomType.surface_area);
            total_surface_area = parseFloat(total_surface_area) + surfaceAreaDifference;
        } else {
            // If the room type is new, add the surface_area to total_surface_area
            total_surface_area = parseFloat(total_surface_area) + parseFloat(surface_area);
        }

    }

    console.log("updatedArray", updatedArray)


    // Set the new form data
    setFormData((prevData) => ({
        ...prevData,
        employee_per_hour_cost: employee_per_hour_cost,
        hourly_rate:hourly_rate,
        company_surcharge: company_surcharge,
        surface_area: surface_area,
        performance_value: performance_value,
        hourly_rate:hourly_rate,
        total_surface_area: total_surface_area,
        total_monthly_weekday_hours: total_monthly_weekday_hours,
        total_monthly_weekday_cost: total_monthly_weekday_cost,
        total_monthly_sunday_hours: total_monthly_sunday_hours,
        total_monthly_sunday_cost: total_monthly_sunday_cost,
        total_monthly_hours: total_monthly_hours,
        total_monthly_cost: total_monthly_cost,
        total_yearly_hours: total_yearly_hours,
        total_yearly_cost: total_yearly_cost,
        interval_array: updatedArray, // Update or append to interval_array
        edit_room_type: "",
        edit_type_of_cleaning: "",
        currentUid: "",
    }));
};

const deleteIntervalCleaningCalculation = ({ formData, setFormData, room_type, type_of_cleaning }) => {

    const updatedIntervalArray = formData.interval_array.filter(
        (item) => !(item.room_type === room_type && item.type_of_cleaning === type_of_cleaning)
    );

    // Recalculate totals based on the updated array
    const updatedTotals = recalculateIntervalTotals(updatedIntervalArray);

    // Update the formData with the new array and recalculated totals
    setFormData(prevData => ({
        ...prevData,
        interval_array: updatedIntervalArray, // Update interval_array
        ...updatedTotals, // Spread the recalculated total values
    }));


}
const recalculateIntervalTotals = (intervalArray) => {
    let totalSurfaceArea = 0;
    let totalMonthlyWeekdayHours = 0;
    let totalMonthlyWeekdayCost = 0;
    let totalMonthlySundayHours = 0;
    let totalMonthlySundayCost = 0;
    let totalMonthlyHours = 0;
    let totalMonthlyCost = 0;
    let totalYearlyHours = 0;
    let totalYearlyCost = 0;

    // Iterate over the remaining intervalArray to sum up all the values
    intervalArray.forEach(item => {
        totalSurfaceArea += parseFloat(item.surface_area);
        totalMonthlyWeekdayHours += parseFloat(item.monthly_weekday_hour);
        totalMonthlyWeekdayCost += parseFloat(item.monthly_weekday_cost);
        totalMonthlySundayHours += parseFloat(item.monthly_sunday_hour);
        totalMonthlySundayCost += parseFloat(item.monthly_sunday_cost);
        totalMonthlyHours += parseFloat(item.monthly_hours);
        totalMonthlyCost += parseFloat(item.monthly_cost);
        totalYearlyHours += parseFloat(item.yearly_hours);
        totalYearlyCost += parseFloat(item.yearly_cost);
    });

    return {
        total_surface_area: totalSurfaceArea,
        total_monthly_weekday_hours: totalMonthlyWeekdayHours,
        total_monthly_weekday_cost: totalMonthlyWeekdayCost,
        total_monthly_sunday_hours: totalMonthlySundayHours,
        total_monthly_sunday_cost: totalMonthlySundayCost,
        total_monthly_hours: totalMonthlyHours,
        total_monthly_cost: totalMonthlyCost,
        total_yearly_hours: totalYearlyHours,
        total_yearly_cost: totalYearlyCost,
    };
};

// Building Division Calculation
const calculateBuildingDivision = ({ formData, childFormData, setFormData, lang }) => {
    console.log("Current FormData:", formData);
    
    const { surface_area, employee_per_hour_cost, company_surcharge, not_selected_area_percentage } = formData;
    const costForPercent = employee_per_hour_cost * (company_surcharge * 0.01);
    const hourly_rate = parseFloat(employee_per_hour_cost) + parseFloat(costForPercent);
    const sunday_hourly_rate = hourly_rate * 1.5;

    // Extract checked areas from childFormData and calculate adjusted area percentages
    const checkedAreas = Object.values(childFormData)?.filter((item) => item.isChecked);
    const totalCheckedAreas = checkedAreas.length;


    // Distribute not_selected_area_percentage equally among checked areas
    const adjustedAreaPercentage = checkedAreas?.map((item) => {
        const distributedPercentage = (parseFloat(not_selected_area_percentage) / totalCheckedAreas) + parseFloat(item.area_percentage);
        return {
            ...item,
            area_percentage: distributedPercentage,
            surface_area: parseFloat(surface_area) * (distributedPercentage / 100),
        };
    });


    // Initialize totals
    let totalMonthlySundayHours = 0;
    let totalMonthlyWeekdayHours = 0;
    let totalMonthlyCost = 0;
    let totalYearlyCost = 0;
    let totalMonthlyWeekdayCost = 0;
    let totalMonthlySundayCost = 0;
    let totalYearlyHours = 0;
    let totalMonthlyHours = 0;

    // Loop through adjustedAreaPercentage to calculate values for each object
    const updatedDivisionArray = adjustedAreaPercentage?.map((area) => {
        const areaSurfaceArea = parseFloat(area.surface_area);
        const hoursReq = parseFloat(areaSurfaceArea) / parseFloat(area.performance_value);

        // Hours calculations
        const yearlyWeekdayHours = hoursReq * (parseFloat(area.weekly_weekday_count) * 52);
        const yearlySundayHours = hoursReq * (parseFloat(area.weekly_sunday_count) * 52);
        const monthlyWeekdayHours = yearlyWeekdayHours / 12;
        const monthlySundayHours = yearlySundayHours / 12;
        const yearlyHours = yearlyWeekdayHours + yearlySundayHours;
        const monthlyHours = yearlyHours / 12;

        // Cost calculations
        const yearlyWeekdayCost = yearlyWeekdayHours * hourly_rate;
        const yearlySundayCost = yearlySundayHours * sunday_hourly_rate;
        const monthlyWeekdayCost = monthlyWeekdayHours * hourly_rate;
        const monthlySundayCost = monthlySundayHours * sunday_hourly_rate;
        const yearlyCost = yearlyWeekdayCost + yearlySundayCost;
        const monthlyCost = yearlyCost / 12;

        const yearlyWeekdayFrequency = parseFloat(area.weekly_weekday_count) * 52;
        const yearlySundayFrequency = parseFloat(area.weekly_sunday_count) * 52;

        // Accumulate totals
        totalMonthlySundayHours += monthlySundayHours;
        totalMonthlyWeekdayHours += monthlyWeekdayHours;
        totalMonthlyCost += monthlyCost;
        totalYearlyCost += yearlyCost;
        totalMonthlyWeekdayCost += monthlyWeekdayCost;
        totalMonthlySundayCost += monthlySundayCost;
        totalYearlyHours += yearlyHours;
        totalMonthlyHours += monthlyHours;

        return {
            ...area,
            surface_area: areaSurfaceArea,
            yearly_weekday_frequency: yearlyWeekdayFrequency,
            yearly_sunday_frequency: yearlySundayFrequency,

            monthly_weekday_hours: monthlyWeekdayHours,
            monthly_sunday_hours: monthlySundayHours,
            monthly_hours: monthlyHours,
            yearly_hours: yearlyHours,

            monthly_weekday_cost: monthlyWeekdayCost,
            monthly_sunday_cost: monthlySundayCost,
            monthly_cost: monthlyCost,
            yearly_cost: yearlyCost,
        };
    });

    // Set the form data with calculated totals and updated division array
    setFormData(prevFormData => ({
        ...prevFormData,
        division_array: updatedDivisionArray,
        hourly_rate,
        sunday_hourly_rate,
        total_surface_area: surface_area,
        total_monthly_sunday_hours: totalMonthlySundayHours,
        total_monthly_weekday_hours: totalMonthlyWeekdayHours,
        total_monthly_cost: totalMonthlyCost,
        total_yearly_cost: totalYearlyCost,
        total_monthly_weekday_cost: totalMonthlyWeekdayCost,
        total_monthly_sunday_cost: totalMonthlySundayCost,
        total_yearly_hours: totalYearlyHours,
        total_monthly_hours: totalMonthlyHours,
    }));
};



const calculateBuildingDivisionGerman = ({ formData, childFormData, setFormData }) => {
    let { surface_area, employee_per_hour_cost, company_surcharge, not_selected_area_percentage } = formData;
    
    surface_area = parseFloat(convertDotToComma(surface_area));
    employee_per_hour_cost = parseFloat(convertDotToComma(employee_per_hour_cost));
    company_surcharge = parseFloat(convertDotToComma(company_surcharge));

   
    const costForPercent = employee_per_hour_cost * (company_surcharge * 0.01);
    const hourly_rate = parseFloat(employee_per_hour_cost) + parseFloat(costForPercent);
    const sunday_hourly_rate = hourly_rate * 1.5;


    // Extract checked areas from childFormData and calculate adjusted area percentages
    const checkedAreas = Object.values(childFormData)?.filter((item) => item.isChecked);
    const totalCheckedAreas = checkedAreas.length;

    // Distribute not_selected_area_percentage equally among checked areas
    const adjustedAreaPercentage = checkedAreas?.map((item) => {
        const distributedPercentage = (parseFloat(not_selected_area_percentage) / totalCheckedAreas) + parseFloat(item.area_percentage);
        return {
            ...item,
            area_percentage: distributedPercentage,
            surface_area: parseFloat(surface_area) * (distributedPercentage / 100),
        };
    });


    // Initialize totals
    let totalMonthlySundayHours = 0;
    let totalMonthlyWeekdayHours = 0;
    let totalMonthlyCost = 0;
    let totalYearlyCost = 0;
    let totalMonthlyWeekdayCost = 0;
    let totalMonthlySundayCost = 0;
    let totalYearlyHours = 0;
    let totalMonthlyHours = 0;

    // Loop through adjustedAreaPercentage to calculate values for each object
    const updatedDivisionArray = adjustedAreaPercentage?.map((area) => {
        const areaSurfaceArea = parseFloat(area.surface_area);
        const hoursReq = parseFloat(areaSurfaceArea) / parseFloat(area.performance_value);

        // Hours calculations
        const yearlyWeekdayHours = hoursReq * (parseFloat(area.weekly_weekday_count) * 52);
        const yearlySundayHours = hoursReq * (parseFloat(area.weekly_sunday_count) * 52);
        const monthlyWeekdayHours = yearlyWeekdayHours / 12;
        const monthlySundayHours = yearlySundayHours / 12;
        const yearlyHours = yearlyWeekdayHours + yearlySundayHours;
        const monthlyHours = yearlyHours / 12;

        // Cost calculations
        const yearlyWeekdayCost = yearlyWeekdayHours * hourly_rate;
        const yearlySundayCost = yearlySundayHours * sunday_hourly_rate;
        const monthlyWeekdayCost = monthlyWeekdayHours * hourly_rate;
        const monthlySundayCost = monthlySundayHours * sunday_hourly_rate;
        const yearlyCost = yearlyWeekdayCost + yearlySundayCost;
        const monthlyCost = yearlyCost / 12;

        const yearlyWeekdayFrequency = parseFloat(area.weekly_weekday_count) * 52;
        const yearlySundayFrequency = parseFloat(area.weekly_sunday_count) * 52;

        // Accumulate totals
        totalMonthlySundayHours += monthlySundayHours;
        totalMonthlyWeekdayHours += monthlyWeekdayHours;
        totalMonthlyCost += monthlyCost;
        totalYearlyCost += yearlyCost;
        totalMonthlyWeekdayCost += monthlyWeekdayCost;
        totalMonthlySundayCost += monthlySundayCost;
        totalYearlyHours += yearlyHours;
        totalMonthlyHours += monthlyHours;

        return {
            ...area,
            surface_area: areaSurfaceArea,
            yearly_weekday_frequency: yearlyWeekdayFrequency,
            yearly_sunday_frequency: yearlySundayFrequency,

            monthly_weekday_hours: monthlyWeekdayHours,
            monthly_sunday_hours: monthlySundayHours,
            monthly_hours: monthlyHours,
            yearly_hours: yearlyHours,

            monthly_weekday_cost: monthlyWeekdayCost,
            monthly_sunday_cost: monthlySundayCost,
            monthly_cost: monthlyCost,
            yearly_cost: yearlyCost,
        };
    });

    console.log("formDataDivisionArray===", updatedDivisionArray)

    // Set the form data with calculated totals and updated division array
    setFormData(prevFormData => ({
        ...prevFormData,
        division_array: updatedDivisionArray,
        employee_per_hour_cost:employee_per_hour_cost,
        hourly_rate,
        sunday_hourly_rate,
        total_surface_area: surface_area,
        total_monthly_sunday_hours: totalMonthlySundayHours,
        total_monthly_weekday_hours: totalMonthlyWeekdayHours,
        total_monthly_cost: totalMonthlyCost,
        total_yearly_cost: totalYearlyCost,
        total_monthly_weekday_cost: totalMonthlyWeekdayCost,
        total_monthly_sunday_cost: totalMonthlySundayCost,
        total_yearly_hours: totalYearlyHours,
        total_monthly_hours: totalMonthlyHours,
    }));
};




// Maintenance Cleaning

const calculateMaintenanceCleaning = ({ formData, setFormData }) => {
    let { surface_area, full_cleaning_performance_value, full_cleaning_yearly_weekday_frequency, full_cleaning_yearly_sunday_frequency, weekday_hourly_rate, sunday_hourly_rate } = formData;
    let { visual_cleaning_weekday_frequency, visual_cleaning_performance_value, visual_cleaning_sunday_frequency } = formData;

    if (full_cleaning_yearly_weekday_frequency || full_cleaning_yearly_sunday_frequency) {
        const hourly_req_full_cleaning = parseInt(surface_area) / parseFloat(full_cleaning_performance_value);
        console.log(surface_area)
        const sundayHourlyRate = parseInt(weekday_hourly_rate) * 1.5;

        // for full cleaning calculation
        const fullCleaningWeekdayFrequency = full_cleaning_yearly_weekday_frequency;
        const fullCleaningSundayFrequency = full_cleaning_yearly_sunday_frequency;


        const fullCleaningWeekdayYearlyHours = fullCleaningWeekdayFrequency * hourly_req_full_cleaning

        const fullCleaningSundayYearlyHours = parseInt(fullCleaningSundayFrequency) * parseInt(hourly_req_full_cleaning)


        const fullCleaningWeekdayYearlyCost = fullCleaningWeekdayYearlyHours * weekday_hourly_rate;
        // console.log("fullCleaningWeekdayYearlyCost",fullCleaningWeekdayYearlyCost)

        const fullCleaningSundayYearlyCost = fullCleaningSundayYearlyHours * sunday_hourly_rate;


        const fullCleaningYearlyCost = parseFloat(fullCleaningWeekdayYearlyCost) + parseFloat(fullCleaningSundayYearlyCost)
        const fullCleaningYearlyHours = fullCleaningWeekdayYearlyHours + fullCleaningSundayYearlyHours;
        // console.log("fullCleaningYearlyHours",fullCleaningYearlyHours)


        const fullCleaningMonthlyCost = fullCleaningYearlyCost / 12
        // console.log("fullCleaningMonthlyCost",fullCleaningMonthlyCost)

        const fullCleaningMonthlyHours = fullCleaningYearlyHours / 12
        // console.log("fullCleaningMonthlyHours",fullCleaningMonthlyHours)

        const fullCleaningWeekdayMonthlyHours = fullCleaningWeekdayYearlyHours / 12
        // console.log("fullCleaningWeekdayMonthlyHours",fullCleaningWeekdayMonthlyHours)

        const fullCleaningSundayMonthlyHours = fullCleaningSundayYearlyHours / 12

        const fullCleaningWeekdayMonthlyCost = fullCleaningWeekdayMonthlyHours * weekday_hourly_rate;
        // console.log("fullCleaningWeekdayMonthlyCost",fullCleaningWeekdayMonthlyCost);

        const fullCleaningSundayMonthlyCost = fullCleaningSundayMonthlyHours * sunday_hourly_rate;
        setFormData(prevFormData => ({
            ...prevFormData,
            weekday_hourly_rate: weekday_hourly_rate,
            full_cleaning_monthly_sunday_cost: Utils.trimDecimal(fullCleaningSundayMonthlyCost, 4),
            full_cleaning_monthly_sunday_hours: fullCleaningSundayMonthlyHours,
            full_cleaning_monthly_weekday_cost: Utils.trimDecimal(fullCleaningWeekdayMonthlyCost, 4),
            full_cleaning_monthly_weekday_hours: fullCleaningWeekdayMonthlyHours,
            full_cleaning_yearly_cost: Utils.trimDecimal(fullCleaningYearlyCost, 4),
            full_cleaning_yearly_hours: fullCleaningYearlyHours,
            full_cleaning_monthly_cost: Utils.trimDecimal(fullCleaningMonthlyCost, 4),
            full_cleaning_monthly_hours: fullCleaningMonthlyHours,
            sunday_hourly_rate: sunday_hourly_rate,
        }));
    }

    if (visual_cleaning_weekday_frequency || visual_cleaning_sunday_frequency) {
        // for visual cleaning calculation
        const hourly_req_visual_cleaning = parseInt(surface_area) / parseFloat(visual_cleaning_performance_value);

        const visualCleaningWeekDayFrequency = parseInt(visual_cleaning_weekday_frequency);

        const visualCleaningSundayFrequency = parseInt(visual_cleaning_sunday_frequency);

        const visualCleaningWeekdayYearlyHours = visualCleaningWeekDayFrequency * hourly_req_visual_cleaning;
        const visualCLeaningSundayYearlyHours = parseFloat(visualCleaningSundayFrequency) * parseFloat(hourly_req_visual_cleaning)

        const visualCleaningWeekdayYearlyCost = visualCleaningWeekdayYearlyHours * parseFloat(weekday_hourly_rate);
        const visualCleaningSundayYearlyCost = visualCLeaningSundayYearlyHours * sunday_hourly_rate;

        const visualCleaningYearlyHours = parseFloat(visualCleaningWeekdayYearlyHours) + parseFloat(visualCLeaningSundayYearlyHours)
        const visualCleaningYearlyCost = parseFloat(visualCleaningWeekdayYearlyCost) + parseFloat(visualCleaningSundayYearlyCost);

        const visualCleaningMonthlyCost = parseFloat(visualCleaningYearlyCost) / 12;
        const visualCleaningMonthlyHours = parseFloat(visualCleaningYearlyHours) / 12;

        const visualCleaningWeekdayMonthlyHours = parseFloat(visualCleaningWeekdayYearlyHours) / 12;
        const visualCleaningSundayMonthlyHours = parseFloat(visualCLeaningSundayYearlyHours) / 12;

        const visualCleaningWeekdayMonthlyCost = visualCleaningWeekdayMonthlyHours * parseFloat(weekday_hourly_rate);
        const visualCleaningSundayMonthlyCost = visualCleaningSundayMonthlyHours * parseFloat(sunday_hourly_rate);
        setFormData(prevFormData => ({
            ...prevFormData,
            visual_cleaning_monthly_cost: Utils.trimDecimal(visualCleaningMonthlyCost, 4),
            visual_cleaning_monthly_hour: visualCleaningMonthlyHours,
            visual_cleaning_yearly_hour: visualCleaningYearlyHours,
            visual_cleaning_yearly_cost: Utils.trimDecimal(visualCleaningYearlyCost, 4),
            visual_cleaning_weekday_cost: Utils.trimDecimal(visualCleaningWeekdayMonthlyCost, 4),
            visual_cleaning_weekday_hour: visualCleaningWeekdayMonthlyHours,
            visual_cleaning_sunday_cost: Utils.trimDecimal(visualCleaningSundayMonthlyCost, 4),
            visual_cleaning_sunday_hour: visualCleaningSundayMonthlyHours,


        }));
    }

}
const calculateMaintenanceCleaningGermen = ({ formData, setFormData }) => {
    let { surface_area, full_cleaning_performance_value, full_cleaning_yearly_weekday_frequency, full_cleaning_yearly_sunday_frequency, weekday_hourly_rate, sunday_hourly_rate } = formData;
    let { visual_cleaning_weekday_frequency, visual_cleaning_performance_value, visual_cleaning_sunday_frequency } = formData;

    surface_area = convertDotToComma(formData?.surface_area);
    full_cleaning_performance_value = convertDotToComma(formData?.full_cleaning_performance_value);
    visual_cleaning_performance_value = convertDotToComma(formData?.visual_cleaning_performance_value)
    weekday_hourly_rate = convertDotToComma(formData?.weekday_hourly_rate);
    sunday_hourly_rate = convertDotToComma(formData?.sunday_hourly_rate);
    // console.log("weekday_hourly_rate", full_cleaning_performance_value) 
    // console.log("weekday_hourly_rate", surface_area) 
    // console.log("weekday_hourly_rate", weekday_hourly_rate) 
    // console.log("weekday_hourly_rate", sunday_hourly_rate)

    if (full_cleaning_yearly_weekday_frequency || full_cleaning_yearly_sunday_frequency) {
        const hourly_req_full_cleaning = parseInt(surface_area) / parseFloat(full_cleaning_performance_value);
        console.log(surface_area)
        const sundayHourlyRate = parseInt(weekday_hourly_rate) * 1.5;

        // for full cleaning calculation
        const fullCleaningWeekdayFrequency = full_cleaning_yearly_weekday_frequency;
        const fullCleaningSundayFrequency = full_cleaning_yearly_sunday_frequency;


        const fullCleaningWeekdayYearlyHours = fullCleaningWeekdayFrequency * hourly_req_full_cleaning

        const fullCleaningSundayYearlyHours = parseInt(fullCleaningSundayFrequency) * parseInt(hourly_req_full_cleaning)


        const fullCleaningWeekdayYearlyCost = fullCleaningWeekdayYearlyHours * weekday_hourly_rate;
        // console.log("fullCleaningWeekdayYearlyCost",fullCleaningWeekdayYearlyCost)

        const fullCleaningSundayYearlyCost = fullCleaningSundayYearlyHours * sunday_hourly_rate;


        const fullCleaningYearlyCost = parseFloat(fullCleaningWeekdayYearlyCost) + parseFloat(fullCleaningSundayYearlyCost)
        const fullCleaningYearlyHours = fullCleaningWeekdayYearlyHours + fullCleaningSundayYearlyHours;
        // console.log("fullCleaningYearlyHours",fullCleaningYearlyHours)


        const fullCleaningMonthlyCost = fullCleaningYearlyCost / 12
        // console.log("fullCleaningMonthlyCost",fullCleaningMonthlyCost)

        const fullCleaningMonthlyHours = fullCleaningYearlyHours / 12
        // console.log("fullCleaningMonthlyHours",fullCleaningMonthlyHours)

        const fullCleaningWeekdayMonthlyHours = fullCleaningWeekdayYearlyHours / 12
        // console.log("fullCleaningWeekdayMonthlyHours",fullCleaningWeekdayMonthlyHours)

        const fullCleaningSundayMonthlyHours = fullCleaningSundayYearlyHours / 12

        const fullCleaningWeekdayMonthlyCost = fullCleaningWeekdayMonthlyHours * weekday_hourly_rate;
        // console.log("fullCleaningWeekdayMonthlyCost",fullCleaningWeekdayMonthlyCost);

        const fullCleaningSundayMonthlyCost = fullCleaningSundayMonthlyHours * sunday_hourly_rate;
      
        setFormData(prevFormData => ({
            ...prevFormData,

            surface_area: surface_area,
            full_cleaning_performance_value: full_cleaning_performance_value,
            visual_cleaning_performance_value: visual_cleaning_performance_value,
            weekday_hourly_rate: weekday_hourly_rate,
            sunday_hourly_rate: sunday_hourly_rate,
            full_cleaning_monthly_sunday_cost: convertDotToComma(Utils.trimDecimalToFour(fullCleaningSundayMonthlyCost || 0, 4)),
            full_cleaning_monthly_sunday_hours: fullCleaningSundayMonthlyHours,
            full_cleaning_monthly_weekday_cost: convertDotToComma(Utils.trimDecimalToFour(fullCleaningWeekdayMonthlyCost || 0, 4)),
            full_cleaning_monthly_weekday_hours: fullCleaningWeekdayMonthlyHours,
            full_cleaning_yearly_cost: convertDotToComma(Utils.trimDecimalToFour(fullCleaningYearlyCost || 0, 4)),
            full_cleaning_yearly_hours: fullCleaningYearlyHours,
            full_cleaning_monthly_cost: convertDotToComma(Utils.trimDecimalToFour((fullCleaningMonthlyCost || 0), 4)),
            full_cleaning_monthly_hours: fullCleaningMonthlyHours,
            sunday_hourly_rate: sunday_hourly_rate,
        }));
    }

    if (visual_cleaning_weekday_frequency || visual_cleaning_sunday_frequency) {
        // for visual cleaning calculation
        const hourly_req_visual_cleaning = parseInt(surface_area) / parseFloat(visual_cleaning_performance_value);

        const visualCleaningWeekDayFrequency = parseInt(visual_cleaning_weekday_frequency);

        const visualCleaningSundayFrequency = parseInt(visual_cleaning_sunday_frequency);

        const visualCleaningWeekdayYearlyHours = visualCleaningWeekDayFrequency * hourly_req_visual_cleaning;
        const visualCLeaningSundayYearlyHours = parseFloat(visualCleaningSundayFrequency) * parseFloat(hourly_req_visual_cleaning)

        const visualCleaningWeekdayYearlyCost = visualCleaningWeekdayYearlyHours * parseFloat(weekday_hourly_rate);
        const visualCleaningSundayYearlyCost = visualCLeaningSundayYearlyHours * sunday_hourly_rate;

        const visualCleaningYearlyHours = parseFloat(visualCleaningWeekdayYearlyHours) + parseFloat(visualCLeaningSundayYearlyHours)
        const visualCleaningYearlyCost = parseFloat(visualCleaningWeekdayYearlyCost) + parseFloat(visualCleaningSundayYearlyCost);

        const visualCleaningMonthlyCost = parseFloat(visualCleaningYearlyCost) / 12;
        const visualCleaningMonthlyHours = parseFloat(visualCleaningYearlyHours) / 12;

        const visualCleaningWeekdayMonthlyHours = parseFloat(visualCleaningWeekdayYearlyHours) / 12;
        const visualCleaningSundayMonthlyHours = parseFloat(visualCLeaningSundayYearlyHours) / 12;

        const visualCleaningWeekdayMonthlyCost = visualCleaningWeekdayMonthlyHours * parseFloat(weekday_hourly_rate);
        const visualCleaningSundayMonthlyCost = visualCleaningSundayMonthlyHours * parseFloat(sunday_hourly_rate);
        setFormData(prevFormData => ({
            ...prevFormData,
            surface_area: surface_area,
            full_cleaning_performance_value: full_cleaning_performance_value,
            visual_cleaning_performance_value: visual_cleaning_performance_value,
            weekday_hourly_rate: weekday_hourly_rate,
            sunday_hourly_rate, sunday_hourly_rate,
            visual_cleaning_monthly_cost: convertDotToComma(Utils.trimDecimalToFour(visualCleaningMonthlyCost || 0, 4)),
            visual_cleaning_monthly_hour: visualCleaningMonthlyHours,
            visual_cleaning_yearly_hour: visualCleaningYearlyHours,
            visual_cleaning_yearly_cost: convertDotToComma(Utils.trimDecimalToFour(visualCleaningYearlyCost || 0, 4)),
            visual_cleaning_weekday_cost: convertDotToComma(Utils.trimDecimalToFour(visualCleaningWeekdayMonthlyCost || 0, 4)),
            visual_cleaning_weekday_hour: visualCleaningWeekdayMonthlyHours,
            visual_cleaning_sunday_cost: convertDotToComma(Utils.trimDecimalToFour(visualCleaningSundayMonthlyCost || 0, 4)),
            visual_cleaning_sunday_hour: visualCleaningSundayMonthlyHours,


        }));
    }

}




const UtilsGetCalculations = {
    calculatePerformance: calculatePerformance,
    calculateStandard: calculateStandard,
    calculateFullCleaning: calculateFullCleaning,
    calculateIntervalCleaning: calculateIntervalCleaning,
    deleteIntervalCleaningCalculation: deleteIntervalCleaningCalculation,
    calculateBuildingDivision: calculateBuildingDivision,
    calculateMaintenanceCleaning: calculateMaintenanceCleaning,
    calculateFullCleaningGermen: calculateFullCleaningGermen,
    calculatePerformanceGermen: calculatePerformanceGermen,
    calculateStandardGermen: calculateStandardGermen,
    calculateIntervalCleaningGermen: calculateIntervalCleaningGermen,
    calculateBuildingDivisionGerman: calculateBuildingDivisionGerman,
    calculateMaintenanceCleaningGermen: calculateMaintenanceCleaningGermen

}

export default UtilsGetCalculations;