/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import ContentLayout from '../layout/ContentLayout'
import { Card } from 'react-bootstrap'
import Icon from '../Common/Icon'
import App_url from '../Common/Constants'
import CustomTableTwo from '../Common/CustomTableTwo'
import Images from '../Common/Image'
import { WebSocketContext } from '../../WSContext'
import { useDispatch, useSelector } from 'react-redux'
import wsSendRequest from '../../socketIO/wsSendRequest'
import { setStoreDiscardChangesModal, setStoreViewExternalOfferModal } from '../../store/modules/modals/action'
import Button from '../Common/Button'
import { useNavigate, useParams } from 'react-router-dom'
import { setWebsocketReqRes } from '../../store/modules/users_data/action'
import { useTranslation } from '../../translate'
import { setStoreCustomersDetails } from '../../store/modules/customers/action'

const ExternalOffers = () => {
    const { t, changeLanguage } = useTranslation();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { ws, send } = useContext(WebSocketContext);
    const { id } = useParams()
    const { device_id, websocketReqRes } = useSelector(App_url.userDataReducers);
    const { customerDetails, citiesIndustriesPincodes } = useSelector(App_url.customerReducers);
    const { externalOfferList, offerDashboardList } = useSelector(App_url.externalOfferReducers);
    const [filter, setFilter] = useState({
        search: "",
        // city: "",
        postcode: "",
        industry_type: "",
        start_date:"",
        end_date:"",
    })


    // console.log("citiesIndustriesPincodes",citiesIndustriesPincodes)

    const industryOptions = citiesIndustriesPincodes?.industries?.map((item) => {
        return { label: item?.industry_type, value: item?.industry_type }
    })

    const postCodeOptions = citiesIndustriesPincodes?.pincodes?.map((item) => {
        return { label: item?.pincode, value: item?.pincode }
    })

    const callExternalOfferListApi = (page) => {
        const param = {
            transmit: "broadcast",
            url: "offer_list",
            request: {
                page_no: page,
                limit: "20",
                status: "",
                from_date: filter?.start_date,
                to_date: filter?.end_date,
                search: filter?.search,
                postal_code: filter?.postcode,
                industry_type: filter?.industry_type,
                customer_id: "",
            }
        };
        send(param);
    };


    const callDashboardApi = () => {
        const param = {
            transmit: "broadcast",
            url: "offer_dashboard",
        };
        // console.log(param)
        send(param);
    };

    useEffect(() => {
        if (ws) {
            callExternalOfferListApi(1);
        }
    }, [ws, filter])

    useEffect(() => {
        if (ws) {
            dispatch(setWebsocketReqRes())
            callDashboardApi(1);
        }
    }, [ws]);


    const callGetSingleOfferList = (item) => {
        const param = {
            transmit: "broadcast",
            url: "offer_get",
            request: {
                external_offer_id: item?.id,
            }
        }
        send(param);
    }

    const handleView = (item) => {
        // console.log("viewItems", item);
        callGetSingleOfferList(item);
        dispatch(setStoreViewExternalOfferModal({
            show: "VIEW_EXTERNAL_OFFER_MODAL",
            data: { item: item },
            callBackModal: (state, data) => handleExternalOfferCallBack(state, data),

        }))

    }
    const callExternalOfferDelete = (extOfferId) => {
        // console.log("extOfferId",extOfferId)
        const param = {
            transmit: "broadcast",
            url: "offer_delete",
            request: { external_offer_id: extOfferId },
            toast: true,
        };
        send(param, device_id);
    }
    useEffect(() => {
        if (websocketReqRes?.url === "offer_delete" || websocketReqRes?.status === 200) {
            callExternalOfferListApi(1)
        }
    }, [websocketReqRes?.url]);

    const handleExternalOfferCallBack = (state, extOfferItem) => {
        // console.log("extOfferItem", extOfferItem)
        if (state === "deleteExternalOffer") {
            dispatch(setStoreViewExternalOfferModal())
            dispatch(setStoreDiscardChangesModal({
                show: "DISCARD_CHANGES_MODAL",
                data: { item: extOfferItem, description: t("Are you sure you want to delete this external offer ?") },
                callBackModal: () => callExternalOfferDelete(extOfferItem),
            }))
        }
        if (state === "editExternalOffer") {
            navigate(`${App_url.link.addExternalOffers}/${extOfferItem?.id}`, { state: extOfferItem })

        }
    }

    const handleClick = () => {
        dispatch(setStoreCustomersDetails());
        navigate(App_url.link.addExternalOffers)
    }
    const maxLength = 25 || "";

    const EllipsisText = (text, maxLength) => {
        if (text?.length > maxLength) {
            return text?.substring(0, maxLength) + '...';
        }
        return text;
    };

    const RenderBody = (item) => {
        // console.log(item)
        return (
            <React.Fragment>
                <td onClick={() => handleView(item)} className='new-calc-td' title={item.company_name}>{EllipsisText(item.company_name, maxLength)}</td>
                <td onClick={() => handleView(item)} className='new-calc-td' title={item.title}>{item.tender_number}</td>
                <td onClick={() => handleView(item)} className='new-calc-td' title={item.title}>{item.industry_type}</td>
                <td onClick={() => handleView(item)} className='new-calc-td'>{item.date}</td>
                <td onClick={() => handleView(item)} className='new-calc-td'>{item.postal_code}</td>
                <td onClick={() => handleView(item)} className='new-calc-td'>{item?.status}</td>
                <td className='new-calc-td'><span className='view-icon-box' onClick={() => handleView(item)}><Icon className={"bg-primary"} attrIcon={App_url.img.EyeIcon} /></span></td>
            </React.Fragment>
        )
    }

    // console.log("offerDashboardList", offerDashboardList);

    return (
        <ContentLayout title={'External Offers'}>
            <div className='ext-offer'>
                <div className='row m-0'>
                    <div className='col-8 pe-0 ps-0'>
                        <Card className='card'>
                            <Card.Body>
                                <div className='card-body-header'>
                                    <h5><b>{t("Statistics")}</b></h5>
                                    <Button className={""} size={'md'} onClick={handleClick} variant={"outline-primary"}>{t("+ Create External offer")}</Button>
                                </div>
                                <div className='statistics '>
                                    <div className='stat-1 '>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div className='d-flex align-items-center'>
                                                <Images alt={'all folder'} width={25} height={25} src={App_url.image.FolderImg} />
                                                <h6 className='ms-2 mb-0'>{t("ALL")}</h6>
                                            </div>
                                            <h6 className='ms-2'>{offerDashboardList?.statistic_list?.[0]?.all_count}</h6>

                                        </div>
                                        <h6 className='mt-3'>€ {offerDashboardList?.statistic_list?.[0]?.all_cost}</h6>
                                    </div>

                                    <div className='stat-2'>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div className='d-flex align-items-center'>
                                                <Images alt={'all folder'} width={25} className='' height={25} src={App_url.image.OpenFolderImg} />
                                                <h6 className='ms-2 mb-0'>{t("OPEN")} </h6>
                                            </div>
                                            <h6 className='ms-4'>{offerDashboardList?.statistic_list?.[0]?.open_count}</h6>
                                        </div>
                                        <h6 className='mt-3'>€ {offerDashboardList?.statistic_list?.[0]?.open_cost}</h6>
                                    </div>
                                    <div className='stat-3'>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div className='d-flex align-items-center'>
                                                <Images alt={'all folder'} width={25} height={25} src={App_url.image.RejectedImg} />
                                                <h6 className='ms-2 mb-0'>{t("REJECTED")}</h6>
                                            </div>
                                            <h6 className='ms-3'>{offerDashboardList?.statistic_list?.[0]?.rejected_count}</h6>
                                        </div>

                                        <h6 className='mt-3'>€ {offerDashboardList?.statistic_list?.[0]?.rejected_cost}</h6>
                                    </div>
                                    <div className='stat-4'>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div className='d-flex align-items-center'>
                                                <Images alt={'all folder'} width={35} height={35} src={App_url.image.ReceiveImg} />
                                                <h6 className='ms-2 mb-0'>{t("RECEIVE")} </h6>
                                            </div>
                                            <h6 className='ms-3'>{offerDashboardList?.statistic_list?.[0]?.receive_count}</h6>

                                        </div>

                                        <h6 className='mt-3'>€ {offerDashboardList?.statistic_list?.[0]?.receive_cost}</h6>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className='col-4 pe-0'>
                        <Card className='card'>
                            <Card.Body className='pb-2'>
                                <div>
                                    <h6><b>{t("Top Offer")}</b></h6>
                                </div>
                                <div className='top-offer'>
                                    <div className='comp-name border-bottom'>
                                        <span className='' ><Icon className='close' attrIcon={App_url.img.BuildingIcon2} image /></span>
                                        <h6 >{offerDashboardList?.max_offer?.[0]?.company_name}</h6>
                                    </div>
                                    <div className='comp-name border-bottom'>
                                        <span className='' ><Icon className='close' attrIcon={App_url.img.SettingIcon} image /></span>
                                        <h6>{offerDashboardList?.max_offer?.[0]?.service_type}</h6>
                                    </div>
                                    <div className='comp-name'>
                                        <span className='' ><Icon className='close' attrIcon={App_url.img.CoinIcon} image /></span>
                                        <h6>€ {offerDashboardList?.max_offer?.[0]?.cost}</h6>

                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
                <div className="mt-3">
                    <CustomTableTwo
                        title={{ text: t("External Offers") }}
                        columnNames={[t('Company name'), t("Tender no"), t("Industry Type"), t('Start Date'), t('Post Code'), t('Status'), t("View")]}
                        recordData={externalOfferList?.data}
                        renderBody={RenderBody}
                        filter={filter}
                        setFilter={setFilter}
                        industryPlaceHolder={t("Select Industry type")}
                        postcodePlaceHolder={t("Select Postcode")}
                        search_placeholder={t("Search by Company Name")}
                        // pagination={externalOfferList?.pagination}
                        // onPagination={(page) => callExternalOfferListApi(page)}
                        industryOptions={industryOptions}
                        postCodeOption={postCodeOptions}
                    />
                </div>
            </div>

        </ContentLayout>)
}

export default ExternalOffers