/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Button from '../Common/Button';
import Icon from '../Common/Icon';
import App_url from '../Common/Constants';
import { Modal } from 'react-bootstrap';
import InputGroup from '../Common/InputGroup';
import { setStoreAdditionalCostModal } from '../../store/modules/modals/action';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from '../../translate';

const AdditionalCostModal = () => {
  const { t, changeLanguage } = useTranslation();

    const dispatch = useDispatch();
    const { ModalPopupTwo } = useSelector(App_url.modalReducers);

    const [formData, setFormData] = useState({
        additional_cost: [
            { type: 'scaffold', cost_per_operation: '', no_of_operations_per_year: '', cost_of_operations_per_year: 0 },
            { type: 'material', cost_per_operation: '', no_of_operations_per_year: '', cost_of_operations_per_year: 0 },
            { type: 'miscellaneous', cost_per_operation: '', no_of_operations_per_year: '', cost_of_operations_per_year: 0 },
        ],
    });

    const [error, setError] = useState({
        additional_cost: [
            { type: 'scaffold', cost_per_operation: '', no_of_operations_per_year: '', },
            { type: 'material', cost_per_operation: '', no_of_operations_per_year: '', },
            { type: 'miscellaneous', cost_per_operation: '', no_of_operations_per_year: '', },
        ],
    })

    useEffect(()=>{
        if(ModalPopupTwo?.show === "ADDITIONAL_COST_MODAL" && ModalPopupTwo?.data?.additionalCoast){
            const additionalCalculationsStringToInt  = ModalPopupTwo?.data?.additionalCoast?.map((item)=>{
                return{ ...item, cost_of_operations_per_year:0, }
            });
            setFormData((data)=>({
                ...data,
                additional_cost:additionalCalculationsStringToInt,
            }))
        }
    },[ModalPopupTwo?.data?.additionalCoast])


    const onChange = (e, type) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            additional_cost: prevData.additional_cost.map(item =>
                item.type === type
                    ? { ...item, [name]: value }
                    : item
            )
        }));
        setError(prevData => ({
            ...prevData,
            additional_cost: prevData.additional_cost.map(item =>
                item.type === type
                    ? { ...item, [name]: "" }
                    : item
            )
        }));
    };


    const validation = () => {
        let val = true;
        const newError = { ...error };
        
        formData.additional_cost.forEach((item, index) => {
            if (item.cost_per_operation !== "" && item.no_of_operations_per_year === '') {
                newError.additional_cost[index].no_of_operations_per_year = t("Please enter operations per year");
                val = false;
            }
            if (item.cost_per_operation === "" && item.no_of_operations_per_year !== '') {
                newError.additional_cost[index].cost_per_operation = t("Please enter operations");
                val = false;
            }
        });
    
        setError(newError);
        return val;
    };




    const calculateYearlyCost = () => {
        const updatedAdditionalCost = formData?.additional_cost?.map(item => {
            const costPerOperation = parseFloat(item.cost_per_operation || 0);
            const noOfOperationsPerYear = parseFloat(item.no_of_operations_per_year || 0);
            const costOfOperationsPerYear = (costPerOperation * noOfOperationsPerYear); // Format with 2 decimals
    
            return {
                ...item,
                cost_of_operations_per_year: parseFloat(costOfOperationsPerYear) // Convert back to number if needed
            };
        });
    
        return updatedAdditionalCost ;
    };
    
    
    const handleCalculateClick = () => {
            if (
                formData?.additional_cost[0]?.cost_of_operations_per_year !== 0 &&
                formData?.additional_cost[1]?.cost_of_operations_per_year !== 0 &&
                formData?.additional_cost[2]?.cost_of_operations_per_year !== 0
              ) {
                return [];
              } else {
                const updatedAdditionalCost  = calculateYearlyCost();
                return updatedAdditionalCost;
                
              }
    };

    const handleClose = async () => {
        if (ModalPopupTwo?.callBackModal) {
            const additionalCalculations  = handleCalculateClick();
            const additionalCalculationsToString  = additionalCalculations?.map((item)=>{
                return{ ...item, cost_of_operations_per_year:item.cost_of_operations_per_year.toFixed(2), }
            });            
            await ModalPopupTwo.callBackModal(additionalCalculationsToString);
        }
        CloseData();
    };

    const CloseData = () => {
        setFormData({
            additional_cost: [
                { type: 'scaffold', cost_per_operation: '', no_of_operations_per_year: '', cost_of_operations_per_year: 0 },
                { type: 'material', cost_per_operation: '', no_of_operations_per_year: '', cost_of_operations_per_year: 0 },
                { type: 'miscellaneous', cost_per_operation: '', no_of_operations_per_year: '', cost_of_operations_per_year: 0 },
            ],
        })
        setError({
            additional_cost: [
                { type: 'scaffold', cost_per_operation: '', no_of_operations_per_year: '', },
                { type: 'material', cost_per_operation: '', no_of_operations_per_year: '', },
                { type: 'miscellaneous', cost_per_operation: '', no_of_operations_per_year: '', },
            ],
        })
        dispatch(setStoreAdditionalCostModal());
    };

    const handleCloseModal = () =>{
        CloseData();
    }

    const onSubmit = () =>{
        if(validation()){
            handleClose();
        }else{
            console.log("Error in additional cost validation")
        }
    }

    if (ModalPopupTwo?.show !== "ADDITIONAL_COST_MODAL") {
        return null;
    }

    return (
        <div>
            <Modal show={true} backdrop="static" className='addcost' centered>
                <Modal.Body>
                    <div className='items'>
                        <h6>{t("Additional Cost")}</h6>
                        <div className='header-button-save-close'>
                            <Button  variant="primary" className={"save-btn"} onClick={onSubmit} size={'sm'}>{t("Save")}</Button>
                            <Icon onClick={handleCloseModal} className={"ms-2 close-appointment-btn"} image attrIcon={App_url.img.RedCrossIcon} />
                        </div>
                    </div>
                    {formData.additional_cost.map((costItem, index) => (
                        <div className='costs row mt-3' key={costItem.type}>
                            <h6>{costItem.type.charAt(0).toUpperCase() + costItem.type.slice(1)} Cost</h6>
                            <div className='col'>
                                <InputGroup
                                    label={t("Cost / Operation")}
                                    placeholder={t("Enter value")}
                                    name={"cost_per_operation"}
                                    value={costItem.cost_per_operation}
                                    error={error?.additional_cost?.[index]?.cost_per_operation}
                                    onChange={(e) => onChange(e, costItem.type)}
                                    type="text"
                                    rightLabel={'€'}
                                    number
                                    allowDot
                                />
                            </div>
                            <div className='col'>
                                <InputGroup
                                    label={t("Operations Per Year")}
                                    placeholder={t("Enter value")}
                                    name={"no_of_operations_per_year"}
                                    value={costItem.no_of_operations_per_year}
                                    error={error?.additional_cost?.[index]?.no_of_operations_per_year}
                                    onChange={(e) => onChange(e, costItem.type)}
                                    type="text"
                                    number
                                    allowDot
                                />
                            </div>
                        </div>
                    ))}
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default AdditionalCostModal;
