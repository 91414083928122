/* eslint-disable */

import React, { useEffect, useMemo, useRef, useState } from "react";
import Button from "./Button";
import Icon from "./Icon";
import { ProgressBar, Row } from "react-bootstrap";
import App_url from "./Constants";
import PropTypes from "prop-types";
// import { PostRequestAPI } from "../api/PostRequest";
import { useSelector } from "react-redux";
import Utils, { AuthenticateResponse, uuidv4 } from "./Utils";
import LazyImage from "./LazyImage";
import { toast } from "react-toastify";
import LazyVideo from "./LazyVideo";
import { PostRequestAPI } from "../../Api/api/PostRequest";

function DragDropUpload(props) {
  const uuid = useMemo(()=>uuidv4(), [])
  const [loader, setLoader] = useState(false)
  const {access_token} = useSelector(App_url.userDataReducers)
  const ref = useRef();

  const handleFileSelect = async (event) => {
    const files = event.target.files;
    let fileSize = 0;
    const validFiles = Array.from(files).filter(file => {
      fileSize = (file.size/1024)/1024;
      const extension = file.name.split('.').pop().toLowerCase();
      const isValidExtension = props?.file_type.includes(`${extension}`);
      const isValidSize = file.size <= 300 * 1024 * 1024; // 300 MB size check
      // return props?.file_type.includes(`${extension}`);
      return isValidExtension && isValidSize; 
    });
    if(fileSize > 300){
     toast.error("File exceeds the 300 MB size limit")
    }
    await handleUploadedFiles(validFiles, fileSize);
    event.target.value = "";
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    let fileSize = 0;
    const validFiles = Array.from(files).filter(file => {
      fileSize = (file.size/1024)/1024;
      const extension = file.name.split('.').pop().toLowerCase();
      const isValidExtension = props?.file_type.includes(`${extension}`);
      const isValidSize = file.size <= 300 * 1024 * 1024; // 300 MB size check
     
      return isValidExtension && isValidSize;
    });
    if(fileSize > 300){
      toast.error("File exceeds the 300 MB size limit")
     }
    await handleUploadedFiles(validFiles, fileSize);
  };

  const handleUploadedFiles = async (files, fileSize) => {
    if(files?.length >0){
      Array.from(files).forEach(async (file) => {
        if (file) {
          await callUploadFiles(file)
        }
      });
    }else{
      if(fileSize < 300){
        toast.error(`Invalid file type please upload ${props?.file_type}`)
       }
      // toast.info("Not accept this file extension")
    }
  };

  const callUploadFiles = async (files1) => {
    setLoader(true);
    const formData = new FormData();
    formData.append("file", files1, files1.name);
    const response = await PostRequestAPI(App_url.api.FILE_UPLOAD, formData, access_token, true);
    if(response?.status === 200){
      if(props?.isMultiple){
        const oldImage = [...props?.previewImage]
        oldImage.push(response?.data)
        const data = {
          target:{value: oldImage, type:"file", name:props?.name}
        }
        await props?.setPreviewImage(data);
      }else{
        const data = {
          target:{value: response?.data, type:"file", name:props?.name}
        }
        await props?.setPreviewImage(data);
        props?.documentIds(response?.data);
      }
    }else{
      AuthenticateResponse(response)
    }
    setLoader(false);
  }
  function formatFileSize(bytes) {
    if (bytes === 0) return '0 Bytes';
  
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
  
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  }

  const ImagePreview = ({item, index}) =>{
    const callRemove = () =>{
      if(props?.isMultiple){
        const oldImage = props?.previewImage?.filter((item1, index1)=>index1 !== index)
        const data = {
          target:{value: oldImage, name:props?.name}
        }
        props?.setPreviewImage(data);
      }else{
        props?.setPreviewImage({
          target:{value: null, name:props?.name}
        });
      }
    }
    if(props?.media_type == "document"){
      return(
        <div className={'upload-file-preview document'} onDragOver={handleDragOver} onDrop={handleDrop} >
          <div className="profile-sm">
            <div className="container-image">
              <Icon  attrIcon={App_url.image.FileDocument} />
              <span>{item?.name?.split('.').pop().toLowerCase()}</span>
            </div>
          </div>
          <div className="file_details">
            <h6 className="text-primary mb-1">{formatFileSize(item?.size)}</h6>
            <p className="mb-1">{item?.name}</p>
            <a className="text-danger cursor-pointer" href onClick={callRemove}>Remove</a>
          </div>
        </div>
      )
    }
    if(props?.media_type == "view"){
      const isVideoFile = Utils.isVideoFile(item?.name)
      const isImageFile = Utils.isImageFile(item?.name)
      return(
        <div className={'upload-file-preview view'} onDragOver={handleDragOver} onDrop={handleDrop} >
          <div className="profile-sm">
            {isImageFile && (
              <Icon auth image attrIcon={`${process.env.REACT_APP_API_DOMAIN_URL}/file/${item?.view_thumbnail_url}`} className={"profile-sm"} />
            )}
            {isVideoFile && (
              <LazyVideo src={`${process.env.REACT_APP_API_DOMAIN_URL}/file/${item?.view_file_url}`} className={"profile-sm"} />
            )}
          </div>
          <div className="file_details">
            <p className="mb-1">{item?.name}</p>
            <a className="text-danger cursor-pointer" href onClick={callRemove}>
              <Icon  attrIcon={App_url.image.delete} />
            </a>
          </div>
        </div>
      )
    }
    return(
      <div className={'upload-file-preview'} onDragOver={handleDragOver} onDrop={handleDrop} >
        <Icon auth image attrIcon={`${process.env.REACT_APP_API_DOMAIN_URL}/file/${item?.view_file_url}`} className={"profile-sm radius-3"} />
        <div className="file_details">
          <h6 className="text-primary mb-1">{formatFileSize(item?.size)}</h6>
          <p className="mb-1">{item?.name}</p>
          <a className="text-danger cursor-pointer" href onClick={callRemove}>Remove</a>
        </div>
      </div>
    )
  }
  const callShowPreviewImage = () =>{
    if(!((props?.previewImage && !props?.isMultiple) || props?.previewImage?.length>0)){
      return <React.Fragment/>
    }
    return(
      <React.Fragment>
        {((props?.previewImage && !props?.isMultiple) || props?.previewImage?.length>0) && <Row className="w-100 m-0">
          {props?.isMultiple && (
            <React.Fragment>
              {props?.previewImage?.length>0 && props?.previewImage?.map((item, index)=>(
                <React.Fragment key={index}>
                  <div className={props?.classNameFileItem}>
                    {ImagePreview({item: item, index : index})}
                  </div>
                </React.Fragment>
              ))}
            </React.Fragment>
          )}
          {(props?.previewImage && !props?.isMultiple) &&(
            <React.Fragment>
              <div className={props?.classNameFileItem}>
                    {ImagePreview({item: props?.previewImage, index : 0})}
              </div>
            </React.Fragment>
          )}
        </Row>}
      </React.Fragment>
    )
  }




  return (
    <React.Fragment>
      <div className={`bulk-add form_group  variant-${props?.variant} ${props?.progress?"progress":"preview"}`}>
        {props?.label && <label className="form-label w-100">{props?.label}</label>}
        <React.Fragment>
            {props?.media_type == "document" ? (
              <React.Fragment>
                <div className={`upload-container-dev ${props?.classNameUploadContainer} mb-3 ${props?.error ? "is-invalid":""}`} onDragOver={handleDragOver} onDrop={handleDrop} >
                  <label className={`card-upload col ${props?.className} `} htmlFor={'input_file'+uuid}>
                    <input ref={ref} type='file' name={props?.name} onChange={handleFileSelect} id={'input_file'+uuid} hidden />
                    <div className={`${props?.document_file?"right_icon":""} ${props?.titleLeft?"left_icon":""} upload-container document`}>
                      {props?.titleLeft &&(
                        props?.titleLeft
                      )}
                      <h5 className='fs-15 m-0'>{props?.title}</h5>
                      {props?.document_file && (
                        <Icon className=' document_file' attrIcon={App_url.image.UploadDocument} image={undefined} />
                      )}
                      
                    </div>
                  </label>
                  {loader &&(
                    <div className='btn-loader'>
                      <div className='loader_dev'>Please wait</div>
                  </div>
                  )}
                </div>
              </React.Fragment>
            ):(
              <React.Fragment>
                <div className={`upload-container-dev mb-3  ${props?.error ? "is-invalid":""}`} onDragOver={handleDragOver} onDrop={handleDrop} >
                  <label className={`card-upload col ${props?.className} `} htmlFor={'input_file'+uuid}>
                    <input ref={ref} type='file' name={props?.name} onChange={handleFileSelect} id={'input_file'+uuid} hidden />
                    <div className='upload-container'>
                      <Icon className='lg mb-2' attrIcon={props?.uploadIcon} image />
                      <h5 className='fs-14 text-center'>{props?.title}</h5>
                      {props?.progress ? (
                        <Button onClick={() => ref?.current.click()} variant={'primary'} className={"mt-2"} size={'sm'}>Browse File</Button>
                      ):(
                        <a className={`text-${props?.variant}`}>{props?.text_secondary}</a>
                      )}
                    </div>
                  </label>
                  {loader &&(
                    <div className='btn-loader'>
                      <div className='loader_dev'>Please wait</div>
                  </div>
                  )}
                </div>
              </React.Fragment>
            )}
        </React.Fragment>
        {props?.error && <div className="invalid-feedback">{props?.error}</div>}
        {callShowPreviewImage()}
        {/* {props?.progress && (
          <div hidden={props?.progress ? false: true} className='upload-file col-12 col-sm-4 px-sm-3 p-0'>
            <h5 className='title-6'>Upload Files</h5>
            {UploadFile?.map((item, index) => (
              <React.Fragment key={index}>
                <div className='upload-file-card'>
                  <Icon attrIcon={App_url.image.fileXLS} image={undefined} className='lg' />
                  <div className='upload-status'>
                    <h6 className='fs-13 fw-7'>Data Fetching (65%)</h6>
                    <ProgressBar variant="primary" now={40} />
                  </div>
                  <Icon attrIcon={App_url.image.CloseCircle} onClick={(e) => callClose(index)} className={'danger nm close-btn'} />
                </div>
              </React.Fragment>
            ))}
          </div>
        )} */}
      </div>
    </React.Fragment>
  )
}
DragDropUpload.propTypes = {
  previewImage: PropTypes.any,
  setPreviewImage: PropTypes.func,
  title: PropTypes.any,
  className: PropTypes.any,
  classNameUploadContainer: PropTypes.any,
  progress: PropTypes.bool,
  isMultiple: PropTypes.bool,
  name: PropTypes.any,
  file_type: PropTypes.array,
  media_type: PropTypes.any,
  classNameFileItem: PropTypes.any,
  uploadIcon: PropTypes.any,
  text_secondary: PropTypes.any,
  variant: PropTypes.any,
  document_file: PropTypes.any,

}
DragDropUpload.defaultProps = {
  previewImage: [],
  setPreviewImage: ()=>{},
  title: "",
  className: "",
  classNameUploadContainer: "",
  progress: false,
  isMultiple: true,
  name: "icon",
  file_type: [ "jpg", "jpeg", "png" ],
  media_type: "image",
  classNameFileItem: "",
  uploadIcon: App_url.image.Upload,
  text_secondary: "Browse",
  variant: "Browse",
  document_file: App_url.image.UploadDocument,
}
export default DragDropUpload;