/* eslint-disable */
import { legacy_createStore as createStore, applyMiddleware, combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { thunk } from "redux-thunk";
import { modalReducers } from "./modules/modals/reducers"
import { userDataReducers } from "./modules/users_data/reducers";
import { customerReducers } from "./modules/customers/reducers";
import { calculationReducers } from "./modules/calculation/reducers";

import { promotionReducers } from "./modules/promotions/reducers";
import { externalOfferReducers } from "./modules/ExternalOffer/reducers";
import { servicesReducers } from "./modules/services/reducers";
import { documentationReducers } from "./modules/documentation/reducers";
import { settingReducers } from "./modules/setting_reducers/reducers";
import { languageReducer } from "./modules/language/reducers";
import { employeeReducers } from "./modules/EmployeeUser/reducers";

const persistConfig = {
    key: "sale_app",
    storage
};

const reducers = combineReducers({
    modalReducers: modalReducers,
    userDataReducers: userDataReducers,
    customerReducers: customerReducers,
    calculationReducers: calculationReducers,
    promotionReducers: promotionReducers,
    externalOfferReducers: externalOfferReducers,
    servicesReducers: servicesReducers,
    documentationReducers: documentationReducers,
    settingReducers: settingReducers,
    languageReducer: languageReducer,
    employeeReducers:employeeReducers,

})

// export default reducers;
const middleware = [thunk];


const persistedReducer = persistReducer(persistConfig, reducers);

const store = createStore(persistedReducer, applyMiddleware(...middleware));

export const persistor = persistStore(store);

export default store;

