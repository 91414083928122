import { ActionTypes } from "./action";

const initialData = {
    customersList: {
        data: [],
        pagination: {},
    },
    remarkList: {
        data: [],
        pagination: {}
    },
    scheduleList: {
        data: [],
        pagination: {}
    },
    documentList: {
        data: [],
        pagination: {},
    },
    emailHistoryList: {
        data: [],
        pagination: {},
    },
    sentEmailHistoryList: {
        data: [],
        pagination: {},
    },
    appointmentList: {
        data: [],
        pagination: {},
    },
    // emailHistoryList :[],
    customerDetails: [],
    inviteesUserList:{
        data:[],
        pagination:{},
    },
    appointmentDetails:[],
    inviteeAvailableDates:[],
    citiesIndustriesPincodes:{
        cities:[],
        industries:[],
        pincodes:[],
    }
}

export const customerReducers = (state = initialData, action) => {
    switch (action.type) {
        case ActionTypes.SET_STORE_CUSTOMERS_LIST: {
            // console.log("action?.payload", action?.payload);
            return {
                ...state,
                customersList: {
                    data: action?.payload?.data,
                    pagination: action?.payload?.pagination,
                }
            }
        }
        case ActionTypes.SET_STORE_REMARK_LIST: {
            // console.log("action?.payload",action?.payload);
            return {
                ...state,
                remarkList: {
                    data: action?.payload?.data,
                    pagination: action?.payload?.pagination,
                }
            }
        }

        case ActionTypes.SET_STORE_CUSTOMER_DETAILS: {
            // console.log("action?.payload", action?.payload);
            return {
                ...state,
                customerDetails: action?.payload,
            }
        }

        case ActionTypes.SET_STORE_SCHEDULE_LIST:{
            //  console.log("action?.payload", action?.payload);
            return{
                ...state,
                scheduleList: {
                    data: action?.payload?.data,
                    pagination: action?.payload?.pagination,
                }
            }
        }
        case ActionTypes.SET_STORE_DOCUMENTS_LIST: {
            // console.log("action?.payload", action?.payload);
            return {
                ...state,
                documentList: {
                    data: action?.payload?.data,
                    pagination: action?.payload?.pagination,
                }
            }
        }

        case ActionTypes.SET_STORE_EMAIL_HISTORY_LIST: {
            // console.log("action?.payload", action?.payload);
            return {
                ...state,
                emailHistoryList: {
                    data: action?.payload?.data,
                    pagination: action?.payload?.pagination,
                }
            }
        }
        case ActionTypes.SET_STORE_SENT_EMAIL_HISTORY_LIST: {
            // console.log("action?.payload of email sent ", action?.payload);
            return {
                ...state,
                sentEmailHistoryList: {
                    data: action?.payload?.data,
                    pagination: action?.payload?.pagination,
                }
            }
        }
        case ActionTypes.SET_STORE_APPOINTMENT_LIST: {
            // console.log("action?.payload of appointment ", action?.payload);
            return {
                ...state,
                appointmentList: {
                    data: action?.payload?.data,
                    pagination: action?.payload?.pagination,
                }
            }
        }
        case ActionTypes.SET_STORE_INVITEES_USER_LIST: {
            // console.log("SET_STORE_INVITEES_USER_LIST", action?.payload);
            return {
                ...state,
                inviteesUserList: {
                    data: action?.payload?.data,
                    pagination: action?.payload?.pagination,
                }
            }
        }
        case ActionTypes.SET_STORE_APPOINTMENT_DETAILS: {
            // console.log("SET_STORE_APPOINTMENT_DETAILS", action?.payload);
            return {
                ...state,
                appointmentDetails: action?.payload
            }
        }
        case ActionTypes.SET_STORE_INVITEE_AVAILABLE_DATES: {
            // console.log("SET_STORE_APPOINTMENT_DETAILS", action?.payload);
            return {
                ...state,
                inviteeAvailableDates: action?.payload
            }
        }
        case ActionTypes.SET_STORE_CITIES_INDUSTRIES_PINCODES: {
            // console.log("action?.payload", action?.payload);
            return {
                ...state,
                citiesIndustriesPincodes: {
                    cities: action?.payload?.cities,
                    industries: action?.payload?.industry,
                    pincodes: action?.payload?.pincodes,
                    // pagination: action?.payload?.pagination,

                }

            }
        }

        default:
            return state;
    }
}