/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import Images from './Image'
import App_url from './Constants'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';

export default function LoaderPage() {
  const {access_token} = useSelector(App_url.userDataReducers);
  const navigate = useNavigate();
  // useEffect(()=>{
  //   if(access_token){
  //     navigate(App_url.link.dashboard);
  //   }
  // },[access_token])
  return (
    <div className='bg-white text-center d-flex justify-content-center align-items-center' style={{ height:"80vh" }}>
    <Images width={500} height={500} src={App_url.img.LoaderIcon} className={"md loader-image-size"} buttonClassName={"btn-sm br-2"} />
    </div>
  )
}
