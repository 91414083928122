/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState, useTransition } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import App_url from '../Common/Constants';
import { setConfirmModalPopup, setStoreComposeMailModal, setStoreUserMessageModal, setViewDocumentModal } from '../../store/modules/modals/action';
import { PostRequestAPI } from '../../Api/api/PostRequest';
import Button from '../Common/Button';
import Icon from '../Common/Icon';
import Images from '../Common/Image';
import wsSendRequest from '../../socketIO/wsSendRequest';
import { WebSocketContext } from '../../WSContext';
import { useNavigate } from 'react-router-dom';
import { setWebsocketReqRes } from '../../store/modules/users_data/action';
import { useTranslation } from '../../translate';

const ViewDocument = () => {
    const { t, changeLanguage } = useTranslation();
    const dispatch = useDispatch();
    const {ws}  = useContext(WebSocketContext);
    const navigate = useNavigate();
    const { ModalPopupTwo } = useSelector(App_url.modalReducers);
    const { access_token, device_id, websocketReqRes } = useSelector(App_url.userDataReducers);
    const { customerDetails } = useSelector(App_url.customerReducers);
    const { lang } = useSelector(App_url.settingReducers);
    const [fileUrl, setFileUrl] = useState(null);
    const [fileType, setFileType] = useState(null);

    const ModalData = ModalPopupTwo?.data
    // console.log("ModalData========", ModalPopupTwo)

    const handleClose = async () => {
        if (ModalPopupTwo?.callBackModal) {
            await ModalPopupTwo?.callBackModal(ModalPopupTwo?.data?.item);
        }
        CloseData()
    };
    const CloseData = () => {
        dispatch(setViewDocumentModal())
    }


    const handleSendMailDocument = () => {
        if(ModalData?.item?.fromPage === "offerOverviewDetails"){
            dispatch(setViewDocumentModal());
            console.log("ModalPopupTwo", ModalPopupTwo)
            dispatch(setConfirmModalPopup({
                show: "CONFIRM_MODAL",
                data: { title: t("Do you want to Attach more </br> document for this client?"), documentImage: [ModalPopupTwo?.data?.item], fileView: [ModalData?.item?.view_file_url]},
                buttonSuccess:"Yes",
                buttonCancel:"No",
                callBackCancelModal: () => callSendMail(),
                callBackModal: () => navigate(App_url.link.offerDetailsAddDocument, {state:ModalData?.item}),
            }))
        }else{
            dispatch(setViewDocumentModal());
            callSendMail();

        }
    }

    const callSendMail = () =>{
        ModalData?.item?.fromPage &&  dispatch(setConfirmModalPopup());
        dispatch(setStoreComposeMailModal({
            show: "COMPOSE_MAIL_MODAL",
            data: { documentImage: [ModalPopupTwo?.data?.item], fileView: [ModalData?.item?.view_file_url],},
            callBackModal: (mailData, modalData) => handleComposeMailAPI(mailData, modalData),
        }))
    }

    const handleComposeMailAPI = async (mailData, modalData) => {
        console.log("mailData", mailData);
        const payload = {
            subject: mailData?.subject,
            body: `<body>${mailData?.msg}</body>`,
            document: mailData?.document || [],
            inline_ids: mailData?.inline_ids || [],
            to: mailData?.email || "",
            cc: mailData?.cc || "",
            bcc: mailData?.bcc || "",
            customer: [customerDetails?.[0]?.id || ""],
            content_type: "Html"
        };
        console.log('payload', payload)
        const response = await PostRequestAPI(App_url.api.COMPOSE_MAIL, payload, access_token)
        console.log("responseEmail", response);
        if (response?.status === 200) {
            toast.success(response?.data?.message);
            const  paramData = modalData?.documentImage?.[0]
            const param = {
              url: "create_offer_overview",
              transmit: "broadcast",
              request: {
                offer_id: paramData?.offerId || "",
                overview_id:  ModalData?.item?.overview_id || "",
                customer_id: paramData?.customerId || "",
                calculation_id: paramData?.calculationId || "",
                offer_type: paramData?.calculationId ? "Regular offer":"Quick offer",
                document: paramData?.document_image || "",
                lang: lang,
              }
            };
            wsSendRequest(ws, param, device_id);
            if(paramData?.offerId){
                const param2 = {
                    transmit: "broadcast",
                    url: "delete_offer",
                    request: { offer_id: paramData?.offerId }
                  };
                wsSendRequest(ws, param2, device_id);
            }
            if(paramData?.calculationId){
                const param3 = {
                  transmit: "broadcast",
                  url: "delete_calculation",
                  request: {
                    calculation_id: paramData?.calculationId
                  }
                };
                wsSendRequest(ws, param3, device_id);
            }
            console.log("Param", param , modalData)
        } else {
            toast.error(response?.data?.error || response.data?.detail);
        }
    }



    if (ModalPopupTwo?.show !== "VIEW_DOCUMENT_MODAL") {
        return null
    }
    return (
        <Modal show={true} className='viewDoc'>
            <Modal.Body className='' style={{ minHeight: "240px" }}>
                <div className='header'>
                    <h6 className='title ellipsis2'>{ModalData?.item?.document_name}</h6>
                    <div className='subheader'>
                        <Button onClick={() => handleSendMailDocument(ModalPopupTwo?.data?.item, ModalData?.item?.view_file_url)} variant="primary" className='' size={"sm"}>{t("Send Mail")}</Button>
                        <Icon onClick={CloseData} className={"ms-2 close-appointment-btn"} image attrIcon={App_url.img.RedCrossIcon} />
                    </div>
                </div>
                <div>
                    <Images file_view auth width={100} height={100} src={`${process.env.REACT_APP_API_DOMAIN_URL}/file/${ModalData?.item?.view_file_url}`} alt="Document" />
                    {/* <Images file_view auth width={100} height={100} src={`${process.env.REACT_APP_API_DOMAIN_URL}/file/${ModalData?.view_file_url}`} alt="Document" /> */}
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ViewDocument