/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import CustomTableTwo from '../Common/CustomTableTwo'
import ContentLayout from '../layout/ContentLayout'
import App_url from '../Common/Constants'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { WebSocketContext } from '../../WSContext'
import wsSendRequest from '../../socketIO/wsSendRequest'
import Utils from '../Common/Utils'
import UtilsGetList from '../Common/UtilsGetList'
import { useTranslation } from '../../translate'

const NewCalculation = () => {
  const { t, changeLanguage } = useTranslation();

    const { ws, send } = useContext(WebSocketContext);
    const { device_id, websocketReqRes } = useSelector(App_url.userDataReducers);
    const { customersList, citiesIndustriesPincodes } = useSelector(App_url.customerReducers);
    const { lang } = useSelector(App_url.settingReducers);
    // console.log("citiesIndustriesPincodes", citiesIndustriesPincodes)
    const navigate = useNavigate();
    const [filter, setFilter] = useState({
        search: "",
        city: "",
        postcode: "",
        industry_search:""
    })

    const handleView = (customerId) => {    
        UtilsGetList.callCustomerDetailsApi({ws, customer_id:customerId, lang:lang})
        navigate(`${App_url.link.selectCategory}/${customerId}`)
    }
    const callBack = (e, state, btnName, customerId) => {
        console.log(state)
        if (state === 'AddButton2' || btnName === "withoutCustomer" && !customerId) {
            const nocustomerId = "proceedWithoutCustomer"
            navigate(`${App_url.link.selectCategory}/${nocustomerId}`)
        }
    }

    const citiesOptions = citiesIndustriesPincodes?.cities?.map((item) => {
        return { label: item?.city_name, value: item?.city_name }
    })

    const postCodeOptions = citiesIndustriesPincodes?.pincodes?.map((item) => {
        return { label: item?.pincode, value: item?.pincode }
    })


    const callCustomersListApi = (page) => {
        const param = {
            transmit: "broadcast",
            url: "customer_list",
            request: {
                status: true,
                limit: "10",
                page_no: page,
                pincode_search: filter?.postcode,
                city_search: filter?.city,
                customer_type: [""],
                industry_search: "",
                search: filter?.search,
                order_by: "-updated_at",
            }
        };
        send(param, device_id);
    };

    useEffect(() => {
        if (ws) {
            callCustomersListApi(1);
            UtilsGetList.getPinCodeCityList({ws, device_id});
        }
    }, [ws, filter]);


    const RenderBody = (item) => {
        // console.log("item", item);
        return (
            <React.Fragment>
                <td className='new-calc-td' onClick={() => handleView(item?.id)}>{item?.industry_type ? item?.industry_type : "-"}</td>
                <td className='new-calc-td' onClick={() => handleView(item?.id)}>{item?.company_name ? item?.company_name : "-"}</td>
                <td className='new-calc-td' onClick={() => handleView(item?.id)}>{item?.customer_name ? item?.customer_name : "-"}</td>
                <td className='new-calc-td' onClick={() => handleView(item?.id)}>{item?.email ? item?.email : "-"}</td>
                <td className='new-calc-td' onClick={() => handleView(item?.id)}>{item?.company_mobile ? item?.company_mobile : "-"}</td>
                <td className='new-calc-td' onClick={() => handleView(item?.id)}>{item?.company_address ? item?.company_address : "-"}</td>
                <td className='new-calc-td px-3' onClick={() => handleView(item?.id)}>{item?.city ? item?.city : "-"}</td>
            </React.Fragment>
        )
    }

    return (
        <ContentLayout title={t('Calculation')}>
            <CustomTableTwo
                title={{ text: t("Customer List"), size: "fs-18" }}
                subtitle={{ text: t("Select a customer to proceed further"), size: "fs-12" }}
                AddButton={{ title: t("Proceed Without Customer"), variant: "grey", size: "md" }}
                cityPlaceHolder={t("Select City")}
                postcodePlaceHolder={t("Select Postcode")}
                typePlaceHolder2={t("Select City")}
                columnNames={[t("Industry"), t("Company Name"), t("Person Name"),t( "Email"), t("Phone No."), t("Street No"), t("City")]}
                recordData={customersList?.data}
                filter={filter}
                setFilter={setFilter}
                search_placeholder={t("Search by Company Name")}
                callBack={(e, state) => callBack(e, state, "withoutCustomer")}
                renderBody={RenderBody}
                pagination={customersList?.pagination}
                onPagination={(page) => callCustomersListApi(page)}
                sr_no={"new-calc-td"}
                cityOption={citiesOptions}
                postCodeOption={postCodeOptions}
                // scrollbar={customersList?.data?.length > 3 && true}
                // style={{ height: "500px" }}

            />

        </ContentLayout>
    )
}

export default NewCalculation