/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import ContentLayout from '../layout/ContentLayout'
import { Breadcrumb, Card } from 'react-bootstrap'
import CustomTableTwo from '../Common/CustomTableTwo'
import Icon from '../Common/Icon'
import App_url from '../Common/Constants'
import CreateFolderModal from './CreateFolderModal'
import { WebSocketContext } from '../../WSContext'
import { useDispatch, useSelector } from 'react-redux'
import wsSendRequest from '../../socketIO/wsSendRequest'
import Utils from '../Common/Utils'
import { setWebsocketReqRes } from '../../store/modules/users_data/action'
import { toast } from 'react-toastify'
import { PostRequestAPI } from '../../Api/api/PostRequest'
import { setStoreDiscardChangesModal, setStoreRenameFolderModal, setViewDocumentModal } from '../../store/modules/modals/action'
import { useTranslation } from '../../translate'
import RenameFolder from './RenameFolder'
import { useLocation } from 'react-router-dom'
import AnchorLink from '../Common/AnchorLink'

export default function InsideFolderList() {
    const { t, changeLanguage } = useTranslation();
    const dispatch = useDispatch();
    const { ws, send } = useContext(WebSocketContext);
    const { device_id, websocketReqRes, access_token } = useSelector(App_url.userDataReducers);
    const {documentationList, documentInsideFolderList } = useSelector(App_url.documentationReducers);
    const [loader, setLoader] = useState(false);
    const [folderState, setFolderState] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const location = useLocation();
    const state = location?.state;
    console.log("state", state);

    const [filter, setFilter] = useState({
        search: "",
        file_type: "",
    })




    const closeModal = () => {
        setIsModalOpen(false);
    };

    const callBrowserInsideFolderListAPI = (page) => {
        const param = {
            transmit: "broadcast",
            url: "document_list_inside_folder",
            request: {
                folder_id: state?.id,
                page_no: "1",
                limit: "10"
            }
        };
        send(param);
    }

    const fileTypesOptions = Array.from(new Set(documentationList?.data?.map(item => item.document_type)))
        .map(document_type => {
            return { label: document_type, value: document_type };
        });

    const callBack = async (e, state) => {
        // console.log("e, state", state, e);
        if (state === "BrowseButton") {
            const file = e.target.files[0];
            if (file) {
                setLoader(true);
                const formData = new FormData();
                formData.append("file", file);
                try {
                    const response = await PostRequestAPI(App_url.api.FILE_UPLOAD, formData, access_token, true);
                    console.log("File upload response:", response);
                    setLoader(false);
                    if (response?.status === 200) {
                        addFileDocument(response?.data);
                    }
                } catch (error) {
                    console.error("File upload failed:", error);
                }
            }
        }
        if (state === "AddButton2") {
            setIsModalOpen(true);
        }
    }

    const addFileDocument = (file) => {
        const param = {
            transmit: "broadcast",
            url: "document_add",
            request: {
                document_image: file?.id,
                document_type: file?.name?.split('.')?.[1],
                document_name: file?.name,
                document_size: file?.size,
            }
        };
        send(param);
    }

    const handleCreateFolder = (folderName) => {
        // console.log("foldername", folderName);
        const param = {
            transmit: "broadcast",
            url: "document_add",
            request: {
                document_image: "",
                document_type: "folder",
                document_name: folderName,
                document_size: ""
            },
        };
        send(param);
    }
    // document_name
    const handleRenameFolder = (docItem, folderName) => {
        console.log("docItem", docItem, folderName);
        const param = {
            transmit: "broadcast",
            url: "document_edit",
            request: {
                document_id: docItem?.id,
                document_name: folderName,
                document_type: "folder"
            },
        };

        // console.log("docItem===", param)
        send(param);
    }
    // console.log(documentationList?.data[0]?.id)
    const callRemoveDocumentFromFolder = (docId) => {
        const param = {
          transmit: "broadcast",
          url: "remove_from_folder",
          request: {
            document_id_list: docId,
            folder_id: state?.id
          }
        };
        send(param);
    }
    useEffect(() => {
        if (ws) {
            callBrowserInsideFolderListAPI(1);
        }
    }, [ws, filter]);

    const handleDelete = (docId) => {
        dispatch(setStoreDiscardChangesModal({
            show: "DISCARD_CHANGES_MODAL",
            data: { description: "Are you sure you want to remove this from folder ?" },
            callBackModal: () => callRemoveDocumentFromFolder(docId),
        }))
    }

    useEffect(() => {
        if (websocketReqRes?.url === "remove_from_folder" || websocketReqRes?.url === "move_to_folder") {
            if (websocketReqRes?.status === 200) {
                toast.success(websocketReqRes?.response?.msg)
                dispatch(setWebsocketReqRes())
                callBrowserInsideFolderListAPI(1);
                // navigate(App_url.link.savedCalculation);
            } else {
                toast.info(websocketReqRes?.response?.msg || "error");
                dispatch(setWebsocketReqRes());
            }
        }
    }, [websocketReqRes?.url])

    const handleView = (item) => {
        console.log("item", item)
        dispatch(setViewDocumentModal({
            show: "VIEW_DOCUMENT_MODAL",
            data: { item: item },
        }))
    }
    const handleEdit = (docItem) => {
        console.log("item", docItem)
        dispatch(setStoreRenameFolderModal({
            show: "RENAME_FOLDER_MODAL",
            data: { item: docItem },
            callBackModal: (docItem, folderName) => handleRenameFolder(docItem, folderName),

        }))
    }

    const handleInsideFolder = (item) => {
        const param = {
            transmit: "broadcast",
            url: "document_list_inside_folder",
            request: {
                folder_id: item?.id,
                page_no: "1",
                limit: "10"
            }
        };
        send(param);
    }

    const RenderBody = (item,) => {
        // console.log("item", item);
        return (
            <React.Fragment>
                <td><Icon className={"me-2 document-type"} attrIcon={Utils.getIcon(item?.document_type)} image />{Utils.formatOneLineEllipse(item?.document_name)}</td>
                <td className='text-uppercase'>{item?.document_type}</td>
                <td>{item?.document_size ? Utils.getFileSize(item?.document_size) : "NA"}</td>
                <td>{Utils.formatDateTime2(item?.updated_at, t)}</td>
                <td className='d-flex gap-2'>
                    {item?.document_type !== "folder" ?
                        (<span className='view-icon-box' onClick={() => handleView(item)}><Icon className={"bg-primary"} attrIcon={App_url.img.EyeIcon} /></span>)
                        :
                        (<span className='view-icon-box' onClick={() => handleInsideFolder(item)} ><Icon className={"bg-primary"} attrIcon={App_url.img.EyeIcon} /></span>)
                    }
                    {item?.document_type === "folder" && <span className='view-icon-box' onClick={() => handleEdit(item)}><Icon className={"bg-primary"} attrIcon={App_url.img.EditIcon} /></span>}
                    {/* {item?.document_type !== "folder" && <span className='view-icon-box'><Icon className={"bg-primary"} attrIcon={App_url.img.ShareIcon} /></span>} */}
                    <span className='view-icon-box' onClick={() => handleDelete(item?.id)}><Icon className={"bg-primary"} attrIcon={App_url.img.DeleteIcon} /></span>
                    {/* <span className='view-icon-box' onClick={openModal}><Icon className={"bg-primary"} attrIcon={App_url.img.ShareFolderIcon} /></span> */}
                </td>
            </React.Fragment>
        )
    }
    return (
        <ContentLayout title={"Documentation Page"}>
            <Breadcrumb className="breadcrumb-main">
                <Breadcrumb.Item>
                    <AnchorLink to={App_url.link.browseList}>{t("Browse List")}</AnchorLink>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>{state?.document_name}</Breadcrumb.Item>
            </Breadcrumb>
            <CustomTableTwo
                title={{ text: `${state?.document_name}`, size: "fs-20", textTwo: "" }}
                // BrowseButton={{ title: `${loader ? t("Please wait...") : t("+ Add Document")}`, variant: "outline-primary", size: "md" }}
                // AddButton2={{ title: t("Create Folder"), variant: "primary", size: "md" }}
                columnNames={[t("Name "), t("Type"), t("File Size"), t("Last Modified"), t("Action")]}
                recordData={documentInsideFolderList?.data}
                renderBody={RenderBody}
                callBack={(e, state) => callBack(e, state)}
                setFilter={setFilter}
                filter={filter}
                pagination={documentInsideFolderList?.pagination}
                onPagination={(page) => callBrowserInsideFolderListAPI(page)}
                search_placeholder={t("Search By Name")}
                typePlaceHolder={t("Select File Type")}
                typeOption={fileTypesOptions || []}
            />
            {/* <CreateFolderModal isModalOpen={isModalOpen} handleClose={closeModal} handleCreateFolder={handleCreateFolder} /> */}
        </ContentLayout>
    )
}