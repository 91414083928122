/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { WebSocketContext } from '../../WSContext';
import InputGroup from '../Common/InputGroup';
import App_url from '../Common/Constants';
import Scrollbar from '../Common/Scrollbar';
import { setStoreAddCustomerModal } from '../../store/modules/modals/action';
import Icon from '../Common/Icon';
import Button from '../Common/Button';
import { useTranslation } from '../../translate';


export default function AddCustomerModal(props) {
  const { t, changeLanguage } = useTranslation();

    const dispatch = useDispatch();
    const { ws, send } = useContext(WebSocketContext);
    const { ModalPopupTwo } = useSelector(App_url.modalReducers);
    const { customersList } = useSelector(App_url.customerReducers);
    const { device_id, websocketReqRes } = useSelector(App_url.userDataReducers);
    const { citiesIndustriesPincodes } = useSelector(App_url.customerReducers);
    const [showFilter, setShowFilter] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')
    const [customer, setCustomer] = useState([]); //showing added customers records in []
    const [filteredCustomers, setFilteredCustomers] = useState(customersList);
    const [formData, setFormData] = useState({
        pincode: '',
        industry_type: '',
        city: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const callIndustryTypeApi = (page) => {
        const param = {
            "transmit": "broadcast",
            "url": "pincode_and_city_list",
        }
        // console.log("param", param)
        send(param);
    };

    const callCustomersListApi = (page) => {
        const param = {
            transmit: "broadcast",
            url: "customer_list",
            request: {
                status: true,
                limit: "10",
                page_no: page,
                pincode_search: "",
                city_search: "",
                customer_type: [""],
                order_by: "-updated_at",
                industry_search: ""
            }
        };
        send(param, device_id);
    };

    useEffect(() => {
        if (websocketReqRes?.url === 'pincode_and_city_list' && websocketReqRes?.url === 'customer_list' && ModalPopupTwo?.show === "ADD_CUSTOMER_MODAL") {
            callCustomersListApi(1);
            callIndustryTypeApi(1);
        }
    }, [websocketReqRes?.url]);
    
    //   useEffect(() => {
    //     if (websocketReqRes?.url === 'pincode_and_city_list' && websocketReqRes?.url === 'customer_list') {
    //         callCustomersListApi(1);
    //         callIndustryTypeApi(1);
    //     }
    // }, [websocketReqRes?.url]);

    useEffect(() => {
        if (ModalPopupTwo?.show === "ADD_CUSTOMER_MODAL") {
            setCustomer([])
            callIndustryTypeApi();
        }
    }, [customersList])


    const handleClose = async () => {
        if (ModalPopupTwo?.callBackModal) {
            await ModalPopupTwo?.callBackModal(customer);
            // setCustomer([])
        }
        CloseData()
    };

    const handleSelectedValue = (selectedValue) => {
        const removedCustomer = customer?.filter((item) => item?.id !== selectedValue?.id)
        setCustomer(removedCustomer);
    }
    const handleAddSelectedInvitee = (item) => {
        setCustomer((prevCustomer) => {
            const isAlreadyAdded = prevCustomer?.some(inv => inv?.id === item?.id);
            if (isAlreadyAdded) {
                return prevCustomer?.filter(inv => inv.id !== item?.id);
            } else {
                return [...prevCustomer, item];
            }
        });
    };
    useEffect(() => {
        const updatedFilteredCustomers = customersList?.data?.filter(customer =>
            customer?.customer_name?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
            customer?.last_name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
        ) || [];
        setFilteredCustomers(updatedFilteredCustomers);
    }, [searchTerm, customersList?.data]);

    const CloseData = () => {
        dispatch(setStoreAddCustomerModal());
        setShowFilter(false);
    }

    if (ModalPopupTwo?.show !== "ADD_CUSTOMER_MODAL") {
        return null
    }

    const handleIconClick = () => {
        setShowFilter(!showFilter);
    };
    const industryTypeOptions = citiesIndustriesPincodes?.industries?.map((item) => {

        return { label: item?.industry_type, value: item?.industry_type }
    })
    const citiesOptions = citiesIndustriesPincodes?.cities?.map((item) => {

        return { label: item?.city_name, value: item?.city_name }
    })
    const pincodeOptions = citiesIndustriesPincodes?.pincodes?.map((item) => {

        return { label: item?.pincode, value: item?.pincode }
    })
    const applyFilters = () => {
        let filtered = customersList?.data;
        // Apply filter by city if selected from citiesOptions
        if (formData?.city_name) {
            filtered = filtered?.filter((customer) => customer?.city === formData?.city_name);
        }
        // Apply filter by pincode if selected from pincodeOptions
        if (formData?.pincode) {
            filtered = filtered?.filter((customer) => customer?.postal_code === formData?.pincode);
        }
        // Apply filter by industry type if selected from industryTypeOptions
        if (formData?.industry_type) {
            filtered = filtered?.filter((customer) => customer?.industry_type === formData?.industry_type);
        }
        setFilteredCustomers(filtered);
        setFormData({
            pincode: '',
            industry_type: '',
            city: '',
        });
        setSearchTerm('');
        setShowFilter(false);
    };

    const handleCancel = () => {
        setFormData({
            customer_name: '',
            pincode: '',
            industry_type: '',
            city: ''
        });
        setFilteredCustomers(customersList);
        setShowFilter(false);
    };

    return (
        <Modal show={true} centered className='customer-modal-dialog' size={"lg"}>
            <Scrollbar style={{ height: "calc(100vh - 62px)" }}>
                <Modal.Body className='modal-body customer-modal' >
                    <div className='header-container d-flex justify-content-between align-items-center'>
                        <h6 className='header-title fs-20 '>{t("Add Customer")}</h6>
                        <div className='header-button-save-close'>
                            <Button onClick={handleClose} variant={"primary save-appointment-btn "} size={'md'}>{t("Save")}</Button>
                            <Icon onClick={CloseData} className={"ms-2 close-appointment-btn"} image attrIcon={App_url.img.RedCrossIcon} />
                        </div>
                    </div>
                    <div>
                        <div className='' style={{ position: 'relative' }}>
                            <InputGroup
                                placeholder={t("Search by customer name, last name")}
                                formClassName={"mt-3 col-12 invitee-input-box"}
                                name={"searchTerm"}
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                isSearchable
                                type={'search'}
                                leftLabel={searchTerm === "" ? <Icon className={props?.size} attrIcon={App_url.img.SearchIcon} /> : null}
                                rightLabel={searchTerm !== "" ? <Icon className={props?.size} attrIcon={App_url.img.SearchIcon} /> : null}
                                rightLabel1={<Icon className={""} onClick={handleIconClick} image attrIcon={App_url.img.FilterIcon} />}
                            />
                            <div>
                                {showFilter && (

                                    <div className={`filter-dropdown`} >
                                        <h6 className='fs-20'>{t("Filter")}</h6>
                                        <InputGroup
                                            placeholder={t("Select City")}
                                            formClassName=" mb-2"
                                            name="city_name"
                                            option={citiesOptions}
                                            value={formData?.city_name}
                                            onChange={handleInputChange}
                                            select
                                            type={'select'}
                                            leftLabel={<Icon className={""} attrIcon={App_url.img.CityIcon} />}
                                        />
                                        <InputGroup
                                            placeholder={t("Select Postcode")}
                                            formClassName=" mb-2"
                                            name="pincode"
                                            option={pincodeOptions}
                                            value={formData?.pincode}
                                            onChange={handleInputChange}
                                            select
                                            type={'select'}
                                            leftLabel={<Icon className={""} attrIcon={App_url.img.PostcodeIcon} />}
                                        />
                                        <InputGroup
                                            placeholder={t("Select Industry")}
                                            formClassName=" mb-2"
                                            name="industry_type"
                                            option={industryTypeOptions}
                                            value={formData?.industry_type}
                                            onChange={handleInputChange}
                                            select
                                            type={'select'}
                                            leftLabel={<Icon className={""} attrIcon={App_url.img.IndustryIcon} />}
                                        />
                                        {/* <InputGroup
                                            placeholder={"All"}
                                            formClassName=" mb-2"
                                            name=""
                                            option={''}
                                            // value={options[0]?.value}
                                            onChange={onChange}
                                            select
                                            type={'select'}
                                            leftLabel={<Icon className={""} attrIcon={App_url.img.UserIcon} />}
                                        /> */}
                                        <div className='d-flex gap-1'>
                                            <Button onClick={handleCancel} className={' col-6'} variant={'outline-primary'}>{t("Cancel")}</Button>
                                            <Button onClick={applyFilters} className={'col-6'} variant={'primary'}>{t("Apply")}</Button>
                                        </div>
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                    {/* Selected Customer */}
                    <div className='mt-2 invitee-selected-value-box'>
                        {customer?.length > 0 && customer?.map((item) => (
                            <h6 className='invitee-selected-value-title' key={item?.id}>{item?.customer_name} {item?.last_name}<Icon className={"invitee-selected-value-cross-icon"} onClick={() => handleSelectedValue(item)} image attrIcon={App_url.img.CircleCross} /></h6>
                        ))}
                    </div>

                    {/* Customer List */}
                    {/* <div>
                        {customersList?.data?.map((item) => (
                            <div className='invitee-data' key={item.id}>
                                <h6>{item?.customer_name} {item?.last_name}</h6>
                                {customer?.some(inv => inv.id === item.id) ? (
                                    <h6 className='invitee-add-btn'>-Added</h6>
                                ) : (
                                    <h6 className='invitee-add-btn' onClick={() => handleAddSelectedInvitee(item)}>+Add</h6>
                                )}
                            </div>
                        ))}
                        
                    </div> */}
                    <div>
                        {filteredCustomers?.length > 0 ? (
                            filteredCustomers?.map((item) => (
                                <div className='invitee-data' key={item.id}>
                                    <h6>{item?.customer_name} {item?.last_name}</h6>
                                    {customer?.some(inv => inv?.id === item?.id) ? (
                                        <h6 className='invitee-add-btn'>{t("- Added")}</h6>
                                    ) : (
                                        <h6 className='invitee-add-btn' onClick={() => handleAddSelectedInvitee(item)}>{t("+Add")}</h6>
                                    )}
                                </div>
                            ))
                        ) : (
                            <p className='ms-3'>No customers found.</p>
                        )}
                    </div>
                </Modal.Body>
            </Scrollbar>
        </Modal>
    )
}
AddCustomerModal.propTypes = {
    handleClose: PropTypes?.func,
    show: PropTypes?.bool,
}
AddCustomerModal.defaultProps = {
    handleClose: () => { },
    show: false,
}