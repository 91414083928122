/* eslint-disable */
import React, { useContext, useEffect } from 'react'
import SideBar from './Sidebar'
import { Outlet } from 'react-router'
import { useDispatch, useSelector } from 'react-redux';
import App_url from '../Common/Constants';
import { WebSocketContext } from '../../WSContext';
import { setStoreMagicCode } from '../../store/modules/users_data/action';
import { PostRequestAPI } from '../../Api/api/PostRequest';
import Sidebar2 from './Sidebar2';

export default function DashboardLayout() {

  const { device_id, access_token, magic_code } = useSelector(App_url.userDataReducers)
  const { ws, send } = useContext(WebSocketContext);
  const dispatch = useDispatch()
  useEffect(() => {
    if (ws && !magic_code && access_token) {
      // getMagicCode();
    }
  }, [ws]);


  const getMagicCode = async () => {
    const payload = {
      device_type: "web",
      device_id: device_id
    }
    const response = await PostRequestAPI(App_url.api.GET_MAGIC_CODE, payload, access_token);
    if (response?.status == 200) {
      dispatch(setStoreMagicCode(response?.data?.data));
      const URL = `${process.env.API_URL}/${App_url.api.USER_SET_LOGIN}/${response?.data?.data}/${device_id}/web/${window.location.hostname}`
      window.location.replace(URL);
      console.log("URL", URL);

    } else {
      AuthenticateResponse(response)
    }
  }
  return (
    <section className='main-section'>
      <SideBar />
      <aside className='content-outlet'>
        {/* <Sidebar2/> */}
        <Outlet />
      </aside>
    </section>
  )
}
