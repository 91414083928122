/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../../translate';
import CustomTableTwo from '../Common/CustomTableTwo';
import ContentLayout from '../layout/ContentLayout';
import App_url from '../Common/Constants';
import InputGroup from '../Common/InputGroup';
import Icon from '../Common/Icon';
import { WebSocketContext } from '../../WSContext';
import { useSelector } from 'react-redux';

export default function EmployersPage() {
  const { t, changeLanguage } = useTranslation();
  const navigate = useNavigate();
  const { ws, send } = useContext(WebSocketContext);
  const { inviteesUserList } = useSelector(App_url.customerReducers);
  const [isActive, setActive] = useState(false);
  const [filter, setFilter] = useState({
    search: ""
  })

  const tableData = [
    { name: "Anuj Grover", emp_id: "#HGHFFG12F", dept: "Cleaning", designation: "Office", email: "Anuj@gmail.com", phone_no: "+49 15 1234 5678 ", status: "active" }
  ]
  const callBack = (e, state) => {
    if (state === "AddButton") {
      navigate(App_url.link.createEmployee)
    }
  }
  const handleIsActive = (e) => {
    setActive(!isActive);
  }
  const callUserListApi = (page) => {
    const param = {
      transmit: "broadcast",
      url: "user_list",
      request: {
        status: "",
        limit: "20",
        page_no: page,
        search: "",
        order_by: "-updated_at",
        end_time: "",
        start_time: "",

      }
    };
    send(param);
  };
  useEffect(() => {
    if (ws) {
      callUserListApi(1);
    }
  }, [ws])
  
  const handleEditEmployee = (empId,empData) => {
    // console.log(empData)
    navigate(`${App_url.link.createEmployee}/${empId}`, { state: empData })

}
  const RenderBody = (item) => {
    return (
      <React.Fragment>
        <td>{item?.first_name ?  `${item?.first_name} ${item?.last_name}` : "-" }</td>
        <td>{item?.emp_code ? item?.emp_code : "-"}</td>
        <td>{item?.department === "sale_employee" ? "Sales":"Admin"}</td>
        <td>{item?.designation === "sale_employee" ? "Sales Executive":"Admin"}</td>
        <td>{item?.email}</td>
        <td>{item?.mobile}</td>
        <td><InputGroup type="switch" name={"status"} checked={item?.is_active} onCheck={(e) => handleIsActive(e)} /></td>
        <td className='d-flex gap-2'>
          <span className='view-icon-box'><Icon className={"bg-primary"} onClick={() => handleEditEmployee(item?.id, item)} attrIcon={App_url.img.EyeIcon} /></span>
        </td>
      </React.Fragment>
    )
  }


  return (
    <ContentLayout title={t("Customers Page")}>
      <div>
        <CustomTableTwo
          title={{ text: t("Employee List"), textTwo: `${t("Total Employee List")} ${inviteesUserList?.data?.length || 0}` }}
          AddButton={{ title: t("+Add Employee"), variant: "outline-primary", size: "sm" }}
          columnNames={[t('Name'), t("Employee ID"), t("Department"), t("Designation"), t("Email"), t("Mobile"), t("Active"), t("View")]}
          recordData={inviteesUserList?.data}
          renderBody={RenderBody}
          callBack={callBack}
          filter={filter}
          search_placeholder={t("Search offer")}
          typePlaceHolder={t("Select Status")}
          pagination={inviteesUserList?.pagination}
          onPagination={(page) => callUserListApi(page)}
          style={{ height: "182px" }}
        />
      </div>
    </ContentLayout>
  )
}
