/* eslint-disable */
import React from 'react'
import { Table } from 'react-bootstrap';
import Utils from '../../Common/Utils';
import { useTranslation } from '../../../translate';
import App_url from '../../Common/Constants';

export default function CalculationPreviewTable ({ formData }) {
    console.log("formData===", formData)
    const { t, changeLanguage } = useTranslation();
    return (
        <Table bordered>
            <thead>
                <tr>
                    <th className="top-left-radius">{t("Area Name")}</th>
                    <th>{t("Area in %")}</th>
                    <th>{t("Area M2")}</th>
                    <th>{t("Weekday")}</th>
                    <th>{t("Sunday")}</th>
                    <th>{t("M2/Hour")}</th>
                    <th className='text-center'>{t("Monthly Cost")}</th>
                    <th className="top-right-radius text-center">{t("Yearly Cost")}</th>
                </tr>
            </thead>
            <tbody className='table-with-radius'>
                {formData?.division_array?.map((row, index) => (
                    <tr key={index}>
                        <td>{t(row.area_name)}</td>
                        <td>{Utils.trimDecimal(row.area_percentage, 2)} %</td>
                        <td>{Utils.trimDecimal(row.surface_area, 2)}</td>
                        <td>{row.weekly_weekday_count}</td>
                        <td>{row.weekly_sunday_count}</td>
                        <td>{Utils.trimDecimal(row.monthly_hours, 2)}</td>
                        <td className='bg-clr text-center'>{App_url.currencySymbol.euro} {Utils.trimDecimal(row.monthly_cost, 2)}</td>
                        <td className='bg-clr text-center'>{App_url.currencySymbol.euro} {Utils.trimDecimal(row.yearly_cost, 2)}</td>
                    </tr>
                ))}
                <tr className='border-t'>
                    <th>{" "}</th>
                    <th>{" "}</th>
                    <th>{Utils.trimDecimal(formData?.total_surface_area, 2)}</th>
                    <th>{" "}</th>
                    <th>{" "}</th>
                    <th>{t("Total Cost")} :</th>
                    <th className='text-center'>{App_url.currencySymbol.euro} {Utils.trimDecimal(formData?.total_monthly_cost, 2)}</th>
                    <th className='text-center'>{App_url.currencySymbol.euro} {Utils.trimDecimal(formData?.total_yearly_cost, 2)}</th>
                </tr>
            </tbody>
        </Table>
    );
};
