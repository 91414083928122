/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import AnchorLink from "./AnchorLink";

function PaginationPage(props) {
  let active = parseFloat(props?.pagination?.current_page);
  const length = Math.ceil(parseFloat(props?.pagination?.total_records) / parseFloat(props?.pagination?.record_limit));
  let items = [];
  
  const Pagination = (item) => {
    return (
      <li className="page-item">
        <AnchorLink onClick={item?.onClick} className="page-link">
          <span aria-hidden="true">{item?.data}</span>
        </AnchorLink>
      </li>
    )
  }

  const PaginationItem = (item) => {
    return (
      <li className={`page-item ${item?.number === active ? "active" : ""}`}>
        <AnchorLink onClick={item?.onClick} className="page-link">
          {item?.children}
        </AnchorLink>
      </li>
    )
  }

  const startPage = Math.max(1, active - 2);
  const endPage = Math.min(length, active + 2);
  
  for (let number = startPage; number <= endPage; number++) {
    items.push(
      <PaginationItem number={number} onClick={function(e){
        e.preventDefault();
        e.stopPropagation()
        if (number === active) {
          return null;
        } else {
          props?.onChange(number);
        }
      }} key={number} active={number === active}>
        {number}
      </PaginationItem>,
    );
  }

  if (parseFloat(props?.pagination?.total_records) <= 0 || props?.pagination?.total_records === undefined) {
    return (
      <React.Fragment>
      </React.Fragment>
    )
  } else {
    return (
      <div className="CommonPagination">
        <div className="pagination">
          <Pagination
            onClick={function() {
              if (active === 1) {
                return null;
              } else {
                props?.onChange(parseInt(active) - 1)
              }
            }}
            data={"‹"}
          />
          {items}
          <Pagination
            onClick={function() {
              if (active === length) {
                return null;
              } else {
                props?.onChange(parseInt(active) + 1)
              }
            }}
            data={"›"}
          />
        </div>
      </div>
    );
  }
}

PaginationPage.propTypes = {
  onChange: PropTypes.func,
  pagination: PropTypes.any,
}

PaginationPage.defaultProps = {
  onChange: () => {},
  pagination: {
    current_page: "",
    total_records: 0,
    record_limit: 0
  }
}

export default PaginationPage;
