/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Card, Modal, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { WebSocketContext } from '../../WSContext';
import App_url from '../Common/Constants';
import Icon from '../Common/Icon';
import InputGroup from '../Common/InputGroup';
import Button from '../Common/Button';
import Scrollbar from '../Common/Scrollbar';
import PaginationPage from '../Common/PaginationPage';
import { setStoreTemplateModal } from '../../store/modules/modals/action';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../../translate';
import Utils from '../Common/Utils';



const TemplatesModal = (props) => {
    const { t, changeLanguage } = useTranslation();

    const dispatch = useDispatch()
    const { ws, send } = useContext(WebSocketContext);
    const { device_id, access_token } = useSelector(App_url.userDataReducers);
    const { customerDetails } = useSelector(App_url.customerReducers);
    const { documentationList } = useSelector(App_url.documentationReducers);
    // console.log("documentationList", documentationList);
    const navigate = useNavigate()
    const [date, setDate] = useState(new Date());
    const [search, setSearch] = useState(props?.filter?.search);
    const { ModalPopupTwo } = useSelector(App_url.modalReducers);
    const [selectedData, setSelectedData] = useState([]);
    const [tempDocList, setTempDocList] = useState([])
    // console.log("documentationList", documentationList)

    useEffect(() => {
        if (documentationList?.data) {
            setTempDocList(documentationList?.data || []);
        }
    }, [documentationList])

    const [filter, setFilter] = useState({
        file_type: "",
        document_type: "",
        limit: "",
    })

    const onChange = (e) => {
        if (e.target.name === "file_type" && e.target.value === null) {
            setTempDocList(documentationList?.data || []);
            setSelectedData([]);
        }
        setFilter((data) => ({
            ...data,
            [e.target.name]: e.target.value,
        }))
    }


    useEffect(() => {
        if (filter?.file_type) {
            const docList = documentationList?.data?.filter(item => item?.document_type === filter.file_type);
            setTempDocList(docList)
        }
    }, [filter?.file_type, filter])




    const handleClose = async (data) => {
        if (ModalPopupTwo?.callBackModal) {
            await ModalPopupTwo?.callBackModal(data);
        }
        CloseData()
    };
    const CloseData = () => {
        dispatch(setStoreTemplateModal())
    }

    const onChangeFilter = (e) => {
        if (e.target.name === "search") {
            setSearch(e.target.value)
        } else {
            props?.setFilter((data) => ({
                ...data,
                [e.target.name]: e.target.value
            }));
        }
    }
    const callSearch = (data) => {
        setSearch(data);
    }


    const iconMapping = {
        pdf: App_url.img.PdfIcon2,
        excel: App_url.img.ExcelIcon,
        word: App_url.img.WordIcon,
        png: App_url.img.GalleryIcon2,
        jpg: App_url.img.GalleryIcon2,
        jpeg: App_url.img.GalleryIcon2,
        svg: App_url.img.GalleryIcon2,
        MOV: App_url.img.TEXTIcon,
        rtf: App_url.img.TEXTIcon,
        pptx: App_url.img.PPTIcon,
        pages: App_url.img.TEXTIcon,
        numbers: App_url.img.TEXTIcon,

    };
    const getIcon = (docType) => {
        return iconMapping[docType] || App_url.img.TEXTIcon;
    };

    const fileTypesOptions = Array.from(new Set(documentationList?.data?.map(item => item.document_type)))
        .map(document_type => {
            return { label: document_type, value: document_type };
        });


    const handleSelectedData = (item) => {
        if (selectedData?.includes(item)) {
            setSelectedData(selectedData?.filter(sl => sl != item))
        } else {
            setSelectedData([...selectedData, item]);
        }
    }

    const handleSelectAll = (e) => {
        console.log(e.target.checked);
        if (e.target.checked) {
            setSelectedData(tempDocList);
        } else {
            setSelectedData([]);
        }
    }
    const handleShareViaMail = () => {
        event.preventDefault();
        // console.log("SelectedData", selectedData)
        if (selectedData?.length > 0) {
            handleClose(selectedData)
            // dispatch(setStoreTemplateModal())
            // navigate(`${App_url.link.createPromotion}`, { state: selectedData })
        }

    }

    if (ModalPopupTwo?.show !== "TEMPLATE_MODAL") {
        return null;
    }

    return (
        <div>
            <Modal show={true} onHide={handleClose} backdrop="static" size='lg' className='sharedoc'>
                <Modal.Header className='docheader'>
                    <div className='header'>
                        <div>
                            <h6 className='title'>{t("Templates")}</h6>
                        </div>
                        <div className='subheader'>
                            <h6 className=''>{t("Total Template")} {tempDocList?.length}</h6>
                            <Icon onClick={handleClose} className={"ms-2 close-appointment-btn"} image attrIcon={App_url.img.RedCrossIcon} />
                        </div>
                    </div>
                    <div className='searchable d-flex gap-2'>
                        <InputGroup
                            size={"md"}
                            onChange={onChangeFilter}
                            name={"search"}
                            leftLabel={search === "" ? <Icon className={props?.size} attrIcon={App_url.img.SearchIcon} /> : null}
                            rightLabel={search !== "" ? <Icon className={props?.size} attrIcon={App_url.img.CloseIcon} /> : null}
                            rightLabel1={search !== "" ? <Icon className={props?.size} attrIcon={App_url.img.SearchIcon} /> : null}
                            onSubmit={(data) => callSearch(data)}
                            rightIconClick1={() => callSearch(search)}
                            rightIconClick={() => callSearch("")}
                            placeholder={t("Search customer")}
                            value={search}
                            formClassName={"docsearch col-10"}
                        />
                        <InputGroup
                            placeholder={t("All File Types")}
                            name={"file_type"}
                            value={filter?.file_type}
                            error={""}
                            onChange={onChange}
                            type="select"
                            select
                            option={fileTypesOptions}
                            isClearable={true}
                            className='docselect col-2'
                        />
                    </div>
                </Modal.Header>
                <Modal.Body className=''>
                    <div className='bodyheader'>
                        <h6>{t("Template Name")}</h6>
                        <Button onClick={(e) => handleShareViaMail(e, selectedData)} variant="primary" size={"sm"} className='col-2 '>
                            {t("Share Via Mail")}
                        </Button>
                    </div>
                    <div className='custom-table-three'>
                        <Scrollbar style={{ height: "calc(100vh - 290px)" }}>
                            <div className='scrollbar-padding'>
                                <Card>
                                    <Table  >
                                        <thead className=''>
                                            <tr>
                                                <th>{t("Name")}</th>
                                                <th>{t("File type")}</th>
                                                <th >
                                                    {t("Select All")}
                                                    <input
                                                        type="checkbox"
                                                        className="custom-checkbox ms-3"
                                                        checked={selectedData?.length > 0 && tempDocList?.length === selectedData?.length}
                                                        onChange={handleSelectAll}
                                                    />

                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className='table-with-radius'>
                                            {tempDocList?.map((item, index) => (
                                                <tr key={index}>
                                                    <td >{Utils.formatOneLineEllipse(item.document_name)}</td>
                                                    <td className='item2 document-type '><Icon className={"me-2"} attrIcon={getIcon(item?.document_type)} image />{item?.document_type}</td>
                                                    <td className='text-end'>
                                                        <input
                                                            type="checkbox"
                                                            className="custom-checkbox"
                                                            checked={selectedData.includes(item)}
                                                            onChange={() => handleSelectedData(item)}
                                                        />

                                                    </td>
                                                </tr>

                                            ))}
                                        </tbody>
                                    </Table>
                                    <PaginationPage pagination={props?.pagination} onChange={props?.onPagination} />
                                </Card>
                            </div>
                        </Scrollbar>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}
export default TemplatesModal;