/* eslint-disable */
export const ActionTypes ={
    SET_STORE_DOCUMENTATION_LIST:"SET_STORE_DOCUMENTATION_LIST",
    SET_STORE_DOCUMENT_INSIDE_FOLDER_LIST:"SET_STORE_DOCUMENT_INSIDE_FOLDER_LIST",
}

export const setStoreDocumentationsList = (token) => {
    return {
        type: ActionTypes.SET_STORE_DOCUMENTATION_LIST,
        payload: token,
     }
}
export const setStoreDocumentInsideFolderList = (token) => {
    return {
        type: ActionTypes.SET_STORE_DOCUMENT_INSIDE_FOLDER_LIST,
        payload: token,
     }
}
