export const DeleteRequestCallAPI = async (apiurl, access_token, body) => {
  const headers = {
    'Accept': "application/json",
  };

  if (access_token) {
    headers.Authorization = `Bearer ${access_token}`;
  }

  

  const options = {
    headers: headers,
  };

  if (body) {
    options.data = body; // Add the body to the options object
  }

  const getResponse = window.axios.delete(`${process.env.REACT_APP_API_DOMAIN_URL}/${apiurl}`, options)
    .then(result => result)
    .catch(e => e.response);

  return getResponse;
};
