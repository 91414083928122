/* eslint-disable */
// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";
import { toast } from "react-toastify";
import Icon from "./Icon";
import App_url from "./Constants";
import { setStoreCurrentUser } from "../../store/modules/users_data/action";
import { useSelector } from "react-redux";
import store from '../../store/reduxStore';



export const getLanguageSetting = () => {
  const state = store.getState() // Direct access to store state
  return state.settingReducers.lang; // Access `lang` property from `settingReducers`
};

/* eslint-disable eqeqeq */
export function uuidv4() { // Public Domain/MIT
  let d = new Date().getTime();//Timestamp
  let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16;//random number between 0 and 16
    if (d > 0) {//Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {//Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
}

export function getCurrentURL(context) {
  const { req } = context;

  const host = req?.headers?.host;
  const protocol = req?.headers['x-forwarded-proto'] || 'http';
  return `${protocol}://${host}`;
}
function checkEmail(emailAddress) {
  return String(emailAddress)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}
function ValidateEmail(value, error1, error2) {
  const emailRegex = checkEmail(value)
  if (emailRegex) {
    return false;
  } else {
    if (value == "") {
      return error1 || "Please enter email address";
    }
    return error2 || 'Invalid email address!';
  }
}
const checkPasswordValidity = (value) => {
  const isNonWhiteSpace = /^\S*$/;
  if (!isNonWhiteSpace.test(value)) {
    return "Password must not contain Whitespaces.";
  }

  const isContainsUppercase = /^(?=.*[A-Z]).*$/;
  if (!isContainsUppercase.test(value)) {
    return "Password must have at least one Uppercase Character.";
  }

  const isContainsLowercase = /^(?=.*[a-z]).*$/;
  if (!isContainsLowercase.test(value)) {
    return "Password must have at least one Lowercase Character.";
  }

  const isContainsNumber = /^(?=.*[0-9]).*$/;
  if (!isContainsNumber.test(value)) {
    return "Password must contain at least one Digit.";
  }

  const isContainsSymbol =
    /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).*$/;
  if (!isContainsSymbol.test(value)) {
    return "Password must contain at least one Special Symbol.";
  }

  const isValidLength = /^.{8,16}$/;
  if (!isValidLength.test(value)) {
    return "Password must be 8-16 Characters Long.";
  }
  return null;
}

export const AuthenticateResponse = (response, dispatch, state, dataReturn) => {
  // console.log("response", response);
  const errorData = {};

  if (response?.status === 400) {
    toast.error(response?.data?.message || response?.data?.error);
  } else if (response?.status === 403) {
    toast.error(response?.data?.error || response?.data?.detail);

  } else if (response?.status === 401) {
    toast.error(response?.data?.message || response?.data?.error || "Unauthorized Access")
    dispatch(setStoreCurrentUser());
  } else if (response?.status === 404) {
    toast.error(response?.data?.message || response?.data?.error);
  }

  if (dataReturn) {
    return errorData;
  }
};

const validateMobileNumber = (number) => {
  const regex = /^[0-9]{10}$/;
  if (!regex.test(number)) {
    return "Please enter a valid 10-digit mobile number."
  } else {
    return null;
  }
};

// For showing schedule time in schedule call list

function formatDateWithDash(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // months are 0-indexed
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

function formatDateTime(dateStr = '', timeStr = '', t) {
  if (!dateStr || !timeStr) {
    return 'Invalid date or time';
  }
  const [year, month, day] = dateStr.split('-').map(Number);
  const [hours, minutes] = timeStr.split(':').map(Number);

  const date = new Date(year, month - 1, day, hours, minutes);

  const dayOfMonth = date.getDate();
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const monthName = t(monthNames[date.getMonth()]);
  const yearNumber = date.getFullYear();

  let hours12 = date.getHours() % 12 || 12; // Convert to 12-hour format
  const minutesFormatted = String(date.getMinutes()).padStart(2, '0');
  const ampm = date.getHours() >= 12 ? 'PM' : 'AM';

  // Combine into final string
  return `${dayOfMonth} ${monthName} ${yearNumber}, ${hours12}:${minutesFormatted} ${ampm}`;
}
// utils.js

export function formatDateTime2(dateString, t) {
  if (!dateString) {
    return 'Invalid date or time';
  }

  // Parse date and time
  const date = new Date(dateString);

  const day = date.getUTCDate();
  const month = date.toLocaleString('en-US', { month: 'short', timeZone: 'UTC' });
  const year = date.getUTCFullYear();

  let hours = date.getUTCHours();
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  // Translate the month
  const translatedMonth = t(month);
  // console.log("translatedMonth",translatedMonth)

  return `${day} ${translatedMonth} ${year}, ${hours}:${minutes} ${ampm}`;
}

function formatDateRange(isoString, t) {

  if (!isoString) {
    return 'Invalid date or time';
  }

  const date = new Date(isoString);
  // Format date
  const dayOfWeekNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const dayOfWeek = t(dayOfWeekNames[date.getDay()]);
  const dayOfMonth = t(date.getDate());
  const monthName = t(monthNames[date.getMonth()]);


  // Combine into final string without year
  return `${dayOfWeek}, ${monthName} ${dayOfMonth}`;

}
function formatDate(dateString) {
  if (!dateString) {
    return 'NA';
  }
  const date = new Date(dateString);
  const options = { day: 'numeric', month: 'short', year: 'numeric' };
  return date.toLocaleDateString('en-GB', options);
}
function formatTime(timeString) {
  // Create a Date object from the time string
  const date = new Date(`1970-01-01T${timeString}Z`); // Using 'Z' to avoid timezone issues

  // Extract hours and minutes
  let hours = date.getUTCHours();
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');

  // Determine AM or PM
  const period = hours >= 12 ? 'PM' : 'AM';

  // Convert to 12-hour format
  hours = hours % 12 || 12;

  // Construct the final time string
  return `${hours}:${minutes}${period}`;
}
function formatDateTimeCreatedAt(dateStr) {
  const date = new Date(dateStr);

  const options = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };

  // Get the formatted date string
  let formattedDate = date.toLocaleString('en-GB', options).replace(',', '');

  // Convert 'am/pm' to 'AM/PM'
  return formattedDate.replace(/am|pm/g, match => match.toUpperCase());
}
// function formatDateCreatedAt(dateStr) {
//   const date = new Date(dateStr);

//   const options = {
//     day: '2-digit',
//     month: 'short',
//     year: 'numeric',
//     // hour: '2-digit',
//     // minute: '2-digit',
//     // hour12: true,
//   };

//   // Get the formatted date string
//   let formattedDate = date.toLocaleString('en-GB', options).replace(',', '');

//   // Convert 'am/pm' to 'AM/PM'
//   return formattedDate.replace(/am|pm/g, match => match.toUpperCase());
// }
export function formatDateCreatedAt2(dateStr, t) {
  // Create a new date object from the input string
  const date = new Date(dateStr);

  // Check for valid date
  if (isNaN(date)) {
    return 'Invalid date';
  }

  // Get components of the date
  const dayOfMonth = date.getUTCDate().toString().padStart(2, '0'); // Format day as 2 digits
  const monthIndex = date.getUTCMonth(); // Get the month index (0-11)
  const yearNumber = date.getUTCFullYear(); // Get the year

  // Use the translation function to get the month name based on language
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const monthName = t(monthNames[monthIndex]);

  // Construct the formatted date string without time
  const formattedDate = `${dayOfMonth} ${monthName} ${yearNumber}`;

  return formattedDate;
}
// utils.js



function formatFullMonthDate(dateString) {
  if (!dateString) {
    return 'NA';
  }
  const date = new Date(dateString);
  const options = { day: 'numeric', month: 'long', year: 'numeric' };
  return date.toLocaleDateString('en-GB', options);
}
function formatTimeMinutes(utcTime) {
  const date = new Date(utcTime);

  // Get today's date without time
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  // Get the local time string in the format of 'hh:mm AM/PM'
  const options = { hour: 'numeric', minute: 'numeric', hour12: true };
  const localTimeString = date.toLocaleTimeString('en-US', options);

  // Get the difference in minutes between the current time and the provided time
  const now = new Date();
  const timeDiffMinutes = Math.floor((now - date) / 60000);

  // Calculate hours and minutes
  const hours = Math.floor(timeDiffMinutes / 60);
  const minutes = timeDiffMinutes % 60;

  // Format the difference as 'X hr Y min ago'
  let timeAgoString = '';
  if (hours > 0) {
    timeAgoString += `${hours} hr${hours > 1 ? 's' : ''}`;
    if (minutes > 0) {
      timeAgoString += ` ${minutes} min`;
    }
    timeAgoString += ' ago';
  } else {
    timeAgoString = `${minutes} min ago`;
  }

  // If the date is today, return time with time ago
  if (date >= today) {
    return `${localTimeString} (${timeAgoString})`;
  } else {
    // If the date is not today, return the date in 'dd MMM yyyy' format
    const dateOptions = { day: '2-digit', month: 'short', year: 'numeric' };
    const localDateString = date.toLocaleDateString('en-GB', dateOptions).replace(',', '');
    return `${localDateString}`;
  }
}
function convertToISOString(dateString) {
  // Create a new Date object from the input date string
  const date = new Date(dateString);

  // Convert the date to ISO 8601 format and return it
  return date.toISOString();
}
function convertToDateOnly(dateString) {
  // Create a new Date object from the input date string
  const date = new Date(dateString);

  // Extract the year, month, and day from the Date object
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');

  // Return the formatted date in YYYY-MM-DD format
  return `${year}-${month}-${day}`;
}
function ensureISOString(date) {
  // Convert the date to ISO string
  const isoString = date.toISOString();

  // Convert the date to a local time string and compare it with the ISO string
  if (date.toString() === new Date(isoString).toString()) {
    return isoString; // Already in ISO format
  } else {
    // If the date is not in ISO format, convert it to ISO string
    return new Date(date).toISOString();
  }
}

// new appointment dateTime conversions
function formatOnlyDate(dateString) {
  // this: Thu Sep 05 2024 18:48:33 GMT+0530 (India Standard Time)
  // to this: 2024-09-05

  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}
function formatOnlyTime(dateString) {
  // this: Thu Sep 05 2024 18:48:33 GMT+0530 (India Standard Time)
  // to this: 18:48:33

  const date = new Date(dateString);
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  return `${hours}:${minutes}:${seconds}`;
}
function formatToISOLocal(dateString) {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
}
function concatDateTime(date, time) {
  return `${date}T${time}Z`;
}
function extractTime(dateString) {
  return dateString.split('T')[1].split('Z')[0];
}
function extractDate(dateString) {
  return dateString.split('T')[0];
}
function getOnlyMonthFromDate(dateString) {
  // "Fri Sep 06 2024 12:18:13 GMT+0530 (India Standard Time)";
  // Output: 9 (for September)

  const date = new Date(dateString);
  const month = date.getMonth() + 1; // Adding 1 because getMonth() returns a zero-based index
  return month;
}
function getOnlyYearFromDate(dateString) {
  // "Fri Sep 06 2024 12:18:13 GMT+0530 (India Standard Time)";
  // Output: 2024
  const date = new Date(dateString);
  const year = date.getFullYear();
  return year;
}
function formatTimeAmToPM(startISO, endISO) {
  const startDate = new Date(startISO);
  const endDate = new Date(endISO);

  const formatTime = (date) => {
    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // If hours are 0, set to 12
    const minutesStr = minutes < 10 ? `0${minutes}` : minutes;
    return `${hours}:${minutesStr} ${ampm}`;
  };

  const startTime = formatTime(startDate);
  const endTime = formatTime(endDate);

  return `${startTime} To ${endTime}`;
}


// view appointment
function formatISOToFullDayMonthTime(dateString, t) {
  // This converts "2024-09-01T20:34:00Z" to "Tuesday, July 10, 9:00 AM"

  const date = new Date(dateString);

  const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const dayOfWeek = t(daysOfWeek[date.getUTCDay()]);
  // console.log("dayOfWeek", dayOfWeek)
  const month = date.toLocaleString('en-US', { month: 'short', timeZone: 'UTC' });
  const day = date.getUTCDate();

  let hours = date.getUTCHours();
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  return `${dayOfWeek}, ${month} ${day}, ${hours}:${minutes} ${ampm}`;
}


const formatNameSurnameFirstLetter = (item) => {
  const nameParts = item?.split(' ');
  const formattedParts = nameParts?.map(part => part?.charAt(0)?.toUpperCase());
  return formattedParts?.join('');
}

const getIconsByExtension = (attachment) => {
  const extension = attachment?.split('.').pop();
  // console.log("attachment", extension);
  if (extension === "pdf") {
    return (
      <Icon attrIcon={App_url.img.PdfIcon2} image className='sm me-1 ms-1 d-flex-center' />
    )
  } else if (extension === "jpg" || extension === "jpeg" || extension === "png") {
    return (
      <Icon attrIcon={App_url.img.DocIcon} image className='sm me-1 ms-1 d-flex-center' />
    )
  } else if (extension === "xlsx" || extension === "csv") {
    return (
      <Icon attrIcon={App_url.img.ExcelIcon} image className='sm me-1 ms-1 d-flex-center' />
    )
  } else {
    return (
      <Icon attrIcon={App_url.img.TEXTIcon} image className='sm me-1 ms-1 d-flex-center' />
    )
  }
}
function convertDotToComma(number) {

  // Convert number to a string and replace '.' with ','
  return number?.toString()?.replace('.', ',');
}
// calculations 
function trimDecimal2(value, decimalPlaces,) {
  const lang = getLanguageSetting();
  console.log("lang", lang)
  if (value) {
    // Convert to string and split at the decimal point
    let [integerPart, decimalPart] = value.toString().split(".");

    // Case 1: If there's no decimal part, add `.00`
    if (!decimalPart) {
      return `${integerPart}.${'00'}`;
    }

    // Case 2: If there's a decimal part, trim or pad it to 4 places
    decimalPart = decimalPart.substring(0, decimalPlaces).padEnd(decimalPlaces, '0');

    if (lang === "de") {
      const result = convertDotToComma(`${integerPart}.${decimalPart}`);
      console.log("result", result)
      return result
    } else {
      // Recombine the integer and decimal parts
      return `${integerPart}.${decimalPart}`;
    }
  } else {
    return 0;
  }
}
function trimDecimal(value, decimalPlaces) {
  // console.log("weekday_hourly_rate===", value)
  const lang = getLanguageSetting();

  if (value !== undefined && value !== null) {
    // Format the number to specified decimal places
    let formattedValue = parseFloat(value)
      .toFixed(decimalPlaces) // Fix to desired decimal places
      .toString();

    if (lang === "de") {
      // Replace the decimal point with a comma for German format
      return formattedValue.replace(".", ",");
    } else {
      // For English format, return the formatted value as is
      return formattedValue;
    }
  } else {
    // Return '0,00' or '0.00' based on language if value is falsy
    return lang === "de" ? '0,00' : '0.00';
  }
}
function trimDecimalToFour(value, decimalPlaces) {
  const lang = getLanguageSetting();

  if (value !== undefined && value !== null) {
    // Format the number to 4 decimal places
    let formattedValue = parseFloat(value)
      .toFixed(decimalPlaces) // Fix to 4 decimal places
      .toString();

    if (lang === "de") {
      // Replace the decimal point with a comma for German format
      return formattedValue.replace(".", ",");
    } else {
      // For English format, return the formatted value as is
      return formattedValue;
    }
  } else {
    // Return '0,0000' or '0.0000' based on language if value is falsy
    return lang === "de" ? '0,0000' : '0.0000';
  }
}

// getDocIcon
const getIcon = (document_type) => {
  const iconMapping = {
    pdf: App_url.img.PdfIcon2,
    excel: App_url.img.ExcelIcon,
    xls: App_url.img.ExcelIcon,
    word: App_url.img.WordIcon,
    png: App_url.img.GalleryIcon2,
    jpg: App_url.img.GalleryIcon2,
    jpeg: App_url.img.GalleryIcon2,
    svg: App_url.img.GalleryIcon2,
    MOV: App_url.img.TEXTIcon,
    rtf: App_url.img.TEXTIcon,
    pptx: App_url.img.PPTIcon,
    pages: App_url.img.TEXTIcon,
    numbers: App_url.img.TEXTIcon,
    folder: App_url.img.FolderIcon

  };
  return iconMapping[document_type] || App_url.img.TEXTIcon;
};

//getFileSize
function getFileSize(bytes) {
  const units = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const size = (bytes / Math.pow(k, i)).toFixed(2);

  return `${size} ${units[i]}`;
}

function formatOneLineEllipse(string, len = 50) {
  // console.log("formatOneLineEllipse", string)
  const formattedString = string?.substring(0, len);
  return `${formattedString} ${string?.length >= len ? "..." : ""}`
}


const fetchDocumentWithAuthorization = async (url, access_token) => {
  const headers = new Headers();
  headers.append('Authorization', `Bearer ${access_token}`);
  const requestOptions = {
    method: 'GET',
    headers: headers,
    redirect: 'follow'
  };
  return fetch(url, requestOptions);
};

async function downloadFile(file, access_token) {
  try {
    // Replace this URL with the actual URL to fetch the attachment content
    const url = `${process.env.REACT_APP_API_DOMAIN_URL}/file/view/file/${file?.id}`;


    // Fetch the attachment content
    const response = await fetchDocumentWithAuthorization(url, access_token);


    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    // Get the content as a Blob
    const blob = await response.blob();


    // Create a URL for the Blob
    const blobUrl = URL.createObjectURL(blob);


    // Create an anchor element and trigger the download
    const a = document.createElement('a');
    a.href = blobUrl;
    const fileName = file.name || `file_${file?.id}` || "file";
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    // Clean up by removing the anchor and revoking the Blob URL
    document.body.removeChild(a);
    URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error('Download failed:', error);
  }
};



const Utils = {
  uuidv4: uuidv4,
  formatDateWithDash: formatDateWithDash,
  getCurrentURL: getCurrentURL,
  ValidateEmail: ValidateEmail,
  checkPasswordValidity: checkPasswordValidity,
  AuthenticateResponse: AuthenticateResponse,
  formatDateTime: formatDateTime,
  formatDateTime2: formatDateTime2,
  formatDateRange: formatDateRange,
  formatDate: formatDate,
  formatTime: formatTime,
  formatTimeMinutes: formatTimeMinutes,
  formatDateTimeCreatedAt: formatDateTimeCreatedAt,
  formatDateCreatedAt2: formatDateCreatedAt2,
  formatFullMonthDate: formatFullMonthDate,
  convertToISOString: convertToISOString,
  convertToDateOnly: convertToDateOnly,
  ensureISOString, ensureISOString,
  formatOnlyDate: formatOnlyDate,
  formatOnlyTime: formatOnlyTime,
  formatToISOLocal: formatToISOLocal,
  concatDateTime: concatDateTime,
  extractDate: extractDate,
  extractTime: extractTime,
  formatISOToFullDayMonthTime: formatISOToFullDayMonthTime,
  validateMobileNumber: validateMobileNumber,
  getOnlyMonthFromDate: getOnlyMonthFromDate,
  getOnlyYearFromDate: getOnlyYearFromDate,
  formatTimeAmToPM: formatTimeAmToPM,
  formatNameSurnameFirstLetter: formatNameSurnameFirstLetter,
  getIconsByExtension: getIconsByExtension,
  trimDecimal: trimDecimal,
  trimDecimalToFour: trimDecimalToFour,
  getIcon: getIcon,
  getFileSize: getFileSize,
  formatOneLineEllipse: formatOneLineEllipse,
  downloadFile: downloadFile,
}

export default Utils;