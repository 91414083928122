/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import { Card, Modal } from 'react-bootstrap'
import Button from '../Common/Button'
import Icon from '../Common/Icon'
import App_url from '../Common/Constants'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { WebSocketContext } from '../../WSContext'
import { setStoreOfferModal, setStoreValidationMessageModal, setViewDocumentModal } from '../../store/modules/modals/action'
import { GetRequestCallAPI } from '../../Api/api/GetRequest'
import { toast } from 'react-toastify'
import { PostRequestAPI } from '../../Api/api/PostRequest'
import { useTranslation } from '../../translate'

const OfferModal = () => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const { ModalPopup } = useSelector(App_url.modalReducers);
    const { ws, send } = useContext(WebSocketContext);
    const { access_token } = useSelector(App_url.userDataReducers);
    const { customerDetails } = useSelector(App_url.customerReducers);
    const [pdfFileView, setPdfFileView] = useState('');
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();


    const handleClose = async (state) => {
        if (ModalPopup?.callBackModal) {
            await ModalPopup?.callBackModal(state, ModalPopup?.data?.item);
        }
        CloseData()
    };
    // console.log("modalData", ModalPopup);

    const handleGeneratePDFAPI = async () => {
        // console.log("ModalPopup?.data", ModalPopup?.data)
        setLoader(true);
        const payload = {
            text_array: ModalPopup?.data?.offerContent || [],
            customer_id: ModalPopup?.data?.customerId || "",
            calculation_id: ModalPopup?.data?.calculationId || "",
            offer_id: ModalPopup?.data?.offerId || "",
        };
        // 
        // console.log('payload', payload
        const response = await PostRequestAPI(App_url.api.GENERATE_PDF, payload, access_token)
        setLoader(false);
        console.log("response", response);
        if (response?.status === 201 || response?.status === 200) {
            setPdfFileView(response?.data?.file_view);

        } else {
            toast.error(response?.data?.error || response.data?.detail || "error occurred ...");
        }
    }
    // console.log("ModalPopup?.data?.fromPage",ModalPopup?.data)
    useEffect(() => {
        if (ModalPopup?.show === "OFFER_MODAL") {
            handleGeneratePDFAPI();
        }
    }, [ModalPopup?.show === "OFFER_MODAL"]);


    const CloseData = () => {
        dispatch(setStoreOfferModal())
    }

    if (ModalPopup?.show !== "OFFER_MODAL") {
        return null
    }
    console.log("ModalPopup?.data....offermodal", ModalPopup?.data)
    const handleView = async () => {
       if( ModalPopup?.data?.fromPage === "createOffer" && (customerDetails?.[0]?.email === null || customerDetails?.[0]?.email === "")){
        dispatch(setStoreOfferModal());
        dispatch(setStoreValidationMessageModal({
            show: "VALIDATION_MESSAGE_MODAL",
            title: t("Validation Message"),
            description: t("The information of selected customer is incomplete, please update customer information."),
            callBackModal: () => navigate(`${App_url.link.ProspectsEdit}/${ModalPopup?.data?.customerId}`, {state:{ ...ModalPopup?.data,  pageId: ModalPopup?.data?.customerId}}),
        }));

       }else{
        console.log("comming here");
        console.log("ModalPopup?.data>>>>", ModalPopup?.data)
        const calculationId = ModalPopup?.data?.calculationId;
        const customerId = ModalPopup?.data?.customerId;
        const item = { calculationId: calculationId, customerId: customerId }
        const url = `view/file/${pdfFileView}`;
        // console.log("item", item)
        dispatch(setStoreOfferModal());
        dispatch(setViewDocumentModal({
            show: "VIEW_DOCUMENT_MODAL",
            data: { item: { ...ModalPopup?.data, document_name: "Offer Details.pdf", view_file_url: url, document_image: pdfFileView, email: ModalPopup?.data?.email } },
        }))
       }
    }




    return (
        <div>
            <Modal show={true} centered className='offermodal'>

                <Modal.Body>
                    <div className='heading  '>
                        <h6>{t("Offer Text")}</h6>
                        <div>
                            <Button disable={loader} size={'md'} onClick={() => handleView()} variant={"primary"} >{loader ? t("Please wait ...") : t("Add To Offer")}</Button>
                            <Icon onClick={handleClose} className={"ms-1 close-appointment-btn"} image attrIcon={App_url.img.RedCrossIcon} />
                        </div>
                    </div>
                    <div className='text-1'>

                        {ModalPopup?.data?.offerContent?.length > 0 && ModalPopup?.data?.offerContent?.map?.((offerDetail, index) => (
                            <Card className='mt-2'>
                                <Card.Body>
                                    <h6>{t("Text From Page")} {offerDetail?.page_number}</h6>
                                    <p>{offerDetail?.text}</p>
                                </Card.Body>
                            </Card>
                        ))}


                    </div>

                </Modal.Body>

            </Modal>
        </div>
    )
}

export default OfferModal