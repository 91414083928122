export const ActionTypes = {
    SET_DEVICE_ID:"SET_DEVICE_ID",
    SET_STORE_CURRENT_USER:"SET_STORE_CURRENT_USER",
    SET_STORE_DEVICE_ID:"SET_STORE_DEVICE_ID",
    SET_STORE_MAGIC_CODE:"SET_STORE_MAGIC_CODE",
    SET_WEBSOCKET_REQ_RES:"SET_WEBSOCKET_REQ_RES",
    SET_STORE_SELECTED_INVITEES:"SET_STORE_SELECTED_INVITEES",
    SET_STORE_USER_OUTLOOK_DETAILS:"SET_STORE_USER_OUTLOOK_DETAILS",
}

export const setStoreDeviceID = (token) => {
    return {
       type: ActionTypes.SET_STORE_DEVICE_ID,
       payload: token,
    }
};

export const setStoreCurrentUser = (token) => {
    return {
       type: ActionTypes.SET_STORE_CURRENT_USER,
       payload: token,
    }
};
export const setDeviceId = (state) =>{
    return{
        type: ActionTypes?.SET_DEVICE_ID,
        payload: state
    }
}
export const setStoreMagicCode = (token) =>{
    return {
        type:ActionTypes.SET_STORE_MAGIC_CODE,
        payload:token,
    }
}
export const setWebsocketReqRes = (token) =>{
    return {
        type: ActionTypes.SET_WEBSOCKET_REQ_RES,
        payload: token,
    }
}

export const setStoreSelectedInvitees = (token) => {
    return {
       type: ActionTypes.SET_STORE_SELECTED_INVITEES,
       payload: token,
    }
};
export const setStoreUserOutlookDetails = (token) => {
    return {
       type: ActionTypes.SET_STORE_USER_OUTLOOK_DETAILS,
       payload: token,
    }
};
export const setStoreCreateAppointment = (token) => {
    return {
       type: ActionTypes.SET_STORE_CREATE_APPOINTMENT,
       payload: token,
    }
};





