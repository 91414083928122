/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { WebSocketContext } from '../../WSContext';
import Utils from '../Common/Utils';
import Icon from '../Common/Icon';
import App_url from '../Common/Constants';
import CustomTableTwo from '../Common/CustomTableTwo';
import ContentLayout from '../layout/ContentLayout';
import { setStoreComposeMailModal, setStoreViewMailModal } from '../../store/modules/modals/action';
import { useTranslation } from '../../translate';
import UtilsGetList from '../Common/UtilsGetList';

const MailList = (props) => {
  const { t, changeLanguage } = useTranslation();

  const dispatch = useDispatch();
  const { ws, send } = useContext(WebSocketContext);
  const { device_id } = useSelector(App_url.userDataReducers);
  const { lang } = useSelector(App_url.settingReducers);
  const { emailHistoryList, sentEmailHistoryList, customerDetails } = useSelector(App_url.customerReducers);
  const { access_token } = useSelector(App_url.userDataReducers)
  const [filter, setFilter] = useState({
    search: "",
    from_date: "",
    to_date: "",
  })


  const [selectData, setSelectData] = useState([]);
  const [star, setStar] = useState(false)

  const customTabs = [
    { label: t("OutBox"), value: "send_mail" },
    { label: t("Inbox"), value: "received_mail" },
    { label: t("Draft"), value: "stared_mail" },
    { label: t("Trash"), value: "trash_mail" },
  ]
  const [activeTab, setActiveTab] = useState(customTabs[1]);

  const handleTabVisibility = (selectedLabel) => {
    setActiveTab(selectedLabel)
  }

  const handleViewMail = (item) => {
    dispatch(setStoreViewMailModal({
      show: "VIEW_MAIL_MODAL",
      data: { item: item },
    }))
  }



  const callBack = (e, state, buttonName) => {
    if (state === "AddButton2" && buttonName === "composeMail") {
      dispatch(setStoreComposeMailModal({
        show: "COMPOSE_MAIL_MODAL",
        // callBackModal: (mailData) => handleComposeMailAPI(mailData)
      }))
    }
  }
  const maxLength = 45 || "";

  const EllipsisText = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text?.substring(0, maxLength) + '...';
    }
    return text;
  };
  
  useEffect(() => {
    if (ws) {
      UtilsGetList.callEmailHistoryListApi({ ws, page: 1, filter, });
      UtilsGetList.callSentEmailHistoryListApi({ ws, page: 1, filter, });
    }
  }, [ws, filter])

  const RenderBody = (item) => {
    // console.log("item", item);
    return (
      <React.Fragment>
        <td className='text-primary' title={item?.to_recipients?.[0]?.emailAddress?.name}><u>{EllipsisText(item?.to_recipients?.[0]?.emailAddress?.name,maxLength)}</u></td>
        <td >{EllipsisText(item?.subject,maxLength)}</td>
        <td>{Utils.formatDateTimeCreatedAt(item?.sent_date_time)}</td>
        <td><span className='view-icon-box' onClick={() => handleViewMail(item)}><Icon className={"bg-primary"} attrIcon={App_url.img.EyeIcon} /></span></td>
      </React.Fragment>
    )
  }
  const RenderBody2 = (item) => {
    // console.log("item====", item);
    return (
      <React.Fragment>
        {/* <td className='w-auto'>
                    <Icon image onClick={handleStarSelected} buttonClassName={"email-star-selected-not-selected-icon"} attrIcon={star ? App_url.img.StarSelected : App_url.img.StarNotSelected} button />
                </td> */}
        <td className='text-primary ' title={item?.from_address}><u>{EllipsisText(item?.from_address,maxLength)}</u></td>
        <td >{EllipsisText(item?.subject,maxLength)}</td>
        <td>{Utils.formatDateTimeCreatedAt(item?.sent_date_time)}</td>
        {/* <td>{Utils.formatDateTime(item?.created_at)}</td> */}
        <td><span className='view-icon-box' onClick={() => handleViewMail(item)}><Icon className={"bg-primary"} attrIcon={App_url.img.EyeIcon} /></span></td>
      </React.Fragment>
    )
  }
  const RenderBody3 = (item) => {
    // console.log("item", item);
    return (
      <React.Fragment>
        <td className='w-auto'>
          <Icon image onClick={handleStarSelected} buttonClassName={"email-star-selected-not-selected-icon"} attrIcon={star ? App_url.img.StarSelected : App_url.img.StarNotSelected} button />
        </td>
        <td className='text-primary'><u>{item?.email_id}</u></td>
        <td >{EllipsisText(item?.subject,maxLength)}</td>
        <td>{item?.dateTime}</td>
        <td><span className='view-icon-box' onClick={() => handleViewMail(item)}><Icon className={"bg-primary"} attrIcon={App_url.img.EyeIcon} /></span></td>
      </React.Fragment>
    )
  }

  return (
    <div>
      <ContentLayout title={t('Email List')}>{console.log("sentEmailHistoryList", sentEmailHistoryList)}
        <React.Fragment>
          <div className="col-lg-12 mt-0">
            {activeTab?.value === "send_mail" &&
              <CustomTableTwo
                title={{ textTwo: `${t("Total Emails")} ${sentEmailHistoryList?.data ? sentEmailHistoryList?.data?.length : '0'}` }}
                columnNames={[t("Email Id"), t("Subject"), t("Date & Time"), t("View")]}
                AddButton={{ title: t("Compose Mail"), variant: "outline-primary", size: "sm" }}
                recordData={sentEmailHistoryList?.data}
                renderBody={RenderBody}
                callBack={(e, state) => callBack(e, state, "composeMail")}
                customTabs={customTabs}
                activeTab={activeTab?.value}
                onCustomTabSelection={(e, item) => handleTabVisibility(item)}
                search_placeholder={t("Search By Name,City,Industry & Postcode")}
                typePlaceHolder={"Select Status"}
                scrollbar={sentEmailHistoryList?.data?.length > 10 && true}
                style={{ height: "350px" }}
                pagination={sentEmailHistoryList?.pagination}
                onPagination={(page) => UtilsGetList.callSentEmailHistoryListApi({ ws, page: page, filter, })}
              />
            }
            {activeTab?.value === "received_mail" &&
              <CustomTableTwo
                title={{ textTwo: `${t("Total Emails")} ${emailHistoryList?.data ? emailHistoryList?.data?.length : '0'}` }}
                // AddButton={{ title: t("New Promotion"), variant: "primary", size: "sm" }}
                AddButton2={{ title: t("Compose Mail"), variant: "outline-primary", size: "sm" }}
                columnNames={[t("Email Id"), t("Subject"), t("Date & Time"), t("View")]}
                recordData={emailHistoryList?.data}
                renderBody={RenderBody2}
                callBack={(e, state) => callBack(e, state, "composeMail")}
                customTabs={customTabs}
                activeTab={activeTab?.value}
                onCustomTabSelection={(e, item) => handleTabVisibility(item)}
                search_placeholder={t("Search By Name,City,Industry & Postcode")}
                typePlaceHolder={t("Select Status")}
                // selected
                // callBackSelectAll={setSelectData}
                // selectList={selectData}
                indexName={"email_id"}
                scrollbar={emailHistoryList?.data?.length > 10 && true}
                style={{ height: "350px" }}
                pagination={emailHistoryList?.pagination}
                onPagination={(page) => UtilsGetList.callEmailHistoryListApi({ ws, page: page })}
              />
            }
            {activeTab?.value === "stared_mail" &&
              <CustomTableTwo
                title={{ textTwo: `${t("Total Emails")} ${[]?.length}` }}
                columnNames={["", t("Email Id"), t("Subject"), t("Date & Time"), t("View")]}
                AddButton={{ title: t("Compose Mail"), variant: "outline-primary", size: "sm" }}
                recordData={[]}
                renderBody={RenderBody3}
                callBack={(e, state) => callBack(e, state, "composeMail")}
                customTabs={customTabs}
                activeTab={activeTab?.value}
                onCustomTabSelection={(e, item) => handleTabVisibility(item)}
                search_placeholder={t("Search By Name,City,Industry & Postcode")}
                typePlaceHolder={t("Select Status")}
              />
            }
            {activeTab?.value === "trash_mail" &&
              <CustomTableTwo
                title={{ textTwo: `${t("Total Emails")} 0` }}
                columnNames={[t("Email Id"), t("Subject"), t("Date & Time"), t("View")]}
                AddButton={{ title: t("Compose Mail"), variant: "outline-primary", size: "sm" }}
                recordData={[]}
                renderBody={RenderBody}
                callBack={(e, state) => callBack(e, state, "composeMail")}
                customTabs={customTabs}
                activeTab={activeTab?.value}
                onCustomTabSelection={(e, item) => handleTabVisibility(item)}
                search_placeholder={t("Search By Name,City,Industry & Postcode")}
                typePlaceHolder={t("Select Status")}
              />
            }
          </div>
        </React.Fragment>
      </ContentLayout>
    </div>
  )
}

export default MailList