/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import ContentLayout from '../layout/ContentLayout'
import { Card, Col, Form, ListGroup, Row } from 'react-bootstrap'
import Button from '../Common/Button'
import { useTranslation } from '../../translate'
import CustomTableTwo from '../Common/CustomTableTwo'
import Icon from '../Common/Icon'
import App_url from '../Common/Constants'
import { WebSocketContext } from '../../WSContext'
import UtilsGetList from '../Common/UtilsGetList'
import { useDispatch, useSelector } from 'react-redux'
import Utils from '../Common/Utils'
import { setStoreComposeMailModal, setViewDocumentModal } from '../../store/modules/modals/action'
import { useLocation, useNavigate } from 'react-router-dom'
import { PostRequestAPI } from '../../Api/api/PostRequest'
import { toast } from 'react-toastify'

const AddDocument = () => {
    const { t, changeLanguage } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {ws, send} = useContext(WebSocketContext);
    const {device_id} = useSelector(App_url.userDataReducers);
    const location = useLocation();
    const state = location?.state;
    const { quickOfferList } = useSelector(App_url.externalOfferReducers);
    const { calculationList } = useSelector(App_url.calculationReducers);
    const { customerDetails } = useSelector(App_url.customerReducers);
    const { access_token } = useSelector(App_url.userDataReducers);
    const { servicesList } = useSelector(App_url.servicesReducers);
    const { lang } = useSelector(App_url.settingReducers);
    const [selectedData, setSelectedData] = useState([])
    const [activeTab, setActiveTab] = useState('Offers'); // Manage the active tab
    const [filter, setFilter] = useState({
        is_offer: true,
    })


    console.log("offerDetailsAddDocument", state)


   
      useEffect(()=>{
        if(ws){
            UtilsGetList.callQuickOfferList({ws, device_id, filter, lang:lang})
            UtilsGetList.callCalculationsList({ws, device_id, filter, lang:lang})
            UtilsGetList.callServiceList({ws, device_id, filter, lang:lang})
        }
      },[ws])

      
      const handleView = (item) =>{
        dispatch(setViewDocumentModal({
            show: "VIEW_DOCUMENT_MODAL",
            data: { item:{...item, document_name:"Offer.pdf", view_file_url:`view/file/${item?.document}`}},
        }))
      }

      const callBack = () =>{
        const documentIds = selectedData?.map(item => ({document_image:item?.document, document_name:item?.document}));
        const documentIds_appendedOld = [{document_image:state?.document_image, document_name:state?.document_image}, ...documentIds]
        console.log("documentIds", documentIds_appendedOld)
        dispatch(setStoreComposeMailModal({
            show: "COMPOSE_MAIL_MODAL",
            data: { documentImage: documentIds_appendedOld},
            callBackModal: (mailData) => handleComposeMailAPI(mailData),
        }))
      }

      const handleComposeMailAPI = async (mailData) => {
        console.log("mailData", mailData);
        const payload = {
            subject: mailData?.subject,
            body: `<body>${mailData?.msg}</body>`,
            document: mailData?.document || [],
            inline_ids: mailData?.inline_ids || [],
            to: mailData?.email || "",
            cc: mailData?.cc || "",
            bcc: mailData?.bcc || "",
            customer: [customerDetails?.[0]?.id || ""],
            content_type: "Html"
        };
        console.log('payload', payload)
        const response = await PostRequestAPI(App_url.api.COMPOSE_MAIL, payload, access_token)
        console.log("responseEmail", response);
        if (response?.status === 200) {
            toast.success(response?.data?.message);
            navigate(App_url.link.offers)
        } else {
            toast.error(response?.data?.error || response.data?.detail);
        }
    }


      const RenderBody = (item) => {
        // console.log(item)
        return (
          <React.Fragment>
            <td></td>
            <td>{Utils.formatDateTimeCreatedAt(item.updated_at)}</td>
            <td>{item?.id}</td>
            <td><span className='view-icon-box' onClick={() => handleView(item)}><Icon className={"bg-primary"} attrIcon={App_url.img.EyeIcon} /></span></td>
          </React.Fragment>
        )
      }  
      const RenderBody2 = (item) => {
        // console.log(item)
        return (
          <React.Fragment>
            <td></td>
            <td>{Utils.formatDateTimeCreatedAt(item.updated_at)}</td>
            <td>{item?.id}</td>
            <td><span className='view-icon-box' onClick={() => handleView(item)}><Icon className={"bg-primary"} attrIcon={App_url.img.EyeIcon} /></span></td>
          </React.Fragment>
        )
      } 
      const RenderBody3 = (item) => {
        // console.log(item)
        return (
          <React.Fragment>
            <td></td>
            <td>{Utils.formatDateTimeCreatedAt(item.updated_at)}</td>
            <td>{item?.id}</td>
            <td><span className='view-icon-box' onClick={() => handleView(item)}><Icon className={"bg-primary"} attrIcon={App_url.img.EyeIcon} /></span></td>
          </React.Fragment>
        )
      } 

      console.log("selectedDAta", selectedData)

    const renderContent = () => {
        switch (activeTab) {
            case 'Offers':
                return (
                    <CustomTableTwo
                        title={{ text: `${t("Offer Overview")} ` }}
                        columnNames={["",t("Date"), t('Offer ID'),  t("View")]}
                        AddButton={{ title: t("Send to Email"), variant: "primary", size: "md" }}
                        recordData={quickOfferList?.data || []}
                        renderBody={RenderBody}
                        selected
                        callBack={callBack}
                        callBackSelectAll={setSelectedData}
                        selectList={selectedData}
                        // filter={filter}
                        search_placeholder={t("Search by Company,Email, ID")}
                        // pagination={externalOfferList?.pagination}
                        // onPagination={(page) => callExternalOfferListApi(page)}
                        typePlaceHolder={t("Select Status")}
                    />
                );

            case 'Calculation':
                return (
                    <CustomTableTwo
                        title={{ text: `${t("Calculation List")} ` }}
                        columnNames={["",t("Date"), t('Calculation ID'),  t("View")]}
                        AddButton={{ title: t("Send to Email"), variant: "primary", size: "md" }}
                        recordData={calculationList?.data || []}
                        renderBody={RenderBody2}
                        // filter={filter}
                        search_placeholder={t("Search by Company,Email, ID")}
                        // pagination={externalOfferList?.pagination}
                        // onPagination={(page) => callExternalOfferListApi(page)}
                        typePlaceHolder={t("Select Status")}
                        selected
                        callBackSelectAll={setSelectedData}
                        selectList={selectedData}
                        callBack={callBack}
                    />
                );

            case 'Service List':
                return (
                    <CustomTableTwo
                        title={{ text: `${t("Service List")} ` }}
                        columnNames={["",t("Date"), t('Service ID'),  t("View")]}
                        AddButton={{ title: t("Send to Email"), variant: "primary", size: "md" }}
                        recordData={servicesList?.data || []}
                        renderBody={RenderBody3}
                        // filter={filter}
                        search_placeholder={t("Search by Company,Email, ID")}
                        // pagination={externalOfferList?.pagination}
                        // onPagination={(page) => callExternalOfferListApi(page)}
                        typePlaceHolder={t("Select Status")}
                        selected
                        callBackSelectAll={setSelectedData}
                        selectList={selectedData}
                        callBack={callBack}
                    />
                );

            default:
                return null;
        }
    };

    return (

        <ContentLayout title={t('Add Document')}>
            <div className='offer-addDocument'>
                <div className='row m-0'>
                    <div className='col-3 pe-0'>
                        <Card className="card rounded-3  me-3">
                            <Card.Body className='tabs '>
                                <div className='card-body-header py-1'>
                                    <h6 className='fs-18'>{t("Add Document")}</h6>
                                </div>
                                <ListGroup variant="" className='list mt-2'>
                                    <ListGroup.Item className={activeTab === 'Offers' ? 'active' : ''} onClick={() => setActiveTab('Offers')}>
                                        <Icon className={" icons cursor-pointer"}  attrIcon={App_url.img.CreateOfferIcon} />
                                        <h6>{t("Offers")}</h6>
                                    </ListGroup.Item>
                                    <ListGroup.Item className={activeTab === 'Calculation' ? 'active' : ''} onClick={() => setActiveTab('Calculation')}>
                                        <Icon className={"  cursor-pointer"}  attrIcon={App_url.img.CalculatorIcon} />
                                        <h6>{t("Calculation")}</h6>
                                    </ListGroup.Item>
                                    <ListGroup.Item className={activeTab === 'Service List' ? 'active' : ''} onClick={() => setActiveTab('Service List')} >
                                        <Icon className={"  cursor-pointer"}  attrIcon={App_url.img.ServicesIcon2} />
                                        <h6>{t("Service List")}</h6>
                                    </ListGroup.Item>
                                </ListGroup>
                                {/* <div className='mb-0'>
                                    <Button variant="grey" className=" col-12 ">{t("Send to Email")}</Button>
                                </div> */}
                            </Card.Body>
                        </Card>
                    </div>
                    <div className='col-9 ps-0'>
                        <div>{renderContent()}</div>
                    </div>
                </div>
            </div>

        </ContentLayout>
    )
}

export default AddDocument