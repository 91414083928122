/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Card, Modal } from 'react-bootstrap'
import Button from '../Common/Button'
import Icon from '../Common/Icon'
import App_url from '../Common/Constants'
import InputGroup from '../Common/InputGroup'
import { toast } from 'react-toastify'
import { useTranslation } from '../../translate'
import { useDispatch, useSelector } from 'react-redux'
import { setStoreRenameFolderModal } from '../../store/modules/modals/action'

const RenameFolder = () => {
    const { t, changeLanguage } = useTranslation();
    const dispatch = useDispatch();
    const { ModalPopup } = useSelector(App_url.modalReducers);
    const [folderName, setFolderName] = useState("");
    // console.log("ModalPopup?.data?.item",ModalPopup?.data)


    useEffect(()=>{
        if(ModalPopup?.show == "RENAME_FOLDER_MODAL"){
            setFolderName(ModalPopup?.data?.item?.document_name)
        }
    },[ModalPopup?.show == "RENAME_FOLDER_MODAL"])

    const handleClose = async () => {
        if (ModalPopup?.callBackModal) {
            await ModalPopup?.callBackModal(ModalPopup?.data?.item, folderName);
        }
        CloseData()
    };
    const CloseData = () => {
        dispatch(setStoreRenameFolderModal())
    }

    if (ModalPopup?.show !== "RENAME_FOLDER_MODAL") {
        return null
    }
    const handleClickOk = () => {
        // console.log( ModalPopup?.data?.item);
        if (folderName) {
            // setFolderName(folderName);
            handleClose();
        } else {
            toast.error("Pleaser enter the folder name")
        }

    }
    return (
        <div>
            <Modal show={true}  size='sm' centered className='createFolder'>

                <Modal.Body>
                    <div className=' heading '>
                        <h6 className='heading-1'>{t("Rename ")}</h6>
                        <h6>{t("Please enter a new name for the file")}</h6>
                    </div>
                    <div className='mt-3'>
                        <InputGroup
                            label={""}
                            placeholder={t("Enter new name")}
                            formClassName=""
                            name="folder_name"
                            type={'text'}
                            value={folderName}
                            onChange={(e) => setFolderName(e.target.value)}
                        />
                    </div>
                    <div className='text-center mt-4'>
                        <Button onClick={CloseData} className={"col-5 me-3"} variant={"outline-primary"}>{t("Cancel")}</Button>
                        <Button className={"col-5"} onClick={handleClickOk} variant={"primary"}>{t("Okay")}</Button>
                    </div>
                </Modal.Body>

            </Modal>
        </div>
    )
}

export default RenameFolder