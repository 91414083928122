/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import Icon from '../Common/Icon';
import LazyImage from '../Common/LazyImage';
import AnchorLink from '../Common/AnchorLink';
import { Link, NavLink, } from 'react-router-dom';
import TabListItem from './TabListItem';
import { useDispatch, useSelector } from 'react-redux';
import { GetRequestCallAPI } from '../../Api/api/GetRequest';
import SidebarSite from './SidebarSite';
import App_url from '../Common/Constants';
import { setConfirmModalPopup } from '../../store/modules/modals/action';
import { setStoreCurrentUser } from '../../store/modules/users_data/action';
import { useTranslation } from '../../translate';

export default function SideBar(props) {
  const { t, changeLanguage } = useTranslation();
  const { access_token, site_code } = useSelector(App_url.userDataReducers);
  const dispatch = useDispatch();
  const [activeMenu, setActiveMenu] = useState(null);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const handleMenuClick = (clickedItem) => {
    setActiveMenu(clickedItem === activeMenu ? null : clickedItem);
  };

  const handleSubMenuClick = (menuIndex, subMenuIndex) => {
    setActiveSubMenu(subMenuIndex);
  };

  const SideList = [
    {
      title: t("Dashboard"),
      Icon: App_url.img.DashboardIcon,
      to: App_url.link.dashboard,
    },
    ,
    {
      title: t("Sales"),
      Icon: App_url.img.SalesProgressIcon,
      data: [
        {
          title: t("Prospects"),
          Icon: App_url.img.StaffIcon,
          to: App_url.link.prospects,
        },
        {
          title: t("Customers"),
          Icon: App_url.img.StaffIcon,
          to: App_url.link.customers,
        },
      ]
    },
    {
      title: t("Offers Overview"),
      Icon: App_url.img.OffersIcon,
      to: App_url.link.offers,
    },
    {
      title: t("Calculation"),
      Icon: App_url.img.CalculatorIcon,
      // to: App_url.link.calculation,
      data: [
        {
          title: t("Saved Calculation"),
          Icon: App_url.img.StaffIcon,
          to: App_url.link.savedCalculation,
        },
        {
          title: t("New Calculation"),
          Icon: App_url.img.StaffIcon,
          to: App_url.link.newCalculation,
        },
      ]
    },
    {
      title: t("Documentation"),
      Icon: App_url.img.DocumentIcon,
      // to: App_url.link.documentation,
      data: [
        {
          title: t("Documents List"),
          Icon: App_url.img.StaffIcon,
          to: App_url.link.documentationList,
        },
        {
          title: t("Browse"),
          Icon: App_url.img.StaffIcon,
          to: App_url.link.browseList,
        },
      ]
    },
    {
      title: t("List of Services"),
      Icon: App_url.img.ServicesIcon,
      to: App_url.link.services,
    },
    {
      title: t("Create Offer"),
      Icon: App_url.img.CreateOfferIcon,
      to: App_url.link.createOffer,
    },
    {
      title:t("Promotions"),
      Icon:App_url.img.PromotionIcon,
      to: App_url.link.promotion,
    },
    {
      title:t("External Offers"),
      Icon:App_url.img.ExternalOffersIcon,
      data: [
        {
          title: t("External Offers"),
          Icon: App_url.img.StaffIcon,
          to: App_url.link.externalOffers,
        },
        {
          title: t("Add External Offer"),
          Icon: App_url.img.StaffIcon,
          to: App_url.link.addExternalOffers,
        },
      ]
    },
    {
      title:t("Mail"),
      Icon:App_url.img.MailIcon,
      to: App_url.link.mail,
    },

  
  ];


  const SideList2 = [
    {
      title: t("Employee List"),
      Icon: App_url.img.DashboardIcon,
      to: App_url.link.employers,
    },
  ]

  const callLoginOutConfirm = (item) => {
    dispatch(setConfirmModalPopup({
      title: t("Log Out"),
      description: t(`Are you sure want to log out?`),
      data: {},
      show: "CONFIRM_MODAL",
      callBackModal: () => LogoutUser(),
      buttonSuccess: "Yes",
      buttonCancel: "Cancel",
    }));
  }
  const LogoutUser = async () => {
    await GetRequestCallAPI(App_url.api.USER_LOG_OUT, access_token);
    dispatch(setStoreCurrentUser())
    window.location.replace(`${process.env.REACT_APP_API_DOMAIN_URL}/${App_url.api.USER_DEL_LOGIN}/${window.location.hostname}`);
  }
  // const siteCode = useMemo(()=>{
  //   return OrgList?.find((item, index)=>item.site_code === site_code);
  // },[site_code]);

  const currentUrl = window.location.pathname;


  const checkUrl = (list, keyword) => {
    // Helper function to recursively check "to" or "data" in the list
    const checkSalesInData = (list) => {
      return list?.some((item) => {
        if (item?.to && currentUrl.includes(keyword)) {
          return true;
        }
        if (item?.data) {
          return checkSalesInData(item?.data);
        }
        return false;
      });
    };
    const isUrlExist = checkSalesInData(list);
    // console.log(`Does the current URL ${currentUrl} include "${keyword}"?`, isUrlExist);
    return isUrlExist;
  };

  return (
    <div className='sidebar-panel'>
      <SidebarSite />
      <aside className='main-sidebar '>
        <div className='logo d-flex justify-content-between'>
          <AnchorLink to={App_url?.link?.home}>
            {/* <LazyImage src={App_url.image.default_logo} /> */}
            <div className='logo_title'>
              <h6 className='side-bar-title'>{t("SALES DETAILS")}</h6>
            </div>
          </AnchorLink>
        </div>
        <div id='sidebar_menu' className='metis_menu'>
          <div className='side-scrolled'>
            <ListGroup>
             {checkUrl(SideList, "sales") && <React.Fragment>
              {SideList?.map((item, index) => (
                <React.Fragment key={index}>
               <TabListItem
                {...item}
                isActive={activeMenu === index}
                onMenuClick={() => handleMenuClick(index)}
                activeSubMenu={activeSubMenu}
                onSubMenuClick={(subMenuIndex) => handleSubMenuClick(index, subMenuIndex)}
              />
                </React.Fragment>
              ))}
              </React.Fragment>}
              {checkUrl(SideList2, "employers") && <React.Fragment>
              {SideList2?.map((item, index) => (
                <React.Fragment key={index}>
               <TabListItem
                {...item}
                isActive={activeMenu === index}
                onMenuClick={() => handleMenuClick(index)}
                activeSubMenu={activeSubMenu}
                onSubMenuClick={(subMenuIndex) => handleSubMenuClick(index, subMenuIndex)}
              />
                </React.Fragment>
              ))}
              </React.Fragment>}

            </ListGroup>

          </div>
        </div>

      </aside>
    </div>
  )
}
