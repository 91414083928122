/* eslint-disable */
import React, { useCallback, useContext, useEffect, useState } from 'react'
import ContentLayout from '../../layout/ContentLayout'
import CustomTable from '../../Common/CustomTable'
import { useNavigate } from 'react-router-dom'
import App_url from '../../Common/Constants';
import { WebSocketContext } from '../../../WSContext';
import { useSelector } from 'react-redux';
import wsSendRequest from '../../../socketIO/wsSendRequest';
import { toast } from 'react-toastify';
import { useTranslation } from '../../../translate';


export default function ProspectsPage(props) {
  const { t, changeLanguage } = useTranslation();

  const navigate = useNavigate();
  const { ws, send } = useContext(WebSocketContext);
  const { device_id, websocketReqRes } = useSelector(App_url.userDataReducers);
  const { customersList } = useSelector(App_url.customerReducers);
  // console.log("customersList", customersList)

  const [filter, setFilter] = useState({
    status: "",
    search: "",
    user_status: ""
  })


  const callCustomersListApi = (page) => {
    const param = {
      transmit: "broadcast",
      url: "customer_list",
      request: {
        status: filter?.user_status,
        limit: "10",
        page_no: page,
        pincode_search: "",
        city_search: "",
        customer_type: [""],
        order_by: "-updated_at",
        industry_search: "",
        search: filter?.search,
        search_status: filter?.user_status
      }
    };

    send(param, device_id);
  };
  // console.log("filter?.status",filter?.status)
  useEffect(() => {
    if (ws) {
      callCustomersListApi(1);
    }
  }, [ws, filter]);

  const statusOptionList = [
    { label: t("Not Contacted"), value: t("Not Contacted") },
    { label: t("Not Interested"), value: t("Not Interested") },
    { label: t("Re-Schedule Call"), value: t("Re-Schedule Call") },
    { label: t("Share Brochure"), value: t("Share Brochure") },
    { label: t("Schedule Appointment"), value: t("Schedule Appointment") },
  ]

  const callBack = (e, state) => {
    if (state === "AddButton") {
      navigate(App_url.link.ProspectsAdd);
    }
  }

  const handleDetails = (customerId) => {
    const param = {
      transmit: "broadcast",
      url: "customer_detail",
      request: { customer_id: customerId }
    };
    send(param);
    navigate(`${App_url.link.prospectsDetails}/${customerId}`)
  }

  const RenderBody = (item) => {
    return (
      <React.Fragment>
        <td onClick={() => handleDetails(item?.id)} className='' title={item?.customer_code}>{item?.customer_code}</td>
        <td onClick={() => handleDetails(item?.id)} title={item?.company_name}>{item?.company_name ? item?.company_name :"-"}</td>
        <td onClick={() => handleDetails(item?.id)} title={item?.customer_name}>{item?.customer_name ? item?.customer_name :"-"}</td>
        <td onClick={() => handleDetails(item?.id)} title={item?.last_name}>{item?.last_name ? item?.last_name :"-"}</td>
        <td onClick={() => handleDetails(item?.id)} title={item?.email}>{item?.email ? item?.email :"-"}</td>
        <td onClick={() => handleDetails(item?.id)} title={item?.company_mobile}>{item?.company_mobile ? item?.company_mobile :"-"}</td>
        <td onClick={() => handleDetails(item?.id)} title={item?.company_address}>{item?.company_address ? item?.company_address :"-"}</td>
        <td onClick={() => handleDetails(item?.id)} title={item?.status}>{item?.status ? item?.status :"-"}</td>
        {/* <td onClick={()=>handleDetails(item?.id)}>{item?.industry_type}</td> */}
      </React.Fragment>
    )
  }
  return (
    <ContentLayout title={t("Prospects Page")}>
      <div className="row m-0">
        <div className="col-lg-12 mt-2">
          <CustomTable
            title={t("Prospect List")}
            AddButton={{ title: t("+Add"), variant: "outline-primary", size: "md" }}
            // AddButton2={{ title: t("Import"), variant:"secondary", size:"md"}}
            columnNames={[t("Customer ID"), t("Company Name"), t("First Name"), t("Last Name"), t("Email"), t("Mobile No."), t("Address"), t("Status")]}
            recordData={customersList?.data}
            renderBody={RenderBody}
            callBack={callBack}
            setFilter={setFilter}
            filter={filter}
            search_placeholder={t("Search By Name,City,Industry & Postcode")}
            typePlaceHolder={t("Select Status")}
            pagination={customersList?.pagination}
            onPagination={(page) => callCustomersListApi(page)}
            typeOption={statusOptionList}

          // scrollbar
          // style={{height: "calc(100vh - 217px)"}}

          />
        </div>
      </div>

    </ContentLayout>
  )
}
