/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import CustomTableTwo from '../Common/CustomTableTwo'
import Icon from '../Common/Icon'
import App_url from '../Common/Constants'
import ContentLayout from '../layout/ContentLayout'
import { redirect, useNavigate } from 'react-router-dom'
import wsSendRequest from '../../socketIO/wsSendRequest'
import { WebSocketContext } from '../../WSContext'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { setStoreDiscardChangesModal } from '../../store/modules/modals/action'
import Utils from '../Common/Utils'
import UtilsGetList from '../Common/UtilsGetList'
import { useTranslation } from '../../translate'
import { setStoreCustomersDetails } from '../../store/modules/customers/action'

const SavedCalculation = () => {
    const { t, changeLanguage } = useTranslation();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { ws, send } = useContext(WebSocketContext);
    const { device_id, websocketReqRes } = useSelector(App_url.userDataReducers);
    const { calculationList } = useSelector(App_url.calculationReducers);
    const { lang } = useSelector(App_url.settingReducers);
    const [filter, setFilter] = useState({
        search: "",
    })
    // console.log("calculationList",calculationList?.data[0]?.category)
    // console.log("calculationList",calculationList?.data?.customer_name)

    const handleView = (calculation) => {
        if(calculation?.customer_id){
            UtilsGetList.callCustomerDetailsApi({ ws, customer_id: calculation?.customer_id, lang: lang });
        }else{
            dispatch(setStoreCustomersDetails())
        }
        UtilsGetList.callGetCalculationListApi({ ws, device_id, calculationId: calculation?.id, lang: lang })
        navigate(`${App_url.link.calculationOverview}/${calculation?.id}`, {state:calculation })
    }

    const handleDeleteCalculationModal = (calculationId) => {
        // console.log("calculationId",calculationId)
        dispatch(setStoreDiscardChangesModal({
            show: "DISCARD_CHANGES_MODAL",
            data: { description: t("Are you sure you want to delete this calculation?") },
            callBackModal: () => handleDeleteCalculation(calculationId)

        }))
    }

    const handleCategoryArray = (category) => {
        const categoryString = category?.join(', ')
        return categoryString
    }

    const handleDeleteCalculation = (calculationId) => {
        const param = {
            transmit: "broadcast",
            url: "delete_calculation",
            request: { calculation_id: calculationId },
            toast: true,
        };
        send(param, device_id)
    }


    const callCalculationListAPI = (page) => {
        const param = {
            transmit: "broadcast",
            url: "calculation_list",
            request: {
                limit: "10",
                page_no: page,
                order_by: "-updated_at",
                search: filter?.search,
            }
        };
        send(param);
    }

    useEffect(() => {
        if (ws) {
            callCalculationListAPI(1)
        }
        if (websocketReqRes?.url === "delete_calculation") {
            callCalculationListAPI(1)
        }
    }, [ws, websocketReqRes?.url, filter])

    const RenderBody = (item) => {
        // console.log("item", item)
        return (
            <React.Fragment className='dashTitle'>
                <td className='new-calc-td' onClick={() => handleView(item)} title={item.category}>{handleCategoryArray(item?.category)}</td>
                <td className='new-calc-td' onClick={() => handleView(item)} title={item.customer_name}>{item?.customer_id ? item?.customer_name ? item?.customer_name : "-" : "NA"}</td>
                <td className='new-calc-td' onClick={() => handleView(item)}>{Utils.formatDateTimeCreatedAt(item?.updated_at,t)}</td>
                <td className='new-calc-td'><span className='view-icon-box' onClick={() => handleDeleteCalculationModal(item?.id, "viewRemark")}><Icon className={"bg-primary"} attrIcon={App_url.img.DeleteIcon} /></span></td>
            </React.Fragment>
        )
    }
    return (
        <ContentLayout title={t('Calculation')}>
            <CustomTableTwo
                title={{ text: t("Saved Calculation"), size: "fs-20", textTwo: `${t("Total Saved Calculations")} ${calculationList?.data?.length}`, sizeTwo: "fs-14" }}
                columnNames={[t("Service Category"), t("Customer Name "), t("Last Edit"), t("Action")]}
                recordData={calculationList?.data}
                renderBody={RenderBody}
                search_placeholder={t("Search by Company Name")}
                //  callBack={callBack}
                pagination={calculationList?.pagination}
                onPagination={(page) => callCalculationListAPI(page)}
                filter={filter}
                setFilter={setFilter}
            />
        </ContentLayout>
    )
}

export default SavedCalculation