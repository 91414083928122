/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import App_url from '../Common/Constants'
import Icon from '../Common/Icon'
import ContentLayout from '../layout/ContentLayout'
import CustomTableTwo from '../Common/CustomTableTwo'
import wsSendRequest from '../../socketIO/wsSendRequest'
import { WebSocketContext } from '../../WSContext'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { setWebsocketReqRes } from '../../store/modules/users_data/action'
import { setStoreDiscardChangesModal } from '../../store/modules/modals/action'
import Utils from '../Common/Utils'
import { useTranslation } from '../../translate'
import { Breadcrumb } from 'react-bootstrap'
import AnchorLink from '../Common/AnchorLink'

const SavedServiceList = () => {
    const { t, changeLanguage } = useTranslation();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { ws, send } = useContext(WebSocketContext);
    const { device_id, websocketReqRes } = useSelector(App_url.userDataReducers);
    const { customerDetails } = useSelector(App_url.customerReducers);
    const { servicesList } = useSelector(App_url.servicesReducers);
    // console.log("servicesList", servicesList);
    const [filter, setFilter] = useState({
        search: "",
        // city:"",
        // postcode:"",
    })
    // console.log("servicesList?.data", servicesList?.data)
    const callSelectedServicesListApi = (page) => {
        const param = {
            transmit: "broadcast",
            url: "service_los_list",
            request: {
                limit: "20",
                page_no: page,
                order_by: "-updated_at"
            }
        };
        // console.log("param",param)
        send(param, device_id);
    };

    useEffect(() => {
        if (ws) {
            callSelectedServicesListApi(1);
        }
    }, [ws]);
    const handleClick = (serviceData) => {
        // console.log("serviceData", serviceData)
        navigate(`${App_url.link.addServiceList}/${serviceData?.id}`, { state: serviceData })
    }
    const handleCopyService = (serviceData) => {
        navigate(`${App_url.link.selectServiceCustomerList}`, { state: { ...serviceData, fromPage: { pageName: "savedServiceList", stateFor: "copayState" } } })
    }

    const callBack = () => {

    }
    const handleDeleteServiceModal = (item) => {
        dispatch(setStoreDiscardChangesModal({
            data: { description: t("Are you sure you want to delete this service ?") },
            callBackModal: () => deleteService(item?.id),
        }))
    }

    const deleteService = (serviceId) => {
        const param = {
            transmit: "broadcast",
            url: "delete_los_service",
            request: { service_id: serviceId }
        };
        send(param);
    }

    const handleCategoryArray = (category) => {
        const categoryString = category?.join(', ')
        return categoryString;
    }

    useEffect(() => {
        if (websocketReqRes?.url === "delete_los_service") {
            if (websocketReqRes?.status === 200) {
                toast.success(websocketReqRes?.response?.msg)
                dispatch(setWebsocketReqRes())
                callSelectedServicesListApi(1)
            } else {
                toast.info(websocketReqRes?.response?.msg || "error");
                dispatch(setWebsocketReqRes());
            }
        }
    }, [websocketReqRes?.url])


    const RenderBody = (item) => {
        // console.log("item", item);
        return (
            <React.Fragment className='dashTitle'>
                <td onClick={() => handleClick(item)} className='new-calc-td'>{handleCategoryArray(item?.category_name)}</td>
                <td onClick={() => handleClick(item)} className='new-calc-td'>{item?.customer_id ? (item?.customer_name || "-" ): "NA"}</td>
                <td onClick={() => handleClick(item)} className='new-calc-td'>{Utils.formatDateTimeCreatedAt(item?.updated_at)}</td>
                <td className='new-calc-td d-flex gap-2'>
                    <span className='view-icon-box' onClick={() => handleDeleteServiceModal(item)}><Icon className={"bg-primary"} attrIcon={App_url.img.DeleteIcon} /></span>
                    <span className='view-icon-box' onClick={() => handleClick(item)}><Icon className={"bg-primary"} attrIcon={App_url.img.EyeIcon} /></span>
                    <span className='view-icon-box' onClick={() => handleCopyService(item)}><Icon className={"bg-primary"} attrIcon={App_url.img.ShareFolderIcon} /></span>
                </td>
            </React.Fragment>
        )
    }
    return (
        <div>

            <ContentLayout title={t("Documentation Page")}>
                <Breadcrumb className="breadcrumb-main">
                    <Breadcrumb.Item>
                        <AnchorLink to={App_url.link.services}>{t("List of services")}  </AnchorLink>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>{"Saved Service List"}</Breadcrumb.Item>
                </Breadcrumb>

                <CustomTableTwo
                    title={{ text: t("Select Service List"), size: "fs-20", textTwo: `${t("Total Saved Calculations")} ${servicesList?.data?.length || 0}` }}
                    // AddButton={{ title: "+ Add Document", variant: "outline-primary", size: "md" }}
                    columnNames={[t("Service Category "), t("Customer Name"), t("Last Edit"), t("Action")]}
                    recordData={servicesList?.data || []}
                    renderBody={RenderBody}
                    callBack={callBack}
                    setFilter={setFilter}
                    filter={filter}
                    search_placeholder={t("Search Company Name")}
                    typePlaceHolder={t("Select Status")}
                />
            </ContentLayout>
        </div>
    )
}

export default SavedServiceList