export const ActionTypes = {
    SET_STORE_EXTERNAL_OFFER_LIST:"SET_STORE_EXTERNAL_OFFER_LIST",
    SET_STORE_QUICK_OFFER_LIST:"SET_STORE_QUICK_OFFER_LIST",
    SET_STORE_OFFER_DASHBOARD_LIST:"SET_STORE_OFFER_DASHBOARD_LIST",
    SET_STORE_GET_EXTERNAL_OFFER_LIST:"SET_STORE_GET_EXTERNAL_OFFER_LIST",
    SET_STORE_GET_OFFER_LIST:'SET_STORE_GET_OFFER_LIST',
    SET_STORE_OFFER_OVERVIEW_LIST:"SET_STORE_OFFER_OVERVIEW_LIST",
    SET_STORE_OFFER_OVERVIEW_DASHBOARD_LIST:"SET_STORE_OFFER_OVERVIEW_DASHBOARD_LIST",
    SET_STORE_GET_OFFER_OVERVIEW:"SET_STORE_GET_OFFER_OVERVIEW",
}


export const setStoreExternalOfferList = (token) => {
    return {
        type: ActionTypes.SET_STORE_EXTERNAL_OFFER_LIST,
        payload: token,
     }
}
export const setStoreQuickOfferList = (token) => {
    return {
        type: ActionTypes.SET_STORE_QUICK_OFFER_LIST,
        payload: token,
     }
}
export const setStoreOfferDashboardList = (token) => {
    return {
        type: ActionTypes.SET_STORE_OFFER_DASHBOARD_LIST,
        payload: token,
     }
}
export const setStoreGetExternalOfferList = (token) => {
    return {
        type: ActionTypes.SET_STORE_GET_EXTERNAL_OFFER_LIST,
        payload: token,
     }
}
export const setStoreGetOfferList = (token) => {
    return {
        type: ActionTypes.SET_STORE_GET_OFFER_LIST,
        payload: token,
     }
}
export const setStoreOfferOverviewList = (token) => {
    return {
        type: ActionTypes.SET_STORE_OFFER_OVERVIEW_LIST,
        payload: token,
     }
}
export const setStoreOfferOverviewDashboardList = (token) => {
    return {
        type: ActionTypes.SET_STORE_OFFER_OVERVIEW_DASHBOARD_LIST,
        payload: token,
     }
}
export const setStoreGetOfferOverview = (token) => {
    return {
        type: ActionTypes.SET_STORE_GET_OFFER_OVERVIEW,
        payload: token,
     }
}



