/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import App_url from '../../../Common/Constants';
import Icon from '../../../Common/Icon';
import Scrollbar from '../../../Common/Scrollbar';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { setStoreInviteeInfoModal } from '../../../../store/modules/modals/action';
import wsSendRequest from '../../../../socketIO/wsSendRequest';
import { WebSocketContext } from '../../../../WSContext';
import Utils from '../../../Common/Utils';
import { GetRequestCallAPI } from '../../../../Api/api/GetRequest';
import { useTranslation } from '../../../../translate';


export default function InviteeInfoModal() {
  const { t, changeLanguage, lang } = useTranslation();

  const dispatch = useDispatch();
  const { ws, send } = useContext(WebSocketContext);
  const { ModalPopupThree } = useSelector(App_url.modalReducers);
  const { device_id, access_token } = useSelector(App_url.userDataReducers);
  const { inviteeAvailableDates } = useSelector(App_url.customerReducers);
  const [bookedAvailableSlots, setBookedAvailableSlots] = useState([]);

  // console.log("ModalPopupThree", ModalPopupThree)

  const initialDate = ModalPopupThree?.data?.startDate
    ? new Date(ModalPopupThree?.data?.startDate)
    : new Date();
  const [date, setDate] = useState(initialDate);

  const handleClose = async () => {
    if (ModalPopupThree?.callBackModal) {
      await ModalPopupThree?.callBackModal(ModalPopupThree?.data?.item);
    }
    CloseData()
  };
  const CloseData = () => {
    dispatch(setStoreInviteeInfoModal())
  }

  const callAppointmentsForMonth = () => {
    const month = Utils.getOnlyMonthFromDate(ModalPopupThree?.data?.startDate);
    const year = Utils.getOnlyYearFromDate(ModalPopupThree?.data?.startDate);
    const param = {
      transmit: "broadcast",
      url: "appointments_for_month",
      request: { month: month, year: year, user_id: ModalPopupThree?.data?.item?.id }
    };
    send( param, device_id)
  }

  const callAvailableBookedSlotTimes = async () =>{
    const payload = {
      date: Utils.formatOnlyDate(ModalPopupThree?.data?.startDate),
    }
    const response = await GetRequestCallAPI(App_url.api.GET_BOOKED_SLOTS_TIME +"/"+ ModalPopupThree?.data?.item?.id, access_token , payload)
    console.log("response", response);
    if(response?.status === 200){
      setBookedAvailableSlots(response?.data);
    }
  }


  useEffect(() => {
    if (ModalPopupThree?.show === "INVITEE_INFO_MODAL" && ModalPopupThree?.data?.item?.id) {
      callAppointmentsForMonth();
       // Update the calendar date when startDate changes
       if (ModalPopupThree?.data?.startDate) {
        setDate(new Date(ModalPopupThree?.data?.startDate));
    }
    }
  }, [ModalPopupThree?.show === "INVITEE_INFO_MODAL", ModalPopupThree?.data?.item?.id, ModalPopupThree?.data?.startDate])



  useEffect(()=>{
    if(ModalPopupThree?.show === "INVITEE_INFO_MODAL" && ModalPopupThree?.data?.startDate){
      callAvailableBookedSlotTimes()
    } 
  },[ModalPopupThree?.show === "INVITEE_INFO_MODAL", ModalPopupThree?.data?.startDate])

  const onChange = (newDate) => {
    setDate(newDate);
  };

  // Function to determine if a tile should be disabled
  const tileDisabled = ({ date, view }) => {
    if (view === 'month') {
      // Get the current month and year
      const currentMonth = new Date().getMonth();
      const currentYear = new Date().getFullYear();

      // Get the month and year of the tile
      const tileMonth = date.getMonth();
      const tileYear = date.getFullYear();

      // Disable tiles that belong to the next month
      if (tileYear === currentYear && tileMonth > currentMonth) {
        return true;
      }

      // Disable tiles that belong to the previous month
      if (tileYear === currentYear && tileMonth < currentMonth) {
        return true;
      }

      // Disable tiles that belong to the next month when the current month is December
      if (tileYear > currentYear || (tileYear === currentYear + 1 && tileMonth === 0 && currentMonth === 11)) {
        return true;
      }

      // Disable tiles that belong to the previous month when the current month is January
      if (tileYear < currentYear || (tileYear === currentYear - 1 && tileMonth === 11 && currentMonth === 0)) {
        return true;
      }
    }

    return false;
  };

  const markedDates = inviteeAvailableDates?.dates?.map(dateStr => {
    const markedDate = new Date(dateStr);
    // Normalize the markedDate by setting time to 00:00:00
    markedDate.setHours(0, 0, 0, 0);
    return markedDate;
  });

  const tileContent = ({ date, view }) => {
    if (view === 'month') {
      // Check if the date is today's date
      const today = new Date(ModalPopupThree?.data?.startDate);
      const isToday =
        date.getFullYear() === today.getFullYear() &&
        date.getMonth() === today.getMonth() &&
        date.getDate() === today.getDate();
  
      // Check if the date is marked
      const isMarked = markedDates?.some(
        markedDate =>
          date.getFullYear() === markedDate.getFullYear() &&
          date.getMonth() === markedDate.getMonth() &&
          date.getDate() === markedDate.getDate()
      );
  
      // Check if the date is the startDate
      const startDate = new Date(ModalPopupThree?.data?.startDate);
      const isStartDate =
        date.getFullYear() === startDate.getFullYear() &&
        date.getMonth() === startDate.getMonth() &&
        date.getDate() === startDate.getDate();
  
      // Return the content with a different background for the startDate
      return (
        <div
          className={`
            ${isMarked ? 'booked-dot' : ''}
            ${isToday ? 'white-dot' : 'dot'}
          `}
        ></div>
      );
    }
  };

  const tileClassName = ({ date, view }) => {
    if (view === 'month') {
      // Check if the date is the startDate
      const startDate = new Date(ModalPopupThree?.data?.startDate);
  
      const isStartDate =
        date.getFullYear() === startDate.getFullYear() &&
        date.getMonth() === startDate.getMonth() &&
        date.getDate() === startDate.getDate();
  
      // If the tile date matches the startDate, return a custom class
      return isStartDate ? 'start-date-tile' : '';
    }
    return ''; // No custom class for other dates
  };
  


  if (ModalPopupThree?.show !== "INVITEE_INFO_MODAL") {
    return null;
  }

  return (
    <Modal show={true} onHide={CloseData} centered className='invitee-info-modal-dialog' size={"lg"}>
      <Scrollbar style={{ height: "calc(100vh - 62px)" }}>
        <Modal.Body className='modal-body invitee-info-modal' >
          <div className='invitee-header'>
            <Icon className={"bg-primary invitee-header-back-icon"} attrIcon={App_url.img.LeftArrowIcon} onClick={CloseData} style={{ cursor: "pointer" }} />
            <h6 className='invitee-header-title text-capitalize'>{ModalPopupThree?.data?.item?.full_name}</h6>
            <h6 className={"invitee-header-title-done"}>{t("Done")}</h6>
          </div>
          <div className='custom-calendar'>
            <Calendar
              value={date}
              tileDisabled={tileDisabled}
              tileContent={tileContent}
              tileClassName={tileClassName}
            />
          </div>
          <div className='time-slot'>
            <h6>{t("Time Slot")}</h6>
            <div className='slot-names'>
              <div className='slot-names-content'>
                <span className='available-slot'></span>
                <h6>{t("Available Slot")}</h6>
              </div>
              <div className='slot-names-content'>
                <span className='booked-slot'></span>
                <h6>{t("Booked Slot")}</h6>
              </div>
              <div className='slot-names-content'>
                <span className='other-slot'></span>
                <h6>{t("Other Time")}</h6>
              </div>
            </div>
          </div>
          <div className='time-slot-box'>
            {bookedAvailableSlots?.timeSlot?.map((item) => (
              <div className={`${item?.status === "Booked" ? "time-slot-list-booked" :"time-slot-list-available"}`}>
                <h6>{Utils.formatTimeAmToPM(item?.start, item?.end)}</h6>
              </div>

            ))}
          </div>
        </Modal.Body>
      </Scrollbar>
    </Modal>
  )
}
InviteeInfoModal.propTypes = {
  handleClose: PropTypes?.func,
  show: PropTypes?.bool,
}
InviteeInfoModal.defaultProps = {
  handleClose: () => { },
  show: false,
}