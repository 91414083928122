/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import ContentLayout from '../../layout/ContentLayout'
import { Breadcrumb, Card, Dropdown, Table } from 'react-bootstrap'
import Button from '../../Common/Button'
import InputGroup from '../../Common/InputGroup'
import Icon from '../../Common/Icon'
import App_url from '../../Common/Constants'
import AnchorLink from '../../Common/AnchorLink'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { WebSocketContext } from '../../../WSContext'
import wsSendRequest from '../../../socketIO/wsSendRequest'
import { setStoreAdditionalCostModal, setStoreDiscardChangesModal } from '../../../store/modules/modals/action'
import UtilsGetCalculations from '../UtilsGetCalculations'
import { toast } from 'react-toastify'
import { setWebsocketReqRes } from '../../../store/modules/users_data/action'
import Utils from '../../Common/Utils'
import { useTranslation } from '../../../translate'
import UtilsGetList from '../../Common/UtilsGetList'
import { setStoreGlobalLoader } from '../../../store/modules/setting_reducers/action'

export default function GlassCleaning(props) {
    const { t, changeLanguage } = useTranslation();

    const { ws, send } = useContext(WebSocketContext);
    const { device_id, websocketReqRes } = useSelector(App_url.userDataReducers);
    const { customerDetails } = useSelector(App_url.customerReducers);
    const { currentCategory, serviceTypeList, frequencyList } = useSelector(App_url.calculationReducers);
    const { lang, globalLoader } = useSelector(App_url.settingReducers);
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [selectedOption, setSelectedOption] = useState('performance');
    const [calculatedValues, setCalculatedValues] = useState(false);
    const location = useLocation();

    const { service } = location.state || {};
    // console.log("service", serviceTypeList);

    const [formData, setFormData] = useState({
        surface_area: "",
        value: "",
        hourly_rate: "",
        category_name: "",
        service_name: '',
        per_exceution_hour: "",
        per_exceution: "",
        yearly: "",
        standRate: "",
        frequency: "",
        monthly_cost: "",
        monthly_hour: "",
        yearly_hour: "",
        performance: true,
        standard: false,
        additional_cost: [],
    })
    const [error, setError] = useState({
        surface_area: '',
        performance_value: '',
        standard_value: '',
        hourly_rate: '',
        category_name: '',
        service_name: '',
    })

    // onChange for Input 
    const onChange = (e) => {
        // console.log("e", e.target.name);
        setFormData((prevData) => ({
            ...prevData,
            [e.target.name]: e.target.value,
        }));
        setError((prevData) => ({
            ...prevData,
            [e.target.name]: "",
            // performance_value: selectedOption === "performance" ? "" : prevData?.performance_value,
            // standard_value: selectedOption === "standard" ? "" : prevData?.standard_value,
        }))
    };

    useEffect(() => {
        if (service) {
            setFormData((prevData) => ({
                ...prevData,
                ...service,
                yearly: service?.yearly_cost,
                service_id: service?.id,
            }))
            setCalculatedValues(true);
        }
    }, [service])

    // console.log("formData", formData);


    // dropdown options
    const serviceTypeOptions = serviceTypeList?.map((item) => {
        return { label: item?.type_name, value: item?.id }
    })
    const frequencyOptions = frequencyList?.data?.map((item) => {
        return { label: item?.frequency_name, value: item?.id }
    })
    // console.log("serviceTypeOptions", serviceTypeOptions)

    // console.log("serviceTypeOptions", serviceTypeList)

    //dropdown select
    const handleSelect = (eventKey, type) => {
        if (type === "serviceType") {
            const selectedServiceType = serviceTypeList?.find((option) => option.id === eventKey);
            setFormData(prevFormData => ({
                ...prevFormData,
                service_name: selectedServiceType?.type_name,
            }));
            setError((prevData) => ({
                ...prevData,
                service_name: "",
            }))
        }
        if (type === "frequency") {
            const selectedFrequency = frequencyList?.data?.find(option => option.id === eventKey);
            console.log("selectedFrequency", selectedFrequency);
            setFormData(prevFormData => ({
                ...prevFormData,
                category_name: selectedFrequency?.frequency_name,
                frequency: selectedFrequency?.no_of_days
            }));
            setError((prevData) => ({
                ...prevData,
                category_name: "",
            }))
        }
    };

    // Performance and Standard selection
    const handleRadioChange = (e) => {
        const value = e.target.value;
        setSelectedOption(value);
        if (value === 'performance') {
            setFormData((prevFormData) => ({
                ...prevFormData,
                value: "",
                hourly_rate: 0,
                per_exceution_hour: 0,
                per_exceution: 0,
                yearly: 0,
                performance: true,
                standard: false,
            }));
            setError((prevData) => ({ ...prevData, standard_value: "" }))
            setCalculatedValues(false);
        } else if (value === 'standard') {
            setFormData((prevFormData) => ({
                ...prevFormData,
                value: "",
                hourly_rate: 0,
                standRate: 0,
                standard: true,
                performance: false,
            }));
            setError((prevData) => ({ ...prevData, performance_value: "", hourly_rate: "", }));
            setCalculatedValues(false);
        }
    };

    // Additional modal
    const handleAdditionalCostModal = (item) => {
        dispatch(setStoreAdditionalCostModal({
            show: "ADDITIONAL_COST_MODAL",
            data: { item: item },
            callBackModal: (formData) => handleAdditionalCostFormData(formData),
        }))
    }

    //additional modal callback
    const handleAdditionalCostFormData = (AdditionalCostData) => {
        setFormData((data) => ({
            ...data,
            additional_cost: AdditionalCostData,
        }))
    }

    //handleEditDeleteAdditionalCost
    const handleEditDeleteAdditionalCost = (state) => {
        if (state === "edit") {
            dispatch(setStoreAdditionalCostModal({
                show: "ADDITIONAL_COST_MODAL",
                data: { additionalCoast: formData?.additional_cost },
                callBackModal: (formData) => handleAdditionalCostFormData(formData),
            }))
        }
        if (state === "delete") {
            dispatch(setStoreDiscardChangesModal({
                show: "DISCARD_CHANGES_MODAL",
                data: { description: "Are you sure you want to delete this additional cost ?" },
                callBackModal: () => setFormData((data) => ({ ...data, additional_cost: [] }))
            }))
        }
    }

    // console.log("formData", formData);

    //validation
    const validation = () => {
        let val = true;
        if (!formData?.service_name) {
            error.service_name = t("Please select the service type")
            val = false;
        }
        if (!formData?.category_name) {
            error.category_name = t("Please select the  frequency")
            val = false;
        }
        if (!formData?.surface_area) {
            error.surface_area = t("Please enter surface area");
            val = false;
        } else if (formData.surface_area <= 0) {
            error.surface_area = t(" must be a positive number");
            val = false;
        }
        if (selectedOption === 'performance') {
            if (!formData?.value) {
                error.performance_value = t("Please enter value in m2/Hr");
                val = false;
            } else if (formData.value <= 0) {
                error.performance_value = t(" must be a positive number");
                val = false;
            }
            if (!formData?.hourly_rate) {
                error.hourly_rate = t("Please enter Hourly Rate");
                val = false;
            } else if (formData?.hourly_rate <= 0) {
                error.hourly_rate = t(" must be a positive number");
                val = false;
            }
        }

        if (selectedOption === 'standard') {
            if (!formData.value) {
                error.standard_value = t("Please enter a value in €/m2");
                val = false;
            } else if (formData.value <= 0) {
                error.standard_value = t(" must be a positive number");
                val = false;
            }
        }
        setError((prevData) => ({
            ...prevData,
            ...error
        }));

        return val;
    };

    //calculations
    const handleCalculateClick = () => {
        const isValid = validation();
        if (!isValid) {
            console.log("Validation failed.");
            return;
        }
        if (selectedOption === 'performance') {
            if (lang === "de") {
                UtilsGetCalculations?.calculatePerformanceGermen({ formData, setFormData });
            }
            else {
                UtilsGetCalculations?.calculatePerformance({ formData, setFormData });
            }
            setFormData(prevFormData => ({
                ...prevFormData,
                standRate: 0
            }));
            setCalculatedValues(true);
        } else if (selectedOption === 'standard') {
            if (lang === "de") {
                UtilsGetCalculations?.calculateStandardGermen({ formData, setFormData });
            }
            else {
                UtilsGetCalculations?.calculateStandard({ formData, setFormData });
            }

            setFormData(prevFormData => ({
                ...prevFormData,

                // per_exceution_hour: 0,
                // per_exceution: 0,
                // yearly: 0,
            }));
            setCalculatedValues(true)
        }
    };

    // console.log('formData', formData);

    const onSubmit = () => {
        if (validation()) {
            callAddEditGlassCleaningApi();
        } else {
            console.log("Validation failed.");
        }
    }

    const callServiceListApi = () => {
        const param = {
            transmit: "broadcast",
            url: "service_type_list",
            request: {
                category_id: currentCategory?.id,
            }
        };
        send(param);
    };


    const callFrequencyListApi = (page) => {
        const param = {
            transmit: "broadcast",
            url: "frequency_list",
            request: {
                // category_id: currentCategory?.id,
                limit: "20",
                "page_no": page,
                "order_by": "-updated_at"
            }
        };
        send(param);
    };

    const trimAdditional = (additionalCost) => {

        const filteredAdditionalCost = additionalCost.map((item) => {
            const filteredItem = Object.keys(item).reduce((acc, key) => {
                if (item[key] !== "" && item[key] !== null && item[key] !== 0 && item[key] !== '0.00') {
                    acc[key] = item[key];
                }
                return acc;
            }, {});
            return Object.keys(filteredItem).length > 1 ? filteredItem : null;
        }).filter(item => item !== null);
        return filteredAdditionalCost;
    }

    const callAddEditGlassCleaningApi = () => {
        dispatch(setStoreGlobalLoader(service ? "update_service" : "add_glass_calculation"))
        const param = {
            transmit: "broadcast",
            url: service ? "update_service" : "add_glass_calculation",
            request: {
                service_name: formData?.service_name,
                surface_area: formData?.surface_area,
                frequency: formData?.frequency,
                performance: formData?.performance,
                standard: formData?.standard,
                value: formData?.value,
                hourly_rate: formData?.hourly_rate,
                monthly_cost: formData?.monthly_cost,
                additional_cost: trimAdditional(formData?.additional_cost),
                per_exceution: formData?.per_exceution,
                yearly: formData?.yearly,
                monthly_hour: formData?.monthly_hour,
                yearly_hour: formData?.yearly_hour,
                per_exceution_hour: formData?.per_exceution_hour,
                ...(!service && { customer_id: customerDetails?.[0]?.id || "" }),
                ...(!service && { category_id: currentCategory?.id }),
                ...(!service && { category_name: currentCategory?.name }),
                ...(!service && { calculation_id: currentCategory?.calculationId ? currentCategory?.calculationId : "" }),
                ...(service && { service_id: formData?.service_id }),
            }
        };
        send(param, device_id);
    };
    useEffect(() => {
        if (ws) {
            callServiceListApi();
            callFrequencyListApi(1);
        }
    }, [ws]);

    useEffect(() => {
        if (websocketReqRes?.url === "add_glass_calculation" || websocketReqRes?.url === "update_service") {
            dispatch(setStoreGlobalLoader());
            if (websocketReqRes?.status === 200) {
                toast.success(websocketReqRes?.response?.msg)
                dispatch(setWebsocketReqRes())
                UtilsGetList.callGetCalculationListApi({ ws, device_id, calculationId: websocketReqRes?.response?.data?.calculation_id, lang: lang })
                navigate(`${App_url.link.calculationOverview}/${websocketReqRes?.response?.data?.calculation_id}`)
                // navigate(App_url.link.savedCalculation);
            } else {
                toast.info(websocketReqRes?.response?.msg || "error");
                dispatch(setWebsocketReqRes());
            }
        }
    }, [websocketReqRes?.url])


    return (
        <ContentLayout title={'Add New Calculation'}>
            <div className='category mx-1'>
                <Breadcrumb className="breadcrumb-main">
                    <Breadcrumb.Item>
                        <AnchorLink to={App_url.link.savedCalculation}>{t("Saved Calculation")} </AnchorLink>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item >
                        <AnchorLink to={`${App_url.link.selectCategory}/${customerDetails?.[0]?.id}`}>{t("Select Category")}</AnchorLink>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active> {t("Glass Cleaning")}</Breadcrumb.Item>
                </Breadcrumb>
                <div className='row mt-2'>
                    <div className='col-8'>
                        <Card className='card glass-cleaning-card '>
                            <div className='card-body-header'>
                                <h6 className='card-body-header-title p-3'>{t("Glass Cleaning")}</h6>
                            </div>
                            <div className='heading mt-0'>
                                <h6>{t("Customer")} : <span className='text-capitalize'>{customerDetails?.[0]?.customer_name ? customerDetails?.[0]?.customer_name : customerDetails?.[0]?.company_name}</span></h6>
                            </div>
                            <Card.Body>
                                <div className='row mt-2'>
                                    <div className='col'>
                                        <label htmlFor="form_group form-label">{t("Select Service type")}</label>
                                        <Dropdown className={`status-dropdown ${error?.service_name ? "error" : ""}`} onSelect={(e) => handleSelect(e, "serviceType")}>
                                            <Dropdown.Toggle variant="secondary" className='status-dropdown-toggle col-12 text-start' id="dropdown-basic">
                                                {formData?.service_name ? formData?.service_name : "Select Service Type"}
                                                <Icon image attrIcon={App_url.img.DropDownIcon} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className='dropdown-menu'>
                                                {serviceTypeOptions && serviceTypeOptions?.map(option => (
                                                    <Dropdown.Item key={option.value} eventKey={option.value} style={{ paddingRight: '8px' }}>
                                                        <div className='check_menu'>
                                                            <span>
                                                                {option.label}
                                                            </span>
                                                            {formData?.service_name === option.value ? (
                                                                <span className='title'> <Icon image attrIcon={App_url.img.Radiobutton2} /> </span>
                                                            ) : (
                                                                <span className='title'> <Icon image attrIcon={App_url.img.RadioCircleIcon} /> </span>
                                                            )}
                                                        </div>
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                            {error?.service_name && <div className='mt-1 ps-1 text-danger fs-13' >{error?.service_name}</div>}
                                        </Dropdown>
                                        <div className='mt-3'>
                                            <label htmlFor="">{t("Select cleaning days / year frequency")}</label>
                                            <Dropdown className={`status-dropdown ${error?.category_name ? "error" : ""}`} onSelect={(e) => handleSelect(e, "frequency")}>
                                                <Dropdown.Toggle variant="secondary" className='status-dropdown-toggle col-12 text-start' id="dropdown-basic">
                                                    {formData?.category_name ? formData?.category_name : 'Select cleaning days or year frequency'}
                                                    <Icon image attrIcon={App_url.img.DropDownIcon} />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className='dropdown-menu'>
                                                    {frequencyOptions && frequencyOptions.map(option => (
                                                        <Dropdown.Item key={option?.value} eventKey={option?.value} style={{ paddingRight: '8px' }}>
                                                            <div className='check_menu'>
                                                                <span>
                                                                    {option?.label}
                                                                </span>
                                                                {formData?.category_name === option?.label ? (
                                                                    <span className='title'> <Icon image attrIcon={App_url.img.Radiobutton2} /> </span>
                                                                ) : (
                                                                    <span className='title'> <Icon image attrIcon={App_url.img.RadioCircleIcon} /> </span>
                                                                )}
                                                            </div>
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                                {error?.category_name && <div className='mt-1 ps-1 text-danger fs-13' >{error?.category_name}</div>}
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className='col '>
                                        <InputGroup
                                            label={t("Surface area (in m2)")}
                                            placeholder={t("Enter Value")}
                                            name={"surface_area"}
                                            value={formData?.surface_area}
                                            error={error?.surface_area}
                                            onChange={onChange}
                                            // formClassName="col-7 col-sm-7 col-md-7 col-lg-5"
                                            type="text"
                                            rightLabel={'m2'}
                                        // number
                                        // allowDot
                                        />
                                    </div>
                                </div>
                                <div className='radios'>
                                    <div className='row'>
                                        <div className='col'>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="performance"
                                                    id="performance"
                                                    value="performance"
                                                    checked={selectedOption === 'performance'}
                                                    onChange={handleRadioChange}
                                                />
                                                <label className="form-check-label" htmlFor="performance">
                                                    {t("Performance")}
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="standard"
                                                    id="standard"
                                                    value="standard"
                                                    checked={selectedOption === 'standard'}
                                                    onChange={handleRadioChange}
                                                />
                                                <label className="form-check-label" htmlFor="standard">
                                                    {t("Standard")}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row mt-3'>
                                    <div className='col'>
                                        <InputGroup
                                            label={t("Value in m2 /Hr")}
                                            placeholder={t("Enter Value")}
                                            name={"value"}  // Matches formData property
                                            type="text"
                                            error={error?.performance_value}
                                            value={selectedOption === 'performance' ? formData?.value : ''}  // Ensure it gets an empty string if undefined
                                            onChange={onChange}  // Correctly bound onChange handler
                                            rightLabel={'m2/Hr'}
                                            disabled={selectedOption !== 'performance'}  // Disabled only when not 'performance'
                                            className={selectedOption === 'performance' ? 'enabled-class' : 'disabled-class'}
                                        // number
                                        // allowDot
                                        />
                                        <InputGroup
                                            label={t("Hourly Rate (in €)")}
                                            placeholder={t("Hourly Rate (in €)")}
                                            name={"hourly_rate"}
                                            formClassName="input-text mt-3"
                                            type="text"
                                            error={error?.hourly_rate}
                                            value={selectedOption === 'performance' ? formData?.hourly_rate : ''}
                                            onChange={onChange}  // Correct casing
                                            rightLabel={'€'}
                                            disabled={selectedOption !== 'performance'}
                                            className={selectedOption === 'performance'}
                                        // number
                                        // allowDot
                                        />
                                    </div>
                                    <div className='col'>
                                        <InputGroup
                                            label={t("Value in € / m2")}
                                            placeholder={t("Enter value")}
                                            name={"value"}
                                            type="text"
                                            error={error?.standard_value}
                                            value={selectedOption === 'standard' ? formData?.value : ''}
                                            onChange={onChange}  // Correct casing
                                            rightLabel={'€/m2'}
                                            disabled={selectedOption !== 'standard'}
                                            className={selectedOption === 'standard'}
                                        // number
                                        // allowDot
                                        />
                                    </div>
                                </div>
                                <div>
                                    <Button size={'sm'} variant={"primary"} onClick={handleCalculateClick} className={'w-25  mt-3'}>{t("Calculate")}</Button>
                                    {/* <hr /> */}
                                </div>
                                <div onClick={formData?.additional_cost?.length === 0 ? () => handleAdditionalCostModal() : undefined} className='additional-cost mt-3'>
                                    <h6 className={"addCost"} style={{ color: `${formData?.additional_cost?.length === 0 ? "" : "#ffbeac"}`, cursor: `${formData?.additional_cost?.length === 0 ? "pointer" : "default"}` }} >{t("+ Additional Cost")}</h6>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className=' col-4 ps-0'>
                        <Card className='card '>
                            <Card.Body>
                                <div className='preview'>
                                    <h6 className='preview-summary'>{t("Preview Summary")}</h6>
                                </div>
                                <div className='custom-table-preview'>
                                    <Card >
                                        <Table className='mb-0'>
                                            <thead className=''>
                                                <tr>
                                                    <th>{t("Particular")}</th>
                                                    <th>{t("Hours")}</th>
                                                    <th>{t("Cost")}</th>
                                                </tr>
                                            </thead>
                                            <tbody className='table-with-radius'>
                                                {selectedOption === 'performance' ? (
                                                    <>
                                                        <tr>
                                                            <td>{t("Each")}</td>
                                                            <td>{Utils.trimDecimal(formData?.per_exceution_hour || 0, 2)}</td>
                                                            <td>€ {Utils.trimDecimal(formData?.per_exceution || 0, 2)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{t("Yearly")}</td>
                                                            <td>{Utils.trimDecimal(formData?.yearly_hour || 0, 2)}</td>
                                                            <td>€ {Utils.trimDecimal(formData?.yearly || 0, 2)}</td>
                                                        </tr>
                                                    </>
                                                ) : (
                                                    <>
                                                        <tr>
                                                            <td>{t("Standard")}</td>
                                                            <td>-</td>
                                                            <td>€ {formData?.standRate || 0}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{t("Yearly")}</td>
                                                            <td>-</td>
                                                            <td>€ {formData?.yearly || 0}</td>
                                                        </tr>
                                                    </>
                                                )}
                                            </tbody>
                                        </Table>
                                    </Card>
                                </div>
                                <div className='additional-cost'>
                                    <h6 className='addCost'>{t("Additional Cost")}</h6>
                                    {formData?.additional_cost?.length > 0 &&
                                        <span>
                                            <Icon onClick={() => handleEditDeleteAdditionalCost("edit")} image className={'me-2'} attrIcon={App_url.img.EditIcon2} />
                                            <Icon onClick={() => handleEditDeleteAdditionalCost("delete")} image className={'me-1'} attrIcon={App_url.img.DeleteIcon2} />
                                        </span>
                                    }
                                </div>
                                <div className='custom-table-preview'>
                                    <Card>
                                        <Table className='mb-0'>
                                            <thead className=''>
                                                <tr>
                                                    <th>{t("Particular")}</th>
                                                    <th>{t("Frequency")}</th>
                                                    <th >{t("Cost")}</th>
                                                </tr>
                                            </thead>
                                            <tbody className='table-with-radius'>
                                                {/* {formData?.additional_cost?.map((costItem, index) => (
                                                    <tr key={costItem.type}>
                                                        <td>{costItem.type.charAt(0).toUpperCase() + costItem.type.slice(1)}</td>
                                                        <td>{costItem?.no_of_operations_per_year || 0}</td>
                                                        <td>€ {parseFloat(costItem?.cost_of_operations_per_year || 0)}</td>
                                                    </tr>
                                                ))} */}
                                                <tr>
                                                    <td>{t("Scaffold")}</td>
                                                    <td>{formData?.additional_cost?.[0]?.no_of_operations_per_year || 0}</td>
                                                    <td>€ {parseFloat(formData?.additional_cost?.[0]?.cost_of_operations_per_year || 0)}</td>
                                                </tr>
                                                <tr>
                                                    <td>{t("Material")}</td>
                                                    <td>{formData?.additional_cost?.[1]?.no_of_operations_per_year || 0}</td>
                                                    <td>€ {parseFloat(formData?.additional_cost?.[1]?.cost_of_operations_per_year || 0)}</td>
                                                </tr>
                                                <tr>
                                                    <td>{t("Miscellaneous")}</td>
                                                    <td>{formData?.additional_cost?.[2]?.no_of_operations_per_year || 0}</td>
                                                    <td>€ {parseFloat(formData?.additional_cost?.[2]?.cost_of_operations_per_year || 0)}</td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                    </Card>
                                </div>
                                <div className=''>
                                    <Button disable={globalLoader || !calculatedValues} size={'md'} variant={"primary"} className={'w-100  mt-5'} onClick={onSubmit}>{service ? `${globalLoader ? t("Please wait..."):t("Update this Service")}` : `${globalLoader ? t("Please wait..."):t("Submit this Service")}`}</Button>

                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        </ContentLayout >
    )
}

