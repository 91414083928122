/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import ContentLayout from '../../layout/ContentLayout'
import { Breadcrumb, Card, Dropdown, Table } from 'react-bootstrap'
import AnchorLink from '../../Common/AnchorLink'
import App_url from '../../Common/Constants'
import InputGroup from '../../Common/InputGroup'
import Icon from '../../Common/Icon'
import Button from '../../Common/Button'
import { useDispatch, useSelector } from 'react-redux'
import { WebSocketContext } from '../../../WSContext'
import wsSendRequest from '../../../socketIO/wsSendRequest'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import UtilsGetCalculations from '../UtilsGetCalculations'
import Utils from '../../Common/Utils'
import { setWebsocketReqRes } from '../../../store/modules/users_data/action'
import { useTranslation } from '../../../translate'
import UtilsGetList from '../../Common/UtilsGetList'
import { setStoreGlobalLoader } from '../../../store/modules/setting_reducers/action'


const FullCleaningCal = () => {
    const { t, changeLanguage } = useTranslation();

    const { lang } = useSelector(App_url.settingReducers);

    const { ws, send } = useContext(WebSocketContext);
    const { device_id, websocketReqRes } = useSelector(App_url.userDataReducers);
    const { currentCategory, } = useSelector(App_url.calculationReducers);
    const { customerDetails } = useSelector(App_url.customerReducers);
    const { globalLoader } = useSelector(App_url.settingReducers);
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const location = useLocation();
    const { service } = location.state || {};
    // console.log("service==", service);

    const [formData, setFormData] = useState({
        employee_per_hour_cost: "",
        category_name: "",
        company_surcharge: "",
        surface_area: "",
        value: "",
        weekdayFrequency: '',
        sundayFrequency: '',
        sunday_frequency: '',
        weekday_frequency: '',
        yearly: 0,
        yearly_hour: 0,
        monthly_cost: 0,
        monthly_hour: 0,
        hourly_rate: 0,
        weekday_cost: 0,
        sunday_cost: 0,
        weekday_hour: 0,
        sunday_hour: 0,
        sunday_hourly_rate: 0,
        yearlySundayCost: 0,
        yearlySundayHours: 0,
        yearlyWeekdayCost: 0,
        yearlyWeekdayHours: 0,
        calculation_id: "",

    })
    const [error, setError] = useState({
        company_surcharge: "",
        employee_per_hour_cost: "",
        value: "",
        surface_area: "",
        weekdayFrequency: "",
        sundayFrequency: "",
    })
    function convertCommaToDot(number) {
        return number?.toString()?.replace(',', '.');
    }
    
    const onChange = (e) => {
        let { employee_per_hour_cost, company_surcharge } = formData;
        employee_per_hour_cost = convertCommaToDot(formData?.employee_per_hour_cost);
        company_surcharge = convertCommaToDot(formData?.company_surcharge)
        // const value = convertCommaToDot(e.target.value);

        if (e.target.name === "company_surcharge" && employee_per_hour_cost > 0 && e.target.value > 0) {
            const costForPercent = parseFloat(employee_per_hour_cost) * (parseFloat(e.target.value) * 0.01);
            const hourly_rate = parseFloat(employee_per_hour_cost) + parseFloat(costForPercent);
            setFormData((data) => ({
                ...data,
                hourly_rate: hourly_rate,
            }));
        }
        if (e.target.name === "employee_per_hour_cost" && company_surcharge > 0 && e.target.value > 0) {
            const costForPercent = parseFloat(e.target.value) * (parseFloat(company_surcharge) * 0.01);
            const hourly_rate = parseFloat(e.target.value) + parseFloat(costForPercent);
            setFormData((data) => ({
                ...data,
                hourly_rate: hourly_rate,
            }));
        }

        setFormData((prevData) => ({
            ...prevData,
            [e.target.name]: e.target.value,
        }));

        setError((prevData) => ({
            ...prevData,
            [e.target.name]: "",
        }));
    };


    useEffect(() => {
        if (service) {
            setFormData((prevData) => ({
                ...prevData,
                ...service,
                service_id: service?.id,
                yearly: service?.yearly_cost,
                weekdayFrequency: selectWeekDayList?.find(item => item?.freq === service?.weekday_frequency)?.label || "",
                sundayFrequency: statusSundayList?.find(item => item?.freq === service?.sunday_frequency)?.label || "",

            }))
        }
    }, [service])

    const selectWeekDayList = [
        { label: t('No Weekday'), value: t('No Weekday'), freq: 0 },
        { label: t("Once a week"), value: t("Once a week"), freq: 52 },
        { label: t("Twice a week"), value: t("Twice a week"), freq: 104 },
        { label: t("Three times a week"), value: t("Three times a week"), freq: 156 },
        { label: t("Four times a week"), value: t("Four times a week"), freq: 208 },
        { label: t("Five times a week"), value: t("Five times a week"), freq: 260 },
        { label: t("Six times a week"), value: t("Six times a week"), freq: 312 },
        { label: t("Four times a month"), value: t("Four times a month"), freq: 48 },
        { label: t("Three times a month"), value: t("Three times a month"), freq: 36 },
        { label: t("Twice a month"), value: t("Twice a month"), freq: 24 },
        { label: t("Once a month"), value: t("Once a month"), freq: 12 },
    ];

    const statusSundayList = [
        { label: t('No Sunday'), value: t('No Sunday'), freq: 0 },
        { label: t("Once a week"), value: t("Once a week"), freq: 52 },
        { label: t("Four times a month"), value: t("Four times a month"), freq: 48 },
        { label: t("Three times a month"), value: t("Three times a month"), freq: 36 },
        { label: t("Twice a month"), value: t("Twice a month"), freq: 24 },
        { label: t("Once a month"), value: t("Once a month"), freq: 12 },
    ];


    const selectedWeekday = selectWeekDayList.find(option => option.value === formData.weekdayFrequency)?.label || t('Weekday Frequency');
    const handleSelectWeekDay = (eventKey) => {
        const selectedOption = selectWeekDayList.find(option => option.value === eventKey);
        if (selectedOption) {
            console.log("Selected weekday Frequency:", selectedOption.freq);

            setFormData(prevFormData => ({
                ...prevFormData,
                weekdayFrequency: selectedOption.value,  // Store value for dropdown
                weekday_frequency: selectedOption.freq,        // Store frequency for calculations
            }));
            setError((prevData) => ({
                ...prevData,
                weekdayFrequency: "",
            }))
        }
    };
    const selectedSunday = statusSundayList.find(option => option.value === formData.sundayFrequency)?.label || t('Sunday Frequency');
    const handleSelectSunday = (eventKey) => {
        // console.log("eventKey",eventKey)
        const selectedOption = statusSundayList.find(option => option.value === eventKey);

        if (selectedOption) {
            console.log("Selected sunday Frequency:", selectedOption.freq);
            setFormData(prevFormData => ({

                ...prevFormData,
                sundayFrequency: selectedOption.value,
                sunday_frequency: selectedOption.freq,
            }));
            setError((prevData) => ({
                ...prevData,
                sundayFrequency: "",
            }))
        }
    };
    // console.log("currentCategory",currentCategory)

    // console.log("formData?.weekdayFrequency", formData?.weekday_frequencyuency)
    const callAddEditFullCleaningApi = () => {
        dispatch(setStoreGlobalLoader(service ? "update_full_calculation":"add_full_calculation"))
        const param = {
            transmit: "broadcast",
            url: service ? "update_full_calculation" : "add_full_calculation",
            request: {
                surface_area: formData?.surface_area,
                value: formData?.value,
                hourly_rate: formData?.hourly_rate,
                monthly_cost: formData?.monthly_cost,
                yearly: formData?.yearly,
                employee_per_hour_cost: formData?.employee_per_hour_cost,
                company_surcharge: formData?.company_surcharge,
                weekday_frequency: formData?.weekday_frequency,
                sunday_frequency: formData?.sunday_frequency,
                sunday_cost: formData?.sunday_cost,
                weekday_cost: formData?.weekday_cost,
                sunday_hourly_rate: formData?.sunday_hourly_rate,
                sunday_hour: formData?.sunday_hour,
                weekday_hour: formData?.weekday_hour,
                monthly_hour: formData?.monthly_hour,
                yearly_hour: formData?.yearly_hour,
                ...(!service && { customer_id: customerDetails?.[0]?.id || "" }),
                ...(!service && { category_id: currentCategory?.id }),
                ...(!service && { category_name: currentCategory?.name }),
                ...(!service && { calculation_id: currentCategory?.calculationId ? currentCategory?.calculationId : "" }),
                ...(service && { customer_id: formData?.customer_id }),
                ...(service && { category_id: formData?.category_id }),
                ...(service && { category_name: formData?.category_name }),
                ...(service && { service_id: formData?.service_id }),
                ...(service && { calculation_id: formData?.calculation_id }),
            },
        };
        send(param, device_id);

    };

    useEffect(() => {
        if (websocketReqRes?.url === "add_full_calculation" || websocketReqRes?.url === "update_full_calculation") {
            dispatch(setStoreGlobalLoader());
            if (websocketReqRes?.status === 200) {
                toast.success(websocketReqRes?.response?.msg)
                dispatch(setWebsocketReqRes())
                UtilsGetList.callGetCalculationListApi({ ws, device_id, calculationId: websocketReqRes?.response?.data?.calculation_id, lang: lang })
                navigate(`${App_url.link.calculationOverview}/${websocketReqRes?.response?.data?.calculation_id}`)
                // navigate(App_url.link.savedCalculation);
            } else {
                toast.info(websocketReqRes?.response?.msg || "error");
                dispatch(setWebsocketReqRes());
            }
        }
    }, [websocketReqRes?.url])

    /*  function convertDotToComma(number) {
                return number?.toString()?.replace(',', '.');
     }
    const emp= convertDotToComma(formData?.value);
 console.log(emp) */
    const validation = () => {
        let val = true;
        let error = {};

        if (!formData.surface_area) {
            error.surface_area = t("Please enter surface area");
            val = false;
        } else if (formData.surface_area <= 0) {
            error.surface_area = t("must be a positive number");
            val = false;
        }
        if (!formData.company_surcharge) {
            error.company_surcharge = t("Please enter company surcharge");
            val = false;
        }
        if (!formData.employee_per_hour_cost) {
            error.employee_per_hour_cost = t("Enter employee per hour cost");
            val = false;
        }
        if (!formData.value) {
            error.value = t("Please enter performance value");
            val = false;
        } else if (formData.value <= 0) {
            error.value = t(" must be a positive number");
            val = false;
        }
        if (!formData?.weekdayFrequency) {
            error.weekdayFrequency = t("Please enter weekend frequency");
            val = false;
        }
        if (!formData?.sundayFrequency) {
            error.sundayFrequency = t("Please enter sunday frequency");
            val = false;
        }

        setError((prevData) => ({
            ...prevData,
            ...error
        }));

        return val;
    };

    // console.log("formData", formData);
    const handleCalculateClick = () => {
        // Perform validation first
        const isValid = validation();
        if (!isValid) {
            console.log("Validation failed.");
            return;
        }
        if (lang === 'de') {
            UtilsGetCalculations?.calculateFullCleaningGermen({ formData, setFormData });
        }
        else {
            UtilsGetCalculations?.calculateFullCleaning({ formData, setFormData });
        }
    }
    console.log("formData", formData)
    const onSubmit = () => {
        const isValid = validation();
        if (!isValid) {
            console.log("Validation failed.");
            return;
        }
        callAddEditFullCleaningApi()
    }

    return (
        <ContentLayout>
            <div className='full-cleaning'>
                <Breadcrumb className="breadcrumb-main">
                    <Breadcrumb.Item>
                        <AnchorLink to={App_url.link.savedCalculation}>{t("Saved Calculation")} </AnchorLink>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item >
                        <AnchorLink to={`${App_url.link.selectCategory}/${customerDetails?.[0]?.id}`}>{t("Select Category")}</AnchorLink>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>{t("Full Cleaning")}</Breadcrumb.Item>
                </Breadcrumb>
                <div className='first row '>
                    <div className='col-8  pe-0'>
                        <Card>
                            <div className='card-body-header'>
                                <h6 className='card-body-header-title p-3'>{t("Full Cleaning")}</h6>{console.log("customerDetails", customerDetails)}
                            </div>
                            <div className='heading mt-0'>
                                <h6>{t("Customer")} : <span className='text-capitalize'>{customerDetails?.[0]?.customer_name ? customerDetails?.[0]?.customer_name : customerDetails?.[0]?.company_name}</span></h6>
                            </div>
                            <Card.Body>
                                <div className='rate'>
                                    <div className='row'>
                                        <div className='col-5 p-0'>
                                            <div className='preview p-0'>
                                                <h6>{t("Hourly Rate (in € )")}</h6>
                                            </div>
                                            <div className='emp-per-hr-cost'>
                                                <label>{t("Employer Per Hour Cost")}</label>
                                                <InputGroup
                                                    name={"employee_per_hour_cost"}
                                                    formClassName={`${error?.employee_per_hour_cost ? "error" : ""} emp-per-hr-cost-input-box `}
                                                    value={formData?.employee_per_hour_cost}
                                                    // error={error?.employee_per_hour_cost}
                                                    onChange={onChange}
                                                    type="text"
                                                    rightLabel={'€'}
                                                // number
                                                // allowDot
                                                />
                                            </div>
                                            {error?.employee_per_hour_cost && <div className='mt-1 text-danger fs-13' >{error?.employee_per_hour_cost}</div>}
                                        </div>
                                        <div className='col-5 p-0 cmp-surcharge-col'>
                                            <div className='cmp-surcharge'>
                                                <label>{t("Company surcharges in %")}</label>
                                                <InputGroup
                                                    name={"company_surcharge"}
                                                    formClassName={`${error?.company_surcharge ? "error" : ""} cmp-surcharge-input-box`}
                                                    value={formData?.company_surcharge}
                                                    // error={error?.company_surcharge}
                                                    onChange={onChange}
                                                    type="text"
                                                    rightLabel={'%'}
                                                // number
                                                // allowDot
                                                />
                                            </div>
                                            {error?.company_surcharge && <div className='mt-1 text-danger fs-13' >{error?.company_surcharge}</div>}
                                        </div>
                                        <div className='col-2  p-0'>
                                            <h6 className='text-rate'>  € {Utils.trimDecimal(formData?.hourly_rate || 0, 2)}</h6>
                                        </div>
                                    </div>

                                </div>
                                <div className='surface row'>
                                    <div className='col'>
                                        <InputGroup
                                            label={t("Surface area (in m2)")}
                                            placeholder={t("Enter Value")}
                                            name={"surface_area"}
                                            value={formData?.surface_area}
                                            error={error?.surface_area}
                                            onChange={onChange}
                                            type="text"
                                            rightLabel={'m2'}
                                        // number
                                        // allowDot
                                        />
                                    </div>
                                    <div className='col'>
                                        <InputGroup
                                            label={t("Performance Value")}
                                            placeholder={t("Enter Value")}
                                            name={"value"}
                                            value={formData?.value}
                                            error={error?.value}
                                            onChange={onChange}
                                            type="text"
                                            rightLabel={' m2/Hr'}
                                        // number
                                        // allowDot
                                        />
                                    </div>
                                </div>
                                <div className=' row mt-3'>
                                    <div className='col'>
                                        <label htmlFor=" ">{t("Weekday Frequency")}</label>
                                        <Dropdown className={`status-dropdown ${error?.weekdayFrequency ? "error" : ""}`} onSelect={handleSelectWeekDay}>
                                            <Dropdown.Toggle variant="secondary" className='status-dropdown-toggle col-12 text-start' id="dropdown-basic">
                                                {selectedWeekday}
                                                <Icon image attrIcon={App_url.img.DropDownIcon} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className='dropdown-menu'>
                                                {selectWeekDayList && selectWeekDayList.map(option => (
                                                    <Dropdown.Item key={option.value} eventKey={option.value} style={{ paddingRight: '8px' }}>
                                                        <div className='check_menu'>
                                                            <span>
                                                                {option.label}
                                                            </span>
                                                            {formData?.weekdayFrequency === option.value ? (
                                                                <span className='title'>
                                                                    <Icon image attrIcon={App_url.img.Radiobutton2} />
                                                                </span>
                                                            ) : (
                                                                <span className='title'>
                                                                    <Icon image attrIcon={App_url.img.RadioCircleIcon} />
                                                                </span>
                                                            )}
                                                        </div>
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                            {error?.weekdayFrequency && <div className='mt-1 ps-1 text-danger fs-13' >{error?.weekdayFrequency}</div>}
                                        </Dropdown>
                                    </div>
                                    <div className='col '>
                                        <label htmlFor="">{t("Sunday Frequency")}</label>
                                        <Dropdown className={`status-dropdown ${error?.sundayFrequency ? "error" : ""}`} onSelect={handleSelectSunday}>
                                            <Dropdown.Toggle variant="secondary" className='status-dropdown-toggle col-12 text-start' id="dropdown-basic">
                                                {selectedSunday}
                                                <Icon image attrIcon={App_url.img.DropDownIcon} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className='dropdown-menu'>
                                                {statusSundayList && statusSundayList.map(option => (
                                                    <Dropdown.Item key={option.value} eventKey={option.value} style={{ paddingRight: '8px' }}>
                                                        <div className='check_menu'>
                                                            <span>
                                                                {option.label}
                                                            </span>
                                                            {formData?.sundayFrequency === option.value ? (
                                                                <span className='title'>
                                                                    <Icon image attrIcon={App_url.img.Radiobutton2} />
                                                                </span>
                                                            ) : (
                                                                <span className='title'>
                                                                    <Icon image attrIcon={App_url.img.RadioCircleIcon} />
                                                                </span>
                                                            )}
                                                        </div>
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                            {error?.sundayFrequency && <div className='mt-1 ps-1 text-danger fs-13' >{error?.sundayFrequency}</div>}
                                        </Dropdown>
                                    </div>
                                </div>
                                <div>
                                    <Button size={'sm'} variant={"primary"} onClick={handleCalculateClick} className={'  mt-3'}>{t("Calculate")}</Button>
                                </div>
                            </Card.Body>
                        </Card>

                    </div>
                    <div className='col-4'>
                        <Card className='card  full-cleaning-preview'>
                            <Card.Body>
                                <div className='preview'>
                                    <h6 className=''>{t("Preview Summary")}</h6>
                                </div>
                                <Card>
                                    <div className='p-2'>

                                        <h6>{t("Surface Area")}: {formData?.surface_area} m<sup className='fs-10'>2</sup></h6>
                                    </div>
                                    <Table>
                                        <thead className=''>
                                            <tr className='preview-thead'>
                                                <th>{t("Particular")}</th>
                                                <th>{t("Hours")}</th>
                                                <th>{t("Cost")}</th>
                                            </tr>
                                        </thead>
                                        <tbody className='table-with-radius preview-tbody'>
                                            <tr>
                                                <td>{t("Sunday")}</td>
                                                <td>{(Utils.trimDecimal(formData?.sunday_hour, 2) || 0)}</td>
                                                <td>€ {(Utils.trimDecimal(formData?.sunday_cost, 2) || 0)}</td>
                                            </tr>
                                            <tr>
                                                <td>{t("Weekday")}</td>

                                                <td>{(Utils.trimDecimal(formData?.weekday_hour, 2) || 0)}</td>
                                                <td>€ {(Utils.trimDecimal(formData?.weekday_cost, 2) || 0)}</td>
                                            </tr>
                                            <tr>
                                                <td>{t("Monthly")}</td>
                                                <td>{(Utils.trimDecimal(formData.monthly_hour, 2) || 0)}</td>
                                                <td>€ {(Utils.trimDecimal(formData?.monthly_cost, 2) || 0)}</td>

                                            </tr>
                                            <tr>
                                                <td>{t("Yearly")}</td>
                                                <td>{(Utils.trimDecimal(formData?.yearly_hour, 2) || 0)}</td>
                                                <td>€ {(Utils.trimDecimal(formData?.yearly, 2) || 0)}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Card>


                                <div className=''>
                                    <Button disable={globalLoader || formData?.yearly_hour === 0} onClick={onSubmit} size={'md'} variant={"primary"} className={'w-100  mt-5'}>{service ? `${globalLoader ? t("Please wait..."):t("Update this Service")}` : `${globalLoader ? t("Please wait..."):t("Submit this Service")}`}</Button>

                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        </ContentLayout>
    )
}

export default FullCleaningCal