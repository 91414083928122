/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import ContentLayout from '../../layout/ContentLayout'
import { Breadcrumb, Card, Dropdown, Form } from 'react-bootstrap'
import InputGroup from '../../Common/InputGroup'
import AnchorLink from '../../Common/AnchorLink'
import Button from '../../Common/Button'
import App_url from '../../Common/Constants'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Icon from '../../Common/Icon'
import Utils from '../../Common/Utils'
import { WebSocketContext } from '../../../WSContext'
import wsSendRequest from '../../../socketIO/wsSendRequest'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from '../../../translate'
import { toast } from 'react-toastify'
import { setWebsocketReqRes } from '../../../store/modules/users_data/action'
import { setStoreGlobalLoader } from '../../../store/modules/setting_reducers/action'
import UtilsGetList from '../../Common/UtilsGetList'
import { setStoreOfferModal } from '../../../store/modules/modals/action'

export default function ProspectsAddEdit(props) {
  const { t, changeLanguage } = useTranslation();
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const { ws, send } = useContext(WebSocketContext)
  const { device_id, websocketReqRes } = useSelector(App_url.userDataReducers);
  const { customerDetails } = useSelector(App_url.customerReducers);
  const { globalLoader, lang } = useSelector(App_url.settingReducers);
  const { id: customerId } = useParams();
  console.log("customerId", customerId)
  const location = useLocation()
  const [formData, setFormData] = useState({
    company_name: "",
    company_mobile: "",
    customer_name: "",
    email: "",
    mobile: "",
    postal_code: "",
    city: "",
    street_no: "",
    customer_type: "",
    is_active: true,
    customer_position: "",
    industry_type: "",
    company_description: "",
    company_address: "",
    last_name: "",
    status: "",
  });
  const [error, setError] = useState({
    company_name: "",
    company_mobile: "",
    customer_name: "",
    email: "",
    mobile: "",
    postal_code: "",
    city: "",
    street_no: "",
    customer_type: "",
    customer_position: "",
    industry_type: "",
    company_description: "",
    company_address: "",
    last_name: "",
    status: "",
  })

  const [statusSaved, setStatusSaved] = useState(false)
  const [statusEdit, setStatusEdit] = useState(false);
  const onChange = (e) => {
    setFormData((data) => ({
      ...data,
      [e.target.name]: e.target.value
    }))
    setError((prevData) => ({
      ...prevData,
      [e.target.name]: "",
      ...(e.target.name === "company_name" && { customer_name: "" }),
      ...(e.target.name === "customer_name" && { company_name: "" }),

    }))
  }

  const customerTypeOptions = [
    { label: t("Prospects"), value: t("prospects") },
    { label: t("Old Customer"), value: t("oldCustomer") },
    { label: t("Customers"), value: t("customers") },
  ]
  const statusOptionList = [
    { label: t("Not Contacted"), value: t("Not Contacted") },
    { label: t("Not Interested"), value: t("Not Interested") },
    { label: t("Re-Schedule Call"), value: t("Re-Schedule Call") },
    { label: t("Share Brochure"), value: t("Share Brochure") },
    { label: t("Schedule Appointment"), value: t("Schedule Appointment") },
  ]
  const iconMap = {
    [t("Not Contacted")]: App_url.img.RedBulletIcon,
    [t("Not Interested")]: App_url.img.GrayBulletIcon,
    [t("Re-Schedule Call")]: App_url.img.YellowBulletIcon,
    [t("Share Brochure")]: App_url.img.GreenBulletIcon,
    [t("Schedule Appointment")]: App_url.img.BlueBulletIcon,
  };
  const validation = () => {
    let val = true;

    // const email = Utils.ValidateEmail(formData?.email);
    // if (email) {
    //   error.email = email;
    //   val = false;
    // }
    if (!formData.company_name && !formData?.customer_name) {
      error.company_name = t("Please enter company name");
      error.customer_name = t("Please enter first name");
      val = false;
    }

    // if (!formData.company_mobile) {
    //   error.company_mobile = t("Please enter phone number");
    //   val = false;
    // }

    // if (!formData.customer_name && !formData.company_name) {
    //   error.customer_name = t("Please enter first name");
    //   val = false;
    // }
    // if (!formData.last_name) {
    //   error.last_name = t("Please enter last name");
    //   val = false;
    // }

    // if (!formData.mobile) {
    //   error.mobile = t("Please enter mobile number");
    //   val = false;
    // }
    // else if (!/^\d{10}$/.test(formData.mobile)) {
    //   error.mobile = "Please enter a valid 10-digit mobile number";
    //   val = false;
    // }

    // if (!formData.postal_code) {
    //   error.postal_code = t("Please enter postcode");
    //   val = false;
    // }
    // else if (!/^\d{6}$/.test(formData.postal_code)) {
    //   error.postal_code = "Please enter a valid 6-digit postal code";
    //   val = false;
    // }

    // if (!formData.city) {
    //   error.city = t("Please enter city name");
    //   val = false;
    // }

    // if (!formData.street_no) {
    //   error.street_no = t("Please enter street number");
    //   val = false;
    // }

    // if (!formData.customer_position) {
    //   error.customer_position = t("Please enter designation");
    //   val = false;
    // }

    // if (!formData.industry_type) {
    //   error.industry_type = t("Please enter industry type");
    //   val = false;
    // }

    // if (!formData.company_description) {
    //   error.company_description = t("Please enter company description");
    //   val = false;
    // }

    // if (!formData.company_address) {
    //   error.company_address = t("Please enter address");
    //   val = false;
    // }


    // Assuming `setError` is the function used to set error state
    setError((prevData) => ({
      ...prevData,
      ...error
    }));

    return val;
  };

  const confirmPayload = (formData) => {
    const payload = {
      company_name: "",
      company_mobile: "",
      customer_name: "",
      email: "",
      mobile: "",
      postal_code: "",
      city: "",
      street_no: "",
      customer_type: customerTypeOptions?.[0]?.value,
      is_active: true,
      customer_position: "",
      industry_type: "",
      company_description: "",
      company_address: "",
      last_name: ""
    };

    if (formData.company_name) {
      payload.company_name = formData.company_name;
    }
    if (formData.company_mobile) {
      payload.company_mobile = formData.company_mobile;
    }
    if (formData.customer_name) {
      payload.customer_name = formData.customer_name;
    }
    if (formData.last_name) {
      payload.last_name = formData.last_name;
    }
    if (formData.email) {
      payload.email = formData.email;
    }
    if (formData.mobile) {
      payload.mobile = formData.mobile;
    }
    if (formData.postal_code) {
      payload.postal_code = formData.postal_code;
    }
    if (formData.city) {
      payload.city = formData.city;
    }
    if (formData.street_no) {
      payload.street_no = formData.street_no;
    }
    if (formData.customer_type) {
      payload.customer_type = formData.customer_type;
    }
    if (formData.is_active !== undefined) {
      payload.is_active = formData.is_active;
    }
    if (formData.customer_position) {
      payload.customer_position = formData.customer_position;
    }
    if (formData.industry_type) {
      payload.industry_type = formData.industry_type;
    }
    if (formData.company_description) {
      payload.company_description = formData.company_description;
    }
    if (formData.company_address) {
      payload.company_address = formData.company_address;
    }

    return payload;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (validation()) {
      dispatch(setStoreGlobalLoader(customerId ? "customer_edit" : "customer_add"));
      callAddEditCustomerApi();
    } else {
      console.log("error in validation");
    }
  }

  const callStatusUpdateApi = (e) => {
    setFormData((data) => ({
      ...data,
      status: e
    }))
    const param = {
      transmit: "broadcast",
      url: "customer_status",
      request: {
        customer_id: customerId,
        status: e,
      },
      toast: true,
    };
    send(param, device_id);
  }

  const callAddEditCustomerApi = () => {
    const payload = confirmPayload(formData);
    if (customerId) {
      payload.customer_id = customerId;
    }
    // console.log("payload", payload);
    const param = {
      transmit: "broadcast",
      url: customerId ? "customer_edit" : "customer_add",
      request: payload,
      // toast: true,
      // redirect: App_url.link.prospects,
    };
    send(param, device_id)
  }

  useEffect(() => {
    if (websocketReqRes?.url === "customer_add" || websocketReqRes?.url === "customer_edit") {
      if (websocketReqRes?.status === 200) {
        toast.success(websocketReqRes?.response?.msg)
        if ((location?.state?.fromPage === "prospectsToDoScheduleAppointment" || location?.state?.fromPage === "prospectsToDoScheduleCall") && websocketReqRes?.url === "customer_edit") {
          UtilsGetList.callCustomerDetailsApi({ ws, customer_id: location?.state?.customerId, lang: lang });
          navigate(`${App_url.link.prospectsDetails}/${location?.state?.pageId}`);
        }
        if (location?.state?.fromPage === "calculationOverview" && websocketReqRes?.url === "customer_edit") {
          UtilsGetList.callCustomerDetailsApi({ ws, customer_id: location?.state?.customerId, lang: lang });
          navigate(`${App_url.link.calculationOverview}/${location?.state?.pageId}`);
        }
        if (location?.state?.fromPage == "addServiceList" && websocketReqRes?.url === "customer_edit") {
          UtilsGetList.callCustomerDetailsApi({ ws, customer_id: location?.state?.customerId, lang: lang });
          navigate(`${App_url.link.addServiceList}/${location?.state?.pageId}`, { state: location?.state?.serviceData || "" });
        }
        if (location?.state?.fromPage == "createOffer" && websocketReqRes?.url === "customer_edit") {
          UtilsGetList.callCustomerDetailsApi({ ws, customer_id: location?.state?.customerId, lang: lang });
          navigate(`${App_url.link.createOffer}`);
          dispatch(setStoreOfferModal({
              show: "OFFER_MODAL",
              data: { ...location?.state }
          }));
        }
        
       

        if (!location?.state?.fromPage) {

          navigate(App_url.link.prospects);
        }

        dispatch(setWebsocketReqRes())
      } else {
        toast.info(websocketReqRes?.response?.msg || "error");
        dispatch(setWebsocketReqRes());
      }
    }
  }, [websocketReqRes?.url])


  useEffect(() => {
    console.log("comming here...", customerId, customerDetails)
    if (customerId && customerDetails?.length > 0) {
      console.log("comming here...", customerId, customerDetails)
      setFormData((data) => ({
        ...data,
        ...customerDetails?.[0],
        // status:{label:customerDetails?.[0]?.status, value:customerDetails?.[0]?.status}
      }))
    }
  }, [customerId, customerDetails])

  const handleStatusSelect = (selectedValue) => {
    setFormData(selectedValue);
    setStatusEdit(true)
    setStatusSaved(false);
  }

  return (
    <ContentLayout title={t(props?.title)}>
      <Breadcrumb className="breadcrumb-main">
        <Breadcrumb.Item>
          <AnchorLink to={App_url.link.prospects}>{t("Prospects")}</AnchorLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{customerId ? t("Edit") : t("Add")}</Breadcrumb.Item>
      </Breadcrumb>
      <Card className='card customers-add-edit-card '>
        <Card.Body>
          <div className='card-body-header'>
            <div className='.item1  .flex-item'>
              <h6 className='card-body-header-title'>{customerId ? customerDetails?.[0]?.company_name : "Add Customer"}</h6>
            </div>
            <div className='card-body-filters'>
              {customerId &&

                <Dropdown className='col-lg-3 status-dropdown ' onSelect={callStatusUpdateApi} >
                  <Dropdown.Toggle variant="secondary" className='status-dropdown-toggle ' id="dropdown-basic">
                    <h6 className=''>{t(formData?.status)}</h6>
                    <Icon image attrIcon={App_url.img.DropDownIcon} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className='dropdown-menu'>
                    {statusOptionList && statusOptionList.map(option => {
                      const bulletIcon = iconMap[option?.value] || App_url.img.RedBulletIcon;

                      return (
                        <Dropdown.Item key={option?.value} eventKey={option?.value} style={{ paddingRight: '8px' }}>
                          <div className='check_menu'>
                            <span className='bulleticons'>
                              <Icon image attrIcon={bulletIcon} />{option?.value}
                            </span>
                            <span className='title'>
                              {formData?.status === option?.value
                                ? <Icon image attrIcon={App_url.img.Radiobutton2} className='' />
                                : <Icon image attrIcon={App_url.img.RadioCircleIcon} className='' />}
                            </span>
                          </div>
                        </Dropdown.Item>
                      );
                    })}

                  </Dropdown.Menu>
                </Dropdown>
              }
            </div>
            <Button disable={globalLoader} onClick={onSubmit} className={""} variant={"primary"}>{customerId ? t("Update") : t("Save")}</Button>
          </div>
          <hr className='card-body-header-hr'></hr>
          <Form onSubmit={onSubmit} className='row'>
            <div className='col-lg-12 mb-2'>
              <h6 className='title-info'>{t("Company Details")}</h6>
            </div>
            <div className='col-lg-8'>
              <div className='row'>
                <InputGroup
                  label={t("Company Name")}
                  placeholder={t("Company name")}
                  formClassName="col-lg-6 mb-3"
                  name="company_name"
                  value={formData?.company_name}
                  error={error?.company_name}
                  onChange={onChange}
                />
                <InputGroup
                  label={t("Phone Number")}
                  placeholder={t("Phone number")}
                  formClassName="col-lg-6 mb-3"
                  name="company_mobile"
                  value={formData?.company_mobile}
                  error={error?.company_mobile}
                  onChange={onChange}
                  rightLabel={<Icon image attrIcon={App_url.img.PhoneRingIcon} />}
                />
                <InputGroup
                  label={t("Industry Type")}
                  placeholder={t("Industry type")}
                  formClassName="col-lg-6 mb-3"
                  name="industry_type"
                  value={formData?.industry_type}
                  error={error?.industry_type}
                  onChange={onChange}
                // select
                // type={'select'}
                />
                <InputGroup
                  label={t("Customer Type")}
                  placeholder={t("Select customer type")}
                  formClassName="col-lg-6 mb-3"
                  name="customer_type"
                  option={customerTypeOptions}
                  value={formData?.customer_type ? formData?.customer_type : customerTypeOptions[0].value}
                  error={error?.customer_type}
                  onChange={onChange}
                  select
                  type={'select'}
                />
              </div>
            </div>

            <InputGroup
              label={t("Company Description:")}
              placeholder={t("Company Description")}
              formClassName="mb-3 col-12 col-lg-4"
              name="company_description"
              as={"textarea"}
              rows='5'
              value={formData?.company_description}
              error={error?.company_description}
              onChange={onChange}
            />
            <div className='col-lg-12 mb-2'>
              <h6 className='title-info'>{t("Contact Person")}</h6>
            </div>
            <InputGroup
              label={t("First Name")}
              placeholder={t("First Name")}
              formClassName="col-lg-4 mb-3"
              name="customer_name"
              value={formData?.customer_name}
              error={error?.customer_name}
              onChange={onChange}
            />
            <InputGroup
              label={t("Last Name")}
              placeholder={t("Last name")}
              formClassName="col-lg-4 mb-3"
              name="last_name"
              value={formData?.last_name}
              error={error?.last_name}
              onChange={onChange}
            />
            <InputGroup
              label={t("Mobile Number")}
              placeholder={"Mobile number"}
              formClassName="col-lg-4 mb-3"
              name="mobile"
              value={formData?.mobile}
              error={error?.mobile}
              onChange={onChange}
              rightLabel={<Icon image attrIcon={App_url.img.PhoneRingIcon} />}
            />
            <InputGroup
              label={t("Email ID")}
              placeholder={t("Email ID")}
              formClassName="col-lg-4 mb-3"
              name="email"
              value={formData?.email}
              error={error?.email}
              onChange={onChange}
              rightLabel={<Icon image attrIcon={App_url.img.EmailIcon3} />}
            />
            <InputGroup
              label={t("Designation")}
              placeholder={t("Designation")}
              formClassName="col-lg-4 mb-3"
              name="customer_position"
              value={formData?.customer_position}
              error={error?.customer_position}
              onChange={onChange}
            />
            <div className='col-lg-4'></div>
            <div className='col-lg-12 mb-2'>
              <h6 className='title-info'>{t("Address Details")}</h6>
            </div>

            <InputGroup
              label={t("Street Number")}
              placeholder={t("Street number")}
              formClassName="col-lg-4 mb-3"
              name="street_no"
              value={formData?.street_no}
              error={error?.street_no}
              onChange={onChange}
            />
            <InputGroup
              label={t("City")}
              placeholder={t("City")}
              formClassName="col-lg-4 mb-3"
              name="city"
              value={formData?.city}
              error={error?.city}
              onChange={onChange}
            />
            <InputGroup
              label={t("Post Code")}
              placeholder={t("Post code")}
              formClassName="col-lg-4 mb-3"
              name="postal_code"
              value={formData?.postal_code}
              error={error?.postal_code}
              onChange={onChange}
            />
            <InputGroup
              label={t("Address")}
              placeholder={t("Address")}
              formClassName="col-lg-8 mb-3"
              name="company_address"
              value={formData?.company_address}
              error={error?.company_address}
              onChange={onChange}
              rightLabel={<Icon image attrIcon={App_url.img.LocationIcon2} />}

            />
          </Form>

        </Card.Body>
      </Card>
    </ContentLayout >
  )
}
