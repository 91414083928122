/* eslint-disable */
import { GetRequestCallAPI } from "../../Api/api/GetRequest";
import wsSendRequest from "../../socketIO/wsSendRequest";
import App_url from "./Constants";

const callAppointmentList = (props) => {
  const param = {
    transmit: "broadcast",
    url: "appointment_list",
    request: {
      status: "",
      year_filter: "",
      limit: "500",
      page_no: "1",
      search: "",
      order_by: "-updated_at",
      customer_id: props?.customerId,
      lang: props?.lang
    },
  };
  wsSendRequest(props?.ws, param, props?.device_id)
}

const callAppointmentDetailsList = (props) => {
  // console.log("pros", props);
  const param = {
    transmit: "broadcast",
    url: "appointment_detail",
    request: {
      appointment_id: props?.appointment_id, lang: props?.lang
    }
  };
  wsSendRequest(props?.ws, param, props?.device_id);
}
const callCustomerDetailsApi = (props) => {
  const param = {
    transmit: "broadcast",
    url: "customer_detail",
    request: { customer_id: props?.customer_id, lang: props?.lang }
  };
  wsSendRequest(props?.ws, param, props?.device_id);
}

const callGetCalculationListApi = (props) => {
  const param = {
    transmit: "broadcast",
    url: "get_calculation",
    request: {
      calculation_id: props?.calculationId, lang: props?.lang
    }
  };
  wsSendRequest(props?.ws, param, props?.device_id);
};

const callCustomersListApi = (props) => {
  console.log("props===", props)
  const param = {
    transmit: "broadcast",
    url: "customer_list",
    request: {
      status: "",
      limit: "10",
      page_no: props?.page,
      pincode_search: props?.filter?.postcode,
      city_search: props?.filter?.city,
      customer_type: [""],
      industry_search: props?.filter?.industry_type,
      search: props?.filter?.search,
      order_by: "-updated_at",
      lang: props?.lang
    }
  };
  wsSendRequest(props?.ws, param, props?.device_id);
};

const getFrequencyList = (props) => {
  const param = {
    transmit: "broadcast",
    url: "frequency_list",
    request: { limit: "-1", page_no: "1", order_by: "-updated_at", lang: props?.lang }
  };
  wsSendRequest(props?.ws, param, props?.device_id);
}

const getOfferContent = async (props) =>{
    const response = await GetRequestCallAPI(App_url.api.GET_OFFER_DETAILS, props?.access_token, false, props?.lang);
    if(response?.status === 200){
      return response?.data || "";
    }
    return 0;
}

const getPinCodeCityList = (props) =>{
  const param = {"transmit":"broadcast","url":"pincode_and_city_list"}
  wsSendRequest(props?.ws, param, props?.device_id)
}

const callEmailHistoryListApi = (props) => {
  const param = {
      transmit: "broadcast",
      url: "inbox_list",
      request: {
          status: true,
          limit: "10",
          page_no: props?.page,
          search: props?.search ||"",
          customer_id: props?.customerId || "",
          form_date: props?.form_date || "",
          to_date: props?.to_date || "",
          order_by: "-updated_at",
          lang: props?.lang 
      }
  };
  wsSendRequest(props?.ws,  param, props?.device_id);
};

const callSentEmailHistoryListApi = (props) => {
  const param = {
      transmit: "broadcast",
      url: "sent_list",
      request: {
          status: true,
          limit: "10",
          page_no: props?.page,
          search: props?.search ||"",
          customer_id: props?.customerId || "",
          form_date: props?.form_date || "",
          to_date: props?.to_date || "",
          order_by: "-updated_at",
          lang: props?.lang 
      }
  };
  wsSendRequest( props?.ws, param, props?.device_id);
};


const callOfferOverviewList = (props) =>{
  const param = {
    transmit: "broadcast",
    url: "offer_overview_list",
    request: {
      limit: "10",
      page_no: props?.page || "1",
      search: props?.filter?.search || "",
      order_by: "-updated_at",
      from_date: props?.filter?.from_date || "",
      to_date: props?.filter?.to_date || "",
      status: "",
      customer_id: props?.customerId,
      is_offer: props?.filter?.is_offer || "",
      lang:props?.lang,
    }
  };
  wsSendRequest(props?.ws, param, props?.device_id)
}

const callCalculationsList = (props) =>{
  const param = {
    transmit: "broadcast",
    url: "calculation_list",
    request: {
      limit: "10",
      page_no: props?.page || "1",
      customer_id: props?.customerId || "",
      is_offer: props?.filter?.is_offer || "",
      order_by: "-updated_at",
      lang:props?.lang,

    }
  };
  wsSendRequest(props?.ws, param, props?.device_id)
}

const callServiceList = (props) =>{
  const param = {
    transmit: "broadcast",
    url: "service_los_list",
    request: {
      limit: "10",
      page_no: props?.page || "1",
      order_by: "-updated_at",
      customer_id: props?.customerId || "",
      is_offer: props?.filter?.is_offer || "",
      lang:props?.lang,
    }
  };
  wsSendRequest(props?.ws, param, props?.device_id)
}

const callQuickOfferList = (props) => {
  const param = {
    transmit: "broadcast",
    url: "quick_offer_list",
    request: {
      page_no: props?.page || 1,
      limit: "10",
      search: props?.filter?.search,
      order_by: '-updated_at',
      is_offer: props?.filter?.is_offer || "",
      lang:props?.lang,
    }
  };
  wsSendRequest(props?.ws, param, props?.device_id)

}

const UtilsGetList = {
  callAppointmentList: callAppointmentList,
  callAppointmentDetailsList: callAppointmentDetailsList,
  callCustomerDetailsApi: callCustomerDetailsApi,
  callGetCalculationListApi: callGetCalculationListApi,
  callCustomersListApi: callCustomersListApi,
  getFrequencyList: getFrequencyList,
  getOfferContent:getOfferContent,
  getPinCodeCityList:getPinCodeCityList,
  callEmailHistoryListApi:callEmailHistoryListApi,
  callSentEmailHistoryListApi:callSentEmailHistoryListApi,
  callOfferOverviewList:callOfferOverviewList,
  callCalculationsList:callCalculationsList,
  callServiceList:callServiceList,
  callQuickOfferList:callQuickOfferList,
}

export default UtilsGetList;