export const ActionTypes = {
    SET_APPLICATION_LANGUAGE:"SET_APPLICATION_LANGUAGE",
    SET_STORE_GLOBAL_LOADER:"SET_STORE_GLOBAL_LOADER",
}

export const setStoreApplicationLanguage = (token) => {
    return {
       type: ActionTypes.SET_APPLICATION_LANGUAGE,
       payload: token,
    }
};
export const setStoreGlobalLoader = (token) => {
    return {
       type: ActionTypes.SET_STORE_GLOBAL_LOADER,
       payload: token,
    }
};