/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types';
import { Form, Modal } from 'react-bootstrap';
import Button from './Button';
import App_url from './Constants';
import { useDispatch, useSelector } from 'react-redux';
import { setConfirmModalPopup } from '../../store/modules/modals/action';


export default function ConfirmModal() {
    const dispatch = useDispatch();
    const { ModalPopupFour } = useSelector(App_url.modalReducers);
    const handleClose = async () => {
        if (ModalPopupFour?.callBackModal) {
            await ModalPopupFour?.callBackModal(ModalPopupFour?.data);
        }
        CloseData()
    };

    const handelCallbackCancel = async() =>{
        console.log("ModalPopupFour", ModalPopupFour)
        if (ModalPopupFour?.callBackCancelModal) {
            await ModalPopupFour?.callBackCancelModal(ModalPopupFour?.data);
        }
        CloseData()
    }
    const CloseData = () => {
        dispatch(setConfirmModalPopup())
    }

    // console.log("CONFIRM_MODAL", ModalPopupFour)
    if (ModalPopupFour?.show !== "CONFIRM_MODAL") {
        return null
    }
    return (
        <Modal show={true} centered className='confirm-modal'>
            <Modal.Body className='modal-body px-4 pt-4'>
                <h6 className='title-5 fw-5 fs-20 text-center text-dark title' dangerouslySetInnerHTML={{ __html:ModalPopupFour?.data?.title }}></h6>
                <h6 className=' text-2'>{ModalPopupFour?.data?.description}</h6>
            </Modal.Body>
            <div className=' button-box text-center'>
                {ModalPopupFour?.buttonCancel && (
                    <Button className={"confirm-modal-cancel-btn me-3"} variant={"outline-primary"} onClick={ModalPopupFour?.callBackCancelModal ? handelCallbackCancel :CloseData} >{ModalPopupFour?.buttonCancel}</Button>
                )}
                {ModalPopupFour?.buttonSuccess && (
                    <Button className={"confirm-modal-success-btn"} variant={"primary"} onClick={handleClose} >{ModalPopupFour?.buttonSuccess}</Button>
                )}
                </div>
        </Modal>
    )
}
ConfirmModal.propTypes = {
    handleClose: PropTypes?.func,
    show: PropTypes?.bool,
}
ConfirmModal.defaultProps = {
    handleClose: () => { },
    show: false,
}