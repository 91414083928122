/* eslint-disable */
import React, { useContext, useEffect } from 'react'
import ContentLayout from '../layout/ContentLayout'
import { Card, Col, Row } from 'react-bootstrap'
import Images from '../Common/Image'
import App_url from '../Common/Constants'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { WebSocketContext } from '../../WSContext'
import wsSendRequest from '../../socketIO/wsSendRequest'
import { setStoreCurrentCategory } from '../../store/modules/calculation/action'
import UtilsGetList from '../Common/UtilsGetList'
import { useTranslation } from '../../translate'

const CategoryList = (props) => {
    const { t, changeLanguage } = useTranslation();
    const { lang } = useSelector(App_url.settingReducers);

    const { ws, send } = useContext(WebSocketContext);
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { id: customerId } = useParams();
    const location = useLocation();
    const calculationId = location.state;
    const { device_id } = useSelector(App_url.userDataReducers);
    const { categoryList } = useSelector(App_url.calculationReducers);
    const { customerDetails } = useSelector(App_url.customerReducers);
    // const {glassCleaningID,fullCleaningID,intervalCleaningID,buildingDivisionID,maintenanceCleaningID} = useSelector(App_url.ids)

    const callCategoryListApi = (page) => {
        const param = {
            transmit: "broadcast",
            url: "category_list",
            request: { limit: "10", page_no: page, order_by: "-updated_at", }
        };
        send(param);
    };
    useEffect(() => {
        if (ws) {
            callCategoryListApi(1);
        }
        if (customerId) {
            UtilsGetList.callCustomerDetailsApi({ ws, device_id, customer_id: customerId, lang: lang });
        }
    }, [ws, customerId]);


    const getRoute = (categoryId) => {
        const routeMapping = {
            "f0d0803b-5b7d-42aa-a049-f80841bb79f2": App_url.link.newCalculationGlassCleaning,
            "38c9966d-45fa-4135-a740-a0361df6c421": App_url.link.newCalculationFullCleaning,
            "7eeeb65d-1a3f-45ce-9601-91e76a964902": App_url.link.newCalculationIntervalCleaning,
            "24b9bcc4-1b14-49e3-a75d-dc507b6f14d7": App_url.link.newCalculationBuildingDivision,
            "eb51831c-0f4f-4866-b972-e64bb7ce7a4d": App_url.link.newCalculationMaintenanceCleaning,
        };
        return routeMapping[[t(categoryId)]] || App_url.link.newCalculationGlassCleaning;
    };
  
    const handleCategoryClick = (category_name, categoryId) => {
        const route = getRoute(categoryId);
        dispatch(setStoreCurrentCategory({ name: category_name, id: categoryId, calculationId: calculationId ? calculationId : null }));

        navigate(route);
    };

    return (
        <ContentLayout title={'Select Category'}>
            <div className='select-category'>
                <Card className='card m-2'>
                    <Card.Body>
                        <div className='card-body-header'>
                            <div className='item1 flex-item'>
                                <h6 className='card-body-header-title'>{t("Select Category")}</h6>
                            </div>
                        </div>
                        <div className='heading'>
                            <h6>{t("Customer")}: {customerDetails?.[0]?.customer_name ? customerDetails?.[0]?.customer_name : customerDetails?.[0]?.company_name}</h6>
                        </div>
                        <Row className=''>
                            {categoryList?.data?.map((category, index) => (
                                <Col md='4' lg="4">
                                    <div
                                        key={index}
                                        className='services'
                                        onClick={() => handleCategoryClick(category?.category_name, category?.id)}
                                    >
                                        <Images auth calculationCategory className={'calc-category-img'} src={`${process.env.REACT_APP_API_DOMAIN_URL}/file/${category?.view_file_url}`} />
                                        <div className='content'>
                                            <h6>{category?.category_name}</h6>
                                        </div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        </ContentLayout>
    )
}

export default CategoryList