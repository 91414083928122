/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import App_url from '../../Common/Constants';
import { Modal } from 'react-bootstrap';
import { setScheduleCallModal, setStoreSearchCompanyModal } from '../../../store/modules/modals/action';
import InputGroup from '../../Common/InputGroup';
import Icon from '../../Common/Icon';
import Scrollbar from '../../Common/Scrollbar';
import wsSendRequest from '../../../socketIO/wsSendRequest';
import { WebSocketContext } from '../../../WSContext';
import { useTranslation } from '../../../translate';

const SearchCompanyModal = (props) => {
  const { t, changeLanguage } = useTranslation();

    const dispatch = useDispatch();
    const { ws, send } = useContext(WebSocketContext);
    const { ModalPopup } = useSelector(App_url.modalReducers);
    const { device_id } = useSelector(App_url.userDataReducers);
    const [searchTerm, setSearchTerm] = useState('');
    const { customersList } = useSelector(App_url.customerReducers)


    const callCustomersListApi = (page) => {
        const param = {
            transmit: "broadcast",
            url: "customer_list",
            request: {
                status: true,
                limit: "10",
                page_no: page,
                pincode_search: "",
                city_search: "",
                customer_type: [""],
                order_by: "-updated_at",
                industry_search: ""
            }
        };
        send(param, device_id);
    };

    useEffect(() => {
        if (ws && ModalPopup?.show == "SEARCH_COMPANY_MODAL") {
            callCustomersListApi(1);
        }
    }, [ws]);
    if (ModalPopup?.show !== "SEARCH_COMPANY_MODAL") {
        return null
    }
    const handleClose = async (item) => {
        if (ModalPopup?.callBackModal) {
            await ModalPopup?.callBackModal(item);
        }
        CloseData()
    };
    const CloseData = () => {
        dispatch(setStoreSearchCompanyModal())
    }
    const handleView = (item) => {
        handleClose(item);
    }

    const callSearch = (data) => {
        setSearchTerm(data);
    }
    // console.log("customersList?.data",customersList?.data)
    const filteredCustomers = customersList?.data?.filter(customer =>
        customer.company_name?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
        customer.customer_name?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
        customer.company_address?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
        customer.postal_code?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
        customer.industry_type?.toLowerCase()?.includes(searchTerm.toLowerCase())
    );

    return (
        <div>
            <Modal show={true} centered className='searchcomapny' size='md'>
                <Modal.Body className=''>
                    <div className='header-container'>
                        <h6 className='header-title'>{ModalPopup?.data?.AptTitle ? t(ModalPopup?.data?.AptTitle ): t("Search Company")}</h6>
                        <div className='header-button-save-close'>
                            {/* <Button variant={"primary save-appointment-btn"}>Save</Button> */}
                            <Icon onClick={CloseData} className={"ms-1 close-appointment-btn"} image attrIcon={App_url.img.RedCrossIcon} />
                        </div>
                    </div>
                    <div className='searchbar'>
                        <InputGroup
                            size={"lg"}
                            // onChange={onChangeFilter}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            name={"search"}
                            leftLabel={searchTerm === "" ? <Icon className={props?.size} attrIcon={App_url.img.SearchIcon} /> : null}
                            rightLabel={searchTerm !== "" ? <Icon className={props?.size} attrIcon={App_url.img.CloseIcon} /> : null}
                            rightLabel1={searchTerm !== "" ? <Icon className={props?.size} attrIcon={App_url.img.SearchIcon} /> : null}
                            rightIconClick1={() => callSearch(searchTerm)}
                            rightIconClick={() => callSearch("")}
                            placeholder={t("Search By Name, City, Industry & Postcode")}
                            value={searchTerm}
                            isClearable={props?.isClearable}
                        />
                    </div>
                    <div className='mt-4'>
                        <Scrollbar style={{ height: "calc(100vh - 290px)" }}>
                            {filteredCustomers?.map((item) => (
                                <div key={item?.id} className='user-details' onClick={() => handleView(item)}>
                                    <div className='details1'>
                                        <div className=' head'>
                                            <h6><Icon image attrIcon={App_url.img.BuildingIcon2} className={'icons'} /></h6>
                                            <h6>{item?.company_name}</h6>
                                        </div>
                                        <h6><Icon image attrIcon={App_url.img.UserIcon} className={'icons'} />
                                            {item?.customer_name}
                                        </h6>
                                    </div>
                                    <div className='details2'>
                                        <h6> <Icon image attrIcon={App_url.img.LocationIcon} className={'icons'} /></h6>
                                        <h6>{item?.company_address}</h6>
                                    </div>
                                </div>
                            ))}
                        </Scrollbar>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default SearchCompanyModal