/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import { redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Breadcrumb, Card, Dropdown, Form } from 'react-bootstrap';
import ContentLayout from '../layout/ContentLayout';
import InputGroup from '../Common/InputGroup';
import Button from '../Common/Button';
import { useTranslation } from '../../translate';
import Icon from '../Common/Icon';
import { WebSocketContext } from '../../WSContext';
import App_url from '../Common/Constants';
import { toast } from 'react-toastify';
import AnchorLink from '../Common/AnchorLink';

const CreateEmployee = () => {
    const { t, changeLanguage } = useTranslation();
    const location = useLocation();
    const empData = location?.state
    const { ws, send } = useContext(WebSocketContext)
    const { websocketReqRes, } = useSelector(App_url.userDataReducers);
    const { customerDetails } = useSelector(App_url.customerReducers);
    const [formData, setFormData] = useState({
        title: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        gender: "",
        personal_mobile: "",
        personal_email: "",
        city: "",
        country: "",
        post_code: '',
        dob: '',
        address: '',
        userid: '',
        date_of_join: "",
        emp_code: "",
        department: "",
        designation: "",
        target: "",
        mobile: "",
        email: "",
        password: "",
        branch: "",
        permissions_access: [],
        permissions: [],
        assign_customer: []

    })
    const [error, setError] = useState({
        title: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        gender: "",
        personal_mobile: "",
        personal_email: "",
        city: "",
        country: "",
        post_code: '',
        dob: '',
        address: '',

        date_of_join: "",
        emp_code: "",
        department: "",
        designation: "",
        target: "",
        mobile: "",
        email: "",
        password: "",
        branch: ""
    })

    const validation = () => {
        let val = true;
        let error = {};

        if (!formData?.first_name) {
            error.first_name = t("Please enter first name");
            val = false;
        }
        if (!formData?.middle_name) {
            error.middle_name = t("Please enter middle name");
            val = false;
        }
        if (!formData?.last_name) {
            error.last_name = t("Please enter last name");
            val = false;
        }
        if (!formData?.address) {
            error.address = t("Please enter address");
            val = false;
        }
        if (!formData?.city) {
            error.city = t("Please enter city");
            val = false;
        }
        if (!formData?.personal_email) {
            error.personal_email = t("Please enter email");
            val = false;
        }
        if (!formData?.personal_mobile) {
            error.personal_mobile = t("Please enter mobile number");
            val = false;
        }
        if (!formData?.title) {
            error.title = t("Please select title");
            val = false;
        }
        if (!formData?.gender) {
            error.gender = t("Please select gender");
            val = false;
        }
        // if (!formData?.dob) {
        //     error.dob = t("Please enter dob");
        //     val = false;
        // }
        if (!formData?.country) {
            error.country = t("Please enter country");
            val = false;
        }
        if (!formData?.post_code) {
            error.post_code = t("Please enter postcode");
            val = false;
        }
        // if (!formData?.date_of_join) {
        //     error.date_of_join = t("Please enter date of joining");
        //     val = false;
        // }
        if (!formData?.emp_code) {
            error.emp_code = t("Enter unique employee ID");
            val = false;
        }
        if (!formData?.department) {
            error.department = t("Please select department");
            val = false;
        }
        if (!formData?.designation) {
            error.designation = t("Please select designation");
            val = false;
        }
        if (!formData?.target) {
            error.target = t("Please enter target");
            val = false;
        }
        if (!formData?.email) {
            error.email = t("Please enter email");
            val = false;
        }
        if (!formData?.mobile) {
            error.mobile = t("Please enter mobile");
            val = false;
        }
        if (!formData?.password) {
            error.password = t("Please enter password");
            val = false;
        }
        if (!formData?.branch) {
            error.branch = t("Please enter branch");
            val = false;
        }


        setError((prevData) => ({
            ...prevData,
            ...error
        }));

        return val;
    };

    console.log("formdata", formData)
    const genderType = [
        { label: t("Male"), value: t("Male") },
        { label: t("Female "), value: t("Female") },
        { label: t("Prefer not to say"), value: t("Prefer not to say") },
    ]
    const titletypes = [
        { label: t("Mr."), value: t("Mr.") },
        { label: t("Miss."), value: t("Miss.") },
    ]
    const departmentType = [
        { label: t("Sales"), value: t("sale_employee") },
        { label: t("Admin"), value: t("super_admin") },
    ]
    const designationTypes = [
        { label: t("Sales Executive"), value: t("sale_employee") },
        { label: t("Admin"), value: t("super_admin") },

    ]

    const onChange = (e) => {
        setFormData((data) => ({
            ...data,
            [e.target.name]: e.target.value
        }))
        setError((prevData) => ({
            ...prevData,
            [e.target.name]: "",
        }))
    }
    // console.log("empData",empData?.id)
    useEffect(() => {
        if (empData) {
            setFormData((prevData) => ({
                ...prevData,
                ...empData
            }))
        }
    }, [empData])
    const callAddEmployeeApi = () => {
        const param = {
            transmit: "broadcast",
            url: empData?.id ? "user_edit" : "user_add",
            request: {
                title: formData?.title,
                first_name: formData?.first_name,
                middle_name: formData?.middle_name,
                last_name: formData?.last_name,
                gender: formData?.gender,
                personal_mobile: formData?.personal_mobile,
                personal_email: formData?.personal_email,
                city: formData?.city,
                country: formData?.country,
                post_code: formData?.post_code,
                dob: formData?.dob,
                address: formData?.address,

                date_of_join: formData?.date_of_join,
                emp_code: formData?.emp_code,
                department: formData?.department,
                designation: formData?.designation,
                target: formData?.target,
                mobile: formData?.mobile,
                email: formData?.email,
                password: formData?.password,
                branch: formData?.branch,
                is_active: true,
                assign_customer: formData?.assign_customer,
                permissions: formData?.permissions,
                permissions_access: formData?.permissions_access,
                ...(empData && { userid: empData?.id }),
                lang: "en"
            },
            toast: true,
            redirect: App_url.link.employers,
        };
        console.log("param", param)
        send(param);
    }
    const onSubmit = (e) => {
        e.preventDefault();
        if (validation()) {
            callAddEmployeeApi();
            console.log("form submitted")
        } else {
            console.log("error in validation");
        }
    }

    return (
        <ContentLayout title={t('Create Employee')}>
            <div className='create-employee'>
                <Breadcrumb className="breadcrumb-main">
                    <Breadcrumb.Item>
                        <AnchorLink to={App_url.link.employers}>{t("Employee List")} </AnchorLink>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>{t("Create Employee")}</Breadcrumb.Item>
                </Breadcrumb>
                <Card>
                    <Card.Body>
                        <div className='card-body-header'>
                            <h6>{t("Create Employee")}</h6>
                            <Button className={""} variant={"primary"} onClick={onSubmit}>{t("Save")}</Button>
                        </div>
                        <hr className='card-body-header-hr'></hr>
                        <Form >
                            <div className='header'>
                                <h6>{t("Personal Details")}</h6>
                            </div>
                            <div className='row'>
                                <div className='col-lg-3'>
                                    <InputGroup
                                        label={t("Title")}
                                        placeholder={t("Select Title")}
                                        name="title"
                                        select
                                        type='select'
                                        option={titletypes}
                                        value={formData?.title ? formData?.title : titletypes[0]?.value}
                                        error={error?.title}
                                        onChange={onChange}
                                    />
                                </div>
                                <div className='col-3'>
                                    <InputGroup
                                        label={t("FIRST NAME")}
                                        placeholder={t("First Name")}
                                        name="first_name"
                                        type='text'
                                        value={formData?.first_name}
                                        error={error?.first_name}
                                        onChange={onChange}
                                    />
                                </div>
                                <div className='col-3'>
                                    <InputGroup
                                        label={t("Middle Name")}
                                        placeholder={t("Middle Name")}
                                        formClassName=" mb-3"
                                        name="middle_name"
                                        type='text'
                                        value={formData?.middle_name}
                                        onChange={onChange}
                                        error={error?.middle_name}
                                    />
                                </div>
                                <div className='col-3'>
                                    <InputGroup
                                        label={t("Last Name")}
                                        placeholder={t("Last Name")}
                                        formClassName=" mb-3"
                                        name="last_name"
                                        type='text'
                                        value={formData?.last_name}
                                        onChange={onChange}
                                        error={error?.last_name}
                                    />
                                </div>

                            </div>
                            <div className='row'>
                                <div className='col-3'>
                                    <InputGroup
                                        label={t("Gender")}
                                        placeholder={t("Select Gender")}
                                        formClassName=" mb-3"
                                        name="gender"
                                        option={genderType}
                                        value={formData?.gender ? formData?.gender : genderType[0]?.value}
                                        error={error?.gender}
                                        onChange={onChange}
                                        select
                                        type='select'
                                    />
                                </div>

                                <div className='col-3'>
                                    <InputGroup
                                        label={t("Mobile")}
                                        placeholder={t("Mobile")}
                                        formClassName=" mb-3"
                                        name="personal_mobile"
                                        type='number'
                                        value={formData?.personal_mobile}
                                        onChange={onChange}
                                        error={error?.personal_mobile}

                                    />
                                </div>
                                <div className='col-3'>
                                    <InputGroup
                                        label={t("Email")}
                                        placeholder={t("Email")}
                                        formClassName=" mb-3"
                                        name="personal_email"
                                        type='text'
                                        value={formData?.personal_email}
                                        onChange={onChange}
                                        error={error?.personal_email}

                                    />
                                </div>
                                <div className='col-3'>
                                    <InputGroup
                                        label={t("DOB")}
                                        placeholder={t("DOB")}
                                        formClassName=" mb-3"
                                        name="dob"
                                        value={formData?.dob}
                                        onChange={onChange}
                                        type='date'
                                        // error={error?.dob}
                                        rightLabel={<Icon attrIcon={App_url.img.CalendarIcon} />}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-3'>
                                    <InputGroup
                                        label={t("Address")}
                                        placeholder={t("Address")}
                                        formClassName=" mb-3"
                                        name="address"
                                        type='txet'
                                        error={error?.address}
                                        value={formData?.address}
                                        onChange={onChange}
                                        rightLabel={<Icon attrIcon={App_url.img.LocationIcon2} />}
                                    />
                                </div>
                                <div className='col-3'>
                                    <InputGroup
                                        label={t("City")}
                                        placeholder={t("city")}
                                        formClassName=" mb-3"
                                        name="city"
                                        type='txet'
                                        error={error?.city}
                                        value={formData?.city}
                                        onChange={onChange}
                                    />
                                </div>
                                <div className='col-3'>
                                    <InputGroup
                                        label={t("Country")}
                                        placeholder={t("Country")}
                                        formClassName=" mb-3"
                                        name="country"
                                        type='txet'
                                        error={error?.country}
                                        value={formData?.country}
                                        onChange={onChange}
                                    />
                                </div>
                                <div className='col-3'>
                                    <InputGroup
                                        label={t("Post Code")}
                                        placeholder={t("Post Code")}
                                        formClassName=" mb-3"
                                        name="post_code"
                                        type='txet'
                                        error={error?.post_code}
                                        value={formData?.post_code}
                                        onChange={onChange}
                                    />
                                </div>


                            </div>
                            <hr />
                            <div className='header'>
                                <h6>{t("Employment Details")}</h6>
                            </div>
                            <div className='row'>
                                <div className='col-lg-3'>
                                    <InputGroup
                                        label={t("Date of Joining")}
                                        placeholder={t("Date of Joining")}
                                        name="date_of_join"
                                        select
                                        type='date'
                                        value={formData?.date_of_join}
                                        // error={error?.date_of_join}
                                        onChange={onChange}
                                        rightLabel={<Icon attrIcon={App_url.img.CalendarIcon} />}

                                    />
                                </div>
                                <div className='col-3'>
                                    <InputGroup
                                        label={t("Employee ID")}
                                        placeholder={t("Employee ID")}
                                        name="emp_code"
                                        type='text'
                                        value={formData?.emp_code}
                                        error={error?.emp_code}
                                        onChange={onChange}
                                    />
                                </div>
                                <div className='col-3'>
                                    <InputGroup
                                        label={t("Department")}
                                        placeholder={t("Department")}
                                        formClassName=" mb-3"
                                        name="department"
                                        type='select'
                                        option={departmentType}
                                        value={formData?.department ? formData?.department : departmentType[0]?.value}
                                        onChange={onChange}
                                        select
                                        error={error?.department}
                                    />
                                </div>
                                <div className='col-3'>
                                    <InputGroup
                                        label={t("Designation")}
                                        placeholder={t("Designation")}
                                        formClassName=" mb-3"
                                        name="designation"
                                        type='select'
                                        select
                                        option={designationTypes}
                                        value={formData?.designation ? formData?.designation : designationTypes[0]?.value}
                                        onChange={onChange}
                                        error={error?.designation}
                                    />
                                </div>

                            </div>
                            <div className='row'>
                                <div className='col-3'>
                                    <InputGroup
                                        label={t("Target")}
                                        placeholder={t("Target")}
                                        formClassName=" mb-3"
                                        name="target"
                                        value={formData?.target}
                                        error={error?.target}
                                        onChange={onChange}
                                        type='text'
                                        rightLabel={'€'}

                                    />
                                </div>

                                <div className='col-3'>
                                    <InputGroup
                                        label={t("Company Mobile")}
                                        placeholder={t("Company Mobile")}
                                        formClassName=" mb-3"
                                        name="mobile"
                                        type='number'
                                        value={formData?.mobile}
                                        onChange={onChange}
                                        error={error?.mobile}

                                    />
                                </div>
                                <div className='col-3'>
                                    <InputGroup
                                        label={t("Company Email")}
                                        placeholder={t("Company Email")}
                                        formClassName=" mb-3"
                                        name="email"
                                        type='text'
                                        value={formData?.email}
                                        onChange={onChange}
                                        error={error?.email}

                                    />
                                </div>
                                <div className='col-3'>
                                    <InputGroup
                                        label={t("Password")}
                                        placeholder={t("Password")}
                                        formClassName=" mb-3"
                                        name="password"
                                        value={formData?.password}
                                        onChange={onChange}
                                        type='password'
                                        error={error?.password}

                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-3'>
                                    <InputGroup
                                        label={t("Branch")}
                                        placeholder={t("Branch")}
                                        formClassName=" mb-3"
                                        name="branch"
                                        type='txet'
                                        error={error?.branch}
                                        value={formData?.branch}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </Form>

                    </Card.Body>
                </Card>
            </div>

        </ContentLayout >
    )
}

export default CreateEmployee