/* eslint-disable */


import React, { useEffect, useState } from 'react'
import { Card, Modal } from 'react-bootstrap'
import Icon from '../../../Common/Icon'
import App_url from '../../../Common/Constants'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types';
import { setStoreViewMailModal } from '../../../../store/modules/modals/action'
import { GetRequestCallAPI } from '../../../../Api/api/GetRequest'
import Scrollbar from '../../../Common/Scrollbar'
import Utils from '../../../Common/Utils'
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import Images from '../../../Common/Image'
import { useTranslation } from '../../../../translate'

export default function ViewEmail() {
  const { t, changeLanguage, lang } = useTranslation();

    const dispatch = useDispatch();
    const { ModalPopup } = useSelector(App_url.modalReducers);
    const { access_token } = useSelector(App_url.userDataReducers);
    const [mailDetails, setMailDetails] = useState(null);
    const [inlineIds, setInlineIds] = useState([]);
    const [attachments, setAttachments] = useState([]);
    const [loader, setLoader] = useState(false);

    // console.log("Modalpopup====>", ModalPopup?.data?.item);

    const handleClose = async () => {
        if (ModalPopup?.callBackModal) {
            await ModalPopup?.callBackModal(ModalPopup?.data?.item);
        }
        CloseData()
    };
    const CloseData = () => {
        dispatch(setStoreViewMailModal())
        setMailDetails(null);
    }

    const callEmailDetailsApi = async () => {
        const payload = {
            type: "sent",
        }
        const response = await GetRequestCallAPI(App_url.api.GET_MAIL_DETAILS + ModalPopup?.data?.item?.id, access_token, payload);
        // console.log("response", response);
        setMailDetails(response?.data);

        const attachments = response?.data?.data?.attachment || [];

        // Arrays to hold the filtered attachments
        const inline = [];
        const uploads = [];

        // Loop through the attachments and separate them
        attachments.forEach(attachment => {
            if (attachment.is_inline) {
                inline.push(attachment);
            } else {
                uploads.push(attachment);
            }
        });

        setInlineIds(inline);
        setAttachments(uploads);
    }


    useEffect(() => {
        if (ModalPopup?.data?.item?.id && ModalPopup?.show == "VIEW_MAIL_MODAL") {
            callEmailDetailsApi();
        }
    }, [ModalPopup?.data, ModalPopup?.show == "VIEW_MAIL_MODAL"]);

    const replaceImageSrc = (htmlString, inlineIds) => {
        // Regular expression to match src="cid:..."
        const regex = /src="cid:([^"]*)"/g;

        // Replace each matched src="cid:..." with the corresponding base64 content
        const replacedHtmlString = htmlString?.replaceAll(regex, (match, cid) => {
            // Find the corresponding object in inlineIds by matching the cid

            const inlineObj = inlineIds.find(obj => obj.content_id === cid);
            if (inlineObj) {
                // Get the content bytes and content type
                const { content_type, content_bytes } = inlineObj;
                // Replace with the base64 content and appropriate content type
                return `src="data:${content_type};base64,${content_bytes}"`;
            }

            // If no matching cid is found, return the original match
            return match;
        });
        return replacedHtmlString;
    };

    // console.log("maildata", mailDetails);
    // console.log("inlineIds", inlineIds);
    // console.log("attachments", attachments);

    if (ModalPopup?.show !== "VIEW_MAIL_MODAL") {
        return null
    }

    const fetchImageWithAuthorization = async (url) => {
        const headers = new Headers();
        headers.append('Authorization', `Bearer ${access_token}`);
        const requestOptions = {
            method: 'GET',
            headers: headers,
            redirect: 'follow'
        };
        return fetch(url, requestOptions);
    };

    const handleDownload = async (attachment) => {
        try {
            ; console.log("attachment", attachment)

            // Replace this URL with the actual URL to fetch the attachment content
            const url = `${process.env.REACT_APP_API_DOMAIN_URL}/file/view/file/${attachment.content_id}`;
            console.log("url===", url);

            // Fetch the attachment content
            const response = await fetchImageWithAuthorization(url);
            console.log("response", response);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            // Get the content as a Blob
            const blob = await response.blob();

            // Create a URL for the Blob
            const blobUrl = URL.createObjectURL(blob);

            // Create an anchor element and trigger the download
            const a = document.createElement('a');
            a.href = blobUrl;
            a.download = attachment.name || 'attachment'; // Set a default filename if none is provided
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            // Revoke the Blob URL after the download
            URL.revokeObjectURL(blobUrl);
        } catch (error) {
            console.error('Download failed:', error);
        }
    };

    const handleDownloadAll = async () => {
        setLoader(true);
        const zip = new JSZip();

        try {
            for (const attachment of attachments) {
                // console.log("attachment", attachment);

                // Replace this URL with the actual URL to fetch the attachment content
                const url = `${process.env.REACT_APP_API_DOMAIN_URL}/file/view/file/${attachment.content_id}`;
                // console.log("url===", url);

                // Fetch the attachment content
                const response = await fetchImageWithAuthorization(url);
                // console.log("response", response);

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                // Get the content as a Blob
                const blob = await response.blob();

                // Add the blob to the ZIP file
                zip.file(attachment.name || 'attachment', blob);
            }

            // Generate the ZIP file and trigger download
            const zipBlob = await zip.generateAsync({ type: 'blob' });
            saveAs(zipBlob, 'attachments.zip');

        } catch (error) {
            console.error('Download failed:', error);
        } finally {
            setLoader(false);
        }

    };
    const maxLength = 45 || "";

    const EllipsisText = (text, maxLength) => {
      if (text?.length > maxLength) {
        return text?.substring(0, maxLength) + '...';
      }
      return text;
    };


    return (
        <div>
            <Modal show={true} onHide={CloseData} variant="secondary" centered size='lg' className='email-view-dialog'>
                <Modal.Header className='email-header'>
                    <div className='w-100 d-flex justify-content-end'>
                        <Icon onClick={CloseData} image className='' attrIcon={App_url.img.RedCrossIcon} />
                    </div>
                    <div className='email'>
                        <div className='email'>
                            <div className='profile'>
                                <h6>{Utils.formatNameSurnameFirstLetter(ModalPopup?.data?.item?.from_name)}</h6>
                            </div>
                            <div>
                                <h6 className='emailtitle'>{ModalPopup?.data?.item?.from_name}</h6>
                                <h6 className='mail' title={ModalPopup?.data?.item?.from_address}>{EllipsisText(ModalPopup?.data?.item?.from_address,maxLength)}</h6>
                            </div>
                        </div>
                        <div>
                            {/* <h6 className='time'>10:32 AM (15 min ago)</h6> */}
                            <h6 className='time'>{mailDetails !== null ? Utils.formatTimeMinutes(mailDetails?.data?.data[0]?.sent_date_time) : ""}</h6>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className='emailview'>
                    <Scrollbar style={{ height: "calc(100vh - 290px)" }}>
                        {mailDetails === null ? (
                            <h6 className='ms-3'>{t("Loading please wait")}...</h6>
                        ) : (
                            <div className='px-3'>
                                <div className='subject'>
                                    <h6 className='emailtitle'>{t("Subject")} - {mailDetails?.data?.data[0]?.subject}</h6>
                                    <p className='mt-1 email-view-content'>
                                        {/* {mailDetails?.data?.data[0]?.body_content} */}
                                        {/* { replaceImageSrc(mailDetails?.data?.data[0]?.body_content, inlineIds)} */}
                                        <div dangerouslySetInnerHTML={{ __html: replaceImageSrc(mailDetails?.data?.data[0]?.body_content, inlineIds) }}></div>
                                    </p>
                                </div>
                                {attachments?.length > 0 ? (
                                    <Card className='attachments-card'>
                                        <Card.Header className='header email'>
                                            <div>
                                                <span className='closeIcon ' onClick={CloseData}>
                                                    <Icon className='close' attrIcon={App_url.img.LinkIcon} />
                                                </span>
                                                <span>
                                                    {attachments.length > 1
                                                        ? ` ${attachments.length} Attachments`
                                                        : ` ${attachments.length} Attachment`}
                                                </span>
                                            </div>
                                            <h6 style={{ cursor: "pointer" }} onClick={handleDownloadAll}>{loader ? <span className='view-email-loader-box'>
                                                {t("Please wait")}...{" "}
                                                <Images
                                                    width={500}
                                                    height={500}
                                                    src={App_url.img.LoaderIcon}
                                                    className={"md view-email-loader-image-size"}
                                                    buttonClassName={"btn-sm br-2"}
                                                />

                                            </span> : "Download All"}</h6>
                                        </Card.Header>
                                        <Card.Body className='attachments-card-body'>
                                            {attachments.map((attachment, index) => {
                                                console.log("attachment", attachment)
                                                // You should adapt this based on the actual properties of `attachment`
                                                const icon = attachment.type === 'pdf' ? App_url.img.PdfIcon2 : App_url.img.ExcelIcon;
                                                return (
                                                    <div key={index} className='attachment-content-box'>
                                                        <div className={'attachment-icon'}>
                                                            {/* <Icon image attrIcon={icon} /> */}
                                                            {Utils.getIconsByExtension(attachment?.name)}
                                                        </div>
                                                        <h6 className='ellipse' title={attachment.name}>{attachment.name}</h6>
                                                        <h6 className='text-primary ms-auto attachment-download-btn' onClick={() => handleDownload(attachment)}>{t("Download")}</h6>
                                                    </div>
                                                );
                                            })}
                                        </Card.Body>
                                    </Card>
                                ) : (<React.Fragment />)}

                            </div>
                        )}
                    </Scrollbar>
                </Modal.Body>
            </Modal>
        </div>
    )
}

ViewEmail.propTypes = {
    handleClose: PropTypes?.func,
    show: PropTypes?.bool,
}
ViewEmail.defaultProps = {
    handleClose: () => { },
    show: false,
}