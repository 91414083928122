/* eslint-disable */
import React from 'react'
import PropTypes from "prop-types"
const Header = React.lazy(()=>import('../layout/Header'));

export default function ContentLayout(props) {
  return (
    <React.Suspense fallback={<React.Fragment/>}>
      <Header title={props?.title}/>
      <div className='body-content'>
        {props?.children}
      </div>
    </React.Suspense>
  )
}
ContentLayout.propTypes = {
    title: PropTypes.any
}
ContentLayout.defaultProps = {
    title: ""
}