/* eslint-disable */
import React, { useContext, useEffect } from 'react'
import ContentLayout from '../layout/ContentLayout'
import { Card, Col, Row } from 'react-bootstrap'
import Images from '../Common/Image'
import App_url from '../Common/Constants'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { WebSocketContext } from '../../WSContext'
import UtilsGetList from '../Common/UtilsGetList'
import Button from '../Common/Button'
import Icon from '../Common/Icon'
import { useTranslation } from '../../translate'
import { setStoreSessionExpiredModal, setStoreValidationMessageModal } from '../../store/modules/modals/action'

const ServicesPage = (props) => {
  const { t, changeLanguage } = useTranslation();


  const { ws, send } = useContext(WebSocketContext);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { id: customerId } = useParams();
  const { device_id } = useSelector(App_url.userDataReducers);
  const { lang } = useSelector(App_url.settingReducers);

  const callCategoryListApi = (page) => {
    const param = {
      transmit: "broadcast",
      url: "category_list",
      request: { limit: "10", page_no: page, order_by: "-updated_at", }
    };
    send(param, device_id);
  };
  useEffect(() => {
    if (ws) {
      callCategoryListApi(1);
    }
    if (customerId) {
      UtilsGetList.callCustomerDetailsApi({ ws, device_id, customer_id: customerId, lang: lang });
    }
  }, [ws, customerId]);

  const serviceCategory = [
    { category_name: t("Fitness Service List"), img: App_url.image.FitnessServiceImg },
    { category_name: t("Hotel Service List"), img: App_url.image.HotelServiceImg },
    { category_name: t("Full Cleaning"), img: App_url.image.FullCleaningImg },
    { category_name: t("Building Division"), img: App_url.image.BuildingDivisionImg },
    { category_name: t("Maintenance Cleaning"), img: App_url.image.MaintenanceCleaningImg },
  ]


  // const handleCategoryClick = (itemId) => {
  //   // navigate(App_url.link.selectServiceCustomerList, {state:category})
  //   dispatch(setStoreValidationMessageModal({
  //     show: "VALIDATION_MESSAGE_MODAL",
  //     data: { description: t("Sorry Email ID not found !") },
  //     callBackModal: () => handleUpdateBtn(itemId),
  //   }))
  // };
  // const handleUpdateBtn = () => {
  //   navigate(App_url.link.ProspectsAdd)
  // }

  // const handleSessionModal = () => {
  //   dispatch(setStoreSessionExpiredModal({
  //     show: "SESSION_EXPIRE_MODAL",
  //     data: { description: t("We're sorry, your session has expired. Please log in again.") },
  //     callBackModal: () => navigaeToLogin(),
  //   }))
  // }
  // const navigaeToLogin = () => {
  //   navigate(App_url.link.login)

  // }
  return (
    <ContentLayout title={'Select Category'}>
      <div className='select-category'>
        <Card className='card m-2'>
          <Card.Body>
            <div className='card-body-header'>
              <h6 className='card-body-header-title'>{t("Select Category")}</h6>
              <div>
                <Button onClick={() => navigate(App_url.link.savedServiceList)} className={'me-2'} size={'md'} variant={'grey'}>{t("Saved Service List")}</Button>
                <Button onClick={() => navigate(App_url.link.selectServiceCustomerList)} size={'md'} className={"ms-2 "} variant={"outline-primary"}><Icon image attrIcon={App_url.img.PlusIcon} /> </Button>

              </div>
            </div>
            <hr />
            <Row className=''>
              {serviceCategory?.map((category, index) => (
                <Col md='4' lg="4">
                  <div
                    key={index}
                    className='services'
                    // onClick={() => handleCategoryClick(category)}
                  >
                    <Images calculationCategory className={'calc-category-img'} src={category?.img} />
                    <div className='content'>
                      <h6>{category?.category_name}</h6>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Card.Body>
        </Card>
      </div>
    </ContentLayout>
  )
}

export default ServicesPage
