import { ActionTypes } from "./action";

const initialData = {
    categoryList: {
        data: [],
        pagination: {},
    },
    serviceTypeList: [],
    frequencyList: {
        data: [],
        pagination: {}
    },
    calculationList: {
        data: [],
        pagination: {}
    },
    currentCategory: null,
    calculationOverviewList: [],
    roomList: {
        rooms: [],
        cleaning_types:[],
        floor_list:[],
        pagination: {}
    },
    serviceSummary:{
        service:[],
        summary:[],
    }

}
export const calculationReducers = (state = initialData, action) => {
    switch (action.type) {
        case ActionTypes.SET_STORE_CATEGORY_LIST: {
            // console.log("action?.payload", action?.payload);
            return {
                ...state,
                categoryList: {
                    data: action?.payload?.data,
                    pagination: action?.payload?.pagination,
                }
            }
        }
        case ActionTypes.SET_STORE_SERVICE_TYPE_LIST: {
            console.log("action?.payload", action?.payload);
            return {
                ...state,
                serviceTypeList:action?.payload,
            }
        }
        case ActionTypes.SET_STORE_CURRENT_CATEGORY: {
            // console.log("action?.payload", action?.payload);
            return {
                ...state,
                currentCategory: action?.payload
            }
        }
        case ActionTypes.SET_STORE_FREQUENCY_LIST: {
            // console.log("action?.payload", action?.payload);
            return {
                ...state,
                frequencyList: {
                    data: action?.payload?.data,
                    pagination: action?.payload?.pagination,
                }
            }
        }
        case ActionTypes.SET_STORE_CALCULATION_LIST: {
            // console.log("action?.payload", action?.payload);
            return {
                ...state,
                calculationList: {
                    data: action?.payload?.data,
                    pagination: action?.payload?.pagination,
                }
            }
        }
        case ActionTypes.SET_STORE_CALCULATION_OVERVIEW_LIST: {
            // console.log("action?.payload", action?.payload);
            return {
                ...state,
                calculationOverviewList: action?.payload

            }
        }
        case ActionTypes.SET_STORE_DELETE_CALCULATION_OVERVIEW_LIST: {
            // console.log("action?.payload", action?.payload);
            const filteredCalculationList = state?.calculationOverviewList?.filter(item => item?.id !== action?.payload?.service_id);
            // console.log("filteredCalculationList", filteredCalculationList)
            return {
                ...state,
                calculationOverviewList: filteredCalculationList,
            }
        }
        case ActionTypes.SET_STORE_ROOM_LIST: {
            // console.log("action?.payload", action?.payload);
            return {
                ...state,
                roomList: {
                    rooms: action?.payload?.rooms,
                    cleaning_types: action?.payload?.cleaning_types,
                    floor_list: action?.payload?.floor_list,
                    pagination: action?.payload?.pagination,
                    
                }

            }
        }
        case ActionTypes.SET_STORE_SERVICE_SUMMARY: {
            return {
                ...state,
                serviceSummary:{
                    service: action?.payload?.service,
                    summary:action?.payload?.summary,
                }

            }
        }

        default:
            return state;
    }

}
