/* eslint-disable */
import React from 'react'
import { Table } from 'react-bootstrap';
import Icon from '../../Common/Icon';
import App_url from '../../Common/Constants';
import UtilsGetCalculations from '../UtilsGetCalculations';
import Utils from '../../Common/Utils';
import { useTranslation } from '../../../translate';

export default function PreviewDataTable({formData, setFormData}) {
    const { t, changeLanguage } = useTranslation();
    const handleDeleteIntervalArray = (roomType, typeOfCleaning) =>{
        UtilsGetCalculations.deleteIntervalCleaningCalculation({formData, setFormData, room_type:roomType, type_of_cleaning:typeOfCleaning})
    }

    const handleEditIntervalArray = (row) =>{
        console.log("row", row);
        setFormData((prevData) => ({
            ...prevData,
            employee_per_hour_cost: row?.employee_per_hour_cost,
            company_surcharge: row?.company_surcharge,
            hourly_rate: row?.hourly_rate,
            room_type: row?.room_type,
            type_of_cleaning: row?.type_of_cleaning,
            surface_area: row?.surface_area,
            performance_value: row?.performance_value || row?.value,
            weekly_weekday_count: row?.weekly_weekday_count || row?.weekday_count,
            weekly_sunday_count: row?.weekly_sunday_count || row?.sunday_count,
            edit_room_type: row?.room_type,
            edit_type_of_cleaning: row?.type_of_cleaning,
            currentUid:  row?.id
        }))
    }

    return (
        <Table bordered className='custom-table interval-cleaning-preview-data-table'>
            <thead>
                <tr >
                    <th className="top-left-radius">{t("Action")}</th>
                    <th >{t("Room Type")}</th>
                    <th >{t("Type of Cleaning")}</th>
                    <th >{t("Area M2")}</th>
                    <th >{t("Weekday")}</th>
                    <th >{t("Sunday")}</th>
                    <th >{t("M2/Hour")}</th>
                    <th >{t("Monthly Cost")}</th>
                    <th className="top-right-radius">{t("Yearly Cost")}</th>
                </tr>
            </thead>
            <tbody>
                {formData?.interval_array?.map((row, index) =>
                    <tr>
                        <td className=' text-center'>
                            <Icon className={"delete-icon me-2"} onClick={() => handleDeleteIntervalArray(row.room_type, row.type_of_cleaning)} attrIcon={App_url.img.DeleteIcon} />
                            <Icon className={"edit-icon"} onClick={() => handleEditIntervalArray(row)} attrIcon={App_url.img.EditIcon} />
                        </td>
                        <td>{row?.room_type}</td>
                        <td>{row?.type_of_cleaning}</td>
                        <td>{row?.surface_area}</td>
                        <td>{row?.weekly_weekday_count || row?.weekday_count}</td>
                        <td>{row?.weekly_sunday_count || row?.sunday_count}</td>
                        <td>{row?.performance_value || row?.value}</td>
                        <td>€ {Utils.trimDecimal(row?.monthly_cost, 2)}</td>
                        <td>€ {Utils.trimDecimal(row?.yearly_cost, 2)}</td>
                    </tr>
                )}
                <tr className='border-t'>
                    <td colSpan="3"></td>
                    <td>{Utils.trimDecimal(formData?.total_surface_area, 2)}</td>
                    <td></td>
                    <td></td>
                    <td>Total Cost: </td>
                    <td>€ {Utils.trimDecimal(formData?.total_monthly_cost, 2)}</td>
                    <td>€ {Utils.trimDecimal(formData?.total_yearly_cost, 2)}</td>
                </tr>
            </tbody>
        </Table>
    );
}
