/* eslint-disable no-unused-vars */


import React from 'react'
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap'
import App_url from './Constants'
import Button from './Button'
import { useDispatch, useSelector } from 'react-redux';
import Images from './Image';
import { setStoreUserMessageModal } from '../../store/modules/modals/action';
import { useNavigate } from 'react-router-dom';

export default function UserMessageModal(){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { ModalPopupThree } = useSelector(App_url.modalReducers);

    
    const handleClose = async () => {
        if (ModalPopupThree?.callBackModal) {
            await ModalPopupThree?.callBackModal(ModalPopupThree?.data?.item);
        }
        CloseData()
    };
    const CloseData = () => {
        dispatch(setStoreUserMessageModal())
    }
    if (ModalPopupThree?.show !== "USER_MESSAGE_MODAL") {
        return null
    }

    return (
        <div>
            <Modal show={true} centered className='user-message-modal' >
                <Modal.Body className='user-message-modal'>
                    <div className='main-content'>
                        <Images className='discard-icon' src={App_url.img.UserIcon} image />
                         <h6 className='title'>Company: {ModalPopupThree?.data?.message}</h6>
                    </div>
                   <div className='discard-btn-container'>
                    <Button variant="outline-primary" className='no-btn text-nowrap' onClick={()=> navigate(App_url.link.savedCalculation)}>Saved Calculation</Button>
                    <Button onClick={handleClose} variant="primary" className='yes-btn text-nowrap'>Add to Offer</Button>
                   </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

UserMessageModal.propTypes = {
    handleClose: PropTypes?.func,
    show: PropTypes?.bool,
}
UserMessageModal.defaultProps = {
    handleClose: () => { },
    show: false,
}