/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import CustomTableTwo from '../../Common/CustomTableTwo';
import ContentLayout from '../../layout/ContentLayout';
import App_url from '../../Common/Constants';
import { WebSocketContext } from '../../../WSContext';
import { Breadcrumb, Card, Form } from 'react-bootstrap';
import Button from '../../Common/Button';
import InputGroup from '../../Common/InputGroup';
import Icon from '../../Common/Icon';
import { setStoreOfferModal, setStoreUserMessageModal, setStoreValidationMessageModal } from '../../../store/modules/modals/action';
import { setStoreCustomersDetails } from '../../../store/modules/customers/action';
import UtilsGetList from '../../Common/UtilsGetList';
import Utils from '../../Common/Utils';
import { toast } from 'react-toastify';
import { setWebsocketReqRes } from '../../../store/modules/users_data/action';
import { useTranslation } from '../../../translate';
import { PostRequestAPI } from '../../../Api/api/PostRequest';
import AnchorLink from '../../Common/AnchorLink';

const SelectCustomerServices = () => {
    const { t, changeLanguage } = useTranslation();
    const { ws, send } = useContext(WebSocketContext);
    const { device_id, websocketReqRes, access_token } = useSelector(App_url.userDataReducers);
    const { customersList, citiesIndustriesPincodes } = useSelector(App_url.customerReducers);
    const { lang } = useSelector(App_url.settingReducers);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [switchTabs, setSwitchTabs] = useState(false);

    const location = useLocation();
    const  state  = location?.state || {};
    console.log("serviceId",state)

    const [filter, setFilter] = useState({
        search: "",
        city: "",
        postcode: "",
    })
    const handleNavigate = (customerId) => {
        navigate(`${App_url.link.ProspectsEdit}/${customerId}`, { state: { fromPage: "addServiceList", pageId: state?.serviceId, customerId: customerId, serviceData:state?.serviceData } })
    }

    const handleView = async (customerId, item) => {
        console.log("customerId===", customerId, item);
        console.log("item", item)
        const missingFields = [];
        if (item?.email === "" || item?.email === null) {
            if (item?.customer_name === "" || item?.customer_name === null) {
                missingFields.push("customer name");
            }
            if (item?.email === "" || item?.email === null) {
                missingFields.push("email");
            }
            if (item?.company_address === "" || item?.company_address === null) {
                missingFields.push("company address");
            }
            if (item?.street_no === "" || item?.street_no === null) {
                missingFields.push("street number");
            }
            if (item?.city === "" || item?.city === null) {
                missingFields.push("city");
            }
            if (item?.postal_code === "" || item?.postal_code === null) {
                missingFields.push("postal code");
            }
            if (
                (item?.company_mobile === "" || item?.company_mobile === null) &&
                (item?.mobile === "" || item?.mobile === null)
            ) {
                missingFields.push("phone number");
            }

            const missingFieldsMessage = missingFields
                ? t("The information of selected customer is incomplete, please update customer information.")
                : "";

            UtilsGetList.callCustomerDetailsApi({ ws, customer_id: customerId, lang: lang });
            dispatch(setStoreValidationMessageModal({
                show: "VALIDATION_MESSAGE_MODAL",
                title: t("Validation Message"),
                description: missingFieldsMessage,
                callBackModal: () => handleNavigate(customerId),
            }));
        } else {
            dispatch(setStoreCustomersDetails())
            UtilsGetList.callCustomerDetailsApi({ ws, customer_id: customerId, lang: lang })
            const payload = {
                service_id: state?.serviceId,
                customer_id: customerId
            }
            const response = await PostRequestAPI(App_url.api.SERVICE_PDF, payload, access_token)
            console.log("response", response);
            if (response?.status === 201 || response?.status === 200) {
                const viewFileUrl = response?.data?.file_view;
                navigate(`${App_url.link.previewServiceList}/${customerId}`, { state: { view_file_url: viewFileUrl, fromPage: "editServicePage" } });
            } else {
                toast.error(response?.data?.error || response.data?.detail || "error...");
            }
        }

    }
    const callBack = (e, state) => {

    }
    // const callCustomersListApi = (page) => {
    //     const param = {
    //         transmit: "broadcast",
    //         url: "customer_list",
    //         request: {
    //             status: true,
    //             limit: "10",
    //             page_no: page,
    //             pincode_search: filter?.pincode  ,
    //             city_search: filter?.city,
    //             search: filter?.search,
    //             customer_type: [""],
    //             order_by: "-updated_at",
    //             industry_search: "",
    //         }
    //     };
    //     send(param, device_id);
    // };

    useEffect(() => {
        if (ws) {
            UtilsGetList.callCustomersListApi({ ws, page: 1, filter });
            UtilsGetList.getPinCodeCityList({ ws, device_id });
        }
    }, [ws, filter]);


    const citiesOptions = citiesIndustriesPincodes?.cities?.map((item) => {
        return { label: item?.city_name, value: item?.city_name }
    })

    const postCodeOptions = citiesIndustriesPincodes?.pincodes?.map((item) => {
        return { label: item?.pincode, value: item?.pincode }
    })

    // customer add =====
    const [formData, setFormData] = useState({
        company_name: "",
        company_mobile: "",
        customer_name: "",
        email: "",
        mobile: "",
        postal_code: "",
        city: "",
        street_no: "",
        customer_type: "",
        is_active: true,
        customer_position: "",
        industry_type: "",
        company_description: "",
        company_address: "",
        last_name: "",
        status: "",
    });
    const [error, setError] = useState({
        company_name: "",
        company_mobile: "",
        customer_name: "",
        email: "",
        mobile: "",
        postal_code: "",
        city: "",
        street_no: "",
        customer_type: "",
        customer_position: "",
        industry_type: "",
        company_description: "",
        company_address: "",
        last_name: "",
        status: "",
    })

    const [statusSaved, setStatusSaved] = useState(false)
    const [statusEdit, setStatusEdit] = useState(false);
    const onChange = (e) => {
        setFormData((data) => ({
            ...data,
            [e.target.name]: e.target.value
        }))
        setError((prevData) => ({
            ...prevData,
            [e.target.name]: "",
        }))
    }

    const customerTypeOptions = [
        { label: t("Prospects"), value: t("prospects") },
        { label: t("Old Customer"), value: t("oldCustomer") },
        { label: t("Customers"), value: t("customers") },
    ]
    const statusOptionList = [
        { label: t("Not Contacted"), value: t("Not Contacted") },
        { label: t("Not Interested"), value: t("Not Interested") },
        { label: t("Re-Schedule Call"), value: t("Re-Schedule Call") },
        { label: t("Share Brochure"), value: t("Share Brochure") },
        { label: t("Schedule Appointment"), value: t("Schedule Appointment") },
    ]
    const iconMap = {
        "Not Contacted": App_url.img.RedBulletIcon,
        "Not Interested": App_url.img.GrayBulletIcon,
        "Re-Schedule Call": App_url.img.YellowBulletIcon,
        "Share Brochure": App_url.img.GreenBulletIcon,
        "Schedule Appointment": App_url.img.BlueBulletIcon,
    };
    const validation = () => {
        let val = true;

        const email = Utils.ValidateEmail(formData?.email);
        if (email) {
            error.email = email;
            val = false;
        }
        if (!formData.company_name) {
            error.company_name = t("Please enter company name");
            val = false;
        }

        if (!formData.company_mobile) {
            error.company_mobile = t("Please enter phone number");
            val = false;
        }

        if (!formData.customer_name) {
            error.customer_name = t("Please enter first name");
            val = false;
        }
        if (!formData.last_name) {
            error.last_name = t("Please enter last name");
            val = false;
        }

        if (!formData.mobile) {
            error.mobile = t("Please enter mobile number");
            val = false;
        }
        // else if (!/^\d{10}$/.test(formData.mobile)) {
        //   error.mobile = "Please enter a valid 10-digit mobile number";
        //   val = false;
        // }

        if (!formData.postal_code) {
            error.postal_code = t("Please enter postal code");
            val = false;
        }
        // else if (!/^\d{6}$/.test(formData.postal_code)) {
        //   error.postal_code = "Please enter a valid 6-digit postal code";
        //   val = false;
        // }

        if (!formData.city) {
            error.city = t("Please enter city");
            val = false;
        }

        if (!formData.street_no) {
            error.street_no = t("Please enter street number");
            val = false;
        }

        if (!formData.customer_position) {
            error.customer_position = t("Please enter designation");
            val = false;
        }

        if (!formData.industry_type) {
            error.industry_type = t("Please enter industry type");
            val = false;
        }

        if (!formData.company_description) {
            error.company_description = t("Please enter company description");
            val = false;
        }

        if (!formData.company_address) {
            error.company_address = t("Please enter address");
            val = false;
        }


        // Assuming `setError` is the function used to set error state
        setError((prevData) => ({
            ...prevData,
            ...error
        }));

        return val;
    };

    const confirmPayload = (formData) => {
        const payload = {
            company_name: "",
            company_mobile: "",
            customer_name: "",
            email: "",
            mobile: "",
            postal_code: "",
            city: "",
            street_no: "",
            customer_type: customerTypeOptions[0].value,
            is_active: true,
            customer_position: "",
            industry_type: "",
            company_description: "",
            company_address: "",
            last_name: ""
        };

        if (formData.company_name) {
            payload.company_name = formData.company_name;
        }
        if (formData.company_mobile) {
            payload.company_mobile = formData.company_mobile;
        }
        if (formData.customer_name) {
            payload.customer_name = formData.customer_name;
        }
        if (formData.last_name) {
            payload.last_name = formData.last_name;
        }
        if (formData.email) {
            payload.email = formData.email;
        }
        if (formData.mobile) {
            payload.mobile = formData.mobile;
        }
        if (formData.postal_code) {
            payload.postal_code = formData.postal_code;
        }
        if (formData.city) {
            payload.city = formData.city;
        }
        if (formData.street_no) {
            payload.street_no = formData.street_no;
        }
        if (formData.customer_type) {
            payload.customer_type = formData.customer_type;
        }
        if (formData.is_active !== undefined) {
            payload.is_active = formData.is_active;
        }
        if (formData.customer_position) {
            payload.customer_position = formData.customer_position;
        }
        if (formData.industry_type) {
            payload.industry_type = formData.industry_type;
        }
        if (formData.company_description) {
            payload.company_description = formData.company_description;
        }
        if (formData.company_address) {
            payload.company_address = formData.company_address;
        }

        return payload;
    };

    const callAddEditCustomerApi = () => {
        const payload = confirmPayload(formData);
        // console.log("payload", payload);
        const param = {
            transmit: "broadcast",
            url: "customer_add",
            request: payload,
        };
        send(param, device_id)
    }

    console.log("websocketReqRes", websocketReqRes)
    useEffect(() => {
        if (websocketReqRes?.url === "customer_add") {
            if (websocketReqRes?.status === 200) {
                toast.success(websocketReqRes?.response?.msg)
                dispatch(setStoreCustomersDetails())
                UtilsGetList.callCustomerDetailsApi({ ws, customer_id: websocketReqRes?.response?.data?.id, lang: lang });
                UtilsGetList.callCustomersListApi({ ws, page: 1, filter });
                setSwitchTabs(!switchTabs)
                dispatch(setStoreUserMessageModal({
                    show: "USER_MESSAGE_MODAL",
                    data: { message: websocketReqRes?.response?.data?.company_name },
                    callBackModal: () => callAddToOffer(websocketReqRes?.response?.data?.id),
                }))
            } else {
                toast.info(websocketReqRes?.response?.msg || "error");
                dispatch(setWebsocketReqRes());
            }
        }
    }, [websocketReqRes?.url])

    const callAddToOffer = (customerId) => {
        dispatch(setStoreOfferModal({
            show: "OFFER_MODAL",
            data: { calculationId, customerId, offerContent }
        }));
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (validation()) {
            callAddEditCustomerApi();
            console.log("validation Done");
        } else {
            console.log("error in validation");
        }
    }


    const getOfferContent = () => {

    }

    const RenderBody = (item) => {
        // console.log("item", item?.id);
        return (
            <React.Fragment>
                <td className='new-calc-td' onClick={() => handleView(item?.id, item)}>{item?.industry_type ? item?.industry_type : "-"}</td>
                <td className='new-calc-td' onClick={() => handleView(item?.id, item)}>{item?.company_name ? item?.company_name : "-"}</td>
                <td className='new-calc-td' onClick={() => handleView(item?.id, item)}>{item?.customer_name ? item?.customer_name : "-"}</td>
                <td className='new-calc-td' onClick={() => handleView(item?.id, item)}>{item?.email ? item?.email : "-"}</td>
                <td className='new-calc-td' onClick={() => handleView(item?.id, item)}>{item?.company_mobile ? item?.company_mobile : "-"}</td>
                <td className='new-calc-td' onClick={() => handleView(item?.id, item)}>{item?.company_address ? item?.company_address : "-"}</td>
                <td className='new-calc-td' onClick={() => handleView(item?.id, item)}>{item?.city ? item?.city : "-"}</td>
            </React.Fragment>
        )
    }


    return (
        <ContentLayout title={'Calculation'}>
            <Breadcrumb className="breadcrumb-main">
                <Breadcrumb.Item>
                    <AnchorLink to={App_url.link.savedServiceList}>{t("Saved Service List")}  </AnchorLink>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>{"Select Customer or Add Customer"}</Breadcrumb.Item>
            </Breadcrumb>
            {switchTabs ? (
                <React.Fragment>

                    <Card className='card customers-add-edit-card '>
                        <Card.Body>
                            <div className='card-body-header'>
                                <div className='.item1  .flex-item'>
                                    <div className='switch-two-tabs-box'>
                                        <Button onClick={() => setSwitchTabs(!switchTabs)} className={''} variant={`${switchTabs ? "" : "primary"}`} size={"sm"}>{t("Select from Customer List")}</Button>
                                        <Button onClick={() => setSwitchTabs(!switchTabs)} className={''} variant={`${switchTabs ? "primary" : ""}`} size={"sm"}>{t("Create New Customer")}</Button>
                                    </div>
                                </div>
                                <div className='card-body-filters'>

                                </div>
                                <Button onClick={onSubmit} className={"btn-dark"} variant={""}>{t("Save Customer")}</Button>
                            </div>
                            <hr className='card-body-header-hr'></hr>
                            <Form onSubmit={onSubmit} className='row'>
                                <div className='col-lg-12 mb-2'>
                                    <h6 className='title-info'>{t("Company Details")}</h6>
                                </div>
                                <div className='col-lg-8'>
                                    <div className='row'>
                                        <InputGroup
                                            label={t("Company Name")}
                                            placeholder={t("company name")}
                                            formClassName="col-lg-6 mb-3"
                                            name="company_name"
                                            value={formData?.company_name}
                                            error={error?.company_name}
                                            onChange={onChange}
                                        />
                                        <InputGroup
                                            label={t("Phone Number")}
                                            placeholder={t("phone number")}
                                            formClassName="col-lg-6 mb-3"
                                            name="company_mobile"
                                            value={formData?.company_mobile}
                                            error={error?.company_mobile}
                                            onChange={onChange}
                                            rightLabel={<Icon image attrIcon={App_url.img.PhoneRingIcon} />}
                                        />
                                        <InputGroup
                                            label={t("Industry Type")}
                                            placeholder={t("industry type")}
                                            formClassName="col-lg-6 mb-3"
                                            name="industry_type"
                                            value={formData?.industry_type}
                                            error={error?.industry_type}
                                            onChange={onChange}
                                        // select
                                        // type={'select'}
                                        />
                                        <InputGroup
                                            label={t("Customer Type")}
                                            placeholder={t("Select customer type")}
                                            formClassName="col-lg-6 mb-3"
                                            name="customer_type"
                                            option={customerTypeOptions}
                                            value={formData?.customer_type ? formData?.customer_type : customerTypeOptions[0].value}
                                            error={error?.customer_type}
                                            onChange={onChange}
                                            select
                                            type={'select'}
                                        />
                                    </div>
                                </div>

                                <InputGroup
                                    label={t("Company Description:")}
                                    placeholder={t("company description...")}
                                    formClassName="mb-3 col-12 col-lg-4"
                                    name="company_description"
                                    as={"textarea"}
                                    rows='5'
                                    value={formData?.company_description}
                                    error={error?.company_description}
                                    onChange={onChange}
                                />
                                <div className='col-lg-12 mb-2'>
                                    <h6 className='title-info'>{t("Contact Person")}</h6>
                                </div>
                                <InputGroup
                                    label={t("First Name")}
                                    placeholder={t("first name")}
                                    formClassName="col-lg-4 mb-3"
                                    name="customer_name"
                                    value={formData?.customer_name}
                                    error={error?.customer_name}
                                    onChange={onChange}
                                />
                                <InputGroup
                                    label={t("Last Name")}
                                    placeholder={t("last name")}
                                    formClassName="col-lg-4 mb-3"
                                    name="last_name"
                                    value={formData?.last_name}
                                    error={error?.last_name}
                                    onChange={onChange}
                                />
                                <InputGroup
                                    label={t("Mobile Number")}
                                    placeholder={t("mobile number")}
                                    formClassName="col-lg-4 mb-3"
                                    name="mobile"
                                    value={formData?.mobile}
                                    error={error?.mobile}
                                    onChange={onChange}
                                    rightLabel={<Icon image attrIcon={App_url.img.PhoneRingIcon} />}
                                />
                                <InputGroup
                                    label={t("Email ID")}
                                    placeholder={t("email id")}
                                    formClassName="col-lg-4 mb-3"
                                    name="email"
                                    value={formData?.email}
                                    error={error?.email}
                                    onChange={onChange}
                                    rightLabel={<Icon image attrIcon={App_url.img.EmailIcon3} />}
                                />
                                <InputGroup
                                    label={t("Designation")}
                                    placeholder={t("designation")}
                                    formClassName="col-lg-4 mb-3"
                                    name="customer_position"
                                    value={formData?.customer_position}
                                    error={error?.customer_position}
                                    onChange={onChange}
                                />
                                <div className='col-lg-4'></div>
                                <div className='col-lg-12 mb-2'>
                                    <h6 className='title-info'>{t("Address Details")}</h6>
                                </div>

                                <InputGroup
                                    label={t("Street Number")}
                                    placeholder={t("street number")}
                                    formClassName="col-lg-4 mb-3"
                                    name="street_no"
                                    value={formData?.street_no}
                                    error={error?.street_no}
                                    onChange={onChange}
                                />
                                <InputGroup
                                    label={t("City")}
                                    placeholder={t("city")}
                                    formClassName="col-lg-4 mb-3"
                                    name="city"
                                    value={formData?.city}
                                    error={error?.city}
                                    onChange={onChange}
                                />
                                <InputGroup
                                    label={t("Post Code")}
                                    placeholder={t("post code")}
                                    formClassName="col-lg-4 mb-3"
                                    name="postal_code"
                                    value={formData?.postal_code}
                                    error={error?.postal_code}
                                    onChange={onChange}
                                />
                                <InputGroup
                                    label={t("Address")}
                                    placeholder={t("address")}
                                    formClassName="col-lg-8 mb-3"
                                    name="company_address"
                                    value={formData?.company_address}
                                    error={error?.company_address}
                                    onChange={onChange}
                                    rightLabel={<Icon image attrIcon={App_url.img.LocationIcon2} />}

                                />
                            </Form>

                        </Card.Body>
                    </Card>
                </React.Fragment>
            ) : (
                <CustomTableTwo
                    switchTwoTabs={true}
                    switchTabs={switchTabs}
                    setSwitchTabs={setSwitchTabs}
                    title={{ text: "", size: "" }}
                    // AddButton2={{ title: t("Send To Mail"), variant: "primary", size: "md" }}
                    cityPlaceHolder={t("Select City")}
                    postcodePlaceHolder={t("Select Postcode")}
                    typePlaceHolder2={t("Select City")}
                    columnNames={[t("Industry"), t("Company Name"), t("Person Name"), t("Email"), (t("Phone No.")), t("Street No"), t("City")]}
                    recordData={customersList?.data}
                    filter={filter}
                    setFilter={setFilter}
                    search_placeholder={t("Search Company Name")}
                    callBack={callBack}
                    renderBody={RenderBody}
                    pagination={customersList?.pagination}
                    onPagination={(page) => callCustomersListApi(page)}
                    sr_no={"new-calc-td"}
                    cityOption={citiesOptions}
                    postCodeOption={postCodeOptions}

                />
            )}

        </ContentLayout>
    )
}

export default SelectCustomerServices