/* eslint-disable */
import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { WebSocketContext } from '../../WSContext';
import { setStoreAppointmentViewModal, setStoreViewExternalOfferModal } from '../../store/modules/modals/action';
import Icon from '../Common/Icon';
import App_url from '../Common/Constants';
import { Modal } from 'react-bootstrap';
import wsSendRequest from '../../socketIO/wsSendRequest';
import { toast } from 'react-toastify';
import Utils from '../Common/Utils';
import { useTranslation } from '../../translate';

const ViewExternalOfferDetailModal = () => {
  const { t, changeLanguage } = useTranslation();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { ws, send } = useContext(WebSocketContext)
    const { device_id } = useSelector(App_url.userDataReducers);
    const { ModalPopup } = useSelector(App_url.modalReducers);
    const { customerDetails } = useSelector(App_url.customerReducers);
    const { getExternalOfferList, externalOfferList } = useSelector(App_url.externalOfferReducers);


    const handleClose = async (state, getExternalOfferList) => {
        if (ModalPopup?.callBackModal) {
            await ModalPopup?.callBackModal(state, getExternalOfferList);
        }
        CloseData()
    };


    const CloseData = () => {
        dispatch(setStoreViewExternalOfferModal())
    }


    if (ModalPopup?.show !== "VIEW_EXTERNAL_OFFER_MODAL") {
        return null
    }



    return (
        <div>
            <Modal show={true} size="lg" className='view-extoffer-modal-dialog'>
                <Modal.Header>
                    <div className='header'>
                        <h6>{t("Ext. Document Detail")}</h6>
                        <div className='header-icons-box'>
                            <Icon onClick={() => handleClose("editExternalOffer", getExternalOfferList?.offer?.[0])} image attrIcon={App_url.img.EditIcon} />
                            <Icon onClick={() => handleClose("deleteExternalOffer",getExternalOfferList?.offer?.[0]?.id)} image attrIcon={App_url.img.DeleteIcon} />
                            <Icon onClick={CloseData} className={"ms-1"} image attrIcon={App_url.img.RedCrossIcon} />
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className='modal-body view-appointment-modal'>
                    <div className='title-box'>
                        <h6 className='title-font'>{t("Company Name")}</h6>
                        <div className='title-content-box'>
                            <Icon image attrIcon={App_url.img.BuildingIcon2} />
                            <h6 className='title-font-2'>{getExternalOfferList?.offer?.[0]?.company_name}</h6>
                        </div>
                    </div>
                    <div className='title-box'>
                        <h6 className='title-font'>{t("Tender Number")}</h6>
                        <div className='title-content-box'>
                            <Icon image attrIcon={App_url.img.FileIcon} />
                            <h6 className='title-font-2'>{getExternalOfferList?.offer?.[0]?.tender_number}</h6>
                        </div>
                    </div>
                    <div className='title-box'>
                        <h6 className='title-font'>{t("Service Type")}</h6>
                        <div className='title-content-box'>
                            <Icon image attrIcon={App_url.img.SettingIcon} />
                            <h6 className='title-font-2'>{getExternalOfferList?.offer?.[0]?.service_type}</h6>
                        </div>
                    </div>
                    <div className='title-box'>
                        <h6 className='title-font'>{t("Cost")}</h6>
                        <div className='title-content-box'>
                            <Icon image attrIcon={App_url.img.CoinIcon} />
                            <h6 className='title-font-2'>{getExternalOfferList?.offer?.[0]?.cost}</h6>
                        </div>
                    </div>
                    <div className='title-box'>
                        <h6 className='title-font'>{t("Date")}</h6>
                        <div className='title-content-box'>
                            <Icon image attrIcon={App_url.img.CalendarIcon3} />
                            <h6 className='title-font-2'>{t("Start Date")} : {Utils.formatISOToFullDayMonthTime(getExternalOfferList?.offer?.[0]?.updated_at,t)}</h6>
                        </div>
                    </div>
                    <div className='row '>
                        <div className='col-6'>
                            <div className='title-box'>
                                <h6 className='title-font'>{t("Post Code")}</h6>
                                <div className='title-content-box'>
                                    <Icon image attrIcon={App_url.img.AaIcon} />
                                    <h6 className='title-font-2'>{getExternalOfferList?.offer?.[0]?.postal_code}</h6>
                                </div>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className='title-box'>
                                <h6 className='title-font'>{t("City")}</h6>
                                <div className='title-content-box'>
                                    <Icon image attrIcon={App_url.img.LocationIcon} />
                                    <h6 className='title-font-2'>{getExternalOfferList?.offer?.[0]?.city}</h6>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='title-box'>
                        <h6 className='title-font'>{t("Status")}</h6>
                        <div className='title-content-box'>
                            <Icon image attrIcon={App_url.img.CirclesTwoIcon} />
                            <h6 className='title-font-2'>{getExternalOfferList?.offer?.[0]?.status}</h6>
                        </div>
                    </div>
                    <div className='title-box'>
                        <h6 className='title-font'>{t("Attachments")}</h6>
                        <div className='title-content-box'>
                            <Icon image attrIcon={App_url.img.PdfIcon2} />
                            <h6 className='title-font-2'>{getExternalOfferList?.offer?.[0]?.document_list?.[0]?.name}</h6>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ViewExternalOfferDetailModal