/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import App_url from './Constants';

export default function LazyVideo(props) {
  const { src, mobileSrc, alt, type, className, defaultImage } = props;
  const [source, setSource] = useState(null);
  const { access_token } = useSelector(App_url.userDataReducers);

  // Determine the image source URL

  const ImageSource = () => {
    if (props?.view_file) {
      return `${process.env}/file/${props?.src}`;
    } else {
      return props?.src;
    }
  }

  useEffect(() => {
    const img = new Image();

    if (!props?.auth || !access_token || window.location.hostname !== "localhost") {
      img.src = src;
      img.onload = () => setSource(img.src);
      img.onerror = () => setSource(defaultImage); // Fallback on error
    } else if (access_token && window.location.hostname === "localhost") {
      fetchData();
    }

    return () => {
      img.src = ''; // Clean up
    };
  }, [src, source]);

  const fetchData = async () => {
    try {
      const response = await fetchImageWithAuthorization();
      if (response.ok) {
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        setSource(url);
      } else {
        setSource(defaultImage);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setSource(defaultImage);
    }
  };

  const fetchImageWithAuthorization = async () => {
    const url = ImageSource();
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${access_token}`);
    const requestOptions = {
      method: 'GET',
      headers: headers,
      redirect: 'follow'
    };
    return await fetch(url, requestOptions);
  };

  const ImagePreview = () => {
    const handleError = (e) => {
      console.error('Video playback error:', e);
      console.log('Error details:', {
        src,
        source,
        access_token,
        message: 'An error occurred while loading the video. Please try again later.',
      });
      setSource(defaultImage); // Set fallback source on error
    };

    return (
      <video controls poster={source} className={`video_player ${className}`} onError={handleError}>
        <source src={src} type={type} />
        Your browser does not support the video tag.
      </video>
    );
  };

  return (
    <React.Fragment>
      <ImagePreview />
    </React.Fragment>
  );
}

LazyVideo.propTypes = {
  type: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
  onError: PropTypes.func,
  view_file: PropTypes.bool,
  auth: PropTypes.bool,
  defaultImage: PropTypes.string,
};

LazyVideo.defaultProps = {
  type: "video/mp4",
  src: '',
  alt: '',
  className: '',
  onError: () => {},
  view_file: false,
  auth: false,
  defaultImage: '', // Set a default image path
};
