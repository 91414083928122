export const ActionTypes = {
    SET_STORE_PROMOTION_LIST:"SET_STORE_PROMOTION_LIST",
   
}
export const setStorePromotionList = (token) => {
    return {
        type: ActionTypes.SET_STORE_PROMOTION_LIST,
        payload: token,
     }
}
