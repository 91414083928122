export const ActionTypes = {
    SET_STORE_CUSTOMERS_LIST:"SET_STORE_CUSTOMERS_LIST",
    SET_STORE_REMARK_LIST:"SET_STORE_REMARK_LIST",
    SET_STORE_CUSTOMER_DETAILS:"SET_STORE_CUSTOMER_DETAILS",
    SET_STORE_SCHEDULE_LIST:"SET_STORE_SCHEDULE_LIST",
    SET_STORE_DOCUMENTS_LIST:"SET_STORE_DOCUMENTS_LIST",
    SET_STORE_EMAIL_HISTORY_LIST:"SET_STORE_EMAIL_HISTORY_LIST",
    SET_STORE_SENT_EMAIL_HISTORY_LIST:"SET_STORE_SENT_EMAIL_HISTORY_LIST",
    SET_STORE_APPOINTMENT_LIST:"SET_STORE_APPOINTMENT_LIST",
    SET_STORE_INVITEES_USER_LIST:"SET_STORE_INVITEES_USER_LIST",
    SET_STORE_APPOINTMENT_DETAILS:"SET_STORE_APPOINTMENT_DETAILS",
    SET_STORE_INVITEE_AVAILABLE_DATES:"SET_STORE_INVITEE_AVAILABLE_DATES",
    SET_STORE_CITIES_INDUSTRIES_PINCODES:"SET_STORE_CITIES_INDUSTRIES_PINCODES"

}

export const setStoreCustomersList = (token) => {
    return {
        type: ActionTypes.SET_STORE_CUSTOMERS_LIST,
        payload: token,
     }
}
export const setStoreCustomersDetails = (token) =>{
    return {
        type: ActionTypes.SET_STORE_CUSTOMER_DETAILS,
        payload: token,
    }
}
export const setStoreRemarkList = (token) => {
    return {
       type: ActionTypes.SET_STORE_REMARK_LIST,
       payload: token,
    }
};
export const setStoreScheduleList = (token) => {
    return {
       type: ActionTypes.SET_STORE_SCHEDULE_LIST,
       payload: token,
    }
};
export const setStoreDocumentsList = (token) => {
    return {
       type: ActionTypes.SET_STORE_DOCUMENTS_LIST,
       payload: token,
    }
};
export const setStoreEmailHistoryList = (token) => {
    return {
       type: ActionTypes.SET_STORE_EMAIL_HISTORY_LIST,
       payload: token,
    }
};
export const setStoreSentEmailHistoryList = (token) => {
    return {
       type: ActionTypes.SET_STORE_SENT_EMAIL_HISTORY_LIST,
       payload: token,
    }
};
export const setStoreAppointmentList = (token) => {
    return {
       type: ActionTypes.SET_STORE_APPOINTMENT_LIST,
       payload: token,
    }
};
export const setStoreInviteesUserList = (token) => {
    return {
       type: ActionTypes.SET_STORE_INVITEES_USER_LIST,
       payload: token,
    }
};
export const setStoreAppointmentDetails = (token) => {
    return {
       type: ActionTypes.SET_STORE_APPOINTMENT_DETAILS,
       payload: token,
    }
};
export const setStoreInviteeAvailableDates = (token) => {
    return {
       type: ActionTypes.SET_STORE_INVITEE_AVAILABLE_DATES,
       payload: token,
    }
};
export const setStoreCitiesIndustriesPincodes = (token) => {
    return {
       type: ActionTypes.SET_STORE_CITIES_INDUSTRIES_PINCODES,
       payload: token,
    }
};