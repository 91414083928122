import { ActionTypes } from "./action";

const initialData = {
    promotionList: {
        data: [],
        pagination: {}
    },

}
export const promotionReducers = (state = initialData, action) => {
    switch (action.type) {
        case ActionTypes.SET_STORE_PROMOTION_LIST: {
            // console.log("action?.payload", action?.payload);
            return {
                ...state,
                promotionList: {
                    data: action?.payload?.data,
                    pagination: action?.payload?.pagination,
                }
            }
        }


        default:
            return state;
    }

}