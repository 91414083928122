/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import App_url from '../../Common/Constants';
import Icon from '../../Common/Icon';
import CustomTableTwo from '../../Common/CustomTableTwo';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setEditAppointmentModal, setNewAppointmentModal, setScheduleCallModal, setStoreAddRemarkModal, setStoreAppointmentViewModal, setStoreCallScheduleSuccessfulModal, setStoreDiscardChangesModal, setStoreValidationMessageModal, setStoreViewRemarkModal, setStoreViewScheduledCallModal } from '../../../store/modules/modals/action';
import wsSendRequest from '../../../socketIO/wsSendRequest';
import { WebSocketContext } from '../../../WSContext';
import Utils from '../../Common/Utils.js';
import { toast } from 'react-toastify';
import { PostRequestAPI } from '../../../Api/api/PostRequest.js';
import { DeleteRequestCallAPI } from '../../../Api/api/DeleteRequestCall.js';
import { PutRequestCallAPI } from '../../../Api/api/PutRequest.js';
import { useTranslation } from '../../../translate/index.jsx';


export default function Todo(props) {
  const { t, changeLanguage } = useTranslation();

  const [state, setState] = useState("");
  const [view, setView] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate()
  // console.log("props", props);

  const { device_id, websocketReqRes, access_token } = useSelector(App_url.userDataReducers)
  const { remarkList, scheduleList, customerDetails, appointmentList } = useSelector(App_url.customerReducers);
  const { ws, send } = useContext(WebSocketContext);
  const [endPoint, setEndPoint] = useState("");
  const [filter, setFilter] = useState({
    status: true,
    limit: "20",
    page_no: "1",
    search: "",
    order_by: "-updated_at",
    customer_id: ""
  })
  const callAppointmentList = () => {
    const param = {
      transmit: "broadcast",
      url: "appointment_list",
      request: {
        status: "",
        year_filter: "",
        limit: "500",
        page_no: "1",
        search: "",
        order_by: "-updated_at",
        customer_id: props?.customerId,
      },
    };
    send( param, device_id)
  }
  // console.log("appointmentList",appointmentList)
  const callRemarkList = () => {
    const param = {
      transmit: "broadcast",
      url: "remark_list",
      request: {
        limit: "500",
        page_no: "1",
        search: filter?.search,
        order_by: "-updated_at",
        customer_id: props?.customerId,
      },
    };
    send( param, device_id)
  }
  const callScheduleList = () => {
    const param = {
      transmit: "broadcast",
      url: "schedule_list",
      request: {
        limit: "500",
        page_no: "1",
        search: "",
        order_by: "-updated_at",
        customer_id: props?.customerId,
        form_date: "",
        form_date: "",
        type: "",
      },
    };
    send( param, device_id)
  }
  // console.log("scheduleList",scheduleList)
  useEffect(() => {
    if (ws) {
      // Ensure websocketReqRes.url has changed
      if (websocketReqRes?.url === "remark_delete" ||
        websocketReqRes?.url === "remark_add" ||
        websocketReqRes?.url === "remark_edit") {
        callRemarkList();

      }
      if (websocketReqRes?.url === "schedule_add" ||
        websocketReqRes?.url === "schedule_edit" && websocketReqRes?.status === 200) {
        callScheduleList();
        dispatch(setStoreCallScheduleSuccessfulModal({
          show: "CALL_SCHEDULED_SUCCESSFUL_MODAL",
          data: { item: websocketReqRes?.response, message:`${websocketReqRes?.url === "schedule_add" ? t("Call Scheduled Successfully"): t("Call Rescheduled Successfully")}`}
        }))
      }
      if (websocketReqRes?.url === "schedule_delete" && websocketReqRes?.status === 200) {
        callScheduleList();
      }
    }

  }, [ws, websocketReqRes?.url]);

  useEffect(() => {
   if(ws){
    callAppointmentList();
    callRemarkList();
    callScheduleList();
   }
  }, [ws])

 
  const callBack = (e, state, buttonName) => {
    if (state === "AddButton" && buttonName === "scheduleAppointment") {
      // console.log("customerDetails", customerDetails)
      if(!customerDetails?.[0]?.email && !customerDetails?.[0]?.company_address){
        dispatch(setStoreValidationMessageModal({
          show:"VALIDATION_MESSAGE_MODAL",
          title:t("Validation Message"), 
          description:t("The information of selected customer is incomplete, please update customer information."),
          data:{},
          callBackModal: () => navigate(`${App_url.link.ProspectsEdit}/${customerDetails?.[0]?.id}`, {state:{fromPage:"prospectsToDoScheduleAppointment", pageId:customerDetails?.[0]?.id, customerId:customerDetails?.[0]?.id}})
        }))
      }else{
        dispatch(setNewAppointmentModal({
          show: "NEW_APPOINTMENT_MODAL",
        }));
      }
    }
    if (state === "AddButton" && buttonName === "scheduleCall") {
      console.log("customerDetails", customerDetails)
      if(!customerDetails?.[0]?.mobile){
        dispatch(setStoreValidationMessageModal({
          show:"VALIDATION_MESSAGE_MODAL",
          title:t("Validation Message"), 
          description:t("The information of selected customer is incomplete, please update customer information. <strong> (Mobile number)</strong>"),
          data:{},
          callBackModal: () => navigate(`${App_url.link.ProspectsEdit}/${customerDetails?.[0]?.id}`, {state:{fromPage:"prospectsToDoScheduleCall", pageId:customerDetails?.[0]?.id, customerId:customerDetails?.[0]?.id}})
        }))
      }else{
        dispatch(setScheduleCallModal({
          show: "SCHEDULE_CALL_MODAL",
          callBackModal: (scheduleData) => callScheduleCall(scheduleData),
        }));
      }
    }
    if (state === "AddButton" && buttonName === "addRemark") {
      dispatch(setStoreAddRemarkModal({
        show: "ADD_REMARK_MODAL",
        callBackModal: (message) => callAddEditRemark(message),
      }))
    }
  }


  // Schedule Call
  const callScheduleCall = (scheduleData, item, state) => {
    console.log("scheduleData", scheduleData)
    const payload = {
      ...scheduleData,
      customer_id: customerDetails?.[0]?.id
    };
    if (state === 'schedule_edit') {
      payload.schedule_id = item?.id;
    }
    console.log("payload", payload);

    const param = {
      transmit: "broadcast",
      url: state === "schedule_edit" ? "schedule_edit" : "schedule_add",
      request: payload,
    };
    send( param, device_id);
  }
  const handleScheduledCallCallBack = (state, scheduleCallItem) => {
    // console.log("scheduleCallItem",scheduleCallItem)
    if (state === "deleteScheduledCall") {
      dispatch(setStoreViewRemarkModal())
      dispatch(setStoreDiscardChangesModal({
        show: "DISCARD_CHANGES_MODAL",
        data: { item: scheduleCallItem, description: t("Are you sure you want to delete this scheduled call ?") },
        callBackModal: (item) => callScheduledCallDelete(item),
      }))
    }
    if (state === "editScheduledCall") {
      dispatch(setScheduleCallModal({
        show: "SCHEDULE_CALL_MODAL",
        data: { item: scheduleCallItem },
        callBackModal: (scheduleData, item) => callScheduleCall(scheduleData, item, "schedule_edit"),
      }))
    }
  }
  // console.log("acesstoken", access_token);
  const handleDeleteAppAPI = async (appointmentItem) => {
    // console.log("item",appointmentItem?.appointment_id)
    const payload = {
      appointment_id: appointmentItem,
    }
    const response = await DeleteRequestCallAPI(App_url.api.DELETE_APPOINTMENT, access_token, payload)
    console.log("responseAppointment", response);
    if (response?.status === 200) {
      toast.success(response?.data?.message || t("deleted"));
      callAppointmentList();
    } else {
      toast.error(response?.data?.error || response.data?.detail || response.data?.message);
    }
  }
  const handleUpdateAppAPI = async (appointmentItem) => {
    // console.log("item",appointmentItem?.subject)
    const payload = {
      subject: appointmentItem?.subject,
      appointment_id: appointmentItem?.appointment_id,
      start_time: appointmentItem?.start_time,
      end_time: appointmentItem?.end_time,
      location: "",
      attendees: [
        { emailAddress: { address: "" }, type: "required" },
        { emailAddress: { address: "" }, type: "required" }
      ],
      customer_id: props?.customerId,
      is_all_day: false,
      reminder_minutes: 15,
      is_reminder_on: true,
      notes: "",
      recurrence: {
        pattern: { type: "", interval: 1, daysOfWeek: [''] },
        range: { type: 'endDate', startDate: "", endDate: "" },
        reminder_minutes_second_start: 12
      }

    }
    const response = await PutRequestCallAPI(App_url.api.UPDATE_APPOINTMENT, access_token, payload)
    console.log("responseAppointment", response);
    if (response?.status === 200) {
      toast.success(response?.data?.message || "deleted");
      callAppointmentList();
    } else {
      toast.error(response?.data?.error || response.data?.detail || response.data?.message);
    }
  }

  const handleAppointmentCallBack = (state,appointmentItem, appointmentId) => {
    // console.log("appointmentItem==============", appointmentItem)
    // console.log("state",state)
    if (state === "deleteAppointment") {
      dispatch(setStoreAppointmentViewModal())
      dispatch(setStoreDiscardChangesModal({
        show: "DISCARD_CHANGES_MODAL",
        data: { item: appointmentItem, description: t("Are you sure you want to delete this Appointment ?") },
        callBackModal: (item) => handleDeleteAppAPI(appointmentItem),
      }))
    }
  
    // if (state === "editAppointment") {
    //   console.log('calling edit ap==========================i',state)
    //   dispatch(setStoreAppointmentViewModal());
    //   dispatch(setEditAppointmentModal({
    //     show: "EDIT_APPOINTMENT_MODAL",
    //     // data: { item: appointmentItem },
    //     // callBackModal: (appointmentData,item) => handleUpdateAppAPI(appointmentData,item),
    //   }))
    // }
    if (state === "editAppointment") {
      // console.log('calling edit ap==========================i',state)
      dispatch(setStoreAppointmentViewModal());
      dispatch(setNewAppointmentModal({
        show: "NEW_APPOINTMENT_MODAL",
        data: { item: appointmentItem },
        callBackModal: (appointmentData,item) => handleUpdateAppAPI(appointmentData,item),
      }))
    }

  }

  const callScheduledCallDelete = (item) => {
    const param = {
      transmit: "broadcast",
      url: "schedule_delete",
      request: { schedule_id: item?.id },
      toast: true,
    };
    send( param, device_id);
  }

  // Remark apis
  const callAddEditRemark = (message, item, state) => {

    const payload = {
      customer_id: props?.customerId,
      message: message,
    }
    if (state === 'remark_edit') {
      payload.remark_id = item?.id;
      delete payload.customer_id;
    }
    console.log("payload", payload);
    const param = {
      transmit: "broadcast",
      url: state === "remark_edit" ? "remark_edit" : "remark_add",
      request: payload,
      toast: true,
    };
    send( param, device_id);

  }
  const handleRemarkCallBack = (state, remarkItem) => {
    if (state === "deleteRemark") {
      dispatch(setStoreViewRemarkModal())
      dispatch(setStoreDiscardChangesModal({
        show: "DISCARD_CHANGES_MODAL",
        data: { item: remarkItem, description: t("Are you sure you want to delete this remark ?") },
        callBackModal: (item) => callRemarkDelete(item),
      }))
    }
    if (state === "editRemark") {
      dispatch(setStoreAddRemarkModal({
        show: "ADD_REMARK_MODAL",
        data: { item: remarkItem },
        callBackModal: (message, item) => callAddEditRemark(message, item, "remark_edit"),
      }))
    }

  }
  const callRemarkDelete = (item) => {
    const param = {
      transmit: "broadcast",
      url: "remark_delete",
      request: { "remark_id": item?.id },
      toast: true,
    };
    send( param, device_id);
  }

  const handleView = (item, state) => {
    // console.log("viewItems", item);
    if (state === "appointmentView") {
      dispatch(setStoreAppointmentViewModal({
        show: "APPOINTMENT_VIEW_MODAL",
        data: { item: item },
        callBackModal: (state, appointmentId) => handleAppointmentCallBack(state, appointmentId),
      }))
    }
    if (state === "callView") {
      dispatch(setStoreViewScheduledCallModal({
        show: "VIEW_SCHEDULED_CALL_MODAL",
        data: { item: item },
        callBackModal: (state, scheduleCallItem) => handleScheduledCallCallBack(state, scheduleCallItem),
      }))
    }
    if (state === "viewRemark") {
      dispatch(setStoreViewRemarkModal({
        show: "VIEW_REMARK_MODAL",
        data: { item: item },
        callBackModal: (state, remarkItem) => handleRemarkCallBack(state, remarkItem),

      }))
    }
  }
  const maxLength = 23;
  const EllipsisText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  const RenderBody = (item) => {

    return (
      <React.Fragment>
        {/* <td>{item?.dateTime}</td> */}
        <td>{Utils.formatDateTime2(item?.start_time,t)}{/*  {Utils.formatDateTime2(item?.end_time)} */} </td>
        <td>{item?.customer_name}</td>
        <td>{EllipsisText(item?.subject, maxLength)}</td>
        <td><span onClick={() => handleView(item, "appointmentView")} className='view-icon-box'><Icon className={"bg-primary"} attrIcon={App_url.img.EyeIcon} /></span></td>
      </React.Fragment>
    )
  }
  const RenderBody2 = (item) => {
    return (
      <React.Fragment>
        <td>{Utils.formatDateTime(item?.schedule_date, item?.schedule_time,t)}</td>
        <td>{item?.remark}</td>
        <td><span onClick={() => handleView(item, "callView")} className='view-icon-box'><Icon className={"bg-primary"} attrIcon={App_url.img.EyeIcon} /></span></td>
      </React.Fragment>
    )
  }
  const RenderBody3 = (item) => {
    // console.log("remarkItem", item);
    return (
      <React.Fragment>
        <td>{Utils.formatDateTime2(item?.created_at,t)}</td>
        <td>{item?.message}</td>
        <td><span className='view-icon-box' onClick={() => handleView(item, "viewRemark")}><Icon className={"bg-primary"} attrIcon={App_url.img.EyeIcon} /></span></td>
      </React.Fragment>
    )
  }

  const filteredAppointmentsList = appointmentList?.data?.filter(appointment => appointment.status !== t("canceled"));

  return (
    <React.Fragment>
      <div className="col-lg-6">
        <CustomTableTwo
          title={{ text: `${t("Scheduled Appointment")} (${appointmentList?.data?.length > 0 ? appointmentList?.data?.length :"0"})` }}
          AddButton={{ title: t("Schedule Appointment"), variant: "outline-primary", size: "sm" }}
          columnNames={[t('Date & Time'), t("Sales Person"), t("Remark"), t("View")]}
          recordData={filteredAppointmentsList}
          renderBody={RenderBody}
          callBack={(e, state) => callBack(e, state, "scheduleAppointment")}
          search_placeholder={t("Search By Name,City,Industry & Postcode")}
          typePlaceHolder={t("Select Status")}
          scrollbar={appointmentList?.data?.length > 3 && true}
          style={{ height: "182px" }}
        />
      </div>
      <div className="col-lg-6">
        <CustomTableTwo
          title={{ text: t("Scheduled Call") }}
          AddButton={{ title: t("Schedule Call"), variant: "outline-primary", size: "sm" }}
          columnNames={[t("Date & Time"),t( "Remark"), t("View")]}
          recordData={scheduleList?.data}
          // recordData={CallList}
          renderBody={RenderBody2}
          callBack={(e, state) => callBack(e, state, "scheduleCall")}
          search_placeholder={t("Search By Name,City,Industry & Postcode")}
          typePlaceHolder={t("Select Status")}
          scrollbar={scheduleList?.data?.length > 3 && true}
          style={{ height: "182px" }}
        />
      </div>

      <div className="col-lg-12 mt-3">
        <CustomTableTwo
          title={{ text: t("Remarks"), size: "fs-20" }}
          AddButton={{ title: t("Add Remark"), variant: "outline-primary", size: "md" }}
          columnNames={[t("Date & Time"),t( "Remark"), t("View")]}
          recordData={remarkList?.data}
          renderBody={RenderBody3}
          callBack={(e, state) => callBack(e, state, "addRemark")}
          search_placeholder={t("Search By Name,City,Industry & Postcode")}
          typePlaceHolder={t("Select Status")}
          // filter={filter}
          // onClickIcon={onClickIcon}
          setFilter={setFilter}
          scrollbar={remarkList?.data?.length > 3 && true}
          style={{ height: "182px" }}
        />
      </div>
    </React.Fragment>
  )
}
