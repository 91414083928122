/* eslint-disable */
import { toast } from "react-toastify";
import wsSendRequest from "./wsSendRequest";
import App_url from "../components/Common/Constants";
import { setStoreCurrentUser, setWebsocketReqRes } from "../store/modules/users_data/action";
import { setStoreAppointmentDetails, setStoreAppointmentList, setStoreCitiesIndustriesPincodes, setStoreCustomersDetails, setStoreCustomersList, setStoreDocumentsList, setStoreEmailHistoryList, setStoreInviteeAvailableDates, setStoreInviteesUserList, setStoreRemarkList, setStoreScheduleList, setStoreSentEmailHistoryList } from "../store/modules/customers/action";
import { Pagination } from "react-bootstrap";
import { setStoreCalculationList, setStoreCalculationOverviewList, setStoreCategoryList, setStoreDeleteCalculationOverviewList, setStoreFrequencyList, setStoreRoomList, setStoreServiceSummary, setStoreServiceTypeList } from "../store/modules/calculation/action";
import { setStorePromotionList } from "../store/modules/promotions/action";
import { setStoreExternalOfferList, setStoreGetExternalOfferList, setStoreGetOfferList, setStoreGetOfferOverview, setStoreIndustryTyppeList, setStoreOfferDashboardList, setStoreOfferOverviewDashboardList, setStoreOfferOverviewList, setStoreQuickOfferList } from "../store/modules/ExternalOffer/action";
import { setStoreGetServicesList, setStoreSelectedServicesList } from "../store/modules/services/action";
import { redirect } from "react-router-dom";
import { setStoreDocumentationsList, setStoreDocumentInsideFolderList } from "../store/modules/documentation/action";
import { setStoreEmployeeUserList } from "../store/modules/EmployeeUser/action";
import { setStoreGlobalLoader } from "../store/modules/setting_reducers/action";

export const Ws_OnMessage = ({ evt, ws, device_id, navigate, send }) => (async (dispatch, getState) => {
    const state = getState()
    const { globalLoader } = getState()?.settingReducers;
    const ws_onmessage = JSON.parse(evt.data);

    if (ws_onmessage?.device_id === device_id) {
        const payload = {
            ...ws_onmessage,
            request: ws_onmessage?.request,
            response: ws_onmessage?.response,
            msg: ws_onmessage?.response?.msg,
            status: ws_onmessage?.response?.status,
            url: ws_onmessage?.url,
        }
        dispatch(setWebsocketReqRes(payload));
        if (ws_onmessage.toast) {
            if (payload?.status === 200) {
                if (typeof payload?.msg == "string") {
                    toast.success(payload?.msg)
                }
            } else {
                toast.error(payload?.msg || payload?.message || "error")
            }
        }
        if (ws_onmessage.redirect) {
            if (payload?.status === 200) {
                navigate(ws_onmessage.redirect);
            }
        }
    }
    console.log("ws_onmessage", ws_onmessage);
    if(globalLoader == ws_onmessage.url){
        dispatch(setStoreGlobalLoader());
    }
    switch (ws_onmessage.url) {
        case "unauthorized": {
            dispatch(setStoreCurrentUser())
            window.location.replace(`${process.env.REACT_APP_API_DOMAIN_URL}/${App_url.api.USER_DEL_LOGIN}/${window.location.hostname}`)
            break;
        }
        case "customer_list": {
            if (ws_onmessage?.response?.status === 200) {
                dispatch(setStoreCustomersList(ws_onmessage?.response?.data))
            } else {
                dispatch(setStoreCustomersList({}))
            }
            break;
        }
        case "remark_list": {
            if (ws_onmessage?.response?.status === 200) {
                dispatch(setStoreRemarkList(ws_onmessage?.response?.data))
            } else {
                dispatch(setStoreRemarkList({}))

            }
            break;
        }

        case "customer_detail": {
            if (ws_onmessage?.response?.status === 200) {
                dispatch(setStoreCustomersDetails(ws_onmessage?.response?.data))
            } else {
                dispatch(setStoreCustomersDetails([]))
            }
            break;
        }

        case "schedule_list": {
            if (ws_onmessage?.response?.status === 200) {
                dispatch(setStoreScheduleList(ws_onmessage?.response?.data))
            } else {
                dispatch(setStoreScheduleList([]))
            }
            break;
        }

        // case "document_list": {
        //     if (ws_onmessage?.response?.status === 200) {
        //         dispatch(setStoreDocumentsList(ws_onmessage?.response?.data))
        //     } else {
        //         dispatch(setStoreDocumentsList({}))
        //     }
        //     break;
        // }

        case "inbox_list": {
            if (ws_onmessage?.response?.status === 200) {
                dispatch(setStoreEmailHistoryList(ws_onmessage?.response?.data))
            } else {
                dispatch(setStoreEmailHistoryList({}))
            }
            break;
        }

        case "sent_list": {
            if (ws_onmessage?.response?.status === 200) {
                dispatch(setStoreSentEmailHistoryList(ws_onmessage?.response?.data))
            } else {
                dispatch(setStoreSentEmailHistoryList({}))
            }
            break;
        }

        case "appointment_list": {
            if (ws_onmessage?.response?.status === 200) {
                dispatch(setStoreAppointmentList(ws_onmessage?.response?.data))
            } else {
                dispatch(setStoreAppointmentList({ data: [], pagination: {} }))
            }
            break;
        }
        case "user_list": {
            if (ws_onmessage?.response?.status === 200) {
                dispatch(setStoreInviteesUserList(ws_onmessage?.response?.data))
            } else {
                dispatch(setStoreInviteesUserList({ data: [], Pagination: [] }))
            }
            break;
        }
        case "appointment_detail": {
            if (ws_onmessage?.response?.status === 200) {
                dispatch(setStoreAppointmentDetails(ws_onmessage?.response?.data))
            } else {
                dispatch(setStoreAppointmentDetails({ data: [] }))
            }
            break;
        }
        case "appointments_for_month": {
            if (ws_onmessage?.response?.status === 200) {
                dispatch(setStoreInviteeAvailableDates(ws_onmessage?.response?.data))
            } else {
                dispatch(setStoreInviteeAvailableDates([]))
            }
            break;
        }
        case "category_list": {
            if (ws_onmessage?.response?.status === 200) {
                dispatch(setStoreCategoryList(ws_onmessage?.response?.data))
            } else {
                dispatch(setStoreCategoryList({ data: [], Pagination: [] }))
            }
            break;
        }
        case "service_type_list": {
            if (ws_onmessage?.response?.status === 200) {
                // console.log("ws_onmessage?.response?.data",ws_onmessage?.response?.data)
                dispatch(setStoreServiceTypeList(ws_onmessage?.response?.data))
            } else {
                dispatch(setStoreServiceTypeList([]))
            }
            break;
        }
        case "frequency_list": {
            if (ws_onmessage?.response?.status === 200) {
                // console.log("ws_onmessage?.response?.data",ws_onmessage?.response?.data)
                dispatch(setStoreFrequencyList(ws_onmessage?.response?.data))
            } else {
                dispatch(setStoreFrequencyList({ data: [], Pagination: [] }))
            }
            break;
        }
        case "calculation_list": {
            if (ws_onmessage?.response?.status === 200) {
                // console.log("ws_onmessage?.response?.data",ws_onmessage?.response?.data)
                dispatch(setStoreCalculationList(ws_onmessage?.response?.data))
            } else {
                dispatch(setStoreCalculationList({ data: [], Pagination: [] }))
            }
            break;
        }
        case "get_calculation": {
            if (ws_onmessage?.response?.status === 200) {
                // console.log("ws_onmessage?.response?.data",ws_onmessage?.response?.data)
                dispatch(setStoreCalculationOverviewList(ws_onmessage?.response?.data))
            } else {
                dispatch(setStoreCalculationOverviewList([]))
            }
            break;
        }
        case "room_list": {
            if (ws_onmessage?.response?.status === 200) {
                // console.log("ws_onmessage?.response?.data",ws_onmessage?.response?.data)
                dispatch(setStoreRoomList(ws_onmessage?.response?.data))
            } else {
                dispatch(setStoreRoomList({ rooms: [], cleaning_types: [], floor_list: [], pagination: [] }))
            }
            break;
        }
        case "promotion_list": {
            if (ws_onmessage?.response?.status === 200) {
                // console.log("ws_onmessage?.response?.data",ws_onmessage?.response?.data)
                dispatch(setStorePromotionList(ws_onmessage?.response?.data))
            } else {
                dispatch(setStorePromotionList({ data: [], Pagination: {} }))
            }
            break;
        }

        case "offer_list": {
            if (ws_onmessage?.response?.status === 200) {
                // console.log("ws_onmessage?.response?.data",ws_onmessage?.response?.data)
                dispatch(setStoreExternalOfferList(ws_onmessage?.response?.data))
            } else {
                dispatch(setStoreExternalOfferList({ data: [], Pagination: [] }))
            }
            break;
        }
        case "pincode_and_city_list": {
            if (ws_onmessage?.response?.status === 200) {
                // console.log("ws_onmessage?.response?.data",ws_onmessage?.response?.data)
                dispatch(setStoreCitiesIndustriesPincodes(ws_onmessage?.response?.data))
            } else {
                dispatch(setStoreCitiesIndustriesPincodes({ cities: [], industries: [], pincodes: [], }))
            }
            break;
        }
        case "offer_dashboard": {
            if (ws_onmessage?.response?.status === 200) {
                // console.log("ws_onmessage?.response?.data",ws_onmessage?.response?.data)
                dispatch(setStoreOfferDashboardList(ws_onmessage?.response?.data))
            } else {
                dispatch(setStoreOfferDashboardList({ statistic_list: [], max_offer: [], }))
            }
            break;
        }
        case "quick_offer_list": {
            if (ws_onmessage?.response?.status === 200) {
                dispatch(setStoreQuickOfferList(ws_onmessage?.response?.data))
            } else {
                dispatch(setStoreQuickOfferList({ data: [], Pagination: [] }))

            }
            break;
        }
        case "service_los_list": {
            if (ws_onmessage?.response?.status === 200) {
                dispatch(setStoreSelectedServicesList(ws_onmessage?.response?.data))
            } else {
                dispatch(setStoreSelectedServicesList({ data: [], Pagination: {} }))

            }
            break;
        }
        case "offer_get": {
            if (ws_onmessage?.response?.status === 200) {
                dispatch(setStoreGetExternalOfferList(ws_onmessage?.response?.data))
            } else {
                dispatch(setStoreGetExternalOfferList({ offer: [], }))

            }
            break;
        }
        case "service_delete": {
            if (ws_onmessage?.response?.status === 200) {
                if (ws_onmessage?.delete) {
                    const params = {
                        transmit: "broadcast",
                        url: "delete_calculation",
                        request: { calculation_id: ws_onmessage?.delete?.calculation_id },
                        toast: true,
                        redirect: App_url.link.savedCalculation
                    };
                    if (!params.request) {
                        params.request = {}
                    }
                    params.request.lang = state?.settingReducers?.lang || "en"
                    console.log("ws_onmessage===2222ws_onmessage", params, ws)
                    wsSendRequest(ws, params, device_id)
                } else {
                    dispatch(setStoreDeleteCalculationOverviewList(ws_onmessage?.request))
                }
            } else {

            }
            break;
        }
        case "document_list": {
            if (ws_onmessage?.response?.status === 200) {
                dispatch(setStoreDocumentationsList(ws_onmessage?.response?.data))
            } else {
                dispatch(setStoreDocumentationsList({ data: [], pagination: {}, }))
            }
            break;
        }
        case "get_service_summary": {
            if (ws_onmessage?.response?.status === 200) {
                dispatch(setStoreServiceSummary(ws_onmessage?.response?.data))
            } else {
                dispatch(setStoreServiceSummary())
            }
            break;
        }
        case "get_offer": {
            if (ws_onmessage?.response?.status === 200) {
                dispatch(setStoreGetOfferList(ws_onmessage?.response?.data))
            } else {
                dispatch(setStoreGetOfferList([]))
            }
            break;
        }
        case "get_los_service": {
            if (ws_onmessage?.response?.status === 200) {
                dispatch(setStoreGetServicesList(ws_onmessage?.response?.data))
            } else {
                dispatch(setStoreGetServicesList([]));
            }
            break;
        }
        case "offer_overview_list": {
            if (ws_onmessage?.response?.status === 200) {
                dispatch(setStoreOfferOverviewList(ws_onmessage?.response?.data))
            } else {
                dispatch(setStoreOfferOverviewList({ data: [], pagination:{} }));
            }
            break;
        }
        case "offer_overview_dashboard": {
            if (ws_onmessage?.response?.status === 200) {
                dispatch(setStoreOfferOverviewDashboardList(ws_onmessage?.response?.data))
            } else {
                dispatch(setStoreOfferOverviewDashboardList({ statistic_list: [], }));
            }
            break;
        }
        case "get_offer_overview": {
            if (ws_onmessage?.response?.status === 200) {
                dispatch(setStoreGetOfferOverview(ws_onmessage?.response?.data))
            } else {
                dispatch(setStoreGetOfferOverview({ offer_status:"", company_details: [], offer_status_details: [], quick_offer_detail: [], calculation_offer_detail: [] }));
            }
            break;
        }

        case "document_list_inside_folder": {
            if (ws_onmessage?.response?.status === 200) {
                dispatch(setStoreDocumentInsideFolderList(ws_onmessage?.response?.data))
            } else {
                dispatch(setStoreDocumentInsideFolderList({ data: [], pagination:{} }));
            }
            break;
        }

        // case "user_list": {
        //     if (ws_onmessage?.response?.status === 200) {
        //         // console.log("ws_onmessage?.response?.data",ws_onmessage?.response?.data)
        //         dispatch(setStoreEmployeeUserList(ws_onmessage?.response?.data))
        //     } else {
        //         dispatch(setStoreEmployeeUserList({ data: [], Pagination: {} }))
        //     }
        //     break;
        // }


        
        default:
            break;
    }
})
