/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import ContentLayout from '../layout/ContentLayout'
import { Card, Dropdown } from 'react-bootstrap'
import Button from '../Common/Button'
import Icon from '../Common/Icon'
import App_url from '../Common/Constants'
import Utils from '../Common/Utils'
import InputGroup from '../Common/InputGroup'
import { useTranslation } from '../../translate'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { WebSocketContext } from '../../WSContext'
import UtilsGetList from '../Common/UtilsGetList'
import { setWebsocketReqRes } from '../../store/modules/users_data/action'
import { toast } from 'react-toastify'
import wsSendRequest from '../../socketIO/wsSendRequest'
import { setStoreOfferModal } from '../../store/modules/modals/action'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const OfferDetails = () => {
    const { t, changeLanguage } = useTranslation();
    const { ws, send } = useContext(WebSocketContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { getOfferOverview } = useSelector(App_url.externalOfferReducers);
    const { frequencyList } = useSelector(App_url.calculationReducers);
    const { access_token, websocketReqRes, device_id } = useSelector(App_url.userDataReducers);
    const { lang } = useSelector(App_url.settingReducers);
    const [offerContent, setOfferContent] = useState([])
    const [isLoading, setIsLoading] = useState(false); // State to control loader
    const [openMenu, setOpenMenu] = useState(false);
    const [remarkText, setRemarkText] = useState("");
    const [remarkError, setRemarkError] = useState("");
    const [startDate, setStartDate] = useState(new Date());


    // const { id: offerOverviewId } = useParams();

    const location = useLocation();
    const state = location?.state;
    console.log("state", state)
    const [status, setStatus] = useState(t('Open'));
    console.log("getOfferOverview===", getOfferOverview);





    useEffect(() => {
        if (ws) {
            UtilsGetList.getFrequencyList({ ws, lang })
        }
        if (getOfferOverview) {
            setStatus(getOfferOverview?.offer_status);
        }
    }, [ws, getOfferOverview])

    useEffect(() => {
        const fetchData = async () => {
            if (ws) {
                const offerContent = await UtilsGetList.getOfferContent({ access_token });
                setOfferContent(offerContent);
            }
        }
        fetchData();

    }, [ws])

    const statusOptions = [
        { label: t("Open"), value: t("Open") },
        { label: t("Close"), value: t("Close") },
        { label: t("Rejected"), value: t("Rejected") },
    ]



    useEffect(() => {
        if (websocketReqRes?.url === "update_overview") {
            if (websocketReqRes?.status === 200) {
                toast.success(websocketReqRes?.response?.msg)
                setOpenMenu(!openMenu)
                dispatch(setWebsocketReqRes())
                callGetOfferOverview();
            } else {
                toast.info(websocketReqRes?.response?.msg || "error");
                dispatch(setWebsocketReqRes());
            }
        }
    }, [websocketReqRes?.url])


    const callGetOfferOverview = () => {
        console.log("newState====", state)
        const param = {
            transmit: "broadcast",
            url: "get_offer_overview",
            request: {
                id: state?.calculationId ? state?.calculationId : state?.offerId,
                overview_id: state?.overview_id,
                type: state?.offer_type,
                lang: lang,
            }
        };
        wsSendRequest(ws, param, device_id)
    }


    const [showDropdown, setShowDropdown] = useState(false); // State to control dropdown visibility

    // Function to toggle the dropdown menu
    const toggleDropdown = (isOpen) => {
        setShowDropdown(isOpen);
    };

    // Function to handle selecting an option
    const handleOptionSelect = (value) => {
        handleSelect(value);
        // Do not close the dropdown here
    };

    const handleFrequency = (frequency) => {
        const itemFrequency = frequencyList?.data?.find(item => item?.no_of_days === frequency?.toString());
        return itemFrequency?.frequency_name || 0;
    }

    const handleDownload = async (fileId) => {
        try {
            setIsLoading(true); // Show loader
            await Utils.downloadFile({ id: fileId }, access_token);
        } catch (error) {
            console.error("File download failed", error);
        } finally {
            setIsLoading(false); // Hide loader once done
        }
    };

    const handleShareViaMail = () => {
        if (state?.customerId) {
            dispatch(setStoreOfferModal({
                show: "OFFER_MODAL",
                data: { calculationId: state?.calculationId, customerId: state?.customerId, offerId: state?.offerId, offerContent, fromPage: "offerOverviewDetails" }
            }));
        }
    }


    const handleSelectedDateTime = (dateTime, type) => {
        if (type === "startDate") {
            setStartDate(dateTime);

        }
    }

    const validation = () => {
        let val = true;
        if (status == "Rejected" && remarkText === "") {
            val = false;
            setRemarkError("Please enter remark");
        }

        return val;
    }

    const handleChangeStatus = () => {
        if (validation()) {
            const param = {
                transmit: "broadcast",
                url: "update_overview",
                request: {
                    over_view_id: getOfferOverview?.offer_status_details?.[0]?.id,
                    remark: status === t("Rejected") ? remarkText : "",
                    start_date: status === t("Close") ? Utils.formatDateWithDash(startDate) : "",
                    status: status === t("Close") ? "Closed" : status,
                }
            };

            send(param)
        }
    }


    const handleOverview = () => {
        if (state?.calculationId) {
            navigate(`${App_url.link.calculationOverview}/${state?.calculationId}`, { state: { overviewId: state?.overview_id } })
        } else if (state?.offerId) {
            UtilsGetList.callCustomerDetailsApi({ ws: ws, customer_id: state?.customerId, lang: lang })
            const param = {
                transmit: "broadcast",
                url: "get_offer",
                request: {
                    offer_id: state?.offerId,
                }
            };
            send(param);
            navigate(`${App_url.link.addCreateOffer}/${state?.offerId}`, { state: { offerItem: { id: state?.offerId }, overviewId: state?.overview_id } })
        }
    }

    const getOfferCount = (index) =>{
        if(index === 0) return t("First Offer");
        if(index === 1) return t("Second Offer");
        if(index === 2) return t("Third Offer");
        if(index === 3) return t("Fourth Offer");
        if(index === 4) return t("Fifth Offer");
        if(index === 5) return t("Sixth Offer");
        if(index === 6) return t("Seventh Offer");
        if(index === 7) return t("Eighth Offer");
        if(index === 8) return t("Ninth Offer");
        if(index === 9) return t("Tenth Offer");
    }

    return (
        <ContentLayout title={"Offer Details Page"}>
            <div className='Offer-Details'>
                {/* =========== Company Details ==================== */}
                <Card className='card'>
                    <Card.Body>
                        <div className='card-body-header'>
                            <h6 className='fs-20'>{t("Company Details")}</h6>
                            <div className='filters-box'>
                                <div className='status-main-box me-2'>
                                    <div className='status-content-box' onClick={() => setOpenMenu(!openMenu)}> <span>{t("Status")} {t(status)}</span> <Icon className={"sm"} attrIcon={App_url.img.ArrowDown} /> </div>
                                    {status != "Closed" && <React.Fragment>
                                        <div className={`${openMenu ? "d-block" : "d-none"} status-menu-box`}>

                                            {statusOptions && statusOptions?.map((option) => (
                                                <div className='label-box' onClick={() => { setStatus(option?.value); }}>
                                                    <span>{option?.label}</span>
                                                    {status === option?.value ? (
                                                        <span className='title'>
                                                            <Icon image attrIcon={App_url.img.Radiobutton2} />
                                                        </span>
                                                    ) : (
                                                        <span className='title'>
                                                            <Icon image attrIcon={App_url.img.RadioCircleIcon} />
                                                        </span>
                                                    )}
                                                </div>
                                            ))}

                                            {status === t("Close") &&
                                                <>
                                                    <div className='custom-date-box'>
                                                        <span>Start Date</span>
                                                        <div>
                                                            <DatePicker
                                                                selected={startDate}
                                                                onChange={(date) => handleSelectedDateTime(date, "startDate")}
                                                                dateFormat="dd MMM yyyy"
                                                                placeholderText={t("Start Date")}
                                                                name={"startDate"}
                                                                value={new Date()}
                                                                className={"start-date "}
                                                                wrapperClassName='custom-date-time-wrapper'
                                                                minDate={new Date()}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            {status === t("Rejected") &&
                                                <>
                                                    <InputGroup
                                                        label={""}
                                                        placeholder={t("Enter remark note")}
                                                        formClassName=" col-12 rounded"
                                                        name={"message"}
                                                        as={"textarea"}
                                                        rows='3'
                                                        className='textgrp'
                                                        value={remarkText}
                                                        error={remarkError}
                                                        onChange={(e) => { setRemarkText(e.target.value); setRemarkError("") }}
                                                    />
                                                </>
                                            }

                                            <div className='status-btn-box'>
                                                <Button onClick={() => setOpenMenu(!openMenu)} className={"status-btn-cancel"} variant={"outline-primary"} size={"sm"}>{t("Cancel")}</Button>
                                                <Button onClick={handleChangeStatus} className={"status-btn-submit"} variant={"primary"} size={"sm"}>{t("Submit")}</Button>
                                            </div>
                                        </div>
                                    </React.Fragment>}
                                </div>
                                {(getOfferOverview?.offer_status !== "Close" && getOfferOverview?.offer_status !== "Closed") &&
                                    <Button onClick={handleOverview} size={'md'} className={""} variant={"grey"} >{t("Update")}</Button>
                                }
                                <Button onClick={handleShareViaMail} size={'md'} className={"ms-2"} variant={"primary"}>{t("Share via mail")}</Button>
                            </div>
                        </div>
                        <div className='comapny '>
                            <Icon className={"bg-white me-2 cursor-pointer"} attrIcon={App_url.img.BuildingIcon2} />
                            <h6 className='company-title'><strong>{t("Company Name")}: {getOfferOverview?.company_details?.company_name}</strong></h6>
                        </div>
                        <hr />
                        <div className='row'>
                            <div className='details col-4'>
                                <Icon className={"bg-white me-2 cursor-pointer"} image attrIcon={App_url.img.EmailIcon2} />
                                <h6>{t("Email Address")}:-</h6>
                                <h6>{getOfferOverview?.company_details?.email}</h6>
                            </div>
                            <div className='details col-4'>
                                <Icon className={"bg-white me-2 cursor-pointer"} image attrIcon={App_url.img.PhoneIcon} />
                                <h6>{t("Company Number")} :-</h6>
                                <h6>{getOfferOverview?.company_details?.company_mobile}</h6>
                            </div>
                            <div className='details col-4'>
                                <Icon className={"bg-white me-2 cursor-pointer"} image attrIcon={App_url.img.UserIcon} />
                                <h6>{t("Person Name")} :-</h6>
                                <h6>{getOfferOverview?.company_details?.first_name} {getOfferOverview?.company_details?.last_name}</h6>
                            </div>

                        </div>
                        <div className='row mt-3'>
                            <div className='details col-4'>
                                <Icon className={"bg-white me-2 cursor-pointer"} image attrIcon={App_url.img.PhoneIcon} />
                                <h6>{t("Contact Person Number")} :-</h6>
                                <h6>{getOfferOverview?.company_details?.phone_number}</h6>
                            </div>
                            <div className='details col-5'>
                                <Icon className={"bg-white me-2 cursor-pointer"} image attrIcon={App_url.img.LocationIcon} />
                                <h6>{t("Address")} :-</h6>
                                <h6>{getOfferOverview?.company_details?.address}</h6>
                            </div>
                            {/* <div className='details col-3'>
                                <Icon className={"bg-white me-2 cursor-pointer"} image attrIcon={App_url.img.DirectionIcon} />
                                <h6>{t("Direction")}</h6>
                            </div> */}
                        </div>
                    </Card.Body>
                </Card>



                {/*============================= offer status Details ================== */}
                <Card className='card rounded-3 calculation-overview-card mt-3'>
                    <Card.Body>
                        <div className='card-body-header'>
                            <h6 className='fs-20'>{t("Offer Status Details")}</h6>
                        </div>

                        <div className='custom-table-overview mt-3'>
                            <table className='table table-bordered'>
                                {getOfferOverview?.offer_status_details?.map((data, index) => {
                                    return (
                                        <React.Fragment>
                                            <thead className=''>
                                                <tr>
                                                    {index === 0 ?
                                                        <th className={"header-1"}  >{t("Offer ID")} {getOfferOverview?.offer_status_details?.[0]?.offer_overview_code}</th>
                                                        : <th></th>
                                                    }
                                                    <th className={"header-2"} colSpan={3}>
                                                        <div className='d-flex justify-content-between'>
                                                            <span>{getOfferCount(index)}</span>

                                                        </div>
                                                    </th>
                                                    {/* {item?.additional_cost?.length > 0 && */}
                                                    <th className={"header-2"} colSpan={3}>
                                                        <div className='d-flex justify-content-between'>
                                                            <span>{t("Status")}</span>
                                                        </div>
                                                    </th>
                                                    {/* } */}
                                                </tr>
                                                <tr className='sub-header-1'>
                                                    {index === 0 ?

                                                        <th className='ps-4 pe-0'>{t("Offer Details")} </th> : <th className='bg-white'></th>
                                                    }

                                                    <th className='text-center'>{t("Cost")}</th>
                                                    <th className='text-center'>{t("Document")}</th>
                                                    <th className='text-center'>{t("Date")}</th>
                                                    {/* {item?.additional_cost?.length > 0 && */}
                                                    <React.Fragment>
                                                        <th className='text-center'>{t(data?.status)}</th>
                                                        <th className='text-center'>{t("Start Date")}</th>
                                                        <th className='text-center'>{t("Date")}</th>
                                                    </React.Fragment>
                                                    {/* } */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className='content'>
                                                    {index === 0 ?
                                                        <td rowSpan={2}>
                                                            <div className='mt-1 ps-3'>
                                                                <Icon image attrIcon={App_url.img.CreateOfferIcon} /> <span>{t("Offer Type")} : {t(data?.offer_type)} </span>
                                                            </div> <br />
                                                            <div className='mb-1 ps-3'>
                                                                <Icon image attrIcon={App_url.img.ServicesIcon2} /> <span>{t("Services")} : {data?.services?.join(", ")} </span>
                                                            </div>
                                                        </td> : <td></td>
                                                    }
                                                    <td rowSpan={2}><div className='mt-1 text-center'>€ {Utils.trimDecimal(data?.total_yearly_cost, 2)}  </div><br /></td>
                                                    <td rowSpan={2}><div className='mt-1 text-center '>
                                                       {data?.document && 
                                                       <>
                                                        <Icon image attrIcon={App_url.img.PdfIcon2} className={'me-3'} />
                                                        <Icon onClick={() => handleDownload(data?.document)} className={"me-3"} image attrIcon={App_url.img.DownloadIcon} />
                                                        {isLoading && <Icon image attrIcon={App_url.img.LoaderIcon} />}
                                                       </>
                                                       }
                                                    </div><br /> </td>
                                                    <td rowSpan={2}><div className='mt-1 text-end'>{Utils.formatDateCreatedAt2(data?.created_at, t)} </div><br /></td>
                                                    <>
                                                        <td className='text-center'></td>
                                                        <td>{data?.start_date ? Utils.formatDateCreatedAt2(data?.start_date, t) : ""}</td>
                                                        <td>{Utils.formatDateCreatedAt2(data?.updated_at, t)}</td>
                                                    </>
                                                </tr>


                                            </tbody>

                                        </React.Fragment>
                                    )
                                })}
                            </table>
                        </div>

                    </Card.Body>
                </Card>

                {/* ===================== calculation overview ================== */}
                {getOfferOverview?.calculation_offer_detail?.length > 0 &&
                    <Card className='mt-3 card rounded-3 calculation-overview-card'>
                        <Card.Body>
                            <div className='card-body-header'>
                                <h6 className='fs-20'>{t("Calculation Overview")}</h6>
                            </div>
                            {getOfferOverview?.calculation_offer_detail?.length > 0 && getOfferOverview?.calculation_offer_detail?.map((item) => (
                                <div className='custom-table-overview mt-3'>
                                    <table className='table table-bordered'>
                                        <thead className=''>
                                            <tr>
                                                <th className={"header-1"}>{t(item?.category_name)}</th>
                                                <th className={"header-2"} colSpan={3}>
                                                    <div className='d-flex justify-content-between'>
                                                        <span> {t("Man Hour Cost Calculation")}</span>
                                                        {(item?.category_id !== App_url.ids.glassCleaningID || item?.additional_cost?.length === 0) &&
                                                            <div>
                                                                {/* <Icon onClick={() => handleEditService(item)} className={"bg-white me-2 cursor-pointer"} attrIcon={App_url.img.EditIcon} /> */}
                                                                {/* <Icon onClick={() => handleDeleteServiceModal(item?.id)} className={"bg-white cursor-pointer"} attrIcon={App_url.img.DeleteIcon} /> */}
                                                            </div>
                                                        }
                                                    </div>
                                                </th>
                                                {item?.additional_cost?.length > 0 &&
                                                    <th className={"header-2"} colSpan={3}>
                                                        <div className='d-flex justify-content-between'>
                                                            <span> {t("Additional Cost Calculation")}</span>
                                                            <div>
                                                                <Icon className={"bg-white me-2"} attrIcon={App_url.img.EditIcon} />
                                                                {/* <Icon onClick={() => handleDeleteServiceModal(item?.id)} className={"bg-white cursor-pointer"} attrIcon={App_url.img.DeleteIcon} /> */}
                                                            </div>
                                                        </div>
                                                    </th>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className='sub-header-1'>
                                                {item?.category_id === App_url.ids.glassCleaningID ? <th className='ps-4 pe-0'>{item?.service_name}</th> : <th className='ps-4 pe-0'>{t("Surface Area")} : {item?.surface_area} {t("Meter Square")}</th>}
                                                <th className='text-center'>{t("Particular")}</th>
                                                <th className='text-center'>{t("Hours")}</th>
                                                <th className='text-center'>{t("Cost")}</th>
                                                {item?.additional_cost?.length > 0 &&
                                                    <React.Fragment>
                                                        <th className='text-center'>{t("Particular")}</th>
                                                        <th className='text-center'>{t("Frequency")}</th>
                                                        <th className='text-center'>{t("Cost")}</th>
                                                    </React.Fragment>
                                                }
                                            </tr>
                                            {item?.category_id === App_url.ids.glassCleaningID && <React.Fragment>
                                                <tr className='content'>
                                                    <td rowSpan={2}>
                                                        <div className='mt-1 ps-3'>
                                                            <Icon image attrIcon={App_url.img.SurfaceIcon} /> <span>{t("Surface Area")}: {item?.surface_area} </span>
                                                        </div> <br />
                                                        <div className='mb-1 ps-3'>
                                                            {/* <Icon image attrIcon={App_url.img.CalendarIcon3} /> <span>{t("Frequency")} : {handleFrequency(item?.frequency)}</span> */}
                                                        </div>
                                                    </td>
                                                    <td rowSpan={2}><div className='mt-1 text-center'>{t("Each")}</div><br /> <div className='mb-1 text-center'> {t("Yearly")} </div></td>
                                                    <td rowSpan={2}><div className='mt-1 text-end'>{Utils.trimDecimal(item?.per_exceution_hour, 2)} </div><br /> <div className='mt-1 text-end'>{Utils.trimDecimal(item?.yearly_hour, 2)}</div></td>
                                                    <td rowSpan={2}><div className='mt-1 text-end'>€ {Utils.trimDecimal(item?.per_exceution, 2)}</div><br /><div className='mt-1 text-end'>€ {Utils.trimDecimal(item?.yearly_cost, 2)}</div></td>

                                                    {item?.additional_cost?.length > 0 && (
                                                        <>
                                                            <td className='text-center'>{t("Scaffold")}</td>
                                                            <td className='text-center'>{Utils.trimDecimal(item?.additional_cost?.find(cost => cost.type === 'scaffold')?.no_of_operations_per_year || 0, 2)}</td>
                                                            <td className='text-center'>€ {Utils.trimDecimal(item?.additional_cost?.find(cost => cost.type === 'scaffold')?.cost_per_operation || 0, 2)}</td>
                                                        </>
                                                    )}
                                                </tr>

                                                {item?.additional_cost?.length > 0 &&
                                                    <React.Fragment>
                                                        <tr className='content'>
                                                            <td className='text-center'>{t("Material")}</td>
                                                            <td className='text-center'>{Utils.trimDecimal(item?.additional_cost?.find(cost => cost.type === 'material')?.no_of_operations_per_year || 0, 2)}</td>
                                                            <td className='text-center'>€ {Utils.trimDecimal(item?.additional_cost?.find(cost => cost.type === 'material')?.cost_per_operation || 0, 2)}</td>
                                                        </tr>
                                                    </React.Fragment>
                                                }
                                                {item?.additional_cost?.length > 0 &&
                                                    <React.Fragment>
                                                        <tr className='content'>
                                                            <td></td>
                                                            <td colSpan={3}></td>
                                                            <td className='text-center'>{t("Misc")}</td>
                                                            <td className='text-center'>{Utils.trimDecimal(item?.additional_cost?.find(cost => cost.type === 'miscellaneous')?.no_of_operations_per_year || 0, 2)}</td>
                                                            <td className='text-center'>€ {Utils.trimDecimal(item?.additional_cost?.find(cost => cost.type === 'miscellaneous')?.cost_per_operation || 0, 2)}</td>
                                                        </tr>
                                                    </React.Fragment>
                                                }
                                            </React.Fragment>}
                                            {(item?.category_id === App_url.ids.fullCleaningID ||
                                                item?.category_id === App_url.ids.intervalCleaningID ||
                                                item?.category_id === App_url.ids.buildingDivisionID ||
                                                item?.category_id === App_url.ids.maintenanceCleaningID
                                            ) &&
                                                <React.Fragment>
                                                    <tr className='content'>
                                                        <td rowSpan={4}></td>
                                                        <td className='text-center'>{t("Sunday")}</td>
                                                        <td className='text-center'>{Utils.trimDecimal(item?.sunday_hour, 2)}</td>
                                                        <td className='text-center'>€  {Utils.trimDecimal(item?.sunday_cost, 2)}</td>
                                                    </tr>
                                                    <tr className='content'>
                                                        <td className='text-center'>{t("Weekday")}</td>
                                                        <td className='text-center'>{Utils.trimDecimal(item?.weekday_hour, 2)}</td>
                                                        <td className='text-center'>€  {Utils.trimDecimal(item?.weekday_cost, 2)}</td>
                                                    </tr>
                                                    <tr className='content'>
                                                        <td className='text-center'>{t("Monthly")}</td>
                                                        <td className='text-center'>{Utils.trimDecimal(item?.monthly_hour, 2)}</td>
                                                        <td className='text-center'>€  {Utils.trimDecimal(item?.monthly_cost, 2)}</td>
                                                    </tr>
                                                    <tr className='content'>
                                                        <td className='text-center'>{t("Yearly")}</td>
                                                        <td className='text-center'>{Utils.trimDecimal(item?.yearly_hour, 2)}</td>
                                                        <td className='text-center'>€  {Utils.trimDecimal(item?.yearly_cost, 2)}</td>
                                                    </tr>
                                                </React.Fragment>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            ))}
                        </Card.Body>
                    </Card>
                }

                {/* ===================== quick offer details */}
                {getOfferOverview?.quick_offer_detail?.length > 0 &&
                    <Card className='mt-3 card rounded-3 calculation-overview-card'>
                        <Card.Body>
                            <div className='card-body-header'>
                                <h6 className='fs-20'>{t("Offer Overview Details")}</h6>
                            </div>
                            {getOfferOverview?.quick_offer_detail?.length > 0 && getOfferOverview?.quick_offer_detail?.map((item) => (
                                <div className='custom-table-overview mt-3'>
                                    <table className='table table-bordered'>
                                        <thead className=''>
                                            <tr>
                                                <th className={"header-1"}>{t(item?.service_category)}</th>
                                                <th className={"header-2"} colSpan={3}>
                                                    <div className='d-flex justify-content-between'>
                                                        <span> {t("Man Hour Cost Calculation")}</span>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className='sub-header-1'>
                                                <th></th>
                                                {/* {item?.category_name === "Glass Cleaning" ? <th className='ps-4 pe-0'>{item?.service_name}</th> : <th className='ps-4 pe-0'>Surface Area : {item?.surface_area} Meter Square</th>} */}
                                                <th className='text-center'>{t("Particular")}</th>
                                                {/* <th className='text-center'>{t("Hours")}</th> */}
                                                <th className='text-center'>{t("Cost")}</th>
                                            </tr>
                                            <tr className='content'>
                                                <td rowSpan={2}>
                                                    <div className='mb-1 ps-3'>
                                                        <Icon image attrIcon={App_url.img.CalendarIcon3} /> <span>{t("Frequency")} : {handleFrequency(item?.yearly_frequency)}</span>
                                                    </div>
                                                </td>
                                                <td rowSpan={2}><div className='mt-1 text-center'>{t("Each")}</div><br /> <div className='mb-1 text-center'> {t("Yearly")} </div></td>
                                                {/* <td rowSpan={2}><div className='mt-1 text-end'>{Utils.trimDecimal(item?.per_exceution_hour, 2)} </div><br /> <div className='mt-1 text-end'>{Utils.trimDecimal(item?.yearly_hour, 2)}</div></td> */}
                                                <td rowSpan={2}><div className='mt-1 text-center'>€ {Utils.trimDecimal(item?.cost, 2)}</div><br /><div className='mt-1 text-center'>€ {Utils.trimDecimal(item?.yearly_cost, 2)}</div></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            ))}
                        </Card.Body>
                    </Card>

                }
            </div>
        </ContentLayout>
    )
}

export default OfferDetails