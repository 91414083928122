/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import Select from 'react-select';
import ContentLayout from '../layout/ContentLayout'
import { Breadcrumb, Card, Dropdown, Form } from 'react-bootstrap'
import Button from '../Common/Button'
import InputGroup from '../Common/InputGroup'
import Icon from '../Common/Icon'
import App_url from '../Common/Constants'
import DragDropUpload from '../Common/DragDropUpload'
import wsSendRequest from '../../socketIO/wsSendRequest'
import { WebSocketContext } from '../../WSContext'
import { useDispatch, useSelector } from 'react-redux'
import { redirect, useLocation, useNavigate } from 'react-router-dom'
import { setStoreOfferModal } from '../../store/modules/modals/action'
import UtilsGetList from '../Common/UtilsGetList'
import { setWebsocketReqRes } from '../../store/modules/users_data/action'
import { toast } from 'react-toastify'
import Utils from '../Common/Utils'
import { useTranslation } from '../../translate'
import AnchorLink from '../Common/AnchorLink'

const AddCreateOfferLetter = () => {
    const { t, changeLanguage } = useTranslation();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const offerItem = location.state;
    const overview_id = location.state?.overviewId;
    const { ws, send } = useContext(WebSocketContext)
    const { device_id, access_token, websocketReqRes } = useSelector(App_url.userDataReducers);
    const { lang } = useSelector(App_url.settingReducers);
    const { customersList } = useSelector(App_url.customerReducers);
    const { categoryList, frequencyList, currentCategory } = useSelector(App_url.calculationReducers);
    const { customerDetails } = useSelector(App_url.customerReducers);
    // console.log("customerDetails", customerDetails);
    const { getOfferList } = useSelector(App_url.externalOfferReducers);
    const [offerContent, setOfferContent] = useState("");

    const [formData, setFormData] = useState({
        address: "",
        company_name: "",
        company_id:"",
        first_name: "",
        last_name: "",
        phone_number: "",
        email: "",
        street: "",
        city: "",
        postal_code: "",
        offer_detail: [],
        total_yearly_cost: 0,
        customer_id: '',
        category_id: [],
        offer_id: ''

    })
    // console.log("formData", formData);

    const [error, setError] = useState({
        company_name: "",
        tender_number: "",
        service_type: "",
        industry_type: "",
        cost: "",
        date: "",
        postal_code: "",
        city: "",
        document: "",
        service_category: '',
        yearly_frequency: '',
        cost: ''
    })
    //   console.log("error", error)

    const categoryOptions = categoryList?.data?.map((item) => {
        return { label: item?.category_name, value: item?.id }
    })

    const frequencyOptions = frequencyList?.data?.map((item) => {
        return { label: item?.frequency_name, value: item?.id }
    })

    console.log("customersList", customersList);

    const companyNameList = customersList?.data?.map((item) => {
        return {
            label: item?.company_name ? item?.company_name : item?.customer_name,
            value: item?.id,
            customer_name: item?.customer_name,
            last_name: item?.last_name,
            company_mobile: item?.company_mobile,
            city: item?.city,
            email: item?.email,
            company_address: item?.company_address,
            street_no: item?.street_no,
            postal_code: item?.postal_code,
            company_name: item?.company_name,
            customer_id: item?.id,
        };
    });
  
    // console.log("getOfferList", getOfferList);


    const handleSelectCompanyName = (event) => {
        const companyId = event.target.value;
        const selectedCompany = companyNameList?.find(option => option.customer_id === companyId);
        console.log("customerDetails", selectedCompany);

        UtilsGetList.callCustomerDetailsApi({ ws: ws, customer_id: selectedCompany?.customer_id, lang: lang })

        setFormData(prev => ({
            ...prev,
            company_name: selectedCompany?.label || '',
            company_id: selectedCompany?.customer_id || '',
            first_name: selectedCompany?.customer_name || '',
            customer_id: selectedCompany?.customer_id || '',
            last_name: selectedCompany?.last_name || '',
            phone_number: selectedCompany?.company_mobile || '',
            city: selectedCompany?.city || '',
            email: selectedCompany?.email || '',
            address: selectedCompany?.company_address || '',
            street: selectedCompany?.street_no || '',
            postal_code: selectedCompany?.postal_code || '',
        }));
        setError((prevData) => ({
            ...prevData,
            company_name: "",
            first_name: "",
            customer_id: "",
            last_name: "",
            phone_number: "",
            city: "",
            email: "",
            address: "",
            street: "",
            postal_code: "",

        }))

    };



    //showing offer_detail foem 1st time without delete button
    useEffect(() => {
        if (!formData?.offer_detail || formData?.offer_detail.length === 0) {
            setFormData({
                ...formData,
                offer_detail: [{ building_detail: "", yearly_frequency: '', cost: "", remark: "", category_id: '', service_category: '' }]
            });
        }
    }, [formData?.offer_detail?.yearly_cost]);

    useEffect(() => {
        if (ws) {
            callCustomersListApi(1);
            callCategoryListApi(1);
            UtilsGetList.getFrequencyList({ ws: ws, device_id: device_id, lang: lang });
        }
        const fetchData = async () => {
            if (ws) {
                const offerContent = await UtilsGetList.getOfferContent({ access_token });
                setOfferContent(offerContent);
            }
        }
        fetchData();

    }, [ws, offerItem]);

    console.log("getOfferList", getOfferList);

    useEffect(() => {
        if (offerItem) {
            const categoryIds = getOfferList[0]?.offer_detail?.map(item => item?.category_id);
            // console.log("categoryIds", categoryIds);

            setFormData((prevData) => ({
                ...prevData,
                ...getOfferList?.[0],
                prevOfferDetails: getOfferList?.[0]?.offer_detail,
                company_id: getOfferList?.[0]?.customer_id,
                category_id: categoryIds,
            }))
        }
    }, [offerItem, getOfferList]);




    const handleServiceCategorySelect = (value, index) => {
        const selectedOption = categoryOptions?.find(option => option.value === value);
        if (selectedOption) {
            setFormData((prevData) => ({
                ...prevData,
                // category_id: [...prevData?.category_id, selectedOption?.value],
                offer_detail: prevData.offer_detail?.map((item, idx) =>
                    idx === index
                        ? {
                            ...item,
                            service_category: selectedOption.label,
                            category_id: selectedOption.value
                        }
                        : item
                )
            }));
            setError((prevData) => ({
                ...prevData,
                [`service_category_${index}`]: "",
            }));
        }
    };

    const handleYearlyFrequencySelect = (eventKey, index) => {
        const selectedFrequency = frequencyList?.data?.find(option => option.id === eventKey);
        console.log("selectedFrequency", selectedFrequency);

        if (selectedFrequency) {
            setFormData((prevData) => ({
                ...prevData,
                offer_detail: prevData?.offer_detail?.map((item, idx) =>
                    idx === index
                        ? {
                            ...item,
                            yearly_frequency: selectedFrequency?.no_of_days,
                            yearly_frequency_value: selectedFrequency?.frequency_name
                        }
                        : item
                )
            }));

            setError((prevData) => ({
                ...prevData,
                [`yearly_frequency_${index}`]: "",
            }));
        }
    };

    // Add more Offer Details
    const handleAddMore = () => {
        setFormData((prevData) => ({
            ...prevData,
            offer_detail: [
                ...prevData.offer_detail,
                { building_detail: "", yearly_frequency: '', cost: "", remark: "", category_id: '', service_category: '', yearly_cost: 0 }
            ]
        }));
    };


    // Handle input changes for individual offer fields and update yearly_cost and total_yearly_cost
    const handleInputChange = (name, value, index) => {
        setFormData((prevData) => {
            const updatedOfferDetails = prevData.offer_detail.map((item, idx) => {
                if (idx === index) {
                    const updatedItem = { ...item, [name]: value };

                    // Check if the selected service category is one of the specified ones
                    if (
                        updatedItem?.service_category === 'Maintenance Cleaning' ||
                        updatedItem?.service_category === 'Building Division' ||
                        updatedItem?.service_category === 'Full Cleaning' ||
                        updatedItem?.service_category === 'Interval Cleaning'
                    ) {
                        if (name === "cost" || name === "yearly_frequency") {
                            const yearly_frequency = parseFloat(updatedItem.yearly_frequency) || 0;
                            const cost = parseFloat(updatedItem.cost) || 0;
                            updatedItem.yearly_cost = yearly_frequency * cost; // Calculate based on yearly_frequency and cost
                        }
                    } else {
                        // If the category is not one of the specified ones, calculate yearly cost as cost * 12
                        if (name === "cost") {
                            const cost = parseFloat(updatedItem.cost) || 0;
                            updatedItem.yearly_cost = cost * 12; // Calculate as cost * 12
                        }
                    }
                    return updatedItem;
                }
                return item;
            });
            // console.log(updatedOfferDetails)
            const totalYearlyCost = updatedOfferDetails.reduce((acc, item) => acc + (item.yearly_cost || 0), 0);
            return {
                ...prevData,
                offer_detail: updatedOfferDetails,
                total_yearly_cost: totalYearlyCost
            };
        });
        setError((prevData) => ({
            ...prevData,
            [`cost_${index}`]: "",
        }));

    };

    // Delete Offer Detail
    const handleDelete = (index) => {
        const offer_detail = formData?.offer_detail?.filter((_, idx) => idx !== index);
        const total_yearly_cost = formData?.offer_detail.reduce((acc, item) => acc + (item.yearly_cost || 0), 0);
        setFormData((prevData) => ({
            ...prevData,
            offer_detail: offer_detail,
            total_yearly_cost: total_yearly_cost,
        }));
    };


    const callCustomersListApi = (page) => {
        const param = {
            transmit: "broadcast",
            url: "customer_list",
            request: {
                status: true,
                limit: "-1",
                page_no: page,
                pincode_search: "",
                city_search: "",
                customer_type: [""],
                order_by: "-updated_at",
                industry_search: "",
            }
        };
        send(param);
    };

    const callCategoryListApi = (page) => {
        const param = {
            transmit: "broadcast",
            url: "category_list",
            request: { limit: "10", page_no: page, order_by: "-updated_at", }
        };
        send(param);
    };
    const handleGetOffer = () => {
        const param = {
            transmit: "broadcast",
            url: "get_offer",
            request: {
                offer_id: offerItem?.id
            }
        };
        // console.log("param",param)
        send(param);
    }

 

    // console.log("offerItem", offerItem);

    const callAddCreateOfferApi = () => {
        const param = {
            transmit: "broadcast",
            url: offerItem?.id ? "create_offer" : "create_offer",
            request: {
                offer_id: offerItem?.id ? offerItem?.id : '',
                address: formData?.address,
                company_name: formData?.company_name,
                first_name: formData?.first_name,
                last_name: formData?.last_name,
                phone_number: formData?.phone_number,
                email: formData?.email,
                street: formData?.street,
                city: formData?.city,
                postal_code: formData?.postal_code,
                customer_id: formData?.customer_id,
                category_id: formData?.offer_detail?.map(item => item?.category_id),
                offer_detail: formData?.offer_detail,
                total_yearly_cost: formData?.total_yearly_cost
            },
        };
        // console.log("param", param)
        send(param, device_id);
    };

    useEffect(() => {
        if (websocketReqRes?.url === "create_offer" && !offerItem) {
            if (websocketReqRes?.status === 200) {
                toast.success(websocketReqRes?.response?.msg)
                dispatch(setWebsocketReqRes())
                navigate(App_url.link.createOffer);
                dispatch(setStoreOfferModal({
                    show: "OFFER_MODAL",
                    data: { offerContent, offerId: websocketReqRes?.response?.data?.offer_id, customerId: customerDetails?.[0]?.id, email: formData?.email, fromPage:"createOffer" }
                }));

            } else {
                toast.info(websocketReqRes?.response?.msg || "error");
                dispatch(setWebsocketReqRes());
            }
        } else if (websocketReqRes?.url === "create_offer" && offerItem) {
            if (websocketReqRes?.status === 200) {
                // toast.success(websocketReqRes?.response?.msg)
                toast.success("Offer updated successfully")
                dispatch(setWebsocketReqRes())
                navigate(App_url.link.createOffer);
                dispatch(setStoreOfferModal({
                    show: "OFFER_MODAL",
                    data: { offerContent, offerId: websocketReqRes?.response?.data?.offer_id, customerId: customerDetails?.[0]?.id, email: formData?.email }
                }));

            } else {
                toast.info(websocketReqRes?.response?.msg || "error");
                dispatch(setWebsocketReqRes());
            }
        }
    }, [websocketReqRes?.url])


    const validation = () => {
        let val = true;
        let error = {};

        if (!formData?.company_name) {
            error.company_name = t("Please select or type company name");
            val = false;
        }

        // if (!formData?.address) {
        //     error.address = "Please enter address";
        //     val = false;
        // }

        // if (!formData?.street) {
        //     error.street = t("Please enter street name");
        //     val = false;
        // }
        // if (!formData?.first_name) {
        //     error.first_name = "Please enter first name";
        //     val = false;
        // }
        // if (!formData?.last_name) {
        //     error.last_name = "Please enter last name";
        //     val = false;
        // }
        // if (!formData?.city) {
        //     error.city = "Please enter city name";
        //     val = false;
        // }
        // if (!formData?.postal_code) {
        //     error.postal_code = "Please enter postal code";
        //     val = false;
        // }
        // if (!formData?.phone_number) {
        //     error.phone_number = "Please enter phone number";
        //     val = false;
        // }
        // if (!formData?.email) {
        //     error.email = "Please enter email";
        //     error.address = t("Please enter address");
        //     val = false;
        // }

        // if (!formData?.street) {
        //     error.street = t("Please enter street name");
        //     val = false;
        // }
        // if (!formData?.first_name) {
        //     error.first_name = t("Please enter first name");
        //     val = false;
        // }
        // if (!formData?.last_name) {
        //     error.last_name = t("Please enter last name");
        //     val = false;
        // }
        // if (!formData?.city) {
        //     error.city = t("Please enter city name");
        //     val = false;
        // }
        // if (!formData?.postal_code) {
        //     error.postal_code = t("Please enter postcode");
        //     val = false;
        // }
        // if (!formData?.phone_number) {
        //     error.phone_number = t("Please enter phone number");
        //     val = false;
        // }
        // if (!formData?.email) {
        //     error.email = t("Please enter email");
        //     val = false;
        // }
        formData?.offer_detail?.forEach((item, index) => {
            if (!item?.service_category) {
                error[`service_category_${index}`] = t("Please select service category");
            }
            if (!item?.yearly_frequency) {
                error[`yearly_frequency_${index}`] = t("Please select frequency");
            }
            if (!item?.cost) {
                error[`cost_${index}`] = t("Please enter cost");
            }
        });
        if (error && Object.keys(error).length > 0) {
            setError(error);
            return;
        }
        setError((prevData) => ({
            ...prevData,
            ...error
        }));

        return val;
    };
    const onSubmit = (e) => {
        e.preventDefault();
        if (validation()) {
            if (offerItem) {
                if (formData?.prevOfferDetails?.length !== formData?.offer_detail?.length) {
                    console.log("offerLength", formData?.prevOfferDetails?.length, formData?.offer_detail?.length)
                    callAddCreateOfferApi();
                } else {
                    // Compare content of each object
                    const hasChanges = formData?.prevOfferDetails?.some((prevOffer, index) => {
                        const currentOffer = formData?.offer_detail[index];
                        return (
                            prevOffer.cost !== currentOffer.cost ||
                            prevOffer.remark !== currentOffer.remark ||
                            prevOffer.category_id !== currentOffer.category_id ||
                            prevOffer.yearly_cost !== currentOffer.yearly_cost ||
                            prevOffer.building_detail !== currentOffer.building_detail ||
                            prevOffer.service_category !== currentOffer.service_category ||
                            prevOffer.yearly_frequency !== currentOffer.yearly_frequency ||
                            prevOffer.yearly_frequency_value !== currentOffer.yearly_frequency_value
                        );
                    });

                    // If there are changes, call the API
                    if (hasChanges) {
                        callAddCreateOfferApi();
                    } else {
                        dispatch(setStoreOfferModal({
                            show: "OFFER_MODAL",
                            data: { offerContent, offerId: offerItem?.id, customerId: customerDetails?.[0]?.id, email: formData?.email, fromPage:"createOffer" }
                        }));
                    }
                }
            } else {
                callAddCreateOfferApi();
            }
        }


    };

    const onChange = (e) => {
        setFormData((data) => ({
            ...data,
            [e.target.name]: e.target.value
        }))
        setError((prevData) => ({
            ...prevData,
            [e.target.name]: "",
        }))
    }

    // console.log("formData", formData);

    return (
        <div><ContentLayout title={'Add Create Offer-Letter'}>
            <div className='create-offer'>
                <Breadcrumb className="breadcrumb-main">
                    <Breadcrumb.Item>
                        <AnchorLink to={App_url.link.createOffer}>{t("Saved Offer")} </AnchorLink>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>{t("Create Quick Offer")}</Breadcrumb.Item>
                </Breadcrumb>
                <Card>
                    <Card.Body>
                        <div className='card-body-header'>
                            <h6><b>{t("Quick Offer")}</b></h6>
                            <div >
                                <Button className={"me-3"} variant={"grey"} onClick={handleAddMore}>{t("Add More")}</Button>
                                <Button className={""} variant={"primary"} onClick={onSubmit}>{t("Next")}</Button>
                            </div>
                        </div>
                        <hr className='card-body-header-hr'></hr>
                        <Form >
                            <div className='preview'>
                                <h6 className=''>{t("Company Details")}</h6>
                            </div>

                            <div className='row'>
                                <div className='col-lg-4'>
                                    <div>
                                        <InputGroup
                                            label={t("Company Name")}
                                            placeholder={t("Select  or type company name")}
                                            formClassName="col-12 col-md-12 mb-4 secondary-primary"
                                            type="select"
                                            select
                                            option={companyNameList}
                                            name={"company_name"}
                                            value={formData?.company_id}
                                            error={error?.company_name}
                                            onChange={handleSelectCompanyName}
                                            // isClearable
                                            // disabled={offerItem}
                                        />
                                    </div>

                                </div>

                                <div className='col-4'>
                                    <InputGroup
                                        label={t("First Name")}
                                        placeholder={t("Enter first name")}
                                        name="first_name"
                                        value={formData?.first_name}
                                        error={error?.first_name}
                                        onChange={onChange}
                                        // disabled={offerItem}
                                    />
                                </div>
                                <div className='col-4'>
                                    <InputGroup
                                        label={t("Last Name")}
                                        placeholder={t("Enter last name")}
                                        formClassName="mb-3"
                                        name="last_name"
                                        value={formData?.last_name}
                                        // error={error?.last_name}
                                        onChange={onChange}
                                        type={'text'}
                                        // disabled={offerItem}
                                    />
                                </div>

                            </div>

                            <div className='row'>
                                <div className='col-lg-4'>
                                    <InputGroup
                                        label={t("Phone Number")}
                                        placeholder={t("Enter phone number")}
                                        name="phone_number"
                                        value={formData?.phone_number}
                                        // error={error?.phone_number}
                                        onChange={onChange}
                                        rightLabel={<Icon image attrIcon={App_url.img.PhoneRingIcon} />}
                                        rightLabelClass={offerItem ? "disable" : ""}
                                        type={''}
                                        max={10}
                                        // disabled={offerItem}
                                    />
                                </div>
                                <div className='col-4'>
                                    <InputGroup
                                        label={t("Email ID")}
                                        placeholder={t("Enter email ID")}
                                        name="email"
                                        value={formData?.email}
                                        // error={error?.email}
                                        // onChange={onChange}
                                        rightLabel={<Icon image attrIcon={App_url.img.EmailIcon3} />}
                                        // disabled={offerItem}
                                        rightLabelClass={offerItem ? "disable" : ""}
                                    />
                                </div>
                                <div className='col-4'>
                                    <InputGroup
                                        label={t("Address")}
                                        placeholder={t("Enter address")}
                                        formClassName="mb-3"
                                        name="address"
                                        value={formData?.address}
                                        // error={error?.address}
                                        onChange={onChange}
                                        type={'text'}
                                        rightLabel={<Icon image attrIcon={App_url.img.LocationIcon2} />}
                                        // disabled={offerItem}
                                        rightLabelClass={offerItem ? "disable" : ""}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-4'>
                                    <InputGroup
                                        label={t("STREET")}
                                        placeholder={t("Enter street")}
                                        name="street"
                                        value={formData?.street}
                                        error={error?.street}
                                        onChange={onChange}
                                        // disabled={offerItem}
                                    />
                                </div>
                                <div className='col-4'>
                                    <InputGroup
                                        label={t("City")}
                                        placeholder={t("Enter city")}
                                        formClassName="mb-3"
                                        name="city"
                                        value={formData?.city}
                                        // error={error?.city}
                                        onChange={onChange}
                                        type={'text'}
                                        // disabled={offerItem}
                                    />
                                </div>
                                <div className='col-4'>
                                    <InputGroup
                                        label={t("Post Code")}
                                        placeholder={t("Enter post code")}
                                        name="postal_code"
                                        value={formData?.postal_code}
                                        error={error?.postal_code}
                                        onChange={onChange}
                                        // disabled={offerItem}
                                    />
                                </div>
                            </div>
                            {formData?.offer_detail?.map((detail, index) => (
                                <div key={index} className='offer-detail-form'>
                                    <div className='preview d-flex'>
                                        <h6 className=''>{t("Offer Details")}</h6>
                                        {index > 0 && (
                                            <span className='deleteIcon'> <Icon className={"bg-primary "} onClick={() => handleDelete(index)} attrIcon={App_url.img.DeleteIcon2} /></span>
                                        )}
                                    </div>
                                    <div className='row mb-3'>
                                        <div className='col-lg-4'>
                                            <InputGroup
                                                label={t("BUILDING DETAILS(OPTIONAL)")}
                                                placeholder={t("Enter building detail")}
                                                name="building_detail"
                                                value={detail?.building_detail}
                                                onChange={(e) => handleInputChange('building_detail', e.target.value, index)}
                                            />
                                        </div>
                                        <div className='col-4'>
                                            <label htmlFor=" ">{t("Service Category")}</label>
                                            <Dropdown className={`status-dropdown ${error[`service_category_${index}`] ? "error" : ""}`} onSelect={(e) => handleServiceCategorySelect(e, index)}>
                                                <Dropdown.Toggle variant="secondary" className='status-dropdown-toggle col-12 text-start' id="dropdown-basic">
                                                    {detail?.service_category ? detail?.service_category : t("Select service category")}
                                                    <Icon image attrIcon={App_url.img.DropDownIcon} />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className='dropdown-menu'>
                                                    {categoryOptions?.map(option => (
                                                        <Dropdown.Item key={option.value} eventKey={option.value} className='check_menu'>
                                                            {option.label}
                                                            {detail?.service_category === option.label && (
                                                                <span className='title'>
                                                                    <Icon image attrIcon={App_url.img.Radiobutton2} />
                                                                </span>
                                                            )}
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                                {error[`service_category_${index}`] && <div className='mt-1 ps-1 text-danger fs-13'>{error[`service_category_${index}`]}</div>}
                                            </Dropdown>
                                        </div>
                                        <div className='col-4'>
                                            <label htmlFor=" ">{t("Select cleaning days / year frequency")}</label>
                                            <Dropdown className={`status-dropdown ${error?.[`yearly_frequency_${index}`] ? "error" : ""}`} onSelect={(eventKey) => handleYearlyFrequencySelect(eventKey, index)}>
                                                <Dropdown.Toggle variant="secondary" className='status-dropdown-toggle col-12 text-start' id="dropdown-basic">
                                                    {formData?.offer_detail?.[index]?.yearly_frequency_value
                                                        ? formData?.offer_detail[index]?.yearly_frequency_value
                                                        : t('Select frequency')}
                                                    <Icon image attrIcon={App_url.img.DropDownIcon} />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className='dropdown-menu'>
                                                    {frequencyOptions && frequencyOptions?.map(option => (
                                                        <Dropdown.Item key={option?.value} eventKey={option?.value} style={{ paddingRight: '8px' }}>
                                                            <div className='check_menu'>
                                                                <span>{option?.label}</span>
                                                                {formData?.offer_detail?.[index]?.yearly_frequency_value === option?.label ? (
                                                                    <span className='title'><Icon image attrIcon={App_url.img.Radiobutton2} /></span>
                                                                ) : (
                                                                    <span className='title'><Icon image attrIcon={App_url.img.RadioCircleIcon} /></span>
                                                                )}
                                                            </div>
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>

                                                {error?.[`yearly_frequency_${index}`] && (<div className='mt-1 ps-1 text-danger fs-13'>{error?.[`yearly_frequency_${index}`]}</div>)}
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-4'>
                                            <InputGroup
                                                label={`${(formData?.offer_detail?.[index]?.service_category === t("Glass Cleaning") ||
                                                    formData?.service_category === t("Special Cleaning")
                                                ) ? t("EACH EXECUTION COST") : t("MONTHLY FIXED COST")}`}
                                                placeholder={t("Enter cost")}
                                                name="cost"
                                                value={detail.cost}
                                                className={` ${error[`cost_${index}`] ? "error" : ""}`}
                                                onChange={(e) => handleInputChange('cost', e.target.value, index)}
                                            />
                                            {error[`cost_${index}`] && <div className='mt-1 ps-1 text-danger fs-13'>{error[`cost_${index}`]}</div>}
                                        </div>
                                        <div className='col-4'>
                                            <InputGroup
                                                label={t("REMARK (OPTIONAL)")}
                                                placeholder={t("Enter remark")}
                                                name="remark"
                                                value={detail.remark}
                                                onChange={(e) => handleInputChange('remark', e.target.value, index)}
                                            />
                                        </div>
                                        {/* <div className='col-4'>
                                            <InputGroup
                                                label={"Yearly Cost"}
                                                placeholder={"Enter remark"}
                                                name="yearly_cost"
                                                value={detail.yearly_cost}
                                            // onChange={(e) => handleInputChange('yearly_cost', e.target.value, index)}
                                            />
                                        </div> */}

                                    </div>
                                </div>
                            ))}
                        </Form>

                    </Card.Body>
                </Card>
            </div>

        </ContentLayout ></div >
    )
}

export default AddCreateOfferLetter