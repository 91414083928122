/* eslint-disable */
import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import DashboardLayout from '../components/layout/DashboardLayout'
import DashboardPage from '../components/Dashboard/DashboardPage'
import OffersPage from '../components/Offers/OffersPage'
import DocumentationPage from '../components/Documentation/DocumentationPage'
import ServicesPage from '../components/Services/ServicesPage'
import ProspectsPage from '../components/Sales/Prospects/ProspectsPage'
import ProspectsDetails from '../components/Sales/Prospects/ProspectsDetails'
import CustomersPage from '../components/Sales/Customers/CustomersPage'
import ProspectsAddEdit from '../components/Sales/Prospects/ProspectsAddEdit'
import Login from '../components/Login'
import { useSelector } from 'react-redux'
import App_url from '../components/Common/Constants'
import TodayPage from '../components/Sales/Prospects/TodayPage'
import NewCalculation from '../components/Calculation/NewCalculation'
import CalculationOverview from '../components/Calculation/CalculationOverview'
import FullCleaningCal from '../components/Calculation/FullCleaning/FullCleaningCal'
import IntervalCleaning from '../components/Calculation/IntervalCleaning/IntervalCleaning'
import BrowseListPage from '../components/Documentation/BrowseListPage'
import BuildingDivision from '../components/Calculation/BuildingDivision/BuildingDivision'
import ExternalOffers from '../components/ExternalOffer/ExternalOffers'
import AddExternalOffer from '../components/ExternalOffer/AddExternalOffer'
import PromotionList from '../components/Promotion/PromotionList'
import CategoryList from '../components/Calculation/CategoryList'
import GlassCleaning from '../components/Calculation/GlassCleaning/GlassCleaning'
import SavedCalculation from '../components/Calculation/SavedCalculation'
import MaintenanceCleaning from '../components/Calculation/MaintenanceCleaning/MaintenanceCleaning'
import MailList from '../components/mail/MailList'
import SavedOfferList from '../components/createOffer/SavedOfferList'
import AddCreateOfferLetter from '../components/createOffer/AddCreateOfferLetter'
import SavedServiceList from '../components/Services/SavedServiceList'
import SelectServiceCustomerList from '../components/Services/SelectServiceCustomerList'
import AddEditServiceList from '../components/Services/AddEditServiceList'
import CreatePromotion from '../components/Promotion/CreatePromotion'
import SelectCustomer from '../components/Calculation/SelectCustomer/SelectCustomer'
import OfferDetails from '../components/Offers/OfferDetails'
import AddDocument from '../components/Offers/AddDocument'
import SelectCustomerServices from '../components/Services/SelectCustomer/SelectCustomerServices'
import PreviewServiceList from '../components/Services/PreviewServiceList'
import EmployersPage from '../components/Employers/EmployersPage'
import CreateEmployee from '../components/Employers/CreateEmployee'
import ViewPromotion from '../components/Promotion/ViewPromotion'
import InsideFolderList from '../components/Documentation/InsideFolderList'

export default function MainRouter() {

  const { access_token } = useSelector(App_url.userDataReducers);

  return (
    <React.Fragment>
      <Routes>
        {access_token ? (
          //  <Route path={App_url.link.home}  element={access_token === "" ? <Login/> :<DashboardLayout/>} >
          <React.Fragment>
            <Route path='*' element={<Navigate to={App_url.link.dashboard} />} />
            <Route path={`${App_url.link.home}`} element={<DashboardLayout />}>
              <Route index element={<Navigate to={App_url.link.dashboard} />} />
              <Route path={`${App_url.link.dashboard}`} element={<DashboardPage />} />
              <Route path={`${App_url.link.prospects}`} element={<ProspectsPage />}></Route>
              <Route path={`${App_url.link.customers}`} element={<CustomersPage />}></Route>
              <Route path={`${App_url.link.createEmployee}`} element={<CreateEmployee />}></Route>
              <Route path={`${App_url.link.offers}`} element={<OffersPage />}></Route>
              <Route path={`${App_url.link.offersDetails}/:id`} element={<OfferDetails />}></Route>
              <Route path={`${App_url.link.offerDetailsAddDocument}`} element={<AddDocument />}></Route>

              {/* calculations */}
              <Route path={`${App_url.link.savedCalculation}`} element={<SavedCalculation />}></Route>
              <Route path={`${App_url.link.selectCategory}/:id`} element={<CategoryList />}></Route>

              <Route path={`${App_url.link.newCalculationGlassCleaning}`} element={<GlassCleaning />}></Route>
              <Route path={`${App_url.link.newCalculationGlassCleaning}/:id`} element={<GlassCleaning />}></Route>

              <Route path={`${App_url.link.newCalculationFullCleaning}`} element={<FullCleaningCal/>}></Route>
              <Route path={`${App_url.link.newCalculationFullCleaning}/:id`} element={<FullCleaningCal/>}></Route>

              <Route path={`${App_url.link.newCalculationIntervalCleaning}`} element={<IntervalCleaning/>}></Route>
              <Route path={`${App_url.link.newCalculationIntervalCleaning}/:id`} element={<IntervalCleaning/>}></Route>

              <Route path={`${App_url.link.newCalculationBuildingDivision}`} element={<BuildingDivision/>}></Route>
              <Route path={`${App_url.link.newCalculationBuildingDivision}/:id`} element={<BuildingDivision/>}></Route>

              <Route path={`${App_url.link.newCalculationMaintenanceCleaning}`} element={<MaintenanceCleaning/>}></Route>
              <Route path={`${App_url.link.newCalculationMaintenanceCleaning}/:id`} element={<MaintenanceCleaning/>}></Route>

              <Route path={`${App_url.link.newCalculationCategoryOverview}/:id`} element={<CalculationOverview/>}></Route>
              <Route path={`${App_url.link.newCalculation}`} element={<NewCalculation />}></Route>

              <Route path={`${App_url.link.calculationOverview}/:id`} element={<CalculationOverview/>}></Route>
              <Route path={`${App_url.link.selectCustomer}`} element={<SelectCustomer/>}></Route>

              <Route path={`${App_url.link.documentationList}`} element={<DocumentationPage />}></Route>
              <Route path={`${App_url.link.browseList}`} element={<BrowseListPage />}></Route>
              <Route path={`${App_url.link.browseListFolder}`} element={<InsideFolderList />}></Route>

              <Route path={`${App_url.link.services}`} element={<ServicesPage />}></Route>
              <Route path={`${App_url.link.savedServiceList}`} element={<SavedServiceList />}></Route>
              <Route path={`${App_url.link.selectServiceCustomerList}`} element={<SelectServiceCustomerList />}></Route>
              <Route path={`${App_url.link.addServiceList}/:id`} element={<AddEditServiceList />}></Route>
              <Route path={`${App_url.link.addServiceListWithoutCustomer}`} element={<AddEditServiceList />}></Route>
              <Route path={`${App_url.link.selectCustomerServices}`} element={<SelectCustomerServices/>}></Route>
              <Route path={`${App_url.link.previewServiceList}/:id`} element={<PreviewServiceList/>}></Route>


              <Route path={`${App_url.link.employers}`} element={<EmployersPage />}></Route>



              <Route path={`${App_url.link.createOffer}`} element={<SavedOfferList />}></Route>
              <Route path={`${App_url.link.addCreateOffer}`} element={<AddCreateOfferLetter />}></Route>
              <Route path={`${App_url.link.addCreateOffer}/:id`} element={<AddCreateOfferLetter />}></Route>
              <Route path={`${App_url.link.prospectsDetails}/:id`} element={<ProspectsDetails />}></Route>
              <Route path={`${App_url.link.ProspectsAdd}`} element={<ProspectsAddEdit />}></Route>
              <Route path={`${App_url.link.ProspectsEdit}/:id`} element={<ProspectsAddEdit />}></Route>
              <Route path={`${App_url.link.prospectsTodayDetails}`} element={<TodayPage />}></Route>
              <Route path={`${App_url.link.externalOffers}`} element={<ExternalOffers />}></Route>
              <Route path={`${App_url.link.addExternalOffers}`} element={<AddExternalOffer />}></Route>
              <Route path={`${App_url.link.addExternalOffers}/:id`} element={<AddExternalOffer />}></Route>
              <Route path={`${App_url.link.promotion}`} element={<PromotionList />}></Route>
              <Route path={`${App_url.link.createPromotion}`} element={<CreatePromotion />}></Route>
              <Route path={`${App_url.link.viewPromotion}/:id`} element={<ViewPromotion />}></Route>
              <Route path={`${App_url.link.mail}`} element={<MailList />}></Route>


              <Route path={`${App_url.link.createEmployee}`} element={<CreateEmployee />}></Route>
              <Route path={`${App_url.link.createEmployee}/:id`} element={<CreateEmployee />}></Route>


            </Route>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {/* <Route path={"*"} element={<Navigate to={App_url.link.login}/>}></Route> */}
            <Route path={`${App_url.link.login}`} element={<Login />}></Route>
          </React.Fragment>
        )}
      </Routes>
    </React.Fragment>
  )
}
