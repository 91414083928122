/* eslint-disable */
import React, { useEffect, useState } from 'react'
import App_url from '../Common/Constants';
import { ListGroup, ListGroupItem } from 'react-bootstrap'
import TabListItem from './TabListItem';
import { useDispatch, useSelector } from 'react-redux';
import ToolTip from '../Common/PopOver';
import { useNavigate } from 'react-router';
import AnchorLink from '../Common/AnchorLink';
import TabListImage from './TabListImage';
import { useTranslation } from '../../translate';

export default function SidebarSite(props) {
  const { t, changeLanguage, lang } = useTranslation();
  const { access_token, site_code } = useSelector(App_url.userDataReducers);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // useEffect(()=>{
  //   if(site_code === undefined){
  //       onSelectWebsite(OrgList[0]?.site_code);
  //   }
  // },[site_code])
  const SideList = [
    {
      title: t("Sales"),
      image: App_url.image.Sales,
      to: App_url.link.prospects,
    },
    {
      title: t("Customer Management"),
      image: App_url.image.CustomerManagement,
      to: App_url.link.home,
    },
    {
      title: t("Complaint Management"),
      image: App_url.image.ComplaintManagement,
      to: App_url.link.home,
    },
    {
      title: t("Employers"),
      image: App_url.image.Employers,
      to: App_url.link.employers,
    },
    {
      title: t("Analysis/Statistics"),
      image: App_url.image.Statistics,
      to: App_url.link.home,
    },
    {
      title: t("Account Tax"),
      image: App_url.image.Tax,
      to: App_url.link.home,
    },

  ];

  // const onSelectWebsite = (item) =>{
  //   dispatch(setStoreSelectWebsite(item))
  //   localStorage.setItem("site_code", item)
  //   navigate(App_url.link.userManager);
  // } 

  return (
    <aside className='org-main-sidebar '>
      <div className='logo d-flex justify-content-center'>
        <AnchorLink to={App_url?.link?.home}>
          {/* <LazyImage src={App_url.image.default_logo} /> */}
          <div className='logo_title'>
            <h6 className='sidebar-site-title' >{t("Quick")}<span className='text-orange'> {t("&")} </span> {t("Easy")}</h6>
            <h6 className='sidebar-site-sub-title' >{t("SOLUTIONS")}</h6>
          </div>
        </AnchorLink>
      </div>
      <div id='sidebar_menu' className='metis_menu'>
        <div className='side-scrolled'>
          <ListGroup className='list-image-group'>
            {SideList?.map((item, index) => (
              <React.Fragment key={index}>
                <TabListImage {...item} />
              </React.Fragment>
            ))}
          </ListGroup>
        </div>
      </div>
    </aside>
  )
}
