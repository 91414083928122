/* eslint-disable */
import { ActionTypes } from "./action";

const initialData = {

    externalOfferList: {
        data: [],
        pagination: {}
    },
    industryTypeList: {
        cities: [],
        industry: [],
        pincodes: [],
    },
    quickOfferList: {
        data: [],
        pagination: {}
    },
    offerDashboardList: {
        statistic_list: [],
        max_offer: [],
    },
    getExternalOfferList: {
        offer: []
    },
    getOfferList: [],
    offerOverviewList: {
        data: [],
        pagination: {}
    },
    offerOverviewDashboard:{
        statistic_list:[]
    },
    getOfferOverview:{
        offer_status: "",
        company_details: [],
        offer_status_details: [],
        quick_offer_detail: [],
        calculation_offer_detail: [],

    }
}
export const externalOfferReducers = (state = initialData, action) => {
    switch (action.type) {

        case ActionTypes.SET_STORE_EXTERNAL_OFFER_LIST: {
            // console.log("action?.payload", action?.payload);
            return {
                ...state,
                externalOfferList: {
                    data: action?.payload?.data,
                    pagination: action?.payload?.pagination,
                }
            }
        }

        case ActionTypes.SET_STORE_QUICK_OFFER_LIST: {
            // console.log("action?.payload", action?.payload);
            return {
                ...state,
                quickOfferList: {
                    data: action?.payload?.data,
                    pagination: action?.payload?.pagination,
                }
            }
        }
        case ActionTypes.SET_STORE_OFFER_DASHBOARD_LIST: {
            // console.log("action?.payload", action?.payload);
            return {
                ...state,
                offerDashboardList: {
                    statistic_list: action?.payload?.statistic_list,
                    max_offer: action?.payload?.max_offer,
                }
            }
        }
        case ActionTypes.SET_STORE_GET_EXTERNAL_OFFER_LIST: {
            // console.log("action?.payload", action?.payload);
            return {
                ...state,
                getExternalOfferList: {
                    offer: action?.payload?.offer,

                }

            }
        }
        case ActionTypes.SET_STORE_GET_OFFER_LIST: {
            // console.log("action?.payload", action?.payload);
            return {
                ...state,
                getOfferList: action?.payload,

            }
        }
        case ActionTypes.SET_STORE_OFFER_OVERVIEW_LIST: {
            // console.log("action?.payload", action?.payload);
            return {
                ...state,
                offerOverviewList: {
                    data: action?.payload?.data,
                    pagination: action?.payload?.pagination,
                }
            }
        }
        case ActionTypes.SET_STORE_OFFER_OVERVIEW_DASHBOARD_LIST: {
            // console.log("action?.payload", action?.payload);
            return {
                ...state,
                offerOverviewDashboard: {
                    statistic_list: action?.payload?.statistic_list,

                }

            }
        }
        case ActionTypes.SET_STORE_GET_OFFER_OVERVIEW: {
            // console.log("action?.payload", action?.payload);
            return {
                ...state,
                getOfferOverview: {
                    offer_status: action?.payload?.offer_status,
                    company_details: action?.payload?.company_details,
                    offer_status_details: action?.payload?.offer_status_details,
                    quick_offer_detail: action?.payload?.quick_offer_detail,
                    calculation_offer_detail: action?.payload?.calculation_offer_detail,

                }

            }
        }

        default:
            return state;
    }

}