import React from 'react'
import { Card } from 'react-bootstrap'
import SelectReact from '../Common/SelectReact'
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend,Filler } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend,Filler);

const data = {
    labels: ['1:00 AM', '2:00 AM', '3:00 AM', '4:00 AM', '5:00 AM','6:00 AM'], 
    datasets: [
        {
            label: 'Number of Calls',
            data: [ 10,20, 45, 27, 10,15,35], 
            fill: true,
            backgroundColor: ' #FFF2EF',
            borderColor: '#F9623E',
            tension: 0.4, 
        },
    ],
};

const options = {
    scales: {
      x: {
        title: {
          display: false,
          text: 'Time',
        },
      },
      y: {
        title: {
          display: false,
          text: 'Number of Calls',
        },
        min: 0,
        max: 50,
        ticks: {
          stepSize: 10, 
        },
      },
    },
    plugins: {
      legend: {
        // display: true,
        position: 'bottom',
      },
    },
  };






const Statistics = () => {

    return (
        <div className='statistics'>
            <Card className=''>
                <div className='header '>
                    <h6 className='title'> Statistics </h6>
                    <SelectReact placeholder={'No of calls'}
                 /*  options={['No Of Calls','']} */ className='bullet orange w-100'/>
                </div>
                <div className='points' >
                    <p className='mb-0'><span className='bullet orange'></span>No Of Calls</p>
                    <p className='mb-0 '><span className='bullet green'></span>No Of Appointment</p>
                    <p className='mb-0 '><span className='bullet blue'></span>Share Brochure</p>
                    <p className='mb-0 '><span className='bullet pink'></span>No Of Offers</p>
                    <p className='mb-0 '><span className='bullet yellow'></span>No Of Mail</p>
                </div>
                <Line

                    data={data}
                    options={options}
                />
            </Card>

        </div>
    )
}

export default Statistics