import { ActionTypes } from "./action";

const initialData = {
    ModalPopup: {
        title: "",
        name: "",
        description: "",
        data: {},
        show: "",
        callBackModal: () => null,
        callBackCancelModal: () => null,
        buttonSuccess: "",
        buttonCancel: ""
    },
    ModalPopupTwo: {
        title: "",
        name: "",
        description: "",
        data: {},
        show: "",
        callBackModal: () => null,
        callBackCancelModal: () => null,
        buttonSuccess: "",
        buttonCancel: ""
    },
    ModalPopupThree: {
        title: "",
        name: "",
        description: "",
        data: {},
        show: "",
        callBackModal: () => null,
        callBackCancelModal: () => null,
        buttonSuccess: "",
        buttonCancel: ""
    },
    ModalPopupFour: {
        title: "",
        name: "",
        description: "",
        data: {},
        show: "",
        callBackModal: () => null,
        callBackCancelModal: () => null,
        buttonSuccess: "",
        buttonCancel: ""
    },
}

export const modalReducers = (state = initialData, action) => {
    switch (action.type) {
        case ActionTypes.SET_STORE_MODAL_CONFIRM: {
            return {
                ...state,
                ModalPopupFour: action?.payload?.show ? action?.payload : initialData?.ModalPopupFour
            }
        }
        case ActionTypes.SET_STORE_NEW_APPOINTMENT_MODAL: {
            return {
                ...state,
                ModalPopup: action?.payload?.show ? action?.payload : initialData?.ModalPopup
            }
        }
        case ActionTypes.SET_STORE_EDIT_APPOINTMENT_MODAL: {
            return {
                ...state,
                ModalPopupTwo: action?.payload?.show ? action?.payload : initialData?.ModalPopupTwo
            }
        }
        case ActionTypes.SET_STORE_SCHEDULE_CALL_MODAL: {
            return {
                ...state,
                ModalPopupThree: action?.payload?.show ? action?.payload : initialData?.ModalPopupThree
            }
        }
        case ActionTypes.SET_STORE_CALL_SCHEDULED_SUCCESSFUL_MODAL: {
            return {
                ...state,
                ModalPopupThree: action?.payload?.show ? action?.payload : initialData?.ModalPopupThree
            }
        }
        case ActionTypes.SET_STORE_VIEW_SCHEDULED_CALL_MODAL: {
            return {
                ...state,
                ModalPopup: action?.payload?.show ? action?.payload : initialData?.ModalPopup
            }
        }
        case ActionTypes.SET_STORE_RING_CALL_MODAL: {
            return {
                ...state,
                ModalPopup: action?.payload?.show ? action?.payload : initialData?.ModalPopup
            }
        }
        case ActionTypes.SET_STORE_ADD_REMARK_MODAL: {
            return {
                ...state,
                ModalPopupTwo: action?.payload?.show ? action?.payload : initialData?.ModalPopupTwo
            }
        }
        case ActionTypes.SET_STORE_VIEW_MAIL_MODAL: {
            return {
                ...state,
                ModalPopup: action?.payload?.show ? action?.payload : initialData?.ModalPopup
            }
        }
        case ActionTypes.SET_STORE_COMPOSE_MAIL_MODAL: {
            return {
                ...state,
                ModalPopup: action?.payload?.show ? action?.payload : initialData?.ModalPopup
            }
        }
        case ActionTypes.SET_STORE_VIEW_REMARK_MODAL: {
            return {
                ...state,
                ModalPopup: action?.payload?.show ? action?.payload : initialData?.ModalPopup
            }
        }
        case ActionTypes.SET_STORE_ADD_CUSTOMER_MODAL: {
            return {
                ...state,
                ModalPopupTwo: action?.payload?.show ? action?.payload : initialData?.ModalPopupTwo
            }
        }
        case ActionTypes.SET_STORE_RENAME_FOLDER_MODAL: {
            return {
                ...state,
                ModalPopup: action?.payload?.show ? action?.payload : initialData?.ModalPopup
            }
        }
        case ActionTypes.SET_STORE_DISCARD_CHANGES_MODAL: {
            return {
                ...state,
                ModalPopupThree: action?.payload?.show ? action?.payload : initialData?.ModalPopupThree
            }
        }
        case ActionTypes.SET_VALIDATION_STORE_MESSAGE_MODAL: {
            return {
                ...state,
                ModalPopupThree: action?.payload?.show ? action?.payload : initialData?.ModalPopupThree
            }
        }
        case ActionTypes.SET_STORE_SESSION_EXPIRE_MODAL: {
            return {
                ...state,
                ModalPopupThree: action?.payload?.show ? action?.payload : initialData?.ModalPopupThree
            }
        }
        case ActionTypes.SET_STORE_SHARE_DOCUMENT_MODAL: {
            return {
                ...state,
                ModalPopupTwo: action?.payload?.show ? action?.payload : initialData?.ModalPopupTwo
            }
        }
        case ActionTypes.SET_STORE_TEMPLATE_MODAL: {
            return {
                ...state,
                ModalPopupTwo: action?.payload?.show ? action?.payload : initialData?.ModalPopupTwo
            }
        }
        case ActionTypes.SET_STORE_VIEW_DOCUMENT_MODAL: {
            return {
                ...state,
                ModalPopupTwo: action?.payload?.show ? action?.payload : initialData?.ModalPopupTwo
            }
        }
        case ActionTypes.SET_STORE_MODAL_CONFIRM_TWO: {
            return {
                ...state,
                ModalPopupTwo: action?.payload?.show ? action?.payload : initialData?.ModalPopupTwo
            }
        }
        case ActionTypes.SET_STORE_NEW_INVITEE_MODAL: {
            return {
                ...state,
                ModalPopupTwo: action?.payload?.show ? action?.payload : initialData?.ModalPopupTwo
            }
        }
        case ActionTypes.SET_STORE_INVITEE_INFO_MODAL: {
            return {
                ...state,
                ModalPopupThree: action?.payload?.show ? action?.payload : initialData?.ModalPopupThree
            }
        }
        case ActionTypes.SET_STORE_APPOINTMENT_VIEW_MODAL: {
            return {
                ...state,
                ModalPopup: action?.payload?.show ? action?.payload : initialData?.ModalPopup
            }
        }
        case ActionTypes.SET_STORE_VIEW_EXTERNAL_OFFER_MODAL: {
            return {
                ...state,
                ModalPopup: action?.payload?.show ? action?.payload : initialData?.ModalPopup
            }
        }
        case ActionTypes.SET_STORE_MODAL_POPUP: {
            return {
                ...state,
                ModalPopup: action?.payload?.show ? action?.payload : initialData?.ModalPopup
            }
        }
        case ActionTypes.SET_STORE_SEARCH_COMPANY_MODAL: {
            return {
                ...state,
                ModalPopup: action?.payload?.show ? action?.payload : initialData?.ModalPopup
            }
        }
        case ActionTypes.SET_STORE_ADDITIONAL_COST_MODAL: {
            return {
                ...state,
                ModalPopupTwo: action?.payload?.show ? action?.payload : initialData?.ModalPopupTwo
            }
        }
        case ActionTypes.SET_STORE_OFFER_MODAL: {
            return {
                ...state,
                ModalPopup: action?.payload?.show ? action?.payload : initialData?.ModalPopup
            }
        }
        case ActionTypes.SET_STORE_USER_MESSAGE_MODAL: {
            return {
                ...state,
                ModalPopupThree: action?.payload?.show ? action?.payload : initialData?.ModalPopupThree
            }
        }


        default:
            return state;
    }
}