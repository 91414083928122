/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { Editor } from "primereact/editor";
import { useTranslation } from "../../../translate";
import { useSelector } from "react-redux";
import App_url from "../Constants";

export default function PrimeReactPage({ handleQuillData, refs, initialTemplate }) {
    const { t, changeLanguage, } = useTranslation();
    const { lang } = useSelector(App_url.settingReducers)
    const editorRef = useRef(null);
    const [text, setText] = useState('');


    useEffect(() => {
        console.log("Initial Template:", initialTemplate);  // Debug: Check initialTemplate content

        if (initialTemplate) {
            setText(initialTemplate);
        }
    }, [initialTemplate]);

    useEffect(() => {
        const interval = setInterval(() => {
          if (editorRef.current) {
            const quill = editorRef.current.getQuill();
            if (quill) {
              console.log('Quill instance:', quill);  // Debugging Quill instance
              if (quill.clipboard && initialTemplate) {
                quill.clipboard.dangerouslyPasteHTML(initialTemplate);
              }
              clearInterval(interval);  // Clear the interval once Quill is initialized
            }
          }
        }, 100);  // Check every 100ms
      
        return () => clearInterval(interval);  // Cleanup interval
      }, [initialTemplate]);
      


    const renderHeader = () => {
        return (
            <span className="ql-formats">
                <button ref={el => refs.current.refBold = el} className="ql-bold" aria-label="Bold"></button>
                <button ref={el => refs.current.refItalic = el} className="ql-italic" aria-label="Italic"></button>
                <button ref={el => refs.current.refUnderline = el} className="ql-underline" aria-label="Underline"></button>
                <button ref={el => refs.current.refOrderedList = el} className="ql-list" value="ordered" aria-label="Ordered List"></button>
                <button ref={el => refs.current.refUnOrderedList = el} className="ql-list" value="bullet" aria-label="Unordered List"></button>
                <button ref={el => refs.current.refImage = el} className="ql-image" aria-label="Image"></button>
                <button ref={el => refs.current.refLink = el} className="ql-link" aria-label="Link"></button>
            </span>
        );
    };

    const header = renderHeader()


    const handleTextChange = (e) => {
        setText(e.htmlValue);
        handleQuillData(e.htmlValue);
    };
    return (
        <div className="card mb-3">
            
            <Editor placeholder={`${t("Type here...")}`} value={text} onTextChange={handleTextChange} ref={editorRef} headerTemplate={header} style={{ height: 'auto' }} />
        </div>
    )
}