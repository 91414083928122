import { ActionTypes } from "./action";

const initialData = {
    device_id: "",
    currentUser: null,
    access_token: "",
    remember_me: {},
    magic_code: "",
    websocketReqRes: {
        response: null,
        request:null,
        msg: "",
        status: "",
        url: ""
    },
    socketResponse: {
        response: null,
        request: null,
        msg: "",
        status: "",
        url: "",
    },
    selectedInvitees: [],
    userOutlookDetails:null,
}

// const jsonParse = (data) => {
//     return data?.replaceAll("None", 'null').replaceAll("False", false).replaceAll("True", true)?.replaceAll(/'/g, '"')
// }

export const userDataReducers = (state = initialData, action) => {
    switch (action.type) {
        case ActionTypes.SET_STORE_CURRENT_USER: {
            // console.log("CurrentUser", action?.payload)
            if (!action?.payload) {
                return {
                    ...initialData,
                    remember_me: state?.remember_me,
                };
            }
            return {
                ...state,
                currentUser: action.payload,
                access_token: action?.payload?.access_token,
            }
        }
        case ActionTypes.SET_STORE_DEVICE_ID: {
            return {
                ...state,
                device_id: action?.payload,
            }
        }
        case ActionTypes.SET_STORE_MAGIC_CODE: {
            return {
                ...state,
                magic_code: action?.payload,
            }
        }
        case ActionTypes.SET_WEBSOCKET_REQ_RES: {
            return {
                ...state,
                websocketReqRes: {
                    response: action?.payload?.response || null,
                    request: action?.payload?.request || null,
                    msg: action?.payload?.msg || "",
                    status: action?.payload?.status || "",
                    url: action?.payload?.url || "",
                },
                socketResponse: {
                    response: action?.payload?.response || null,
                    request: action?.payload?.request || null,
                    msg: action?.payload?.msg || "",
                    status: action?.payload?.status || "",
                    url: action?.payload?.url || "",
                }
            }
        }
        
        case ActionTypes.SET_STORE_SELECTED_INVITEES: {
            return {
                ...state,
                selectedInvitees: action?.payload
            }
        }
        case ActionTypes.SET_STORE_USER_OUTLOOK_DETAILS: {
            return {
                ...state,
                userOutlookDetails: action?.payload
            }
        }
        default:
            return state;
    }
}