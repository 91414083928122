/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import App_url from '../Common/Constants'
import Icon from '../Common/Icon'
import ContentLayout from '../layout/ContentLayout'
import { WebSocketContext } from '../../WSContext'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import Utils from '../Common/Utils'
import { useTranslation } from '../../translate'
import { Breadcrumb } from 'react-bootstrap'
import AnchorLink from '../Common/AnchorLink'
import Images from '../Common/Image'
import Button from '../Common/Button'
import { setStoreComposeMailModal } from '../../store/modules/modals/action'
import { toast } from 'react-toastify'
import { PostRequestAPI } from '../../Api/api/PostRequest'

const PreviewServiceList = () => {
  const { t, changeLanguage } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { ws, send } = useContext(WebSocketContext);
  const { device_id, websocketReqRes, access_token } = useSelector(App_url.userDataReducers);
  const { customerDetails } = useSelector(App_url.customerReducers);
  console.log("customerDetails", customerDetails)

  const location = useLocation();
  const { view_file_url, fromPage } = location?.state || {}

console.log("fromPage",fromPage)
  const handleSendEmail = () => {
    const dataImage = { document_name: "Service Offer.pdf", view_file_url: `view/file/${view_file_url}`, document_image: view_file_url };
    dispatch(setStoreComposeMailModal({
      show: "COMPOSE_MAIL_MODAL",
      data: { documentImage: [dataImage], fileView: [view_file_url], fromPage: fromPage },
      callBackModal: (mailData) => handleComposeMailAPI(mailData),
    }))
  }
  const emailTemplate = `
    Hi,<br/>
    
  `;
  const handleComposeMailAPI = async (mailData) => {
    console.log("mailData", mailData);
    const payload = {
      subject: mailData?.subject,
      body: `<body>${mailData?.msg}</body>`,
      document: mailData?.document || [],
      inline_ids: mailData?.inline_ids || [],
      to: mailData?.email,
      cc: mailData?.cc,
      bcc: mailData?.bcc,
      customer: [customerDetails?.[0]?.id || ""],
      content_type: "Html"
    };
    console.log('payload', payload)
    const response = await PostRequestAPI(App_url.api.COMPOSE_MAIL, payload, access_token)
    console.log("responseEmail", response);
    if (response?.status === 200) {
      toast.success(response?.data?.message);
    } else {
      toast.error(response?.data?.error || response.data?.detail);
    }
  }

  return (
    <div>
      <ContentLayout title={t("Preview Service Page")}>
        <Breadcrumb className="breadcrumb-main">
          <Breadcrumb.Item>
            <AnchorLink to={App_url.link.savedServiceList}>{t("List of Services")}</AnchorLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>{t("Preview List service")}</Breadcrumb.Item>
        </Breadcrumb>

        {view_file_url &&
          <React.Fragment>
            <div style={{ backgroundColor: 'white', padding: '30px' }}>
              <div className=''>
                <Button onClick={handleSendEmail} className={"float-end mb-3"} variant={"primary"}>{t("Send Offer")}</Button>
              </div>
              <Images pdfView auth width={100} height={100} src={`${process.env.REACT_APP_API_DOMAIN_URL}/file/view/file/${view_file_url}`} alt="Document" />
            </div>
          </React.Fragment>
        }

      </ContentLayout>
    </div>
  )
}

export default PreviewServiceList