/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import ContentLayout from '../layout/ContentLayout'
import { Card } from 'react-bootstrap'
import Button from '../Common/Button'
import CustomTableTwo from '../Common/CustomTableTwo'
import { useTranslation } from '../../translate'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { WebSocketContext } from '../../WSContext'
import App_url from '../Common/Constants'
import Icon from '../Common/Icon'
import Utils from '../Common/Utils'
import wsSendRequest from '../../socketIO/wsSendRequest'
import { setStoreCustomersDetails } from '../../store/modules/customers/action'
import UtilsGetList from '../Common/UtilsGetList'

export default function OffersPage() {
  const { t, changeLanguage } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { ws, send } = useContext(WebSocketContext);
  const { id } = useParams()
  const { device_id, websocketReqRes } = useSelector(App_url.userDataReducers);
  const { lang } = useSelector(App_url.settingReducers);
  const { offerOverviewList, offerOverviewDashboard } = useSelector(App_url.externalOfferReducers);
  // console.log("offerOverviewList", offerOverviewList)
  const [filter, setFilter] = useState({
    search: "",
    // status: ""
    dashboard_status:"",
  })
  // console.log(offerOverviewDashboard?.statistic_list)
  const statisticsList = offerOverviewDashboard?.statistic_list[0]
  const callOfferOverviewListApi = (page) => {
    const param = {
      transmit: "broadcast",
      url: "offer_overview_list",
      request: {
        page_no: page,
        limit: "20",
        postal_code: "",
        status: filter?.dashboard_status,
        from_date: "",
        to_date: "",
        customer_id: "",
        search: filter?.search,
      }
    };
    // console.log(param)
    send(param);
  };
  const handleCardClick = (status) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      dashboard_status:status 
    }));
  };

 

  const callOfferDashboardApi = () => {
    const param = { transmit: "broadcast", url: "offer_overview_dashboard" };
    // console.log(param)
    send(param);
  };
  useEffect(() => {
    if (ws) {
      callOfferOverviewListApi(1);
      callOfferDashboardApi(1);
    }
  }, [ws, filter])

  const handleView = (item) => {
    console.log("item====", item)
    const param = {
      transmit: "broadcast",
      url: "get_offer_overview",
      request: {
        id: item?.calculation_id ? item?.calculation_id : item?.offer_id,
        overview_id: item?.id,
        type: item?.offer_type,
      }
    };
    send(param)
    dispatch(setStoreCustomersDetails())
    UtilsGetList.callCustomerDetailsApi({ ws, customer_id: item?.customer_id, lang: lang })
    navigate(`${App_url.link.offersDetails}/${item?.id}`, { state: { calculationId: item?.calculation_id, offerId: item?.offer_id, overview_id: item?.id, customerId: item?.customer_id } });
  }

    const EllipsisText = (text, maxLength = 15) => {
        if (text?.length > maxLength) {
            return text?.substring(0, maxLength) + '...';
        }
        return text;
    };

  const RenderBody = (item, index) => {
    // console.log(item)
    // let index = 1
    return (
      <React.Fragment>
        <td onClick={() => handleView(item)} className='new-calc-td' >{index+1}</td>
        <td onClick={() => handleView(item)} className='new-calc-td' >{item.offer_code ? item.offer_code :"-"}</td>
        <td onClick={() => handleView(item)} className='new-calc-td' >{item.created_at ? Utils.formatDateCreatedAt2(item.created_at, t):"-"}</td>
        <td onClick={() => handleView(item)} className='new-calc-td'  title={item.offer_type}>{item.offer_type ? item.offer_type :"-"}</td>
        <td onClick={() => handleView(item)} className='new-calc-td'  title={item.company_name}>{item.company_name ? EllipsisText(item.company_name, 15) : "-"}</td>
        <td onClick={() => handleView(item)} className='new-calc-td' >{item.phone_number ? item.phone_number :"-"}</td>
        <td onClick={() => handleView(item)} className='new-calc-td'  title={item.first_name}>{item?.first_name ? item?.first_name :"-"}</td>
        <td onClick={() => handleView(item)} className='new-calc-td text-nowrap'> {item?.total_yearly_cost ? ` € ${ Utils.trimDecimal(item?.total_yearly_cost, 2)}` : "-"}</td>
        <td onClick={() => handleView(item)} className='new-calc-td' >{item?.status ? item?.status :"-"}</td>
        <td onClick={() => handleView(item)} className='new-calc-td' ><span className='view-icon-box' onClick={() => handleView(item)}><Icon className={"bg-primary"} attrIcon={App_url.img.EyeIcon} /></span></td>
        {/* index++; */}
      </React.Fragment>
    )
  }
  const callback = (e, state, btnName) => {
    // if (state === 'AddButton' && btnName === 'addDocument') {
    //   navigate(App_url.link.offerDetailsAddDocument)
    // }
  }
  return (
    <ContentLayout title={"Offers Page"}>
      <div className='offer-overview'>
        <div className='cards '>
          <Card className='card all-offer'>
            <Card.Body>
              <div className='cursor-pointer' onClick={() => handleCardClick('')}>
                <h6 className='fs-25'>€ {Utils.trimDecimal(statisticsList?.all_cost, 2)}</h6>
                <span>{statisticsList?.all_count} {t("All Offers")}</span>
              </div>

            </Card.Body>
          </Card>
          <Card className='card open-offer'>
            <Card.Body>
              <div className='cursor-pointer' onClick={() => handleCardClick('Open')}>
                <h6 className='fs-25'>€ {Utils.trimDecimal(statisticsList?.open_cost, 2)}</h6>
                <span>{statisticsList?.open_count} {t("Open Offers")}</span>
              </div>

            </Card.Body>
          </Card>
          <Card className='card close-offer cursor-pointer'>
            <Card.Body>
              <div className='cursor-pointer' onClick={() => handleCardClick('Closed')}>
                <h6 className='fs-25'>€  {Utils.trimDecimal(statisticsList?.closed_offer, 2)}</h6>
                <span>{statisticsList?.closed_count} {t("Close Offers")}</span>
              </div>

            </Card.Body>
          </Card>
          <Card className='card reject-offer cursor-pointer'>
            <Card.Body>
              <div className='' onClick={() => handleCardClick('Reject')}>
                <h6 className='fs-25'>€ {Utils.trimDecimal(statisticsList?.rejected_offer, 2)}</h6>
                <span>{statisticsList?.rejected_count} {t("Rejected Offers")}</span>
              </div>

            </Card.Body>
          </Card>
        </div>

        <div className="mt-3">
          <CustomTableTwo
            title={{ text: `${t("Offer Overview")} `, textTwo: `${t("Total Offer Overview")} ${offerOverviewList?.data?.length} ` }}
            // AddButton={{ title: t("Add Document"), variant: "outline-primary", size: "md" }}
            columnNames={["Sr. No", t('Offer ID'), t("Offer Date"), t("Offer Type"), t('Company Name'), t('Mobile No.'), t('Person Name'), t("Yearly"), t("Status"), t("View")]}
            // recordData={filteredData}
            recordData={offerOverviewList?.data}
            renderBody={RenderBody}
            filter={filter}
            setFilter={setFilter}
            callBack={(e, state) => callback(e, state, "addDocument")}
            search_placeholder={t("Search by Company, Email, ID")}
            pagination={offerOverviewList?.pagination}
            onPagination={(page) => callOfferOverviewListApi(page)}
            // typePlaceHolder={t("Select Status")}
            // sr_no
          />
        </div>
      </div>
    </ContentLayout>
  )
}
