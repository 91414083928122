/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import App_url from '../../../Common/Constants';
import { Dropdown, Modal } from 'react-bootstrap';
import Icon from '../../../Common/Icon';
import 'react-datepicker/dist/react-datepicker.css';
import Scrollbar from '../../../Common/Scrollbar';
import ViewAppointmentStatus from './ViewAppointmentStatus';
import Button from '../../../Common/Button';
import { setNewAppointmentModal, setStoreAppointmentViewModal } from '../../../../store/modules/modals/action';
import Utils from '../../../Common/Utils';
import { toast } from 'react-toastify';
import { WebSocketContext } from '../../../../WSContext';
import wsSendRequest from '../../../../socketIO/wsSendRequest';
import { PutRequestCallAPI } from '../../../../Api/api/PutRequest';
import UtilsGetList from '../../../Common/UtilsGetList';
import AnchorLink from '../../../Common/AnchorLink';
import { useTranslation } from '../../../../translate';

export default function ViewAppointmentModal() {
    const { t, changeLanguage } = useTranslation();

    const { ws, send } = useContext(WebSocketContext);
    const dispatch = useDispatch();
    const { ModalPopup } = useSelector(App_url.modalReducers);
    const { selectedInvitees, device_id, access_token } = useSelector(App_url.userDataReducers);
    const { customerDetails, appointmentDetails } = useSelector(App_url.customerReducers);
    const { lang } = useSelector(App_url.settingReducers);
    const [status, setStatus] = useState("Scheduled");
    const [statusSaved, setStatusSaved] = useState(false)
    const [statusEdit, setStatusEdit] = useState(false);
    const [formData, setFormData] = useState({
        cancel_comment: "",
        completed_comment: "",
        conclusion: "Positive",
        start_time: null,
        end_time: null,
        is_all_day: false,
    })

    useEffect(() => {
        if (ModalPopup?.show === "APPOINTMENT_VIEW_MODAL") {
            UtilsGetList.callappointmentDetails?.List({ ws, device_id, appointment_id: ModalPopup?.data?.item?.appointment_id, lang: lang })
        }
    }, [ModalPopup?.show === "APPOINTMENT_VIEW_MODAL"]);

    useEffect(() => {
        if (appointmentDetails?.[0]?.id) {
            setStatus(appointmentDetails?.[0]?.status?.charAt(0).toUpperCase() + appointmentDetails?.[0]?.status?.slice(1).toLowerCase());
            setFormData((data) => ({
                ...data,
                cancel_comment: appointmentDetails?.[0]?.cancel_comment,
                conclusion: appointmentDetails?.[0]?.conclusion || "Positive",
                completed_comment: appointmentDetails?.[0]?.completed_comment,
            }))
        }
    }, [appointmentDetails?.[0]?.id])


    const handleClose = async (state) => {
        if (ModalPopup?.callBackModal) {
            await ModalPopup?.callBackModal(state, appointmentDetails?.[0]?.appointment_id);
        }
        CloseData()
    };
    const CloseData = () => {
        dispatch(setNewAppointmentModal())
        setStatusSaved(false)
        setStatusEdit(false);
    }

    const statusOptions = [
        { label: t("Scheduled"), value: t("Scheduled") },
        { label: t("Rescheduled"), value: t("Rescheduled") },
        { label: t("Cancelled"), value: t("Cancelled") },
        { label: t("Completed"), value: t("Completed") },
    ]
    const conclusionOptions = [
        { label: t("Negative"), value: t("Negative") },
        { label: t("Positive"), value: t("Positive") },
        { label: t("Followup"), value: t("Followup") },
    ]

    const handleStatusSelect = (selectedValue) => {
        setStatus(selectedValue);
        setStatusEdit(true)
        setStatusSaved(false);
    }


    const handleConclusionSelect = (selectedValue) => {
        setFormData((data) => ({
            ...data,
            conclusion: selectedValue
        }))
    }

    const onChange = (e) => {
        setFormData((data) => ({
            ...data,
            [e.target.name]: e.target.value,
        }))
    }

    const handleReScheduleData = (data) => {
        setFormData((prevData) => ({
            ...prevData,
            start_time: data?.start_time,
            end_time: data?.end_time,
            is_all_day: data?.is_all_day,
        }))
    }


    // console.log("completed_comment", formData);

    const callRescheduleAppointmentApi = async () => {
        setStatusSaved(true);
        const payload = {
            start_time: formData?.start_time,
            end_time: formData?.end_time,
            is_all_day: formData?.is_all_day,
            appointment_id: ModalPopup?.data?.item?.appointment_id
        }
        const response = await PutRequestCallAPI(App_url.api.RESCHEDULE_APPOINTMENT, payload, access_token);
        console.log("rescheduleResponse", response);
        if (response?.status === 200) {
            toast.success(response?.data?.message);
            UtilsGetList.callAppointmentList({ ws, device_id, customerId: customerDetails[0]?.id, lang: lang });
            UtilsGetList.callappointmentDetails?.List({ ws, device_id, appointment_id: ModalPopup?.data?.item?.appointment_id, lang: lang });
        } else {
            Utils.AuthenticateResponse(response, dispatch);
            CloseData();
        }
    }

    const callCancelAppointmentApi = async () => {
        setStatusSaved(true);
        const payload = {
            cancel_comment: formData?.cancel_comment,
            appointment_id: ModalPopup?.data?.item?.appointment_id
        }
        const response = await PatchRequestCallAPI(App_url.api.CANCEL_APPOINTMENT, payload, access_token);
        console.log("cancelResponse", response);
        if (response?.status === 200) {
            toast.success(response?.data?.message);
            UtilsGetList.callAppointmentList({ ws, device_id, customerId: customerDetails[0]?.id, lang: lang });
            CloseData();
        } else {
            Utils.AuthenticateResponse(response, dispatch);
            CloseData();
        }
    }

    const callCompletedAppointmentApi = async () => {
        setStatusSaved(true);
        const payload = {
            completed_comment: formData?.completed_comment,
            appointment_id: ModalPopup?.data?.item?.appointment_id,
            conclusion: formData?.conclusion,
        }
        console.log("payload", payload);
        const response = await PutRequestCallAPI(App_url.api.COMPLETED_APPOINTMENT, payload, access_token);
        console.log("completeResponse", response);
        if (response?.status === 200) {
            toast.success(response?.data?.message);
            UtilsGetList.callAppointmentList({ ws, device_id, customerId: customerDetails[0]?.id, lang: lang });
            UtilsGetList.callappointmentDetails?.List({ ws, device_id, appointment_id: ModalPopup?.data?.item?.appointment_id, lang: lang });
        } else {
            Utils.AuthenticateResponse(response, dispatch);
            CloseData();
        }
    }

    if (ModalPopup?.show !== "APPOINTMENT_VIEW_MODAL") {
        return null
    }

    // console.log("ModalPopup", ModalPopup)


    const getAlertTime = (time) => {

        switch (time) {
            case "None":
                setFormData((data) => ({ ...data, is_reminder_on: false, reminder_minutes: null, is_second_reminder_on: false, reminder_minutes_second_start: null }));
                setSecondAlert(AlertOptions[0]?.value);
                break;
            case 0:
                return "At time of event";
            case 5:
                return "5 minutes before";
            case 15:
                return "15 minutes before";
            case 30:
                return "30 minutes before";
            case 60:
                return "1hour before";
            case 120:
                return "2 hours before";
            case 1440:
                return "1 day before";
            case 2880:
                return "2 days before";
            case 10080:
                return "1 week before";
            default:
                return "none";
        }
    };


    // console.log("ModalPopup?.data?.item?.start_time",ModalPopup?.data?.item?.start_time)
    return (
        <Modal show={true} size="lg" className='view-appointment-modal-dialog'>
            <Scrollbar style={{ height: "calc(100vh - 2px)" }}>
                <Modal.Header>
                    <div className='header'>
                        <h6>{t("Appointment")}</h6>
                        <div className='header-icons-box'>
                            <Icon onClick={() => handleClose("editAppointment")} image attrIcon={App_url.img.EditIcon} />
                            <Icon onClick={() => handleClose("deleteAppointment")} image attrIcon={App_url.img.DeleteIcon} />
                            <Icon onClick={CloseData} className={"ms-1"} image attrIcon={App_url.img.RedCrossIcon} />
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className='modal-body view-appointment-modal'>

                    {formData?.repeat &&
                        <div className='title-box'>
                            <h6 className='title-font'>{t("REPEAT")}</h6>
                            <div className='title-content-box'>
                                <Icon image attrIcon={App_url.img.RepeatIcon2} />
                                <h6 className='title-font-2'>{appointmentDetails?.[0]?.subject}</h6>
                            </div>
                        </div>
                    }

                    <div className='title-box'>
                        <h6 className='title-font'>{t("TITLE")}</h6>
                        <div className='title-content-box'>
                            <Icon image attrIcon={App_url.img.AaIcon} />
                            <h6 className='title-font-2'>{appointmentDetails?.[0]?.subject}</h6>
                        </div>
                    </div>
                    <div className='status-box'>
                        <h6 className='title-font'>{t("STATUS")}</h6>
                        <div className='status-info-box'>
                            <div>
                                <Dropdown className='status-dropdown' onSelect={handleStatusSelect}>
                                    <Dropdown.Toggle variant="light" className='status-dropdown-toggle'>
                                        <div className='status-content-box'>
                                            <Icon image attrIcon={App_url.img.CirclesTwoIcon} />
                                            <h6 className='title-font-2'>{status}</h6>
                                        </div>
                                        <Icon attrIcon={App_url.img.AngleRightIcon} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='dropdown-menu'>
                                        {statusOptions && statusOptions.map(option => (
                                            <Dropdown.Item key={option?.value} eventKey={option?.value} style={{ paddingRight: '8px' }}>
                                                <div className='check_menu'>
                                                    <span className='title-font-2'>{option?.value}</span>
                                                    {status === option?.value ? <Icon image attrIcon={App_url.img.RadioIcon} /> :
                                                        <Icon image attrIcon={App_url.img.RadioCircleIcon} />}
                                                </div>
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            {(formData?.conclusion && status === "Completed") &&
                                <React.Fragment>
                                    <div className='status-hr'>
                                        <hr />
                                    </div>
                                    <div>
                                        <div className='status-note-content-box'>
                                            <Icon image attrIcon={App_url.img.CirclesTwoIcon} />
                                            <h6 className='title-font-2'>{formData?.conclusion}</h6>
                                        </div>
                                    </div>
                                </React.Fragment>
                            }
                            {(formData?.cancel_comment && status === "Cancelled") &&
                                <React.Fragment>
                                    <div className='status-hr'>
                                        <hr />
                                    </div>
                                    <div>
                                        <div className='status-note-content-box'>
                                            <Icon image attrIcon={App_url.img.NoteIcon} />
                                            <h6 className='title-font-2'>{formData?.cancel_comment}</h6>
                                        </div>
                                    </div>
                                </React.Fragment>
                            }
                            {(formData?.completed_comment && status === "Completed") &&
                                <React.Fragment>
                                    <div className='status-hr'>
                                        <hr />
                                    </div>
                                    <div>
                                        <div className='status-note-content-box'>
                                            <Icon image attrIcon={App_url.img.NoteIcon} />
                                            <h6 className='title-font-2'>{formData?.completed_comment}</h6>
                                        </div>
                                    </div>
                                </React.Fragment>
                            }
                        </div>
                    </div>
                    {(status === "Rescheduled" && !statusSaved && statusEdit) &&
                        <div className='status-edit-box'>
                            <h6 className='title-font'>{t("RESCHEDULE DATE TIME")}</h6>
                            <div className='status-edit'>
                                <ViewAppointmentStatus
                                    status={status}
                                    handleReScheduleData={handleReScheduleData}
                                    start_time={appointmentDetails?.[0]?.start_time}
                                    end_time={appointmentDetails?.[0]?.end_time}
                                />
                            </div>
                            <div className='status-save-cancel'>
                                <Button onClick={() => setStatusEdit(false)} className={"cancel-btn"} variant={"secondary"} size={"md"}>{t("Cancel")}</Button>
                                <Button onClick={() => callRescheduleAppointmentApi()} className={"save-btn"} variant={'primary'} size={"md"}>{t("Save")}</Button>
                            </div>
                        </div>
                    }
                    {(status === "Cancelled" && !statusSaved && statusEdit) &&
                        <div className='status-edit-box'>
                            <h6 className='title-font'>{t("NOTE")}</h6>
                            <div className='status-edit'>
                                <ViewAppointmentStatus
                                    status={status}
                                    formData={formData}
                                    onChange={onChange} />
                            </div>
                            <div className='status-save-cancel'>
                                <Button onClick={() => setStatusEdit(false)} className={"cancel-btn"} variant={"secondary"} size={"md"}>Cancel</Button>
                                <Button onClick={() => callCancelAppointmentApi()} className={"save-btn"} variant={'primary'} size={"md"}>Save</Button>
                            </div>
                        </div>
                    }

                    {(status === "Completed" && !statusSaved && statusEdit) &&
                        <div className='status-edit-box'>
                            <div className='status-box mb-2'>
                                <h6 className='title-font'>{t("CONCLUSION")}</h6>
                                <div className='status-info-box'>
                                    <div>
                                        <Dropdown className='status-dropdown' onSelect={handleConclusionSelect}>
                                            <Dropdown.Toggle variant="light" className='status-dropdown-toggle'>
                                                <div className='status-content-box'>
                                                    <Icon image attrIcon={App_url.img.CirclesTwoIcon} />
                                                    <h6 className='title-font-2'>{formData?.conclusion}</h6>
                                                </div>
                                                <Icon attrIcon={App_url.img.AngleRightIcon} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className='dropdown-menu'>
                                                {conclusionOptions && conclusionOptions.map(option => (
                                                    <Dropdown.Item key={option?.value} eventKey={option?.value} style={{ paddingRight: '8px' }}>
                                                        <div className='check_menu'>
                                                            <span className='title-font-2'>{option?.value}</span>
                                                            {formData?.conclusion === option?.value ? <Icon image attrIcon={App_url.img.RadioIcon} /> :
                                                                <Icon image attrIcon={App_url.img.RadioCircleIcon} />}
                                                        </div>
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>

                            <h6 className='title-font'>{t("NOTE")}</h6>
                            <div className='status-edit'>
                                <ViewAppointmentStatus
                                    status={status}
                                    formData={formData}
                                    onChange={onChange}
                                    handleStatusSelect={handleStatusSelect}
                                />
                            </div>
                            <div className='status-save-cancel'>
                                <Button onClick={() => setStatusEdit(false)} className={"cancel-btn"} variant={"secondary"} size={"md"}>{t("Cancel")}</Button>
                                <Button onClick={() => callCompletedAppointmentApi()} className={"save-btn"} variant={'primary'} size={"md"}>{t("Save")}</Button>
                            </div>
                        </div>
                    }

                    {/* {(status === "Scheduled" || (status === "Rescheduled") || (status === "Cancelled" && statusSaved)) &&  */}
                    <>
                        <div className='schedule-box'>
                            <h6 className='title-font'>{t("SCHEDULE")}</h6>
                            <div className='schedule-flex-box'>
                                <div className='schedule-content-box'>
                                    <Icon image attrIcon={App_url.img.CalendarIcon3} />
                                    <h6 className='title-font-2'>{t("Start Date")}: </h6>
                                    <h6 className='title-font-2'>{customerDetails && Utils.formatISOToFullDayMonthTime(appointmentDetails[0]?.start_time,t)}</h6>
                                </div>
                                <div className='schedule-content-box'>
                                    <Icon image attrIcon={App_url.img.CalendarIcon3} />
                                    <h6 className='title-font-2'>{t("End Date")}: </h6>
                                    <h6 className='title-font-2'>{customerDetails && Utils.formatISOToFullDayMonthTime(appointmentDetails[0]?.end_time,t)}</h6>
                                </div>
                            </div>
                        </div>


                        {/* <div className='travel-box'>
                            <h6 className='title-font'>TRAVEL TIME</h6>
                            <div className='travel-content-box'>
                                <Icon image attrIcon={App_url.img.ClockIcon} />
                                <h6 className='title-font-2'>1 hr, 30 min travel time</h6>
                            </div>
                        </div> */}

                        {(appointmentDetails?.[0]?.address || appointmentDetails?.[0]?.customer_name) &&
                            <div className='company-info-box'>
                                <h6 className='title-font'>{t("COMPANY INFORMATION")}</h6>
                                <div className='company-info-main-container'>
                                    {((appointmentDetails?.[0]?.customer_name && appointmentDetails?.[0]?.customer_number && appointmentDetails?.[0]?.customer_email)) &&
                                        <><div className='company-info-content-flex'>
                                            <div className='company-info-content-box'>
                                                <Icon image attrIcon={App_url.img.BuildingIcon2} />
                                                <h6 className='title-font-2'>{appointmentDetails?.[0]?.company_name}</h6>
                                            </div>
                                            <div className='company-info-content-box'>
                                                <Icon image attrIcon={App_url.img.PhoneIcon} />
                                                <h6 className='title-font-2'>{appointmentDetails?.[0]?.company_number}</h6>
                                            </div>
                                            <div className='company-info-content-box'>
                                                <Icon image attrIcon={App_url.img.EmailIcon2} />
                                                <h6 className='title-font-2'>{appointmentDetails?.[0]?.customer_email}</h6>
                                            </div>
                                        </div>
                                            <div className='company-info-hr'>
                                                <hr />
                                            </div></>}
                                    {appointmentDetails?.[0]?.location && <>
                                        <div className='company-info-address-flex'>
                                            <Icon image attrIcon={App_url.img.LocationIcon} />
                                            <h6 className='title-font-2'>{appointmentDetails?.[0]?.location}</h6>
                                        </div>
                                        <AnchorLink className='company-info-direction-flex' target={"_blank"} to={`https://www.google.com/maps/dir/?api=1&zoom=20&destination=${appointmentDetails?.[0]?.location}`}>
                                            <Icon onClick={'https://www.google.com'} image attrIcon={App_url.img.DirectionIcon} />
                                            <span onChange={`https://www.google.com`} className='company-direction-title'>{t("Direction")}</span>
                                        </AnchorLink>
                                    </>}
                                </div>
                            </div>
                        }

                        {(appointmentDetails?.[0]?.customer_name || appointmentDetails?.[0]?.customer_number || appointmentDetails?.[0]?.customer_email) &&
                            <div className="contact-person-info-box">
                                <h6 className='title-font'>{t("CONTACT PERSON INFORMATION")}</h6>
                                <div className='contact-person-info-content-flex'>
                                    {appointmentDetails?.[0]?.customer_name && <div className='contact-person-info-content-box'>
                                        <Icon image attrIcon={App_url.img.BuildingIcon2} />
                                        <h6 className='title-font-2'>{appointmentDetails?.[0]?.customer_name}</h6>
                                    </div>}
                                    {appointmentDetails?.[0]?.customer_number && <div className='contact-person-info-content-box'>
                                        <Icon image attrIcon={App_url.img.PhoneIcon} />
                                        <h6 className='title-font-2'>{appointmentDetails?.[0]?.customer_number}</h6>
                                    </div>}
                                    {appointmentDetails?.[0]?.customer_email && <div className='contact-person-info-content-box'>
                                        <Icon image attrIcon={App_url.img.EmailIcon2} />
                                        <h6 className='title-font-2'>{appointmentDetails?.[0]?.customer_email}</h6>
                                    </div>}
                                </div>
                            </div>}

                        {appointmentDetails?.[0]?.attendees?.length > 0 && <div className="invitees-info-box">
                            <h6 className='title-font'>{t("INVITEES")}</h6>
                            <div className='invitees-info-content-flex'>
                                {appointmentDetails?.[0]?.attendees?.map((invitee) => (
                                    <>
                                        <div className='invitees-info-content-box'>
                                            <Icon image attrIcon={App_url.img.UserIcon} />
                                            <h6 className='title-font-2'>{invitee?.name}</h6>
                                        </div>
                                    </>
                                ))}
                            </div>
                        </div>}

                        {appointmentDetails?.[0]?.is_reminder_on && <div className="alert-info-box">
                            <h6 className='title-font'>{t("ALERT")}</h6>
                            <div className='alert-info-content-flex'>
                                <div className='alert-info-content-box'>
                                    <Icon image attrIcon={App_url.img.AlertIcon2} />
                                    <h6 className='title-font-2'>{t("Alert")}({getAlertTime(appointmentDetails?.[0]?.reminder_minutes_before_start)}) </h6>
                                </div>
                                {appointmentDetails?.[0]?.is_second_reminder_on && <div className='alert-info-content-box'>
                                    <Icon image attrIcon={App_url.img.AlertIcon2} />
                                    <h6 className='title-font-2'>{t("Second Alert")} ({getAlertTime(appointmentDetails?.[0]?.reminder_minutes_second_start)})</h6>
                                </div>}
                            </div>
                        </div>}

                        {appointmentDetails?.[0]?.attachments?.length > 0 && <div className="attachments-info-box">
                            <h6 className='title-font'>{t("ATTACHMENTS")}</h6>
                            <div className='attachments-info-content-flex'>
                                {appointmentDetails?.[0]?.attachments?.map((attachment) => (
                                    <>
                                        <div className='attachments-info-content-box'>
                                            {/* <Icon image attrIcon={App_url.img.PdfIcon2} /> */}
                                            {Utils.getIconsByExtension(attachment?.name)}
                                            <h6 className='title-font-2'>{attachment?.name}</h6>
                                        </div>
                                    </>
                                ))}
                            </div>
                        </div>}

                        {appointmentDetails?.[0]?.notes && <div className='url-note-info-box'>
                            <h6 className='title-font'>{t("URL & NOTE")}</h6>
                            <div className='url-note-info-main-container'>
                                {/* <h6 className=' url-note-title-url font-2'>{t("www.appristine.com")}</h6> */}
                                <div className='url-note-info-hr'>
                                    <hr />
                                </div>
                                <h6 className='title-font-2' dangerouslySetInnerHTML={{ __html: appointmentDetails?.[0]?.notes }} ></h6>
                            </div>
                        </div>}
                    </>
                    {/* } */}

                </Modal.Body>
            </Scrollbar>
        </Modal>
    )
}
