/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import ContentLayout from '../../layout/ContentLayout'

export default function CustomersPage() {

  return (
    <ContentLayout>
      <div>Woking on cutomer page ...</div>
    </ContentLayout>
  )
}
