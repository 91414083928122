/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/global.css'
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store, { persistor } from './store/reduxStore';
import { PersistGate } from "redux-persist/es/integration/react";
import { BrowserRouter } from 'react-router-dom';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';
import axios from 'axios';
import { TranslationProvider } from './translate';

window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.Suspense fallback={<React.Fragment />} >
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <PersistGate loading={<div></div>} persistor={persistor}>
          <TranslationProvider>
            <BrowserRouter>
              <App />
              {/* <PageLoad /> */}
              
            </BrowserRouter>
          </TranslationProvider>
        </PersistGate>
      </Provider>
    </MsalProvider>
  </React.Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
