/* eslint-disable react/prop-types */
import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

const StatsCard = ({ data, backgroundColor, borderColor }) => {
    const options = {
        scales: {
            x: {
                display: false, // Hide X-axis labels
            },
            y: {
                display: false, // Hide Y-axis labels
                min: 0,
                max: 70,
                ticks: {
                    stepSize: 10,
                },
            },
        },
        plugins: {
            tooltip: {
                enabled: false,
            },
            legend: {
                display: false,
            },
        },
    };

    const chartData = {
        ...data,
        datasets: [
            {
                ...data.datasets[0],
                backgroundColor: backgroundColor,
                borderColor: borderColor,
                borderWidth: 2,
                fill: true,
                pointRadius: 0, 
                pointBorderWidth: 0,
            },
        ],
    };

    return (
        <div className="chart-container2">
            <Line data={chartData} options={options} />
        </div>
    );
};

export default StatsCard;
