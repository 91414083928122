export const ActionTypes = {
    SET_STORE_SELECTED_SERVICES_LIST:"SET_STORE_SELECTED_SERVICES_LIST",
    SET_STORE_GET_SERVICES_LIST:"SET_STORE_GET_SERVICES_LIST",
}


export const setStoreSelectedServicesList = (token) => {
    return {
        type: ActionTypes.SET_STORE_SELECTED_SERVICES_LIST,
        payload: token,
     }
}
export const setStoreGetServicesList = (token) => {
    return {
        type: ActionTypes.SET_STORE_GET_SERVICES_LIST,
        payload: token,
     }
}

