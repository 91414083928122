export const ActionTypes = {
    SET_STORE_CATEGORY_LIST:"SET_STORE_CATEGORY_LIST",
    SET_STORE_SERVICE_TYPE_LIST:"SET_STORE_SERVICE_TYPE_LIST",
    SET_STORE_CURRENT_CATEGORY:"SET_STORE_CURRENT_CATEGORY",
    SET_STORE_FREQUENCY_LIST:"SET_STORE_FREQUENCY_LIST",
    SET_STORE_ADD_GLASS_CLEANING:"SET_STORE_ADD_GLASS_CLEANING",
    SET_STORE_CALCULATION_LIST:"SET_STORE_CALCULATION_LIST",
    SET_STORE_CALCULATION_OVERVIEW_LIST:"SET_STORE_CALCULATION_OVERVIEW_LIST",
    SET_STORE_ROOM_LIST:"SET_STORE_ROOM_LIST",
    SET_STORE_DELETE_CALCULATION_OVERVIEW_LIST:"SET_STORE_DELETE_CALCULATION_OVERVIEW_LIST",
    SET_STORE_SERVICE_SUMMARY:"SET_STORE_SERVICE_SUMMARY",

}
export const setStoreCategoryList = (token) => {
    return {
        type: ActionTypes.SET_STORE_CATEGORY_LIST,
        payload: token,
     }
}
export const setStoreServiceTypeList = (token) => {
    return {
        type: ActionTypes.SET_STORE_SERVICE_TYPE_LIST,
        payload: token,
     }
}
export const setStoreCurrentCategory = (token) => {
    return {
        type: ActionTypes.SET_STORE_CURRENT_CATEGORY,
        payload: token,
     }
}
export const setStoreFrequencyList = (token) => {
    return {
        type: ActionTypes.SET_STORE_FREQUENCY_LIST,
        payload: token,
     }
}
export const setStoreAddGlassCleaning = (token) => {
    return {
        type: ActionTypes.SET_STORE_ADD_GLASS_CLEANING,
        payload: token,
     }
}
export const setStoreCalculationList = (token) => {
    return {
        type: ActionTypes.SET_STORE_CALCULATION_LIST,
        payload: token,
     }
}

export const setStoreCalculationOverviewList = (token) => {
    return {
        type: ActionTypes.SET_STORE_CALCULATION_OVERVIEW_LIST,
        payload: token,
     }
}
export const setStoreDeleteCalculationOverviewList = (token) => {
    return {
        type: ActionTypes.SET_STORE_DELETE_CALCULATION_OVERVIEW_LIST,
        payload: token,
     }
}
export const setStoreRoomList = (token) => {
    return {
        type: ActionTypes.SET_STORE_ROOM_LIST,
        payload: token,
     }
}
export const setStoreServiceSummary = (token) => {
    if(!token){
        return {
            type: ActionTypes.SET_STORE_SERVICE_SUMMARY,
            payload: {
                service: [],
                summary: []
            }
         }
    }
    return {
        type: ActionTypes.SET_STORE_SERVICE_SUMMARY,
        payload: token,
     }
}


