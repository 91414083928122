/* eslint-disable react/prop-types */
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import App_url from "../Common/Constants";
import Utils from "../Common/Utils";
import { setStoreDeviceID } from "../../store/modules/users_data/action";

export default function GuestLayout ({ children }){
    const { device_id } = useSelector(App_url.userDataReducers);
    const dispatch = useDispatch();

    const setAllState = useCallback(() => {
        if (!device_id) {
            const device = Utils.uuidv4();
            dispatch(setStoreDeviceID(device));
        }
    }, [device_id, dispatch]);

    useEffect(() => {
        setAllState();
    }, [setAllState]);
    return (
    <section className="mainloginwrps">
        {children}
    </section>
    )
}